/* eslint-disable no-unused-vars */
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import React, { useState } from 'react';
import { settingsService } from '../_services';
import { NOTIFICATION_LABELS } from './SettingsLabels';
import { ToggleWithHeading } from './UtilsInSettings';

export function NotificationSettings() {
  const [query, setQuery] = useState({ select: ['name', 'labels'], name: 'notification-settings' });

  const {
    data: notificationSettingsData,
    error,
    isLoading,
    isSuccess,
  } = useQuery({
    queryKey: ['notification-settings'],
    queryFn: () => settingsService.settingsGet(query),
    select: (data) => {
      return data?.data[0];
    },
  });

  return (
    <section className='common_contain'>
      {isLoading && <div>Loading...</div>}
      {isSuccess && <NotificationForm initialData={notificationSettingsData?.labels} />}
    </section>
  );
}

const NotificationForm = ({ initialData }) => {
  const queryClient = useQueryClient();

  const [notificationSettings, setNotificationSettings] = useState(
    initialData || NOTIFICATION_LABELS,
  );

  const handleToggle = (id, value) => {
    setNotificationSettings((prevLabels) =>
      prevLabels.map((label) => (label.id === id ? { ...label, value: !value } : label)),
    );

    setNotificationSettings((prevLabels) => {
      handleMutate(prevLabels);
      return prevLabels;
    });
  };

  // notification settings update
  const handleSettingUpdate = (data) => {
    let newQuery = {};
    newQuery.labels = data;
    newQuery.name = 'notification-settings';

    settingsService.settingsAdd(newQuery);
    return newQuery;
  };

  // --react query fot mutation function
  const { mutate: handleMutate, isError } = useMutation({
    mutationFn: handleSettingUpdate,

    onSuccess: (data) => {
      queryClient.setQueryData(['notification-settings'], (old) => {
        let oldData = { ...old };
        oldData.data[0] = data;
        return oldData;
      });
    },
  });

  return (
    <>
      {notificationSettings?.map((NOTIFICATION) => {
        if (NOTIFICATION.type === 'toggle') {
          return (
            <ToggleWithHeading
              key={NOTIFICATION?.id}
              id={NOTIFICATION?.id}
              handleToggle={() => handleToggle(NOTIFICATION?.id, NOTIFICATION.value)}
              isChecked={NOTIFICATION.value}
              heading={NOTIFICATION.label}
            />
          );
        }
      })}
    </>
  );
};
