/* eslint-disable no-unused-vars */
import { X } from 'lucide-react';
import React, { useEffect, useState } from 'react';
import ReactSelect from 'react-select';
import { Button } from '../_components/CustomButton';
import { CustomDropdownIndicator } from '../_components/SelectionDropDownIcon';
import { alertConstants, COLOR_LIST, CommonDropDownStyle } from '../_constants';
import { labelService } from '../_services/label.service';
import { LabelState } from '../Utils';
import { Cancel, Tick } from '../Utils/SvgIcons';

export function LabelAdd({ id, handleClose = () => {}, afterAdd = () => {} }) {
  const [isLoading, setIsLoading] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [error, setError] = useState({ message: '' });
  const [label, setLabel] = useState(LabelState);
  console.log(id);
  const [query, setQuery] = useState({
    select: ['id', 'name', 'text_color', 'label_color'],
  });

  useEffect(() => {
    if (id && id != '0') {
      query.id = id;
      getAllLabel(query);
    }
  }, [id]);

  /**
   * Get Label
   * */
  const getAllLabel = async (queryTemp) => {
    let queryNew = queryTemp ? queryTemp : query;

    setQuery(queryNew);
    setIsLoading(true);

    try {
      const org = await labelService.labelGet(queryNew);
      if (org?.data?.length) {
        setLabel({
          id: org.data[0]?.id,
          name: org.data[0]?.name,
          color: org.data[0]?.label_color
            ? { label_color: org.data[0]?.label_color, text_color: org.data[0]?.text_color }
            : null,
        });
      }
    } catch (error) {
      setError({
        message: error?.message || alertConstants.SERVER_ERROR,
      });
    } finally {
      setIsLoading(false);
    }
    console.log(error);
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setLabel({
      ...label,
      [name]: value,
    });
  };

  const handleColorChange = (name, value) => {
    setLabel({
      ...label,
      [name]: value,
    });
  };

  console.log(label, 'looppp');

  const handleSubmit = async (event) => {
    event.preventDefault();

    setIsLoading(true);
    setSubmitted(true);

    if (label?.name.toString().trim() === '' || !label?.color) {
      setIsLoading(false);
      return null;
    }

    const payload = {
      id: label?.id,
      name: label?.name,
      label_color: label?.color?.label_color,
      text_color: label?.color?.text_color,
    };
    console.log(payload, 'looppp');

    try {
      await labelService.labelAdd(payload).then((data) => {
        afterAdd({
          id: data?.data?.id,
          name: data?.data?.name,
          label_color: data?.data?.label_color,
          text_color: data?.data?.text_color,
        });
        setSubmitted(false);
      });
      handleClose();
    } catch (error) {
      setError({
        message: error?.message || alertConstants.SERVER_ERROR,
      });
    } finally {
      setIsLoading(false);
      setSubmitted(false);
      setLabel(LabelState);
    }
  };

  // Custom Styles for ReactSelect
  const customStyles = {
    option: (styles, { data }) => ({
      ...styles,
      display: 'flex',
      alignItems: 'center',
      padding: 10,
    }),
    // singleValue: (styles, { data }) => ({
    //   ...styles,
    //   display: 'flex',
    //   alignItems: 'center',
    // }),
  };

  // Custom components for color rendering
  const customSingleValue = ({ data }) => (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <span
        style={{
          height: 15,
          width: 15,
          borderRadius: '50%',
          backgroundColor: data.label_color,
          // display: 'inline-block',
          marginRight: 10,
        }}
      ></span>
      {data.label_color}
    </div>
  );

  const customOption = (props) => {
    const { data, innerRef, innerProps } = props;
    return (
      <div
        ref={innerRef}
        {...innerProps}
        style={{
          display: 'flex',
          alignItems: 'center',
          padding: 10,
          cursor: 'pointer',
        }}
      >
        <span
          style={{
            height: 15,
            width: 15,
            borderRadius: '50%',
            backgroundColor: data.label_color,
            display: 'inline-block',
            marginRight: 10,
          }}
        ></span>
        {data.label_color}
      </div>
    );
  };

  return (
    <div className='modal-end-container'>
      <div className=' modal_header'>
        <div className=' modal-title '>{id ? 'Edit Labels' : 'Add Labels'}</div>
        <div
          className='pointer'
          onClick={() => {
            handleClose();
          }}
        >
          <X size={16} color='#87909E' />
        </div>
      </div>
      <div className='modal-end-content'>
        <form className='d-flex flex-column gap-2' onSubmit={handleSubmit}>
          <div className='row my-2'>
            <div>
              <label htmlFor='name' className='label_input'>
                Label Name<span className='field-required'> *</span>
              </label>
              <input
                type='text'
                className='input-box'
                id='name'
                name='name'
                value={label?.name}
                placeholder='Enter label name '
                onChange={(event) => {
                  handleChange(event);
                }}
                autoFocus
              />
              {submitted && !label?.name.trim() && (
                <div className='help-block'>Label name is required</div>
              )}
            </div>
          </div>

          {/* <div className='row mb-4 '>
            <div className='col-md-6  '>
              <ColorPicker
                heading={'Label Color'}
                value={label?.label_color}
                handleChange={(e) => handleColorChange('label_color', e.target.value)} // Pass the handleColorChange function directly
                id='label_color'
                onBlur={() => {}}
              />
            </div>
            <div className='col-md-6'>
              <ColorPicker
                heading={'Text Color'}
                value={label?.text_color}
                handleChange={(e) => handleColorChange('text_color', e.target.value)} // Pass the handleColorChange function directly
                id='text_color'
                onBlur={() => {}}
              />
            </div>
          </div> */}

          <div>
          <label className='label_input'>
                Label Color<span className='field-required'> *</span>
              </label>
            <ReactSelect
              name='color'
              placeholder={`Choose label color `}
              styles={{ ...CommonDropDownStyle, ...customStyles }}
              components={{
                DropdownIndicator: CustomDropdownIndicator,
                // SingleValue: customSingleValue,
                Option: customOption,
              }} // Use the custom dropdown indicator here
              getOptionLabel={(option) => {
                return option?.label_color;
              }}
              // menuPortalTarget={document.getElementById('MODAL')}
              getOptionValue={(option) => {
                return option?.label_color;
              }}
              // onInputChange={(inputString) => {
              //   setClientSearch(inputString);
              // }}
              // filterOption={null}
              // inputValue={clientSearch}
              options={COLOR_LIST}
              onChange={(selectedOption) => {
                handleChange({
                  target: {
                    name: 'color',
                    value: selectedOption,
                  },
                });
              }}
              value={label?.color}
              // isLoading={isClientLoading}
              // isClearable
            />
             {submitted && !label?.color && (
                <div className='help-block'>Color selection required</div>
              )}
          </div>
        </form>
      </div>

      <div className='modal_footer'>
        <div className=' d-flex justify-content-end gap-2'>
          <Button.Container
            className={'cancel-btn'}
            type='reset'
            handleOk={() => {
              handleClose();
            }}
          >
            <Cancel />
            <Button.Title title={'Cancel'} />
          </Button.Container>

          <Button.Container handleOk={(e) => handleSubmit(e)} type='submit' isDisabled={isLoading}>
            <Tick /> <Button.Title title={'Save'} />
            <Button.Loading isLoading={isLoading} />
          </Button.Container>
        </div>
      </div>
    </div>
  );
}
