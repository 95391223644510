import React from 'react';
function FileDragDrop({ isDragOver = false }) {
  if (!isDragOver) {
    return null;
  }

  return <div className={`drag-drop-container `}>Drop Here</div>;
}

export default FileDragDrop;
