import { RT } from '../../_constants';

export const selectOptionValues = [
  {
    value: 'POST',
    label: 'POST',
  },
  {
    value: 'GET',
    label: 'GET',
  },
  {
    value: 'PUT',
    label: 'PUT',
  },
  {
    value: 'DELETE',
    label: 'DELETE',
  },
];

// whatsapp config tabs - header and body
export const WHATSAPP_CONFIG_TABS = [
  {
    name: 'Headers',
    value: 'headers',
    path: `/${RT.SETTINGS}/${RT.CONFIG}/${RT.WHATSAPP}?id=${RT.WHATSAPP}&&sub=headers`,
  },
  {
    name: 'Body',
    value: 'body',
    path: `/${RT.SETTINGS}/${RT.CONFIG}/${RT.WHATSAPP}?id=${RT.WHATSAPP}&&sub=body`,
  },
];

// methods
export const Methods = [
  { label: 'POST', value: 'POST' },
  { label: 'GET', value: 'GET' },
];

// headers head - key, value
export const headersHeadings = [{ name: 'Key' }, { name: 'Value' }, { name: '' }];

// whatsapp config tabs - header and body
export const EMAIL_CONFIG_TABS = [
  {
    name: 'SMTP',
    value: 'smtp',
    path: `/${RT.SETTINGS}/${RT.CONFIG}/${RT.GMAIL}?id=${RT.GMAIL}&&sub=smtp`,
  },
  {
    name: 'API',
    value: 'api',
    path: `/${RT.SETTINGS}/${RT.CONFIG}/${RT.GMAIL}?id=${RT.GMAIL}&&sub=api`,
  },
];

export const defaultGmailState = {
  smtp: {
    ip: '',
    port: '',
    mail: '',
    username: '',
    password: '',
  },
  api: {
    headers: [{ key: '', value: '' }],
    body: '',
  },
};

export const defaultWhatsAppState = {
  method: 'POST',
  headers: [{ key: '', value: '' }],
  body: '',
};
