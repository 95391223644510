import { apiConstants } from '../_constants';
import { authHeader, handleResponse, logout } from '../_helpers';

export const authService = {
  login,
  logout,
  profile,
  changePassword,
  sentOtpMail,
  verifyOtp,
  newPassword,
  whoAmI,
  userValidate,
  tokenRemove,
  loginAdvanced,
  registerNewUser,
};

function tokenRemove(data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(data),
  };

  return fetch(apiConstants.apiUrl + '/user-logout', requestOptions).then(handleResponse);
}
function userValidate(data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(data),
  };

  return fetch(apiConstants.apiUrl + '/user-validate', requestOptions).then(handleResponse);
}
function login(data) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(data),
  };

  return fetch(apiConstants.apiUrl + '/login-advanced', requestOptions)
    .then(handleResponse)
    .then((user) => {
      // login successful if there's a jwt token in the response
      if (user.data.token) {
        // store user details and jwt token in local storage to keep user logged in between page refreshes
        localStorage.setItem('user', JSON.stringify(user.data));
        localStorage.setItem('company_id', user.data.company_id);
      }
      return user;
    });
}

function loginAdvanced(data) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(data),
  };

  return fetch(apiConstants.apiUrl + '/login-advanced', requestOptions)
    .then(handleResponse)
    .then((user) => {
      // login successful if there's a jwt token in the response
      if (user.data.token) {
        // store user details and jwt token in local storage to keep user logged in between page refreshes
        localStorage.setItem('user', JSON.stringify(user.data));
        localStorage.setItem('company_id', user.data.company_id);
      }
      return user;
    });
}

function whoAmI() {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
  };
  return fetch(apiConstants.apiUrl + '/whoami', requestOptions).then(handleResponse);
}
function profile() {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
  };
  return fetch(apiConstants.apiUrl + '/profile', requestOptions).then(handleResponse);
}

async function changePassword(data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(data),
  };
  return fetch(apiConstants.apiUrl + '/change_password', requestOptions).then(handleResponse);
}
async function sentOtpMail(data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(data),
  };
  return fetch(apiConstants.apiUrl + '/forget-password', requestOptions).then(handleResponse);
}
async function verifyOtp(data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(data),
  };
  return fetch(apiConstants.apiUrl + '/verify_otp', requestOptions).then(handleResponse);
}
async function newPassword(data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(data),
  };
  return fetch(apiConstants.apiUrl + '/reset_password', requestOptions).then(handleResponse);
}

async function registerNewUser(data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(data),
  };
  return fetch(apiConstants.apiUrl + '/new-user-register', requestOptions)
    .then(handleResponse)
    .then((user) => {
      // login successful if there's a jwt token in the response
      if (user.data.token) {
        // store user details and jwt token in local storage to keep user logged in between page refreshes
        localStorage.setItem('user', JSON.stringify(user.data));
        localStorage.setItem('company_id', user.data.company_id);
      }
      return user;
    });
}
