import moment from 'moment';
import React from 'react';
import { COLOR_CONSTANT, VISIBLE_EVENTS } from '../_constants';
import { Images } from '../Utils';

/**
 * CalendarEventView - returns every event details respect to the calendar
 * @argument(Object) event - event object
 */
export const CalendarEventView = ({ event = {} }) => {
  console.log({ CalendarEventView: event });

  return {
    title: <EventTitle title='Reminde on Call meeting on 1234' type={VISIBLE_EVENTS.REMINDER} />,
    startDate: '15/08/2024',
    endDate: '16/08/2024',
    startTime: '12:30:00',
    endTime: '16:00:00',
    bg_color: COLOR_CONSTANT.red.bg,
    draggable: false,
  };
};

/**
 * EventTitle - returns the event title respective to their colors by type
 * @param {string} title - title of the event
 * @param {string} type - type of the event
 * @returns the customized title component
 */
export const EventTitle = ({ title, type }) => {
  let color = '';
  let icon = '';
  if (VISIBLE_EVENTS.REMINDER === type) {
    color = COLOR_CONSTANT.red.text;
    icon = Images.clock;
  } else if (VISIBLE_EVENTS.SUBTASK === type) {
    color = COLOR_CONSTANT.blue.text;
    icon = Images.subtask;
  } else if (VISIBLE_EVENTS.TODO_TASK === type) {
    color = COLOR_CONSTANT.green.text;
    icon = Images.tick;
  }

  return (
    <div className='calendar__event__title_wrap'>
      <div className='calendar__event__title' style={{ color: color }}>
        <img className='calendar-icon' src={icon} /> {title}
      </div>
    </div>
  );
};

/**
 * getTypeOfEvent - returns event type
 * @param {Object} event - event details
 */
export const getTypeOfEvent = (event) => {
  if (event?.reminder) {
    return VISIBLE_EVENTS.REMINDER;
  } else if (event?.sub_task) {
    return VISIBLE_EVENTS.SUBTASK;
  } else if (event?.log) {
    return VISIBLE_EVENTS.LOG;
  } else if (event?.event) {
    return VISIBLE_EVENTS.EVENT;
  } else return VISIBLE_EVENTS.TASK;
};

/**
 * getColorOfEvent - returns color according to the type
 * @param {Object} event - event object
 * @returns returns the event background color
 */
export const getColorOfEvent = (event) => {
  if (event?.reminder) {
    return COLOR_CONSTANT.red.bg;
  } else if (event?.sub_task) {
    return COLOR_CONSTANT.blue.bg;
  } else if (event?.log) {
    return COLOR_CONSTANT.violet.bg;
  } else if (event?.event) {
    return COLOR_CONSTANT.orange.bg;
  } else return COLOR_CONSTANT.green.bg;
};

export const getEventClass = (event) => {
  return event?.reminder
    ? 'event_reminder'
    : event?.sub_task
    ? 'event_sub_task'
    : event?.log
    ? 'event_log'
    : event?.event
    ? ''
    : 'event_task';
};
/**
 * getDateWithTime - returns date and time by milliseconds
 * @param {bigint} milliseconds - milliseconds value
 *  returns date and time by milliseconds
 */
export const getDateWithTime = (milliseconds) => {
  const date = moment(parseInt(milliseconds)).format('DD/MM/YYYY');
  const time = moment(parseInt(milliseconds)).format('HH:mm:ss');
  return { date, time };
};

/**
 * getDateWithTimeSubTask - returns date and time by milliseconds
 * @param {string} dateString - "2024-08-09 06:33:00" format
 * @returns date and time
 */
export const getDateWithTimeSubTask = (dateString) => {
  const dateObject = moment(dateString, 'YYYY-MM-DD HH:mm:ss');
  const date = dateObject.format('DD/MM/YYYY');
  const time = dateObject.format('HH:mm:ss');
  return { date, time };
};

/**
 * getDateTimeLocalBySting - returns a datetime-local format by date and time
 * @param {String} date - date in DD/MM/YYYY format
 * @param {String} time - time in HH:mm:ss
 * @returns  datetime-local - date with time
 */
export const getDateTimeLocalBySting = (date, time) => {
  const dateTimeLocal = moment(`${date} ${time}`, 'DD/MM/YYYY HH:mm:ss').format(
    'YYYY-MM-DDTHH:mm:ss',
  );
  return dateTimeLocal;
};

// Function to flatten the tasks and add user_id
export function processTasks(data) {
  let result = [];

  // Iterate over each entry in the data array
  data?.forEach((entry) => {
    // If taskDetails is non-empty, process it
    if (entry?.taskDetails?.length > 0) {
      entry?.taskDetails?.forEach((task) => {
        // Add user_id to each task and push to result array
        result.push({
          ...task, // Copy existing task properties
          user_id: entry.userData.user_id, // Add user_id from userData
        });
      });
    }
  });

  return result; // Flattened array of tasks with user_id
}

export const getArrayParam = (searchParams, param) => {
  const value = searchParams.getAll(param);
  return value.length > 0 ? value : null;
};

export const CALENDAR_FROM = {
  TASK: 'task',
  EVENT: 'event',
  PROJECT: 'project',
  CALENDAR: 'calendar',
};

export const currentTime = () => {
  // Import moment.js if using in a project
  // import moment from 'moment';

  // Get the current time
  const now = moment();

  // Get the current hour and minutes since midnight
  const hours = now.hours();
  const minutes = now.minutes();
  const seconds = now.seconds();
  const milliseconds = now.milliseconds();

  // Calculate total milliseconds from midnight
  const totalMilliseconds =
    hours * 60 * 60 * 1000 + minutes * 60 * 1000 + seconds * 1000 + milliseconds;

  return totalMilliseconds;
};
