import { useMutation, useQuery } from '@tanstack/react-query';
import { Pin } from 'lucide-react';
import React, { useContext, useEffect } from 'react';
import { Button } from '../_components/CustomButton';
import ToolTip from '../_components/Tooltip/ToolTip';
import { favoriteService } from '../_services/favourite.service';
import { GlobalContext } from '../Utils';
import { favoriteSelect, favTypes } from './favCostants';

export function Favorite({ taskId = null, projectId = null, favType = favTypes.TASK }) {
  const { makeAlert, globalSettingsRefetch } = useContext(GlobalContext);

  // get favorite by task / project
  const {
    data: favoriteData,
    error: favoriteError,
    refetch: favoriteRefetch,
  } = useQuery({
    queryKey: ['favoriteItem', taskId, projectId],
    queryFn: async () => {
      const query = {
        select: favoriteSelect,
        type: favType,
      };
      if (parseInt(taskId)) query.task_id = taskId;
      if (parseInt(projectId)) query.project_id = projectId;
      return await favoriteService.favoriteGet(query);
    },
    select: (data) => {
      return data?.data?.[0];
    },
    enabled: Boolean(taskId || projectId),
  });

  useEffect(() => {
    if (favoriteError?.message) {
      makeAlert(favoriteError?.message);
    }
  }, []);

  // add or remove favorite
  const handleFavorite = async () => {
    try {
      const dataObject = {};
      if (parseInt(taskId)) dataObject['task_id'] = taskId; // task id if available
      if (parseInt(projectId)) dataObject['project_id'] = projectId; // project id if available
      if (favoriteData?.id) dataObject['id'] = favoriteData?.id; // update by id
      dataObject['status'] = favoriteData?.status == 1 ? 0 : 1; // to change or unpin fav
      dataObject['type'] = favType; // default task in task pin
      console.log({ dataObject });
      await favoriteService.favoriteAdd(dataObject);
      globalSettingsRefetch();
    } catch (e) {
      makeAlert(e?.message);
    } finally {
      favoriteRefetch(); // fetch after update
    }
  };

  //-- react query fot mutation function
  const { mutate: handleMutate, isPending } = useMutation({
    mutationFn: handleFavorite,
  });

  return (
    <ToolTip tooltipText={favoriteData?.status == 1 ? 'Unpin' : 'Pin to menu bar'} isModern>
      <Button.Container
        isDisabled={isPending}
        className={'tab-click inline-task-item-container custom-icon pin-container '}
        handleOk={handleMutate}
      >
        <Pin
          height={18}
          width={18}
          color={favoriteData?.status == 1 ? 'var(--text-active)' : 'var(--text-inactive)'}
        />
      </Button.Container>
    </ToolTip>
  );
}
