import { useEffect, useState } from 'react';

const useElapsedTime = (startTimeInMillis) => {
  const [elapsedTime, setElapsedTime] = useState({ hours: 0, minutes: 0, seconds: 0 });

  const updateElapsedTime = () => {
    const startTime = new Date(startTimeInMillis);
    const now = new Date();
    const elapsedMillis = now - startTime;
    const hours = Math.floor(elapsedMillis / (1000 * 60 * 60));
    const minutes = Math.floor((elapsedMillis % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((elapsedMillis % (1000 * 60)) / 1000);
    setElapsedTime({ hours, minutes, seconds });
  };

  useEffect(() => {
    // Update elapsed time immediately
    updateElapsedTime();

    // Update elapsed time every second
    const intervalId = setInterval(updateElapsedTime, 1000);

    // Cleanup the interval on component unmount
    return () => clearInterval(intervalId);
  }, [startTimeInMillis]);

  return elapsedTime;
};

export default useElapsedTime;
