import { ChevronLeft, ChevronRight } from 'lucide-react';
import React, { useState } from 'react';
import './Calendar.css';
import DateView from './DateView';
import MonthView from './MonthView';
import { DownArrow } from './Utils/Icons';
import { months } from './Utils/misc';
import YearView from './YearView';

export function Calendar({ darkMode = true, onSelect, width = 300, pattern = {} }) {
  const gridCol = width < 300 ? 1 : width > 450 ? 4 : 3;
  const date = new Date();

  const [status, setStatus] = useState('initial');
  const [selectedDate, setSelectedDate] = useState({
    day: date.getDay() + 1,
    month: date.getMonth() + 1,
    year: date.getFullYear(),
  });
  const dateView = status === 'initial';
  const monthView = status === 'month';
  const yearView = status === 'year';
  // const isCalendarOpen = status !== '';

  const [monthIndex, setMonthIndex] = useState(date.getMonth());
  const [year, setYear] = useState(date.getFullYear());

  const month = months[monthIndex];

  const incrementMonthAndYear = () => {
    yearView && setYear((prev) => prev + 10);

    monthView && setYear((prev) => prev + 1);

    dateView &&
      setMonthIndex((prev) => {
        if (prev >= months.length - 1) {
          setYear((prev) => prev + 1);
          return 0;
        }
        return prev + 1;
      });
  };

  const decrementMonthAndYear = () => {
    yearView && setYear((prev) => prev - 10);
    monthView && setYear((prev) => prev - 1);
    dateView &&
      setMonthIndex((prev) => {
        if (prev <= 0) {
          setYear((prev) => prev - 1);
          return months.length - 1;
        }
        return prev - 1;
      });
  };

  return (
    <div style={{ position: 'relative', minWidth: 360 }}>
      <div
        className={`calendar-container ${darkMode ? 'dark-bg' : 'light-bg'}`}
        style={{ width: `min(${width}px, 100%)` }}
      >
        <div className='top-bar ms-2 me-3'>
          <a
            onClick={() => {
              if (yearView) return;
              setStatus((prev) => {
                if (prev === 'initial') return 'month';
                return 'year';
              });
            }}
            style={yearView ? { cursor: 'default', opacity: '0.5' } : {}}
            className={`month-year-picker month-year-head  d-flex align-items-center gap-6 ${
              yearView ? 'disabled' : ''
            }`}
          >
            {/* <RecurCalendarIcon /> */}
            <div>
              <div>
                {dateView && `${month}`} {!yearView && year}
              </div>
              <div> {yearView && `${year - (year % 10)}-${year - (year % 10) + 9}`}</div>
            </div>
            <DownArrow />
          </a>
          <div className='d-flex gap-5px'>
            <button onClick={decrementMonthAndYear} className='left-arrow'>
              <ChevronLeft size={30} color='#87909E' />
            </button>
            <button onClick={incrementMonthAndYear} className='right-arrow'>
              <ChevronRight size={30} color='#87909E' />
            </button>
          </div>
        </div>
        {dateView && (
          <DateView
            onSelect={onSelect}
            monthIndex={monthIndex}
            year={year}
            setStatus={setStatus}
            selectedDate={selectedDate}
            setSelectedDate={setSelectedDate}
            width={width}
            pattern={pattern}
          />
        )}
        {monthView && (
          <MonthView
            selectedMonth={monthIndex}
            setMonthIndex={setMonthIndex}
            setStatus={setStatus}
            setSelectedDate={setSelectedDate}
            gridCol={gridCol}
          />
        )}
        {yearView && (
          <YearView
            year={year}
            setSelectedDate={setSelectedDate}
            setYear={setYear}
            setStatus={setStatus}
            gridCol={gridCol}
          />
        )}
      </div>
    </div>
  );
}
