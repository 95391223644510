export function updateTaskOrder(prevTask, currentTask, postTask) {
  let updatedCurrentTask = { ...currentTask };
  let updatedPrevTask = prevTask ? { ...prevTask } : null;
  let updatedPostTask = postTask ? { ...postTask } : null;

  if (prevTask && postTask) {
    if (prevTask.task_order_time === postTask.task_order_time) {
      updatedPrevTask.task_order_time = prevTask.task_order_time + 1;
    }
    updatedCurrentTask.task_order_time = (prevTask.task_order_time + postTask.task_order_time) / 2;
  } else if (!prevTask && postTask) {
    updatedCurrentTask.task_order_time = postTask.task_order_time + 1;
  } else if (prevTask && !postTask) {
    updatedCurrentTask.task_order_time = prevTask.task_order_time - 1;
  }

  return { updatedCurrentTask, updatedPrevTask, updatedPostTask };
}

// export function updateTaskOrder(prevTask, currentTask, postTask) {
//   console.log(prevTask, currentTask, postTask, 'orderSwitch2');

//   let updatedCurrentTask = { ...currentTask };
//   let updatedPrevTask = prevTask ? { ...prevTask } : null;
//   let updatedPostTask = postTask ? { ...postTask } : null;

//   if (prevTask && postTask) {
//     if (prevTask.id == currentTask.id) {
//       updatedCurrentTask.task_order_time = postTask.task_order_time + 1;
//     } else {
//       if (prevTask.task_order_time === postTask.task_order_time) {
//         updatedPrevTask.task_order_time = prevTask.task_order_time + 1;
//       }
//       updatedCurrentTask.task_order_time =
//         (prevTask.task_order_time + postTask.task_order_time) / 2;
//     }
//   } else if (!prevTask && postTask) {
//     updatedCurrentTask.task_order_time = postTask.task_order_time - 1;
//   } else if (prevTask && !postTask) {
//     updatedCurrentTask.task_order_time = prevTask.task_order_time + 1;
//   }

//   return { updatedCurrentTask, updatedPrevTask, updatedPostTask };
// }
