import React, { useEffect, useState } from 'react';
import {  useLocation, useSearchParams } from 'react-router-dom';
import { LoadingImg, Pagination } from '../_components';
import { demoService } from '../_services';

const DemoList = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [demoData, setDemoData] = useState({ rows: [] });
  const [demoRows, setDemoRows] = useState([]);
  const [searchParams, setSearchParams] = useSearchParams();
  const location = useLocation();
  const [query, setQuery] = useState({
    select: ['id', 'name', 'description'],
    pageVo: {
      pageNo: 1,
      noOfItems: 2,
    },
  });

  useEffect(() => {
    console.log('Effect triggered');
    query.pageVo.pageNo = searchParams.get('pageNo') ? parseInt(searchParams.get('pageNo')) : 1;
    query.pageVo.noOfItems = searchParams.get('noOfItems')
      ? parseInt(searchParams.get('noOfItems'))
      : 10;
    getAllDemo(query.pageVo.pageNo, query);
  }, [location]);

  /**
   * Get all demos
   **/
  const getAllDemo = (pageNo, queryTemp) => {
    let queryNew = queryTemp ? queryTemp : query;
    queryNew.pageVo.pageNo = pageNo;
    setQuery(queryNew);
    setIsLoading(true);

    demoService.demoList(queryNew).then(
      (res) => {
        setIsLoading(false);
        setListAndPagination(res.data);
      },
      () => {
        setIsLoading(false);
      },
    );
  };

  /**
   *
   *  set pagination and list rows
   */
  const setListAndPagination = (demoData) => {
    let demoList = [];
    for (let j = 0; demoData && demoData.rows && j < demoData.rows.length; j = j + 1) {
      demoList.push(
        <tr key={j}>
          <td>{(demoData.page - 1) * demoData.limit + j + 1}</td>
          <td>{demoData.rows[j].name} </td>
          <td className='eventCell  eventView'>
            <a href={'/demo/' + demoData.rows[j].id}>view</a>
          </td>
          <td className='eventCell  event Edit'>
            <a href={'/demo-add/' + demoData.rows[j].id}>Edit</a>
          </td>
        </tr>,
      );
    }
    setDemoRows(demoList);
    setDemoData(demoData);
  };
  return (
    <div className='DemoList'>
      <div className='col-md-12'>
        <div className='card regular-table-with-color'>
          <div className='card-header page-header '>
            <div className='row'>
              <div className='col-md-8'>
                <h4> Demo List</h4>
              </div>
              <div className='col-md-4 hr_right'>
                <a className='btn btn-primary float-right' href='/demo-add/0'>
                  <i className='fa fa-envelope' />
                  <span> Add Demo</span>
                </a>
              </div>
            </div>
          </div>
          <div className='card-body  table-responsive main-card-body '>
            {!isLoading && (
              <table className='table table-hover'>
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Name</th>
                    <th className='eventCell  eventView' />
                    <th className='eventCell  eventEdit' />
                  </tr>
                </thead>

                <tbody>{demoRows}</tbody>
              </table>
            )}
            {!!isLoading && <LoadingImg />}
            {demoData && demoData.rows && demoData.rows.length === 0 && (
              <p className='margin-top-20 text-center'> No result found </p>
            )}
          </div>
          <div className='card-footer'>
            <Pagination
              pageOnClick={(data) => {
                // getAllDemo(data);
                searchParams.set('pageNo', data);
                setSearchParams(searchParams);
              }}
              demoData={demoData}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export { DemoList };
