import React from 'react';
import useObserver from '../../Hook/useObserver';
import { CustomEmptyContainer } from '../../Task/TaskComponents/CustomInputs';
import { TASK_LIST_EMPTY_MESSAGE } from '../../Task/taskConstants';
import { LoadingIcon, SubTaskIcon } from '../../Utils/SvgIcons';
import { ActivityContents } from './ActivityContents';

function ActivityReport({
  activityLog = [],
  isFetchingNextPage,
  fetchNextPage,
  hasNextPage,
  isList = false,
}) {
  // observer custom hook for observe the div with reference visible and hasNextPage prop true then fetchNext call
  const { observerRef } = useObserver({
    onIntersection: () => hasNextPage && fetchNextPage(),
    dependency: [hasNextPage],
  });

  return (
    <div
      className='container-fluid work-activity'
      style={{ overflow: 'auto', maxHeight: isList ? 'calc(85vh - 150px)' : 'calc(60vh - 150px)' }}
    >
      {activityLog?.length > 0 && activityLog?.[0]?.data?.count > 0 ? (
        activityLog?.map((page, index) => {
          return <ActivityContents key={index} data={page?.data?.rows} isList={isList} />;
        })
      ) : (
        //empty container
        <CustomEmptyContainer
          Icon={() => {
            return <SubTaskIcon />;
          }}
          text={TASK_LIST_EMPTY_MESSAGE.log_empty}
          disableAdd
        />
      )}
      {isFetchingNextPage && (
        //loader
        <div className='d-flex justify-content-center' style={{ height: '90px' }}>
          <LoadingIcon size={60} />
        </div>
      )}
      <div ref={observerRef} style={{ height: 5, width: 5 }}></div>
    </div>
  );
}

export default ActivityReport;
