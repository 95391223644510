/* eslint-disable no-unused-vars */
import { keepPreviousData, useQuery } from '@tanstack/react-query';
import React, { useState } from 'react';
import { createPortal } from 'react-dom';
import { useSearchParams } from 'react-router-dom';
import { Pagination } from '../_components';
import { Modal } from '../_components/Modal';
import { userService } from '../_services';

const UserList = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [query, setQuery] = useState({
    select: ['id', 'name', 'description', 'first_name'],
    pageVo: {
      pageNo: parseInt(searchParams.get('pageNo')) || 1,
      noOfItems: 9,
    },
    
  });

  const [modal, setmodal] = useState(false);

  const {
    data: userData,
    error,
    isLoading,
  } = useQuery({
    queryKey: ['user', query?.pageVo?.pageNo],
    queryFn: () => userService.userList(query),
    placeholderData: keepPreviousData,
  });

  const tableData = userData?.data?.rows?.map((user, index) => {
    return (
      <tr key={index}>
        <td>{(userData?.data?.page - 1) * userData?.data?.limit + index + 1}</td>
        <td>{user?.first_name} </td>
        <td className='eventCell  eventView'>
          <a href={'/user/' + user?.id}>view</a>
        </td>
        <td className='eventCell  event Edit'>
          <a href={'/userAdd/' + user?.id}>Edit</a>
        </td>
      </tr>
    );
  });

  const TopDiv = () => {
    return createPortal(
      <div style={{ position: 'absolute', height: 500, width: 500, background: 'red' }}>
        This is the top div
      </div>,
      document.body,
    );
  };

  const ModelOne = ({ children }) => {
    return (
      <div className='model_contain' onClick={() => setmodal(false)}>
        {children}
      </div>
    );
  };

  return (
    <div className='UserList'>
      <div className='col-md-12'>
        {modal && (
          <Modal.Container elementName='modal-content' handleClose={() => setmodal(false)}>
            <Modal.View>
              <div onClick={() => setmodal(false)}>asas</div>
            </Modal.View>
          </Modal.Container>
        )}
        <div className='card regular-table-with-color'>
          <div className='card-header page-header '>
            <div className='row'>
              <div onClick={() => setmodal(!modal)}>open</div>
              <div className='col-md-8'>
                <h4> User List</h4>
              </div>
              <div className='col-md-4 hr_right'>
                <a className='btn btn-primary float-right' href='/userAdd/0'>
                  <i className='fa fa-envelope' />
                  <span> Add User</span>
                </a>
              </div>
            </div>
          </div>
          <div className='card-body  table-responsive main-card-body '>
            <table className='table table-hover'>
              <thead>
                <tr>
                  <th>#</th>
                  <th>Name</th>
                  <th className='eventCell  eventView' />
                  <th className='eventCell  eventEdit' />
                </tr>
              </thead>

              <tbody>{tableData}</tbody>
            </table>

            {!!isLoading && <div>loading...</div>}
            {/* {userData && userData.rows && userData.rows.length === 0 && (
              <p className='margin-top-20 text-center'> No result found </p>
            )} */}
          </div>
          <div className='card-footer'>
            <Pagination
              pageOnClick={(data) => {
                setQuery((prev) => ({
                  ...prev,
                  pageVo: {
                    ...prev.pageVo,
                    pageNo: data,
                  },
                }));
                searchParams.set('pageNo', data);
                setSearchParams(searchParams);
              }}
              demoData={userData?.data}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export { UserList };
