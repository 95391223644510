/* eslint-disable no-unused-vars */
import { CableCar, EarthLock, GitFork, Plus, Zap } from 'lucide-react';
import React, { useMemo } from 'react';
import { Handle } from 'reactflow';
import { Modal } from '../_components';
import { NodeAddComponent } from './NodeAddComponent';

export function ConditionNode(props) {
  const { data, ...rest } = props;

  const [addModal, setAddModal] = React.useState(false);

  const labelText = data.label || 'NODE';
  const iconElement = <Zap size={18} />;

  const addIconNeed = data?.addIconNeed;

  console.log('data', data?.operation_details);

  let nodeData = data?.operation_details;

  const getConditionIcon = (id) => {
    console.log('ONE', id);
    if (id === 'project_id') {
      return (
        <EarthLock
          style={{ background: 'black', padding: '1px 3px 1px 3px', borderRadius: 4 }}
          size={16}
          color='white'
        />
      );
    }
  };

  const conditionIcon = useMemo(() => {
    return getConditionIcon(data?.operation_details?.condition?.id);
  }, [data?.operation_details?.condition?.id]);




  

  return (
    <>
      <div className='nd-container'>
        <div className='nd-header'>
          <CableCar className='nd-header-icon' size={14} />
          <span className='nd-header-text'>Condition</span>
        </div>

        <div>
          <div className='nd-content'>
            <GitFork className='nd-content-icon' size={16} />
            <span className='nd-content-text'>
              Check <strong>IF</strong>
            </span>
          </div>
          <div className='nd-divider'></div>
          <div className='nd-description'>
            <span className='nd-description-bold'>{nodeData?.condition?.label}</span> is{' '}
            <span className='nd-description-bold'>{nodeData?.condition_value?.label}</span>
          </div>
        </div>

        <Handle type='target' position='top' id='condition-input' className='nd-handle' />
        <Handle type='source' position='bottom' id='condition-output' className='nd-handle' />

        {addIconNeed && (
          <div onClick={() => setAddModal(true)} className='nd-add-icon'>
            <Plus size={13} />
          </div>
        )}
      </div>

      {addModal && (
        <Modal.Container handleClose={() => setAddModal(false)}>
          <Modal.View className='modal-view-trigger' containerClass='view-end'>
            <NodeAddComponent
              rest={rest}
              data={data}
              addModal={addModal}
              setAddModal={setAddModal}
            />
          </Modal.View>
        </Modal.Container>
      )}
    </>
  );
}
