/* eslint-disable no-unused-vars */
import { FileText, FileUp, X } from 'lucide-react';
import React, { useCallback, useState } from 'react';

import { useDropzone } from 'react-dropzone';
import { compressImageOnly, getFileType, getSize, isImageFile, sanitizeFileName } from '../Utils';
import { Cancel, Tick } from '../Utils/SvgIcons';
import { miscService } from '../_services';
import { Button } from './CustomButton';
import { Modal } from './Modal';

export function CustomFileUpload({
  CustomView,
  handleOK,
  accept,
  name,
  Heading = 'File Upload',
  isMulti = false,
  openOnStart = false,
  onClose = () => {},
  isCompressed = false,
}) {
  const [openModal, setOpenModal] = useState(openOnStart);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [isUploading, setIsUploading] = useState(false);
  const [compressedImage, setCompressedImage] = useState(null);

  const acceptFormat = accept ? (accept === 'image' ? { 'image/*': ['.jpeg', '.png'] } : {}) : {};
  const onFileSelect = (files) => {
    let cloneSelectedFiles = [...selectedFiles];
    for (let file of files) {
      cloneSelectedFiles.push({
        name: file.name.replace(/[^\w.-]|[\s&]/g, ''),
        sanitizeName: sanitizeFileName(file.name),
        fileType: getFileType(file.name.split('.').pop()),
        size: file.size,
        extension: file.name.split('.').pop(),
        file,
      });
    }

    setSelectedFiles([...cloneSelectedFiles]);
  };

  const fileRemove = (index) => {
    let tempArray = [...selectedFiles];
    tempArray.splice(index, 1);
    setSelectedFiles(tempArray);
  };

  const handleFileUpload = async (data) => {
    if (!data.length) {
      return;
    }

    setIsUploading(true);

    const uploadedData = [];
    const compressedData = [];
    const compressedBlobData = [];

    for (let fileObj of data) {
      try {
        if (fileObj.fileType === 'image' && isCompressed) {
          let { compressedFile, compressedImageBlobUrl } = await compressImageOnly(fileObj.file);

          let signedDataCompressed = await miscService.createSignedUploadUrl({
            type: fileObj.fileType, //--video,image,audio
            ext: fileObj.extension, //--jpg or mp4
            name: `${fileObj.sanitizeName}_compressed`,
          });

          if (signedDataCompressed?.data?.signedUrl) {
            let signedUrlCompressed = signedDataCompressed.data.signedUrl;
            let fileNameCompressed = signedDataCompressed.data.filename;

            // Upload the compressed file
            await fetch(signedUrlCompressed, {
              method: 'PUT',
              headers: { 'Content-Type': 'multipart/form-data' },
              body: compressedFile,
            }).then((response) => response.text());

            compressedData.push(fileNameCompressed);
            compressedBlobData.push(compressedImageBlobUrl);
          }
        }

        let signedData = await miscService.createSignedUploadUrl({
          type: fileObj.fileType, //--video,image,audio
          ext: fileObj.extension, //--jpg or mp4
          name: fileObj.sanitizeName,
        });

        if (signedData?.data?.signedUrl) {
          let signedUrl = signedData.data.signedUrl;
          let fileName = signedData.data.filename;

          await fetch(signedUrl, {
            method: 'PUT',
            headers: { 'Content-Type': 'multipart/form-data' },
            body: fileObj.file,
          }).then((response) => response.text());

          uploadedData.push(fileName);
        }
      } catch (error) {
        console.log({ error });
      }
    }

    handleOK(uploadedData, name, compressedData, compressedBlobData);
    setOpenModal(false);
    setIsUploading(false);
    setSelectedFiles([]);
  };

  const selectedFilesData = selectedFiles?.map((FILE, index) => {
    const isImage = isImageFile(FILE.extension);

    return (
      <>
        <div className='file-card-contain'>
          <div className='file-close' onClick={() => fileRemove(index)}>
            <X size={18} />
          </div>
          <div className='icon-contain'>
            <div className='icon-outer'>
              {isImage ? (
                <img
                  src={URL.createObjectURL(FILE.file)}
                  alt={FILE.name}
                  className='file-preview-image'
                />
              ) : (
                <FileText color='var(--bg-primary)' />
              )}
            </div>
          </div>
          <div className='details-contain'>
            <div className='file-head'>{FILE.name}</div>
            <div className='file-size'>{getSize(FILE.size)}</div>
          </div>
        </div>
      </>
    );
  });

  const onDrop = useCallback((acceptedFiles) => {
    // Handle the files here

    onFileSelect(acceptedFiles);
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: acceptFormat,
  });

  return (
    <>
      {!openOnStart && (
        <div
          onClick={(e) => {
            e.preventDefault(), setOpenModal(true);
          }}
        >
          {CustomView ? CustomView : <div>click</div>}
        </div>
      )}

      {openModal && (
        <Modal.Container>
          <Modal.View className={'file-upload'}>
            <Modal.Head
              // containerClass={'file-header'}
              Heading={Heading}
              // headerClass={}
              handleClose={() => {
                setOpenModal(false);
                onClose();
              }}
            />
            {/* 
            <input
              accept={acceptFormat}
              multiple={isMulti}
              onChange={(e) => onFileSelect(e.target.files)}
              id='upload'
              type='file'
              style={{ display: 'none' }}
            /> */}

            <div className='custom-upload-contain'>
              <div {...getRootProps({ className: 'drop-contain' })}>
                <input {...getInputProps()} id='upload' accept='image/*' type='file' />
                <FileUp size={30} color='#d2d2d2' />
                <div>
                  {isDragActive ? (
                    <p>Drop the files here ...</p>
                  ) : (
                    <p>
                      Drag and Drop file here or{' '}
                      <span style={{ textDecoration: 'underline' }}>choose file</span>
                    </p>
                  )}
                </div>
              </div>
            </div>
            <div className='selected-file'>{selectedFilesData}</div>

            <div className='upload-btn-contain pe-2'>
              <Button.Container
                className={'outline-cancel-button'}
                handleOk={() => {
                  setSelectedFiles([]);
                  setOpenModal(false);
                  onClose();
                }}
              >
                <Cancel width={15} height={15} color='#696969' />
                <Button.Title title={'Cancel'} />
              </Button.Container>

              <Button.Container
                isDisabled={isUploading}
                handleOk={() => handleFileUpload(selectedFiles)}
              >
                <Tick width={16} height={16} />
                <Button.Title title={'Upload'} />
                <Button.Loading isLoading={isUploading} />
              </Button.Container>
            </div>
          </Modal.View>
        </Modal.Container>
      )}
    </>
  );
}
