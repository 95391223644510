/* eslint-disable no-unused-vars */
import React, { useContext, useRef, useState } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';

import { useQueryClient } from '@tanstack/react-query';
import { ChevronLeft, ChevronRight } from 'lucide-react';
import moment from 'moment';
import { apiConstants, MENU, RT } from '../_constants';
import { TaskListTypeConst } from '../BoardView/BoardView.constant';
import { favTypes } from '../Favorites/favCostants';
import { useUser } from '../Hook/useUser';
import '../nav.css';
import { getSettingsByName, getTemplateMenu, GlobalContext, ReturnIcon } from '../Utils';
import { Images } from '../Utils/Images';
import {
  FileIconSidebar,
  GroupUser,
  HiDeskIcon,
  LoadingIcon,
  PinIconSideBar,
  ProgressIcon,
  ReportsIcon,
  TemplateIcon,
  TimeHistoryIcon,
  WorkActivityIcon,
} from '../Utils/SvgIcons';

export function SideBar({ isOpen, toggleSidebar }) {
  const { globalSettings, isSettingLoading, permissionsMap, getMenuLabelName } =
    useContext(GlobalContext);
  const [isCollapsed, setIsCollapsed] = useState(false);

  const LABEL_OF_CLIENT = getMenuLabelName(MENU.contact.default_name);
  const LABEL_OF_PROJECT = getMenuLabelName(MENU.project.default_name);

  // get notification count from react query cache   queryKey: ['notificationCount'],
  const queryClient = useQueryClient();
  // Get notification count from React Query cache
  const notificationCount = queryClient.getQueryData(['notificationCount']);

  const chatCount = notificationCount?.data?.chatNotificationCount;

  const menuSettings =
    globalSettings &&
    globalSettings.length &&
    globalSettings.find((settings) => settings?.name === 'menu-settings');

  const favorites =
    globalSettings &&
    globalSettings.length &&
    globalSettings.find((settings) => settings?.name === 'favorites');

  const GeneralSettings = getSettingsByName(globalSettings, 'general-settings');

  const companyLogo =
    GeneralSettings?.labels?.find((settings) => settings?.label === 'Logo').value || '';

  const navigate = useNavigate();
  const todoCheckboxRef = useRef(null);
  const pinnedCheckRef = useRef(null);

  const handleNavLinkClick = () => {
    if (todoCheckboxRef?.current) {
      todoCheckboxRef.current.checked = false;
    }
    // scrollbar close on responsive
    isOpen && toggleSidebar();
  };

  const hasExist = (default_name) => {
    return (
      menuSettings?.labels &&
      menuSettings?.labels.some((label) => label.default_name === default_name)
    );
  };

  function getLabelProperty(defaultName, property) {
    //

    if (!menuSettings?.labels) return null;

    const item = menuSettings?.labels.find((label) => label?.default_name === defaultName);

    return item ? item[property] || null : null;
  }

  const todayStart = moment().startOf('day').valueOf();
  const todayEnd = moment().endOf('day').valueOf();

  const todayCurrent = moment().valueOf();
  const monthStartDate = moment().startOf('month').valueOf();

  const user = useUser()?.id;

  // Function to get the name of a label based on its default_name
  function getLabelName(defaultName) {
    const label =
      menuSettings && menuSettings?.labels?.find((label) => label.default_name === defaultName);
    return label ? label.name : null;
  }

  const extraMenus = menuSettings?.labels?.filter((label) => label?.type === 'template') || [];
  const favoriteItem = (data, index) => {
    const name =
      data.type == favTypes.TASK
        ? data['Task.name']
        : data.type == favTypes.PROJECT
        ? data['Project.name']
        : 'Other';

    const link =
      data.type == favTypes.TASK
        ? `${RT.TASK}/${data?.task_id}?tab=work-history`
        : data.type == favTypes.PROJECT
        ? `${RT.PROJECT}/${data?.project_id}/${RT.PROJECT_TASK_LIST}?status=${
            data.navigation_id ?? 'in-progress'
          }&list_type=${TaskListTypeConst.Project}`
        : 'Other';

    return (
      <NavLink
        style={index === 0 ? { marginTop: 5 } : {}}
        className={({ isActive }) => {
          return isActive ? 'active_tab_sub' : 'non_active_sub';
        }}
        to={link}
      >
        <img className='tab-img' src={Images.Pin} />

        <div className='pin-active-font'>{name}</div>
        <section className='sidebar-tooltip-text'>{name}</section>
      </NavLink>
    );
  };

  const [isChecked, setIsChecked] = useState(false);

  const handleMouseEnter = () => {
    setIsChecked(true);
  };

  const handleMouseLeave = () => {
    setIsChecked(false);
  };
  const [isCheckedPin, setIsCheckedPin] = useState(false);

  const handleMouseEnterPin = () => {
    setIsCheckedPin(true);
  };

  const handleMouseLeavePin = () => {
    setIsCheckedPin(false);
  };

  let companyIcon = apiConstants.imgUrlS3 + companyLogo;

  const in_progress_id = getLabelProperty('team-responsibility', 'in_progress_id');
  const in_progress_id1 = getLabelProperty('my-responsibility', 'in_progress_id');

  const reportVisible =
    !permissionsMap?.work_log &&
    // !permissionsMap?.work_history &&
    // !permissionsMap?.project_report &&
    !permissionsMap?.time_sheet;

  const ref = useRef();
  return (
    <>
      <div className='hamburger' onClick={toggleSidebar}>
        <img src='/icons/hamburger.svg' />
      </div>

      <div
        className={`side_bar_contain ${isOpen ? 'show' : 'hide'} ${
          isCollapsed ? ' collapsed' : ''
        }`}
      >
        <div className='company_icon_contain pointer' onClick={() => navigate('/')}>
          {!isSettingLoading ? (
            isCollapsed ? (
              <HiDeskIcon />
            ) : (
              <img src={companyLogo ? companyIcon : Images.CompanyLogo} />
            )
          ) : (
            <LoadingIcon size={40} />
          )}
        </div>

        {!isOpen ? (
          <section
            className='expand-collapse-button_side_bar_section'
            onClick={() => setIsCollapsed(!isCollapsed)}
          >
            {isCollapsed ? (
              <section className='d-flex'>
                <ChevronRight size={12} color='black' />
                <section className='button_side_bar_section'>Expand</section>
              </section>
            ) : (
              <section className='d-flex'>
                <ChevronLeft size={12} color='black' />
                <section className='button_side_bar_section'>Collapse</section>
              </section>
            )}
          </section>
        ) : (
          <></>
        )}

        <div className='nav_item_contain nav-item-scroll fz-13px-rem mt-1 mb-3'>
          {/* dashboard */}
          {hasExist(MENU.dashboard.default_name) && (
            <NavLink
              className={({ isActive }) => {
                return isActive ? 'active_tab ' : 'non-active';
              }}
              to='/'
              onClick={handleNavLinkClick}
            >
              <div className='sidebar-icon'>{ReturnIcon('Dashboard')}</div>
              <div className='tab-active-font'>Dashboard</div>
              <section className='sidebar-tooltip-text'>Dashboard</section>
            </NavLink>
          )}
          {/*end dashboard */}
          {/* my task */}
          {hasExist(MENU?.myResponsibility?.default_name) && (
            <NavLink
              className={() => {
                const params = new URLSearchParams(location.search);
                return params.get('list_type') === 'my-task' ? 'active_tab' : 'non-active';
              }}
              to={`${RT.TASK_LIST}?status=${in_progress_id1}&list_type=my-task`}
              onClick={handleNavLinkClick}
            >
              <div className='sidebar-icon'>{ReturnIcon('My-Task')}</div>
              <div className='tab-active-font'>
                {getLabelName(MENU?.myResponsibility?.default_name)}
              </div>
              <section className='sidebar-tooltip-text'>
                {getLabelName(MENU?.myResponsibility?.default_name)}
              </section>
            </NavLink>
          )}
          {/* my task end */}

          {/* my team responsibility */}
          {hasExist(MENU.teamResponsibility.default_name) && (
            <NavLink
              className={() => {
                const params = new URLSearchParams(location.search);
                return params.get('list_type') === 'team-task' ? 'active_tab' : 'non-active';
              }}
              to={`${RT.TASK_LIST}?status=${in_progress_id}&list_type=team-task`}
              onClick={handleNavLinkClick}
            >
              <div className='sidebar-icon'>{ReturnIcon('Task')}</div>
              <div className='tab-active-font'>
                {getLabelName(MENU.teamResponsibility.default_name)}
              </div>
              <section className='sidebar-tooltip-text'>
                {getLabelName(MENU?.teamResponsibility?.default_name)}
              </section>
            </NavLink>
          )}

          {/*end task */}
          {extraMenus && extraMenus.length
            ? extraMenus.map((item) => {
                return (
                  <>
                    <NavLink
                      className={({ isActive }) => {
                        return isActive ? 'active_tab' : 'non-active';
                      }}
                      to={`${RT.TEMPLATE}/${item?.id}?status=${
                        item?.navigationId ?? 'in-progress'
                      }&list_type=${TaskListTypeConst.Template}`}
                      onClick={handleNavLinkClick}
                    >
                      <div className='sidebar-icon'>
                        {item?.icon ? getTemplateMenu(item?.icon) : ReturnIcon('Template')}
                      </div>
                      <div className='tab-active-font'>{item?.name}</div>
                      <section className='sidebar-tooltip-text'>{item.name}</section>
                    </NavLink>
                  </>
                );
              })
            : null}

          {/*end template */}

          {/* my team */}
          <NavLink
            className={({ isActive }) => {
              return isActive ? 'active_tab' : 'non-active';
            }}
            // to={`${RT.MY_TEAM_LIST_MAIN}/${RT.MY_TEAM_CALENDAR}`}
            to={`${RT.MY_TEAM_LIST}`}
            onClick={handleNavLinkClick}
          >
            <div className='sidebar-icon'>{ReturnIcon('Team')}</div>
            <div className='tab-active-font'>My Team</div>
            <section className='sidebar-tooltip-text'>My Team</section>
          </NavLink>
          {/*end my team */}

          {/* OKR */}
          {hasExist(MENU.okr.default_name) && (
            <NavLink
              className={({ isActive }) => {
                return isActive ? 'active_tab' : 'non-active';
              }}
              to={RT.OKR_LIST}
              onClick={handleNavLinkClick}
            >
              <div className='sidebar-icon'>{ReturnIcon('OKR')}</div>
              {/* <img className='tab-img' src={Images.Dashboard} /> */}
              <div className='tab-active-font'>OKR</div>
              <section className='sidebar-tooltip-text'>OKR</section>
            </NavLink>
          )}

          {/*end OKR*/}

          {/* Projects */}
          {hasExist(MENU.project.default_name) && (
            <NavLink
              className={({ isActive }) => {
                return isActive ? 'active_tab' : 'non-active';
              }}
              to={RT.PROJECT_LIST}
              onClick={handleNavLinkClick}
            >
              <div className='sidebar-icon'>{ReturnIcon('Project')}</div>
              {/* <img className='tab-img' src={Images.Project} /> */}
              <div className='tab-active-font'>{LABEL_OF_PROJECT}</div>
              <section className='sidebar-tooltip-text'>{LABEL_OF_PROJECT}</section>
            </NavLink>
          )}

          {/*end Projects*/}

          {/* Contact */}
          {hasExist(MENU.contact.default_name) && (
            <NavLink
              className={({ isActive }) => {
                return isActive ? 'active_tab' : 'non-active';
              }}
              to={RT.CLIENT}
              onClick={handleNavLinkClick}
            >
              <div className='sidebar-icon'>{ReturnIcon(LABEL_OF_CLIENT)}</div>
              {/* <img className='tab-img' src={Images.Project} /> */}
              <div className='tab-active-font'>{LABEL_OF_CLIENT}</div>
              <section className='sidebar-tooltip-text'>{LABEL_OF_CLIENT}</section>
            </NavLink>
          )}

          {/*end Contact*/}

          {/* Chats */}
          {hasExist(MENU.chat.default_name) && (
            <NavLink
              className={({ isActive }) => {
                return isActive ? 'active_tab position-relative' : 'non-active position-relative';
              }}
              to={`${RT.CHAT}?tab=all`}
              onClick={handleNavLinkClick}
            >
              <div className='sidebar-icon'>{ReturnIcon('Chat')}</div>
              <div className='tab-active-font'>Chat</div>
              <section className='sidebar-tooltip-text'>Chat</section>
              {parseInt(chatCount) > 0 && <div className='chat-count'>{chatCount}</div>}
            </NavLink>
          )}

          {/*end Chats*/}

          {/* pin */}
          {favorites?.labels && favorites?.labels?.length ? (
            <div onMouseEnter={handleMouseEnterPin} onMouseLeave={handleMouseLeavePin}>
              <input
                checked={isCheckedPin}
                type='checkbox'
                id='pinned'
                name='pinned'
                value='pinned'
                ref={pinnedCheckRef}
              />
              <label htmlFor='pinned' className='user-select-none' style={{ width: '100%' }}>
                <div className='custom_tab_contain'>
                  <div className='custom_tab'>
                    <div className='sidebar-icon'>
                      <PinIconSideBar />
                    </div>
                    <div className='pinned'>{RT.PINNED}</div>
                    <section className='sidebar-tooltip-text'>{RT.PINNED}</section>
                  </div>
                  <div className='sub-menu-arrow'>
                    <img style={{ height: 8 }} src={Images.ArrowDown} />
                  </div>
                </div>
              </label>

              <div id='pinnedDiv' className='sub-menu-container'>
                <div className='hidden_contain overflow-hidden'>
                  {favorites?.labels?.map((label, index) => (
                    <>{favoriteItem(label, index)}</>
                  ))}
                </div>
              </div>
            </div>
          ) : null}

          {/*end pin */}

          {/* Calendar */}
          <NavLink
            className={({ isActive }) => {
              return isActive ? 'active_tab' : 'non-active';
            }}
            to={`${RT.CALENDAR}?user_id=${user}`}
            onClick={handleNavLinkClick}
          >
            <div className='sidebar-icon'>{ReturnIcon('Calendar')}</div>
            <div className='tab-active-font'>Calendar</div>
            <section className='sidebar-tooltip-text'>Calendar</section>
          </NavLink>
          {/* Calendar end */}

          {/* Filesystem */}
          {hasExist(MENU.files.default_name) && (
            <NavLink
              className={({ isActive }) => {
                return isActive ? 'active_tab' : 'non-active';
              }}
              to={`${RT.FILESYSTEM}/${RT.FILELIST}`}
            >
              <div className='sidebar-icon'>
                <FileIconSidebar />
              </div>
              <div className='tab-active-font'>Files</div>
              <section className='sidebar-tooltip-text'>Files</section>
            </NavLink>
          )}

          {/*end files*/}

          {/* template */}
          {hasExist(MENU.template.default_name) && (
            <NavLink
              className={({ isActive }) => {
                return isActive ? 'active_tab' : 'non-active';
              }}
              to={RT.TEMPLATE_LIST}
              onClick={handleNavLinkClick}
            >
              {/* {ReturnIcon('Template')} */}
              <div className='sidebar-icon'>
                <TemplateIcon />
              </div>
              <div className='tab-active-font'>Template</div>
              <section className='sidebar-tooltip-text'>Template</section>
            </NavLink>
          )}
          {/* template  */}

          {/* report */}
          {hasExist(MENU.reports.default_name) && (
            <div
              style={{ display: 'flex', flexDirection: 'column' }}
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
            >
              <input
                checked={isChecked}
                readOnly
                type='checkbox'
                id='todo'
                name='todo'
                value='todo'
                ref={todoCheckboxRef}
              />
              {!reportVisible && (
                <label htmlFor='todo' className='user-select-none pointer'>
                  <div className='custom_tab_contain'>
                    <div className='custom_tab'>
                      <div className='sidebar-icon'>
                        <ReportsIcon />
                      </div>
                      <div className='pinned'>{RT.REPORT}</div>
                      <section className='sidebar-tooltip-text'>{RT.REPORT}</section>
                    </div>
                    <div className='sub-menu-arrow'>
                      <img style={{ height: 8 }} src={Images.ArrowDown} />
                    </div>
                  </div>
                </label>
              )}

              <div id='hiddenDiv' className='sub-menu-container'>
                <div className='hidden_contain overflow-hidden'>
                  {/* <NavLink
                  className={({ isActive }) => {
                    return isActive ? 'active_tab_sub' : 'non_active_sub';
                  }}
                  to={`${RT.TIME_SHEET}?user_id=${user}&fromDate=${todayStart}&toDate=${todayEnd}&pageNo=1`}
                >
                  <img className='tab-img' src={Images.Time_history} />
                  <div className='tab-active-font'>Time Sheet</div>
                </NavLink> */}
                  {permissionsMap?.work_log && (
                    <NavLink
                      className={({ isActive }) => {
                        return isActive ? 'active_tab_sub' : 'non_active_sub';
                      }}
                      to={`${RT.WORK_ACTIVITY}?user_id=${user}&fromDate=${todayStart}&toDate=${todayEnd}`}
                    >
                      <div className='sidebar-icon'>
                        <WorkActivityIcon />
                      </div>
                      {/* <div className='tab-active-font'>Work Activity</div> */}
                      <div className='tab-active-font'>Time Log</div>
                      <section className='sidebar-tooltip-text'>Time Log</section>
                    </NavLink>
                  )}

                  {permissionsMap?.time_sheet && (
                    <NavLink
                      className={({ isActive }) => {
                        return isActive ? 'active_tab_sub' : 'non_active_sub';
                      }}
                      to={`${RT.TIME_HISTORY}`}
                    >
                      <div className='sidebar-icon'>
                        <TimeHistoryIcon />
                      </div>
                      <div className='tab-active-font'>Time History</div>
                      <section className='sidebar-tooltip-text'>Time History</section>
                    </NavLink>
                  )}

                  {/* team report  */}
                  <NavLink
                    className={({ isActive }) => {
                      return isActive ? 'active_tab_sub' : 'non_active_sub';
                    }}
                    to={`${RT.TEAM_REPORT}?fromDate=${todayStart}&toDate=${todayEnd}`}
                  >
                    <div className='sidebar-icon'>
                      <GroupUser height={17} width={19} isSidebar={true} />
                    </div>
                    <div className='tab-active-font'>Team Report</div>
                    <section className='sidebar-tooltip-text'>Team Report</section>
                  </NavLink>
                  {/* )} */}
                  <NavLink
                    className={({ isActive }) => {
                      return isActive ? 'active_tab_sub' : 'non_active_sub';
                    }}
                    // to={RT.GRAPH_REPORT}
                    to={`${RT.GRAPH_REPORT}/${RT.BAR_CHART}?user_id=${user}&fromDate=${monthStartDate}&toDate=${todayCurrent}`}
                  >
                    <div className='sidebar-icon'>
                      <ProgressIcon width={20} height={20} isSidebar={true} />
                    </div>
                    <div className='tab-active-font'>Graphical Report</div>
                    <section className='sidebar-tooltip-text'>Graphical Report</section>
                  </NavLink>
                </div>
              </div>
            </div>
          )}

          {/*end report */}

          {/* Archive
          <NavLink
            className={({ isActive }) => {
              return isActive ? 'active_tab' : 'non-active';
            }}
            to={`${RT.ARCHIVE}?list_type=${TaskListTypeConst.Archive}`}
            onClick={handleNavLinkClick}
          >
            <div className='sidebar-icon'>
              <ArchiveIcon width={18} height={17} strokeWidth='1' isSidebar={true} />
            </div>
            <div className='tab-active-font'>Archive</div>
          </NavLink> */}

          {/* end of archive  */}
        </div>
        {/* <SideBarQuote /> */}
      </div>
    </>
  );
}

const SideBarQuote = () => {};
