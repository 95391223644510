import React, { useEffect, useLayoutEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import useMutationObserver from '../Hook/useMutationObserver';
import useObserver from '../Hook/useObserver';
import useOutsideClickHandler from '../Hook/useOutsideClickHandler';
import useResizeObserver from '../Hook/useResizeObserver';

function PopoverWrapper({ children }) {
  return <div>{children}</div>;
}

/***
 * @callback PositionCallBack
 * @param parentWidth - The parent element width, which can be used to adjust the child's position
 * @param childWidth - The width of the child element.
 */

/***
 * For lifting element over from an enclosed space. This can used inside table, where we want to lift up some element outside table
 * Will calculate it's position exact x and y position and place it by using fixed positioning.
 * @param {Object} props
 * @param props.onClose - This function will be executed when clicked outside of the child
 * @param {PositionCallBack} props.positionLeft - This function can be used to adjust the fixed elements left position
 * @param {PositionCallBack} props.positionTop - This function can be used to adjust the fixed elements top position
 * @param {boolean} props.isToolTip - If the child is tooltip, pass true.
 * @param {{current:Object}} props.scrollRef - If parent table/element of PopOverItem has any scroll element, pass its ref here,
 * so that we can adjust the elements position based on its scroll
 * @param {boolean} props.isVisible
 * @param {number} props.availableSpace  - The available space of the child item. By default, view port height will be the available space
 * @param {boolean} props.isAdjustable - If false, the element will not move based on space availability. True by default
 */
const PopoverItem = ({
  children,
  onClose = null,
  positionLeft = (width) => width / 2,
  isToolTip = false,
  positionTop = () => 0,
  scrollRef = null,
  isVisible = false,
  availableSpace = window.innerHeight,
  isAdjustable = true,
  zIndex = 9,
}) => {
  // const contentRef = useRef(null);
  const { containerRef: contentRef } = useOutsideClickHandler({
    onClose: () => onClose && onClose(),
    bubbling: true,
  });

  const { style, updatePosition } = usePopoverHandle({
    contentRef: contentRef,
    positionLeft,
    positionTop,
    scrollRef,
    isVisible,
    availableSpace,
    isAdjustable,
  });

  useResizeObserver({
    onResize: updatePosition,
    customRef: { current: scrollRef?.current?.querySelector('table') },
  });

  useResizeObserver({
    onResize: updatePosition,
    customRef: contentRef,
  });

  useMutationObserver({
    onMutation: () => {
      updatePosition();
    },
    customRef: contentRef?.current,
  });

  if (!style) {
    return null;
  }

  return (
    <div
      className='popover-item'
      ref={contentRef}
      style={{ ...style, position: 'fixed', zIndex: isToolTip ? '10' : zIndex ?? '9' }}
    >
      {children}
    </div>
  );
};

const PopoverPortalItem = ({
  children,
  onClose = null,
  isToolTip = false,
  zIndex = 0,
  positionLeft = (width) => width / 2,
  positionTop = () => 0,
}) => {
  const [isHovered, setIsHovered] = useState(false);
  // Parent will be popOverWrapper
  const { containerRef: contentRef } = useOutsideClickHandler({
    onClose: () => onClose && onClose(),
  });
  const { style, updatePosition } = usePopoverHandle({
    contentRef: contentRef,
    positionLeft,
    positionTop,
  });
  useObserver({
    customRef: contentRef.current?.parentNode,
    onWatch: () => {
      updatePosition();
    },
  });

  useEffect(() => {
    if (contentRef.current) {
      const onHover = () => {
        setIsHovered(true);
      };

      const onLeave = () => {
        setIsHovered(false);
      };

      contentRef.current?.parentElement?.addEventListener('mouseenter', onHover);
      contentRef.current?.parentElement?.addEventListener('mouseleave', onLeave);

      return () => {
        contentRef.current?.parentElement?.removeEventListener('mouseenter', onHover);
        contentRef.current?.parentElement?.addEventListener('mouseleave', onLeave);
      };
    }
  }, [contentRef.current]);

  if (isHovered) {
    return createPortal(
      <div
        // ref={contentRef}
        className='pop-over-item-container'
        style={{ ...style, position: 'fixed', zIndex: isToolTip ? '10' : zIndex ?? '9' }}
      >
        {children}
      </div>,
      document.getElementById('MODAL'),
    );
  }
  // For detecting the elememnt position
  return <div ref={contentRef}>{children}</div>;
};

const usePopoverHandle = ({
  contentRef,
  occupySpace = false,
  positionLeft,
  positionTop,
  scrollRef = null,
  isVisible = false,
  availableSpace = window.innerHeight,
  isAdjustable = true,
}) => {
  const [style, setStyle] = useState({ top: 'auto', left: 'auto' });
  const [containerSize, setContainerSize] = useState(null);
  /***
   * For updating the position of the child element of PopOverItem
   */
  const updatePosition = () => {
    if (contentRef.current && contentRef.current?.parentNode) {
      const targetRect = contentRef.current?.parentNode?.getBoundingClientRect();
      const childRect = contentRef.current?.lastChild?.getBoundingClientRect();
      // Find whether the element has space to live, otherwise it will scroll above
      const noSpaceToLive =
        childRect?.height && availableSpace - targetRect.bottom <= childRect.height;

      if (occupySpace) {
        setContainerSize({ width: childRect.width, height: childRect.height });
      }

      setStyle({
        left: contentRef.current?.parentNode
          ? positionLeft(targetRect.width, childRect?.width) + targetRect.left
          : targetRect.left,
        top:
          noSpaceToLive && isAdjustable
            ? targetRect.top -
              childRect.height -
              positionTop(targetRect.height, childRect?.width, childRect?.height) -
              5
            : contentRef.current?.parentNode
            ? positionTop(targetRect.height, childRect?.width, childRect?.height) +
              targetRect.bottom
            : targetRect.bottom,
      });
    }
  };

  useLayoutEffect(() => {
    updatePosition();
    // Root page content element.
    const contentElement = document.querySelector('.page-content-section');
    // If content page scroll, we update the element position
    if (contentElement) {
      contentElement.addEventListener('scroll', updatePosition);
    }
    // Mostly scrollRef will be table, if table scrolls, we update the position of popOver children
    scrollRef?.current?.addEventListener('scroll', updatePosition);

    return () => {
      if (contentElement) {
        contentElement.removeEventListener('scroll', updatePosition);
      }
      scrollRef?.current?.removeEventListener('scroll', updatePosition);
    };
  }, [contentRef.current, scrollRef, isVisible]);
  return { style, containerSize, setStyle, updatePosition };
};

export const Popover = {
  PopoverItem,
  PopoverWrapper,
  PopoverPortalItem,
};
