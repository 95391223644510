import React, { useContext } from 'react';
import { GlobalContext } from '../Utils/Contexts';

const AlertComponent = () => {
  const { alert } = useContext(GlobalContext) || {};

  return (
    <>
      {alert?.message && (
        <div
          className={`alert_view slide-in-left test-alert ${
            alert.type === 'success' ? 'ta-success' : 'ta-error'
          }`}
        >
          {alert?.message}
        </div>
      )}
    </>
  );
};

export default React.memo(AlertComponent);
