/* eslint-disable no-unused-vars */
import React from 'react';
import { Outlet } from 'react-router-dom';
import './config.css';

export default function ConfigLayout() {
  return (
    <section>
      <section>
        <Outlet />
      </section>
    </section>
  );
}
