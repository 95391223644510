import { apiConstants } from '../_constants';
import { authHeader, handleResponse } from '../_helpers';
import { jsonToQueryString } from '../_helpers/utilFunctions';
export const statusService = {
  statusAdd,
  statusList,
  statusDelete,
  statusGet,
  // group status
  statusGroupAdd,
  statusGroupList,
  statusGroupDelete,
  statusWithTaskCount,
  getStatusListNonTimeBound,
  statusGroupItemsWithTaskCount,
  statusArrange,
  statusGroupItemList,
};

function statusArrange(data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(data),
  };
  return fetch(apiConstants.apiUrl + '/arrange-status', requestOptions).then(handleResponse);
}
function statusGet(data) {
  const params = jsonToQueryString(data);
  const apiUrl = `${apiConstants.apiUrl}/get_status?${params}`;
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };
  return fetch(apiUrl, requestOptions).then(handleResponse);
}

function statusAdd(data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(data),
  };
  return fetch(apiConstants.apiUrl + '/status_add', requestOptions).then(handleResponse);
}
function statusList(data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(data),
  };
  return fetch(apiConstants.apiUrl + '/status_list', requestOptions).then(handleResponse);
}
function statusDelete(data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(data),
  };
  return fetch(apiConstants.apiUrl + '/status_delete', requestOptions).then(handleResponse);
}

function statusGroupAdd(data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(data),
  };
  return fetch(apiConstants.apiUrl + '/status_group_add', requestOptions).then(handleResponse);
}
function statusGroupList(data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(data),
  };
  return fetch(apiConstants.apiUrl + '/status_group_list', requestOptions).then(handleResponse);
}

function statusGroupDelete(data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(data),
  };
  return fetch(apiConstants.apiUrl + '/status_group_deactivate', requestOptions).then(
    handleResponse,
  );
}
function statusWithTaskCount(data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(data),
  };
  return fetch(apiConstants.apiUrl + '/status_with_task_count', requestOptions).then(
    handleResponse,
  );
}
function statusGroupItemsWithTaskCount(data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(data),
  };
  return fetch(apiConstants.apiUrl + '/status_group_item_with_task_count', requestOptions).then(
    handleResponse,
  );
}

function getStatusListNonTimeBound(data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(data),
  };
  return fetch(apiConstants.apiUrl + '/get_task_statuses', requestOptions).then(handleResponse);
}

function statusGroupItemList(data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(data),
  };
  return fetch(apiConstants.apiUrl + '/get-status-group-items', requestOptions).then(
    handleResponse,
  );
}
