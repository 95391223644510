/* eslint-disable no-unused-vars */
import { useInfiniteQuery } from '@tanstack/react-query';
import parse from 'html-react-parser';
import { Calendar, Flag } from 'lucide-react';
import React, { useContext, useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import StackedAvatars from '../_components/Avatar/StackedAvatars';
import { TASK_TYPES } from '../_constants/template.constants';
import useDebounce from '../_helpers/useDebounce';
import { taskService } from '../_services';
import { boardService } from '../_services/board.service';
import { statusService } from '../_services/status.service';
import TaskSingleViewModal from '../Calander/TaskSingleViewModal';
import useObserver from '../Hook/useObserver';
import { taskButtonLabel, taskButtonName } from '../Task/taskConstants';
import { getSettingsByName, GlobalContext } from '../Utils';
import { ChatIcon, ParentTaskIcon, SubtaskIcon } from '../Utils/SvgIcons';
import './BoardView.css';
import { updateTaskOrder } from './BoardViewHelperFunctions';

export function BoardView({
  project_id,
  assignee_ids,
  template_id,
  milestone_id,
  client_id,
  templateData,
}) {
  const { makeAlert } = useContext(GlobalContext);
  const [searchParams, setSearchParams] = useSearchParams();
  const [taskViewModal, setTaskViewModal] = useState({ modal: false, data: {} });
  const [draggingTaskId, setDraggingTaskId] = useState(null);
  const [dragOverColumnId, setDragOverColumnId] = useState(null);
  const [draggedTaskDetails, setDraggedTaskDetails] = useState({
    prevTask: null,
    postTask: null,
    currentTask: null,
  });

  const searchKey = searchParams.get('search') ? searchParams.get('search') : '';
  const debouncedSearch = useDebounce(searchKey, 500);
  const columnRef = useRef([]);
  const cardRef = useRef([]);

  const getArrayParam = (param) => {
    const value = searchParams.getAll(param);
    return value.length > 0 ? value : null;
  };

  const getParam = (param) => {
    const value = searchParams.get(param);
    return value !== null ? value : null;
  };

  const projectId = getArrayParam('project_id');
  const clientId = getArrayParam('client_id');
  const taskType = getParam('task_type');
  const userIds = getArrayParam('user_id');
  const templateId = getArrayParam('template_id');
  const milestoneId = getParam('milestone_id');
  const priority = getArrayParam('priority_id');
  const filter_start_date = getParam('task_start_date');
  const filter_end_date = getParam('task_end_date');
  const label = getArrayParam('label_id');

  const assigneeIds = userIds?.map((id) => parseInt(id));
  const filterToUrl = {};
  if (priority) filterToUrl.priority = priority;
  if (filter_start_date) filterToUrl.filter_start_date = filter_start_date;
  if (filter_end_date) filterToUrl.filter_end_date = filter_end_date;
  if (label) filterToUrl.label = label;

  const { data, fetchNextPage, hasNextPage, isLoading, refetch } = useInfiniteQuery({
    queryKey: [
      'boardListAdvanced',
      debouncedSearch,
      taskType,
      templateId,
      projectId,
      userIds,
      clientId,
      milestoneId,
      filterToUrl,
    ],
    queryFn: ({ pageParam = 1 }) =>
      boardService.boardListAdvanced({
        pageVo: {
          noOfItems: 15,
          pageNo: pageParam,
        },
        project_id: projectId || project_id,
        assignee_id: assigneeIds || assignee_ids,
        task_type: taskType,
        template_id: templateId || template_id,
        milestone_id: milestoneId || milestone_id,
        searchKey: debouncedSearch,
        // isNeedClient || !isNeedProject ? client_id : defaultPayload?.client_id
        client_id: client_id || !project_id ? client_id : clientId,
        task_filter: { ...filterToUrl },
        ...(templateData?.is_unlisted != null && { isTemplateUnlisted: templateData.is_unlisted }),
      }),
    getNextPageParam: (lastPage, allPages) => {
      let returnValue = undefined;
      if (parseInt(lastPage?.data?.highestBalancedTasks) === 0) {
        returnValue = undefined; // No more pages to fetch
      } else {
        returnValue =
          parseInt(lastPage?.data?.page) < parseInt(lastPage?.data?.pages)
            ? parseInt(lastPage?.data?.page) + 1
            : undefined;
      }
      return returnValue;
    },
  });

  const { observerRef } = useObserver({
    onIntersection: () => {
      if (hasNextPage && !isLoading) {
        fetchNextPage();
      }
    },
    dependency: [hasNextPage, isLoading],
  });

  function groupByAllStatusIds(data) {
    // Create an object to hold the tasks grouped by status_id
    const groupedData = {};

    // Loop through the data array
    data?.forEach((item) => {
      const statusId = parseInt(item.status_id);
      const status_name = item.status_name;
      const status_order = item.order;
      const default_name = item.default_name;
      const color = item.color;
      // Initialize an empty array for tasks if the status_id hasn't been encountered yet
      if (!groupedData[statusId]) {
        groupedData[statusId] = {
          status_id: statusId,
          status_name: status_name,
          total_tasks: item.total_tasks,
          status_order: status_order,
          tasks: [],
          default_name: default_name,
          color: color,
        };
      }
      // Concatenate the current item's tasks to the respective status_id group, if tasks exist
      if (item.tasks) {
        groupedData[statusId].tasks = groupedData[statusId]?.tasks.concat(item.tasks);
      }
    });

    // Return the grouped data as an array (optional)
    return Object.values(groupedData);
  }

  const newTasks = data?.pages?.flatMap((page) => page?.data?.data);

  const groupedResult = groupByAllStatusIds(newTasks);

  const sortedGroupedResult = groupedResult.sort((a, b) => a.status_order - b.status_order);

  const [switchableStatusNames, setSwitchableStatusNames] = useState([]);
  const [switchableStatusDetails, setSwitchableStatusDetails] = useState([]);

  const onDragStart = async (event, taskId, status, currentTask) => {
    setDraggingTaskId(taskId);
    // event.currentTarget.classList.add('dragging-item'); // Add dragging class
    event.dataTransfer.setData('taskId', taskId);
    event.dataTransfer.setData('status', JSON.stringify(status));
    setDraggedTaskDetails((prev) => ({
      ...prev,
      currentTask,
    }));
    try {
      // get switchable status list
      const statusData = await statusService.getStatusListNonTimeBound({
        task_id: taskId,
        select: ['color', 'name', 'id', 'status_default_name'],
      });

      const statuses = statusData?.data.reduce((acc, currentItem) => {
        const { type, inputs } = currentItem;
        const updatedInputs = inputs.map((item) => ({ ...item, type }));
        return acc.concat(updatedInputs);
      }, []);

      setSwitchableStatusDetails([...statuses]);

      const tempStatusNames = statuses.flatMap((item) => item?.status_default_name);
      setSwitchableStatusNames([...tempStatusNames]);
    } catch (error) {
      console.error('Error fetching status list:', error);
      // makeAlert('You have no permission to switch task status');
      // Optionally set fallback state or notify the user
      setSwitchableStatusDetails([]);
      setSwitchableStatusNames([]);
    }
  };

  const onDragEnd = () => {
    setDraggingTaskId(null);
    // Add any additional logic for drag end
  };

  const onDrop = async (event, targetStatus) => {
    event.preventDefault();
    setDragOverColumnId(null);
    const taskId = event.dataTransfer.getData('taskId');
    const sourceStatus = JSON.parse(event.dataTransfer.getData('status'));

    if (parseInt(sourceStatus?.status_id) === parseInt(targetStatus?.status_id)) {
      const { prevTask, postTask, currentTask } = draggedTaskDetails;
      if (currentTask?.id == postTask?.id) {
        return null;
      }
      const { updatedCurrentTask, updatedPostTask } = updateTaskOrder(
        prevTask,
        currentTask,
        postTask,
      );

      try {
        const res = await taskService.taskAdd(updatedCurrentTask);
        if (postTask?.task_order_time != updatedPostTask?.task_order_time) {
          await taskService.taskAdd(updatedPostTask);
        }
        if (res?.data) {
          refetch();
        }
      } catch (e) {
        makeAlert(e?.message);
      }

      return;
    }
    // Count how many times the targetStatus.default_name appears in switchableStatusNames
    const statusCountRelatedToTarget = switchableStatusNames.filter(
      (status) => status === targetStatus?.default_name,
    ).length;

    // Check if targetStatus.default_name is present at least once
    if (statusCountRelatedToTarget > 0) {
      let taskNewStatus = {};

      if (statusCountRelatedToTarget > 1) {
        // Add your additional code here for multiple occurrences
        taskNewStatus = switchableStatusDetails.find(
          (statusDetails) =>
            (parseInt(statusDetails?.status_group_item_id) === parseInt(targetStatus.status_id) &&
              statusDetails?.status_default_name === targetStatus.default_name) ||
            (parseInt(statusDetails?.value) === parseInt(targetStatus.status_id) &&
              statusDetails?.status_default_name === targetStatus.default_name),
        );
      } else {
        taskNewStatus = switchableStatusDetails.find(
          (statusDetails) => statusDetails?.status_default_name === targetStatus.default_name,
        );
      }

      try {
        const payload = {
          id: parseInt(taskId),
          ...taskNewStatus,
        };

        if (payload.name === taskButtonName.START && payload.label === taskButtonLabel.START) {
          payload.name = taskButtonName.STOP;
        }

        const res = await taskService.updateStatus(payload);
        if (res?.data) {
          refetch();
        }
      } catch (e) {
        makeAlert(e?.message);
      }
    } else {
      makeAlert(`You have no permission to switch task to ${targetStatus?.status_name} Status`);
    }
  };

  const onDragOver = (event, status, prevTask, postTask, isLast) => {
    event.preventDefault();
    setDragOverColumnId(status.status_id);
    setDraggedTaskDetails((prev) => ({
      ...prev,
      prevTask,
      postTask,
    }));
  };

  const onDragLeave = (event) => {
    // event.currentTarget.classList.remove('dragging-item'); // Remove dragging class
    setDragOverColumnId(null);
  };

  return (
    <div style={{ width: '100%' }}>
      <div className='board-container '>
        <div className='board'>
          {sortedGroupedResult?.map((status, index) => (
            <div
              className='column'
              key={status.status_id}
              ref={(el) => (columnRef.current[index] = el)}
              // onDragOver={(event) => {
              //   const columnElement = columnRef.current[index];
              //   const columnRect = columnElement.getBoundingClientRect();
              //   const topThreshold = columnRect.top + 50; // Adjust the threshold as needed
              //   const bottomThreshold = columnRect.bottom - 50;

              //   if (event.clientY < topThreshold) {
              //     // Near the top
              //
              //     onDragOver(event, status, null, status.tasks[0], 'foeeee');
              //   } else if (event.clientY > bottomThreshold) {
              //     // Near the bottom
              //
              //     const length = status.tasks.length;
              //     onDragOver(event, status, status.tasks[length - 1], null, 'foeeee');
              //   }
              // }}
              onDragOver={(event) => {
                const columnElement = columnRef.current[index];
                const columnRect = columnElement.getBoundingClientRect();
                const topThreshold = columnRect.top + 50; // Adjust the threshold as needed
                // const bottomThreshold = columnRect.bottom - 50;

                // Find the last card in the current column
                const lastCard = columnElement.querySelector('.board-view-card:last-child'); // Adjust the selector if necessary
                const lastCardRect = lastCard ? lastCard.getBoundingClientRect() : null;

                if (event.clientY < topThreshold) {
                  onDragOver(event, status, null, status.tasks[0]);
                } else if (lastCardRect && event.clientY > lastCardRect.bottom) {
                  onDragOver(event, status, status.tasks[status.tasks.length - 1], null);
                }
              }}
              onDragLeave={onDragLeave}
              onDrop={(event) => onDrop(event, status)}
            >
              <div className='column-header'>
                <div className='status-name'>{status.status_name}</div>
                <span
                  style={{
                    color: status.color,
                    background: status.color ? `${status.color}1A` : 'transparent',
                  }}
                >
                  {parseInt(status.total_tasks)}
                </span>
              </div>
              <div
                className={`column-content ${
                  status.status_id === dragOverColumnId ? 'drag-over' : ''
                }`}
              >
                {status.tasks.length ? (
                  status.tasks.map((task, taskIndex) => {
                    const postTask = status.tasks[taskIndex] || null;
                    const prevTask = status.tasks[taskIndex - 1] || null;
                    const isLast = taskIndex === status.tasks.length - 1;
                    const isFirst = taskIndex === 0;

                    return (
                      <Card
                        key={task.id}
                        ref={(el) => (cardRef.current[taskIndex] = el)} // Store each task element's ref
                        isDragging={task.id === draggingTaskId}
                        task={task}
                        onDragStart={(event) => onDragStart(event, task.id, status, task)}
                        onDragEnd={onDragEnd}
                        setTaskViewModal={setTaskViewModal}
                        onDragOver={(event) => {
                          // Skip column-level handling for middle tasks
                          if (!isFirst) {
                            onDragOver(event, status, prevTask, postTask, false);
                          }
                        }}
                        className={'board-view-card'}
                      />
                    );
                  })
                ) : (
                  <div style={{ textAlign: 'center', fontSize: '13px', marginTop: 10 }}>
                    No task
                  </div>
                )}
              </div>
            </div>
          ))}
        </div>
        <div style={{ height: '20px', width: '100vw' }} ref={observerRef}></div>
      </div>
      {/* single view section  */}
      {taskViewModal.modal && (
        <TaskSingleViewModal
          handleClose={() => {
            setTaskViewModal({ modal: false, data: {} });
            refetch();
          }}
          taskId={taskViewModal?.data?.id}
          tab={taskViewModal?.data?.tab}
        />
      )}
    </div>
  );
}

export const getPriorityLabel = (labelsObject, key) => {
  if (labelsObject) {
    return labelsObject[key] || null;
  }
  return null;
};

export const TwoLineTitle = ({
  title,
  parentTask,
  className = 'd-flex gap-2 align-items-center board-parent-task-name',
}) => {
  return (
    <>
      {parentTask && (
        <span className={className}>
          <span>
            <ParentTaskIcon />
          </span>
          <div>{parentTask}</div>
        </span>
      )}
      <div className='two-line-title'>{title}</div>
    </>
  );
};

const Card = ({
  task,
  status,
  onDragStart,
  onDragEnd,
  setTaskViewModal,
  isDragging,
  onDragOver,
  className,
}) => {
  const { globalSettings } = useContext(GlobalContext);
  const priorityLabelsSettings = getSettingsByName(globalSettings, 'priority-label');
  const priorityLabel = getPriorityLabel(priorityLabelsSettings?.labels, task?.priority);

  return (
    <div
      onClick={() => {
        if (task.task_type === TASK_TYPES.NON_TIME_BOUND_TASK) {
          setTaskViewModal({
            modal: true,
            data: { id: task.id, tab: 'subtask' },
          });
        } else {
          setTaskViewModal({
            modal: true,
            data: { id: task.id, tab: 'work-history' },
          });
        }
      }}
      className={`${className} ${isDragging ? 'dragging' : ''}`}
      // className='board-view-card'
      draggable
      onDragStart={(event) => onDragStart(event, task.id, status)}
      onDragEnd={onDragEnd}
      onDragOver={onDragOver}
    >
      <div className='d-flex justify-content-between'>
        <div className='br-card-header'>
          <div>
            {priorityLabel?.name ? (
              <span className='priority-label'>{priorityLabel.name}</span>
            ) : null}
          </div>
        </div>
      </div>

      <h3 className='bd-task-title'>
        <TwoLineTitle
          title={task?.title}
          className='d-flex gap-2 align-items-center parent-task-name'
          parentTask={task?.parent_task_name}
        />
      </h3>
      <p className='task-detailed-description my-1'>
        <TwoLineTitle title={parse(task?.note)} />
      </p>

      <div className='d-flex align-items-center gap-2'>
        {task?.order_seq > 0 && task?.order_seq < 1000 && (
          <span
            style={{
              fontSize: 14,
              fontWeight: 600,
              display: 'flex',
              alignItems: 'center',
              gap: 2,
              marginTop: 10,
            }}
          >
            <Flag size={13} color='#BC4841' strokeWidth={2.5} />
            {task?.order_seq}
          </span>
        )}
        <div className='labels-container-board-view'>
          {task?.labels?.map((label, idx) => (
            <div
              style={{
                color: label?.text_color,
                backgroundColor: label?.label_color,
                margin: '4px 0',
              }}
              className='label-item-task-list'
              key={idx}
            >
              {label?.name}
            </div>
          ))}
        </div>
      </div>
      {task.done_status_name && <div className='fz-10px'>({task.done_status_name})</div>}

      <div
        style={{
          marginTop: 10,
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <div className='d-flex gap-1 align-items-center'>
          <StackedAvatars
            getSourceByKey={(user) =>
              user.compressed_image_url ? user.compressed_image_url : user.image_url
            }
            getNameByKey={(user) => user.first_name}
            getGenderByKey={(user) => user.gender === 1}
            imageList={[task.assignee]}
            showInitials={false}
            size={25}
            tooltipPopOver
            limit={1}
          />
        </div>

        <div style={{ display: 'flex', gap: 10 }}>
          {task?.subtask_count > 0 && (
            <div className='d-flex gap-1 align-items-center'>
              <SubtaskIcon color='#87909E' height='14' />
              <div className='message-count'> {task?.subtask_count} </div>
            </div>
          )}
          {task?.last_message_details?.unread_count > 0 && (
            <div className='d-flex gap-1 align-items-center'>
              <ChatIcon width={16} height={17} />
              <div className='message-count'> {task?.last_message_details?.unread_count} </div>
            </div>
          )}

          <div className='br-card-date'>
            <Calendar /> OCT 9/2024
          </div>
        </div>
      </div>
    </div>
  );
};
