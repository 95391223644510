import { keepPreviousData, useInfiniteQuery, useQuery } from '@tanstack/react-query';
import React, { useContext, useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
// import { Heading } from '../Misc';
import { FileText, Home } from 'lucide-react';
import { GlobalContext, jsonToUrlEncoded, taskSortPayload } from '../Utils';
import { LoadingIcon } from '../Utils/SvgIcons';
import IconLabelSet from '../_components/IconLabelSet';
import { RT, USER_LIST_ACTIONS } from '../_constants';
import useDebounce from '../_helpers/useDebounce';
import { taskService, userService } from '../_services';
import { statusService } from '../_services/status.service';
import TaskListView from './TaskListView';
import './task.css';
import {
  STATUS,
  TASK_LIST_VISIBILITY_TYPE,
  TASK_TABS,
  taskAssignUserSelect,
} from './taskConstants';

// TODO: TEMP SOLUTION, NEED TO CHANGE TO API
// let taskBasedUserList = null;

const MY_TASK_LIST_TYPE = 'my-task';

export const TaskList = () => {
  // hook section
  const navigate = useNavigate();
  const { globalSettings } = useContext(GlobalContext);
  const menuSettings =
    globalSettings &&
    globalSettings.length &&
    globalSettings.find((settings) => settings?.name === 'menu-settings');

  const getSidebarLabel = (default_name) => {
    return menuSettings?.labels.find((label) => label.default_name === default_name)?.name;
  };

  const [searchParams, setSearchParams] = useSearchParams();
  const [isSortProcessed, setIsSortProcessed] = useState(false);

  const currentTaskViewType = searchParams.get('currentView');

  const [currentView, setCurrentView] = useState(
    currentTaskViewType ? currentTaskViewType : TASK_TABS.TASKS,
  );

  useEffect(() => {
    if (currentTaskViewType) {
      setCurrentView(currentTaskViewType);
    } else {
      setCurrentView(TASK_TABS.TASKS);
    }
  }, [currentTaskViewType]);

  const [query, setQuery] = useState({
    select: [
      'id',
      'name',
      'note',
      'created_time',
      'task_end_date',
      'order_seq',
      'status',
      'priority',
      'recent_chat_message',
      'progress_status',
      'label',
      'project_id',
      'task_start_date',
      'created_time',
      'days_difference',
      'status_id',
      'status_group_item_id',
      'parent_id',
    ],
    // searchKey: '',
    pageVo: {
      pageNo: parseInt(searchParams.get('pageNo')) || 1,
      noOfItems: 10,
    },
    is_duration_required: true,
  });

  const [urlQuery, setULRQuery] = useState();

  if (searchParams.get('pageNo') && query?.pageVo?.pageNo !== searchParams.get('pageNo')) {
    setQuery({
      ...query,
      pageVo: { ...query.pageVo, pageNo: searchParams.get('pageNo') },
    });
  }
  // const { searchKey: taskSearchKey, ...restQuery } = query;
  // const debouncedSearch = useDebounce(taskSearchKey, 500);

  const getArrayParam = (param) => {
    const value = searchParams.getAll(param);
    return value.length > 0 ? value : null;
  };

  const getParam = (param) => {
    const value = searchParams.get(param);
    return value !== null ? value : null;
  };

  const { ...restQuery } = query;
  const taskSearchKey = getParam('search') ? getParam('search') : '';
  const debouncedSearch = useDebounce(taskSearchKey, 500);

  // filter ---
  const priority = getArrayParam('priority_id');
  const filter_start_date = getParam('task_start_date');
  const filter_end_date = getParam('task_end_date');
  const label = getArrayParam('label_id');
  const project_id = getArrayParam('project_id');
  const template_id = getArrayParam('template_id');
  const sort = getArrayParam('sort');
  const assignee_id = getArrayParam('user_id');
  // const status = getArrayParam('status');
  const listType = getParam('list_type');
  const task_status = parseInt(getParam('task_status'));
  const client_id = getArrayParam('client_id');

  const filterToUrl = {};
  if (priority) filterToUrl.priority = priority;
  if (filter_start_date) filterToUrl.filter_start_date = filter_start_date;
  if (filter_end_date) filterToUrl.filter_end_date = filter_end_date;
  if (label) filterToUrl.label = label;
  if (project_id) filterToUrl.project_id = project_id;
  if (client_id) filterToUrl.client_id = client_id;
  if (template_id) filterToUrl.template_id = template_id;
  if (assignee_id) filterToUrl.assignee_id = assignee_id;

  useEffect(() => {
    const sortToUrl = taskSortPayload(sort);

    if (sortToUrl?.length > 0) {
      setQuery((prev) => ({
        ...prev,
        pageVo: { ...prev.pageVo, sort: sortToUrl },
      }));
    } else {
      setQuery((prev) => {
        // eslint-disable-next-line no-unused-vars
        const { sort, ...restPageVo } = prev.pageVo; // Destructure to remove 'sort' key
        return {
          ...prev,
          pageVo: restPageVo,
        };
      });
    }
    setIsSortProcessed(true); // Mark sort processing as done
  }, [JSON.stringify(sort)]);
  const sortCount = sort?.length;

  // const filterCount = Object.keys(filterToUrl).filter((key) => key !== 'assignee_id').length;
  const filterCount =
    Object.keys(filterToUrl).filter((key) => key !== 'assignee_id').length +
    (task_status === STATUS.ARCHIVE ? 1 : 0);

  // task List
  const {
    data: taskStatusList,
    isLoading: statusLoading,
    refetch: taskStatusListRefetch,
  } = useQuery({
    queryKey: ['taskStatusList', filterToUrl, debouncedSearch, currentView, listType],
    queryFn: () =>
      statusService.statusWithTaskCount({
        select: ['id', 'name', 'color', 'default_name', 'icon'],
        task_filter: {
          ...filterToUrl,
          searchKey: debouncedSearch,
          ...(listType === MY_TASK_LIST_TYPE
            ? { view_type: TASK_LIST_VISIBILITY_TYPE.INDIVIDUAL }
            : {}),
        },
      }),
    select: (data) => data.data,
    enabled: currentView == TASK_TABS.TASKS && task_status !== STATUS.ARCHIVE,
    placeholderData: keepPreviousData,
  });

  const defaultStatusName = searchParams.get('status');
  const selectedStatus = taskStatusList?.find(
    (status) =>
      status.default_name?.toString()?.toLowerCase() ===
      defaultStatusName?.toString()?.toLowerCase(),
  );

  const queryKey = [
    'task',
    restQuery,
    query?.pageVo?.pageNo,
    debouncedSearch,
    selectedStatus?.id,
    filterToUrl,
    selectedStatus?.default_name,
    currentView,
    task_status,
    listType,
  ];

  const getPayLoad = (query) => {
    const encodedQuery = Object.keys(query).reduce((acc, key) => {
      acc[key] = typeof query[key] === 'object' ? JSON.stringify(query[key]) : query[key];
      return acc;
    }, {});
    setULRQuery(jsonToUrlEncoded(encodedQuery));
    return query;
  };

  // to fetch task list
  const {
    data: taskData,
    // error,
    isLoading: taskLoading,
    isSuccess,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
    // isFetching,
    refetch: taskListRefetch,
  } = useInfiniteQuery({
    queryKey,
    queryFn: ({ pageParam = 1 }) =>
      taskService.taskListAdvanced(
        getPayLoad({
          ...restQuery,
          status_id: task_status === STATUS.ARCHIVE ? -1 : selectedStatus?.id,
          default_name: task_status === STATUS.ARCHIVE ? 'all' : selectedStatus?.default_name,
          ...(selectedStatus?.completed_status_id
            ? { completed_status_id: selectedStatus?.completed_status_id }
            : {}),
          ...(selectedStatus?.is_verified?.toString()
            ? { is_verified: selectedStatus.is_verified }
            : {}),
          ...filterToUrl,
          ...(task_status ? { status: task_status } : {}),
          searchKey: debouncedSearch,
          ...(listType === MY_TASK_LIST_TYPE
            ? { view_type: TASK_LIST_VISIBILITY_TYPE.INDIVIDUAL }
            : {}),
          pageVo: { ...restQuery.pageVo, pageNo: pageParam },
          // ...(selectedStatus?.default_name === 'to_verify' ? { verify: true } : {}),
        }),
      ),
    enabled:
      (task_status === STATUS.ARCHIVE ? true : Boolean(selectedStatus?.id)) &&
      (!sort || isSortProcessed) &&
      currentView == TASK_TABS.TASKS,

    // enabled:
    //   Boolean(selectedStatus?.id) && (!sort || isSortProcessed) && currentView == TASK_TABS.TASKS, // Enable query only if no sort or sort is processed
    getNextPageParam: (response) =>
      response.data.page < response.data.pages ? response.data.page + 1 : undefined,
    placeholderData: keepPreviousData,
    refetchOnWindowFocus: false,
  });

  // to fetch task user list
  const { data: userList, refetch: userListRefetch } = useQuery({
    queryKey: ['task-list-user-list'],
    queryFn: () =>
      userService.getRoleBasedUserList({
        action: USER_LIST_ACTIONS.TASK_FILTER,
        select: taskAssignUserSelect,
      }),
    select: (response) => response.data.rows,
    enabled: listType !== TASK_LIST_VISIBILITY_TYPE,
  });

  // to refetch after task add - task list and task status list
  const refetchData = () => {
    taskListRefetch();
    taskStatusListRefetch();
    userListRefetch();
  };

  if (statusLoading) {
    return (
      <div className='page-height d-flex justify-content-center align-items-center'>
        <LoadingIcon size={60} />
      </div>
    );
  }

  return (
    <div className='fade-entry'>
      <div className=' url-container'>
        <div className='d-flex align-items-center  breadcrumbs'>
          <IconLabelSet
            label={'Home'}
            fontWeight={400}
            icon={<Home size={13} />}
            onClick={() => navigate(`/`)}
          />{' '}
          <img className='slash-img-icon-size ms-1 mt-1' src='/icons/Lineicon.png' alt='' />
          <IconLabelSet
            label={`${
              listType === 'my-task'
                ? getSidebarLabel('my-responsibility')
                : getSidebarLabel('team-responsibility')
            }`}
            fontWeight={500}
            icon={<FileText size={13} />}
            color={'var(--icon-light)'}
            onClick={() =>
              navigate(
                `/${RT.TASK_LIST}?status=in-progress&list_type=${listType}${
                  assignee_id ? `&user_id=${assignee_id}` : ''
                }`,
              )
            }
          />{' '}
        </div>
      </div>
      <section>
        <TaskListView
          urlQuery={urlQuery}
          query={query}
          // key={'main-task-list' + status}
          setQuery={setQuery}
          taskData={taskData}
          userList={userList}
          searchParams={searchParams}
          setSearchParams={setSearchParams}
          loading={taskLoading}
          // pending={isFetching}
          isSuccess={isSuccess}
          taskStatusList={taskStatusList}
          filterCount={filterCount}
          sortCount={sortCount}
          refetch={refetchData}
          assignee_id={assignee_id ?? null}
          hasNextPage={hasNextPage}
          fetchNextPage={fetchNextPage}
          isFetchingNextPage={isFetchingNextPage}
          currentView={currentView}
          setCurrentView={setCurrentView}
          isArchivedList={task_status === STATUS.ARCHIVE}
          // selectedUsers = {assignee_id}
        />
      </section>
    </div>
  );
};
