import { INPUT_BORDERCOLOR } from '../_constants';

export const toolbarOptions = {
  options: ['inline', 'list'],
  inline: {
    options: ['bold', 'italic'],
  },
  list: {
    options: ['unordered', 'ordered'],
  },
};
export const okrValidationConstant = {
  ASSIGNEE_ID_REQUIRED: 'Assignee id required',
  INVALID_ASSIGNEE_ID: 'Invalid assignee id',
  NAME_REQUIRED: 'Name is required',
  INVALID_NAME: 'Enter a valid name',
  DESCRIPTION_REQUIRED: 'Description is required',
  INVALID_DESCRIPTION: 'Enter a valid Description',
  INTERVAL_REQUIRED: 'Interval is required',
  INVALID_INTERVAL: 'Enter a valid Interval',

  TYPE_REQUIRED: 'objective type is required',

  VISIBILITY_REQUIRED: 'Visibility is required',
  INVALID_VISIBILITY: 'Enter a valid Visibility',
  START_DATE_REQUIRED: 'Task start date is required',
  INVALID_START_DATE: 'Invalid start date',
  END_DATE_REQUIRED: 'Task end date is required',
  INVALID_EXPECTED_HOURS: 'Invalid expected hours',
  VISIBILITY_TEAM_REQUIRED: 'Visibility team is required',
  INVALID_VISIBILITY_TEAM: 'Enter a valid Visibility team',
  MEASUREMENT_REQUIRED: 'Measurement is required',
  INVALID_MEASUREMENT: 'Enter a valid Measurement',
  RESULT_TYPE: 'Result type is required',
  INVALID_RESULT_TYPE: 'Enter a valid Result type',
  RESULT_CURRENT: 'Start is required',
  INVALID_RESULT_CURRENT: 'Enter a valid Start',
  RESULT_TARGET: 'Target is required',
  INVALID_RESULT_TARGET: 'Enter a valid Target',
  RESULT_BINARY: 'Binary Selection is required',
  INVALID_RESULT_BINARY: 'Enter a valid Binary Selection',
  INCORRECT_PROGRESS: 'incorrect progress',
  END_DATE_MUST_BE_GREATER_THAN_START_DATE: 'End date must be grater than start date',
  PROGRESS_START_END_MISS_MATCH: 'End must be grater than start ',

  // CURRENT_PROGRESS_REQUIRED:'Enter PRogress'
};
export const taskVisibleTypes = {
  VISIBLE_TO_RELATED_TASK_USERS: 1,
  VISIBLE_TO_SELECTED_USERS: 2,
};

export const objectiveType = {
  company: 1,
  team: 2,
  personal: 3,
};

// export const taskType = {
//   task: 1,
//   objective: 2,
//   key_result: 3,
// };

export const DropDownStyle = {
  container: (styles) => {
    return {
      fontSize: '13px',
      fontWeight: '400',
      ...styles,
    };
  },

  control: (styles, { isFocused, selectProps }) => {
    const hasError = selectProps?.className?.includes('field-error');
    return {
      ...styles,
      padding: '2px 1px 2px 4px',
      // height: '34px',
      minHeight: '25px',
      borderRadius: '8px',
      borderColor: hasError ? '#EA4D4D' : isFocused ? '#3454D1' : INPUT_BORDERCOLOR,
      boxShadow:
        isFocused && !hasError
          ? '0 0 0 3px rgba(52, 84, 209, 0.25)'
          : hasError && isFocused
          ? ' 0 0 0 3px rgba(255, 0, 0, 0.15)'
          : styles.boxShadow || 'var(--input-box-shadow)',
      '&:hover': {
        borderColor:
          isFocused && !hasError
            ? '#3454D1'
            : hasError
            ? '#EA4D4D'
            : styles['&:hover']?.borderColor || styles.borderColor,
      },
    };
  },
  indicatorsContainer: (styles) => {
    return {
      ...styles,
      minHeight: '25px',
      // height: '33px',
      color: '#87909E',
    };
  },
  menu: (styles) => {
    return {
      ...styles,
      zIndex: 100, // Set your desired z-index value here
      minWidth: '170px',
      fontSize: '13px',
    };
  },
  menuList: (styles) => {
    return {
      ...styles,
      maxHeight: '200px',
      // zIndex: 100000, // Set your desired z-index value here
    };
  },
  singleValue: (styles) => {
    return {
      ...styles,
      fontWeight: '500',
    };
  },
  indicatorSeparator: () => ({
    display: 'none',
  }),
  dropdownIndicator: (styles) => {
    return {
      ...styles,
      color: '#2A2E34',
      '&:hover': {
        color: '#2A2E34',
      },
    };
  },
};

export const timeIntervalOptions = [
  { label: 'Custom', value: 0 },
  { label: '3 Months', value: 1 },
  { label: '6Months', value: 2 },
  { label: '1 year', value: 3 },
];
export const measurementOptions = [
  { label: 'Manually', value: 1 },
  { label: 'Based on child status', value: 2 },
];

export const objectiveVisibility = {
  Team: 1,
  All: 2,
  // User: 2,
};

export const childTypeOptions = [
  { label: 'Team', value: 2 },
  { label: 'Personal', value: 3 },
];

export const childTypeOptionsForTeam = [{ label: 'Personal', value: 3 }];

// constants for progress

export const progressType = {
  manually: 1,
  basedOnChild: 2,
};

export const manualProgressType = {
  percentage: 1,
  numeric: 2,
  binary: 3,
};

export const okrFormAction = {
  Close: 'Close',
  Save: 'Save',
};

export const EXPIRY = { EXPIRED: 0, NOT_EXPIRED: 1 };
