/* eslint-disable no-unused-vars */
import { ArrowUpRight } from 'lucide-react';
import React from 'react';
import { CompletedDot, Description, InfoIcon, LoadingIcon } from '../Utils/SvgIcons';
import {
  generateIconBasedOnResult,
  generateSearchResult,
  TAB_VALUE_CONST,
} from './GlobalSearchHelperFunction';

export const GlobalSearchResult = ({
  currentTab,
  result,
  getNavigation = () => {},
  isLoading = false,
}) => {
  const tabsToShow =
    currentTab === TAB_VALUE_CONST.All.value
      ? Object.values(TAB_VALUE_CONST)
          .filter((tab) => tab.value !== TAB_VALUE_CONST.All.value)
          .map((tab) => tab.value)
      : [currentTab];

  if (isLoading) {
    return (
      <div className='text-center'>
        <LoadingIcon size={40} />
      </div>
    );
  }

  const isNoData = tabsToShow?.every((tab) => result?.[tab]?.count === 0);

  if (isNoData) {
    return (
      <div
        className='d-flex  align-items-center h-100 fz-13px gap-1 mt-4 justify-content-center'
        style={{ color: 'var(--second-text-color)' }}
      >
        <InfoIcon size={16} /> No Data
      </div>
    );
  }

  return (
    <div>
      {tabsToShow?.map((tab) => {
        const currentData = result?.[tab]; // Accessing `tasks`, `projects`, etc., dynamically
        const resultData = generateSearchResult(tab, currentData?.rows);
        const currentDataCount = result?.[tab]?.count;

        return (
          <div key={tab}>
            {Boolean(currentDataCount) && (
              <div className='result-heading mb-2'>{resultData?.title}</div>
            )}

            {currentData?.rows?.map((data, index) => (
              <div
                key={index}
                className='result-item-container pointer'
                onClick={() => {
                  getNavigation(tab, data);
                }}
              >
                <div className='d-flex gap-1 align-items-center'>
                  <span>
                    {tab === TAB_VALUE_CONST.CHAT.value || tab === TAB_VALUE_CONST.USER.value
                      ? generateIconBasedOnResult(data, tab)
                      : resultData?.icon}
                  </span>
                  {tab !== TAB_VALUE_CONST.CHAT.value && tab !== TAB_VALUE_CONST.UPDATE.value && (
                    <div className='d-flex flex-column ms-1' style={{ fontWeight: 500 }}>
                      <span className='d-flex'>
                        <span className='global-search-name'>{data.name}</span>
                        {data.isMatchedByDescription && (
                          <span className='ms-2'>
                            <Description width={12} height={15} color={'#87909E'} />
                          </span>
                        )}
                      </span>

                      {data?.progress_status && (
                        <span style={{ fontSize: 12 }} className='d-flex gap-1 align-items-center'>
                          <CompletedDot color={data?.progress_status?.color} />
                          <span className='text-capitalize'>{data?.progress_status?.status}</span>
                        </span>
                      )}
                    </div>
                  )}
                  {(tab === TAB_VALUE_CONST.CHAT.value || tab === TAB_VALUE_CONST.UPDATE.value) && (
                    <div className='d-flex flex-column ms-1' style={{ fontWeight: 500 }}>
                      <span style={{ fontWeight: 700 }}>{data?.related_info?.name}</span>
                      <span
                        style={{ fontSize: 12, color: 'var(--main-text-color)' }}
                        className='d-flex gap-1 align-items-center'
                      >
                        <span className='global-search-chat-message'>{data?.message}</span>
                      </span>
                    </div>
                  )}
                </div>
                <div
                  className='redirection-icon'
                  // onClick={() => {
                  //   getNavigation(tab, data);
                  // }}
                >
                  <ArrowUpRight color='#87909e' strokeWidth={'1.5'} size={18} />
                </div>
              </div>
            ))}
          </div>
        );
      })}
    </div>
  );
};
