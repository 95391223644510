import React, { useEffect, useRef, useState } from 'react';

export const DraggableFloatingIcon = ({ children }) => {
  const [position, setPosition] = useState({ x: 100, y: 100 });
  const [isDragging, setIsDragging] = useState(false);
  const clickTimeoutRef = useRef(null);

  useEffect(() => {
    if (!isDragging) {
      setPosition((prev) => ({
        ...prev,
        x: 0,
      }));
    }

    const handleMouseMove = (e) => {
      if (isDragging) {
        // Calculate new position
        let newX = window.innerWidth - e.clientX; // Adjust based on icon size
        // let newX = e.clientX; // Adjust based on icon size
        let newY = e.clientY; // Adjust based on icon size

        let container = document.querySelector('.draggable-floating-icon');

        const boxSize = container.scrollWidth;

        // Boundary checks
        if (newX < 0) newX = 0;
        if (newY < 0) newY = 0;
        if (newX > window.innerWidth - boxSize) newX = window.innerWidth - boxSize; // Adjust based on icon size
        if (newY > window.innerHeight - 50) newY = window.innerHeight - 50; // Adjust based on icon size

        setPosition({ x: newX, y: newY });
      }
    };

    const handleMouseUp = () => {
      setIsDragging(false);
    };

    if (isDragging) {
      window.addEventListener('mousemove', handleMouseMove);
      window.addEventListener('mouseup', handleMouseUp);
    } else {
      window.removeEventListener('mousemove', handleMouseMove);
      window.removeEventListener('mouseup', handleMouseUp);
    }

    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
      window.removeEventListener('mouseup', handleMouseUp);
    };
  }, [isDragging]);

  const handleMouseDown = () => {
    if (clickTimeoutRef.current) {
      clearTimeout(clickTimeoutRef.current);
    }

    clickTimeoutRef.current = setTimeout(() => {
      setIsDragging(true);
    }, 100); // Delay to differentiate between click and double-click
  };

  const handleMouseUp = () => {
    if (clickTimeoutRef.current) {
      clearTimeout(clickTimeoutRef.current);
    }
    setIsDragging(false);
  };

  const handleDoubleClick = (e) => {
    e.stopPropagation();
    if (clickTimeoutRef.current) {
      clearTimeout(clickTimeoutRef.current);
      clickTimeoutRef.current = null;
    }
  };

  return (
    <div
      className='draggable-floating-icon'
      style={{
        userSelect: 'none',
        zIndex: 9999,
        position: 'absolute',
        right: position.x,
        top: position.y,
        fontSize: 14,
        cursor: isDragging ? 'grabbing' : 'grab',
        transition: isDragging ? 'none' : 'right 0.1s ease, top 0.1s ease',
      }}
      onMouseDown={handleMouseDown}
      onMouseUp={handleMouseUp}
      onDoubleClick={handleDoubleClick}
    >
      <div>{children}</div>
    </div>
  );
};
