import moment from 'moment';

export const toolbarOptions = {
  options: ['inline', 'list'],
  inline: {
    options: ['bold', 'italic'],
  },
  list: {
    options: ['unordered', 'ordered'],
  },
};
export const taskValidationConstant = {
  TASK_NAME_REQUIRED: 'Task Name is required',
  INVALID_TASK_NAME: 'Invalid Task Name',
  INVALID_NAME: 'Enter a valid name',
  INVALID_TASK_DATES: 'The task dates with expected time is not valid',
  TASK_START_DATE_REQUIRED: 'Task start date is required',
  INVALID_START_DATE: 'Invalid start date',
  INVALID_EXPECTED_TIME: 'Invalid Expected Time',
  TASK_END_DATE_REQUIRED: 'Task end date is required',
  EXPECTED_HOURS_REQUIRED: 'Expected hour is required',
  EXPECTED_TIME_REQUIRED: 'Expected Time is required',
  INVALID_EXPECTED_HOURS: 'Invalid expected hours',
  EXPECTED_MINUTES_REQUIRED: 'Expected minutes is required',
  INVALID_EXPECTED_MINUTES: 'Invalid expected minutes',
  INVALID_ASSIGNEE_ID: 'Invalid assignee id',
  ASSIGNEE_ID_REQUIRED: 'Assignee required',
  INVALID_OKR_PARENT_ID: 'Invalid okr parent id',
  OKR_PARENT_ID_REQUIRED: 'okr parent id required',
  TIME_IS_REQUIRED: 'Expected Time is required',
  TASK_END_DATE_GT_START_DATE: 'End date must be after start date',
  PRIORITY_IS_REQUIRED: 'Priority is required',
  TAGS_IS_REQUIRED: 'Tags is required',
  VERIFY_USERS_REQUIRED: 'Verify users required',
  TASK_DESCRIPTION_IS_REQUIRED: 'Description is required',
  // CONTACT_REQUIRED: `${LABEL_OF_CLIENT} selection is required`,
};
export const taskVisibleTypes = {
  VISIBLE_TO_RELATED_TASK_USERS: 1,
  VISIBLE_TO_SELECTED_USERS: 2,
};
export const templateSelectValues = [
  'id',
  'name',
  'verifying_users',
  'related_tasks',
  'validation_fields',
  'is_verify_user_changeable',
  'subtasks',
  'labels',
  'has_time',
  'start_time',
  'end_time',
  'main_task_name',
  'expected_hours',
];
export const USER_LIST_ACTIONS = {
  TASK_ADD: 'TASK-ADD',
  TASK_EDIT: 'TASK-EDIT',
  OKR_ADD: 'OKR-ADD',
};
export const TaskSingleLayoutMenu = [
  { name: 'description', label: 'Description' },
  { name: 'subtask', label: 'Sub Task' },
  { name: 'related-task', label: 'Related Task' },
  { name: 'reminders', label: 'Reminder' },
  { name: 'work-history', label: 'Work History' },
  { name: 'recurring-task', label: 'Recurring Task' },
];
export const labelSelectValues = ['id', 'name', 'text_color', 'label_color', 'createdAt', 'status'];
export const taskSingleData = [
  'id',
  'name',
  'note',
  'task_start_date',
  'task_end_date',
  'project_id',
  'milestone_id',
  'status',
  'expected_hours',
  'priority',
  'verify_by',
  'parent_id',
  'completed_date',
  'started_date',
  'verified_date',
  'creator_id',
  'assignee_id',
  'template_id',
  'task_start_mode',
  'failed_date',
  'task_visibility_type',
  'subtasks',
];
export const TASK_ACTIONS = {
  VIEW: 'VIEW',
  EDIT: 'EDIT',
  ALL: 'VIEW_ALL',
};

export const orderSequence = [
  { value: '1', label: '1' },
  { value: '2', label: '2' },
  { value: '3', label: '3' },
  { value: '4', label: '4' },
  { value: '5', label: '5' },
  { value: '6', label: '6' },
  { value: '7', label: '7' },
  { value: '8', label: '8' },
  { value: '9', label: '9' },
];
export const subTaskSelect = [
  'id',
  'content',
  'status',
  'startDate',
  'start_date_milli',
  'end_date_milli',
  'task_id',
];
export const reminderSelect = [
  'id',
  'title',
  'note',
  'reminder_date',
  'status',
  'notified',
  'email_notify_enabled',
  'task_id',
  'user_id',
];

export const taskActionsType = {
  BUTTON: 'BUTTON',
  DROPDOWN: 'DROPDOWN',
};
export const taskButtonLabel = {
  START: 'Start',
  COMPLETE: 'Complete',
  Verify: 'Verify',
  REPEAT: 'Repeat',
  PAUSE: 'Pause',
  STOP: 'Stop',
};
export const taskButtonName = {
  START: 'START',
  STOP: 'STOP',
};
export const fieldTypes = {
  TEXT: 'text',
  TEXTAREA: 'textarea',
  SELECT: 'select',
  TIME: 'time',
  INPUT: 'input',
  DATE: 'date',
};

export const SubtaskStatus = {
  1: 'To Do',
  2: 'In Progress',
  3: 'Completed',
};
export const SubtaskStatusArray = [
  { value: 1, label: 'To Do', color: '#4786FF' },
  { value: 2, label: 'In Progress', color: '#FF7E47' },
  { value: 3, label: 'Completed', color: '#3EC595' },
];
export const reminderState = {
  email_notify_enabled: false,
  title: '',
  note: '',
  reminder_date: moment().format('YYYY-MM-DDTHH:mm'),
};

export const today = moment().utcOffset(moment().utcOffset()).format('YYYY-MM-DD');

export const formMode = {
  FORM: 'form',
  INLINE: 'inline',
};
export const TASK_VALIDATIONS_FIELD = {
  startDate: { field: 'task_start_date', isParentOnly: false },
  endDate: { field: 'task_end_date', isParentOnly: false },
  startTime: { field: 'start_time', isParentOnly: false },
  endTime: { field: 'end_time', isParentOnly: false },
  requestedHours: { field: 'expected_hours', isParentOnly: false },
  priority: { field: 'priority', isParentOnly: false },
  assignedUser: { field: 'assignee_id', isParentOnly: false },
  verifyUser: { field: 'verifying_users', isParentOnly: true },
  subtasks: { field: 'subtasks', isParentOnly: false },
};

export const customSelectBoxStyles = {
  control: (style) => ({
    ...style,
    minHeight: 30,
    width: 170,
    zIndex: 99,
    maxWidth: '31.8vw',
    border: '0.5px solid var(--input-border)',
    whiteSpace: 'no-wrap',
    backgroundColor: 'var(--bg-primary)',
  }),
  container: (style) => ({
    ...style,
    height: 30,
    textTransform: 'capitalize',
    backgroundColor: 'var(--bg-primary)',
    fontSize: 13,
  }),
  placeholder: (style) => ({ ...style, fontSize: 13, color: '#87909E' }),
  option: (style) => ({ ...style, fontSize: 12 }),
  menu: (style) => ({
    ...style,
    width: 170,
    top: null,
    fontSize: 13,
    backgroundColor: 'var(--bg-primary)',
    zIndex: 999,
    color: '#7e7e7e',
    textTransform: 'capitalize',
  }),
  valueContainer: (style) => ({
    ...style,
    scrollbarWidth: 'thin',
    overflow: 'auto',
    flexWrap: 'nowrap',
    fontSize: 13,
    color: '#87909E',
  }),
  multiValue: (style) => ({
    ...style,
    minWidth: 'none',
  }),
  singleValue: (style) => ({
    ...style,
    color: '#87909E',
    fontSize: 13,
    fontWeight: 500,
  }),
};
export const customTemplateSelectBoxStyles = {
  control: (style) => ({
    ...style,
    border: 'none', // Remove the border
    boxShadow: 'none', // Remove the box shadow
    '&:hover': {
      border: 'none', // Remove the border on hover
    },
    minHeight: 33,
    width: 150,
    zIndex: 99,
    maxWidth: '31.8vw',
    backgroundColor: 'var(--bg-primary)',
  }),

  container: (style) => ({ ...style, height: 33, textTransform: 'capitalize' }),
  placeholder: (style) => ({ ...style, fontSize: 12, color: '#87909E' }),
  menu: (style) => ({
    ...style,
    width: 150,
    top: null,
    fontSize: 12,
    backgroundColor: 'var(--bg-primary)',
    zIndex: 999,
    color: '#7e7e7e',
    textTransform: 'capitalize',
  }),
  valueContainer: (style) => ({
    ...style,
    scrollbarWidth: 'thin',
    overflow: 'auto',
    flexWrap: 'nowrap',
    color: '#87909E',
  }),
  multiValue: (style) => ({
    ...style,
    minWidth: 'none',
  }),
  singleValue: (style) => ({
    ...style,
    color: '#87909E',
    fontSize: '13px',
  }),
  indicatorSeparator: (style) => ({
    ...style,
    display: 'none',
  }),
  dropdownIndicator: (style) => ({
    ...style,
    padding: '0px',
  }),
};

export const CustomStatusSelectBoxStyles = {
  control: (style) => ({
    ...style,
    height: 25,
    minHeight: 20,
    display: 'flex',
    padding: '0px 3px',
    alignItems: 'center',
    gap: 1,
    borderRadius: 8,
    border: '1px solid  var(--border-color)',
    background: 'var(--bg-primary)',
    width: 118,
  }),
  singleValue: (style) => ({ ...style, fontSize: 11 }),
  input: (style) => ({ ...style, margin: 0, padding: 0, fontSize: 11 }),
  indicatorSeparator: () => ({ width: 0 }),
  indicatorsContainer: (style) => ({
    ...style,
    padding: '0px !important',
    height: 'inherit',
  }),
  placeholder: (style) => ({ ...style, fontSize: 11 }),
  option: (style) => ({
    ...style,
    height: 25,
    fontSize: 11,
    display: 'flex',
    alignItems: 'center',
  }),
};

export const customFlagSelectBoxStyles = {
  control: (style) => ({
    ...style,
    width: 100,
    border: 'none', // Remove the border
    boxShadow: 'none', // Remove the box shadow
    '&:hover': {
      border: 'none', // Remove the border on hover
      cursor: 'pointer',
    },
    backgroundColor: 'var(--bg-primary)',
  }),

  placeholder: (style) => ({ ...style, fontSize: 12, color: '#87909E' }),
  menu: (style) => ({
    ...style,
    width: 100,
    top: null,
    fontSize: 12,
    backgroundColor: 'var(--bg-primary)',
    zIndex: 999,
    textTransform: 'capitalize',
    color: '#7e7e7e',
  }),
  valueContainer: (style) => ({
    ...style,
    scrollbarWidth: 'thin',
    overflow: 'auto',
    flexWrap: 'nowrap',
    color: '#87909E',
    padding: 0,
  }),
  multiValue: (style) => ({
    ...style,
    minWidth: 'none',
  }),
  indicatorSeparator: (style) => ({
    ...style,
    display: 'none',
  }),
  dropdownIndicator: (style) => ({
    ...style,
    padding: '0px',
    display: 'none',
  }),
};
export const customFlagSelectBoxStylesForTimesheet = {
  control: (style) => ({
    ...style,
    width: '100%',
    // border: 'none', // Remove the border
    // boxShadow: 'none', // Remove the box shadow
    '&:hover': {
      // border: 'none', // Remove the border on hover
      // cursor: 'pointer',
    },
  }),

  placeholder: (style) => ({
    ...style,
    fontSize: 14,
    color: '#2A2E34',
    fontWeight: '500',
    textDecoration: 'none',
  }),
  menu: (style) => ({
    ...style,
    width: '100%',
    top: null,
    fontSize: 12,
    backgroundColor: 'var(--bg-primary)',
    zIndex: 999,
    textTransform: 'capitalize',
  }),
  valueContainer: (style) => ({
    ...style,
    scrollbarWidth: 'thin',
    overflow: 'auto',
    flexWrap: 'nowrap',
    color: '#87909E',
    padding: 0,
  }),
  multiValue: (style) => ({
    ...style,
    minWidth: 'none',
  }),
  indicatorSeparator: (style) => ({
    ...style,
    display: 'none',
  }),
  dropdownIndicator: (style) => ({
    ...style,
    padding: '0px',
    color: '#000',
    // display: 'none',
  }),
};

export const customUserSelectBoxStyles = {
  control: (styles) => ({
    ...styles,
    minHeight: '30px',
    fontSize: '13px',
    maxWidth: '160px',
    border: 'none', // Remove the border
    boxShadow: 'none', // Remove the box shadow
    '&:hover': {
      border: 'none', // Remove the border on hover
      cursor: 'pointer',
    },
    background: 'var(--bg-primary)',
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
  menu: (styles) => ({
    ...styles,
    width: '160px', // Adjusts to the width of the control
  }),
  indicatorsContainer: (styles) => ({
    ...styles,
    display: 'none',
  }),
  valueContainer: (styles) => ({
    ...styles,
    padding: 0,
  }),
  menuList: (styles) => ({
    ...styles,
    maxHeight: '170px',
  }),
};

export const STATUS = {
  ACTIVE: 1,
  REMOVED: 0,
  ARCHIVE: 2,
};

export const taskErrorKeyValues = {
  task_start_date: 'Start Date',
  task_end_date: 'End Date',
  expected_time: 'Time',
  expected_hours: 'Hours ',
  expected_minutes: 'Minutes',
  assignee_id: 'Assign User',
  priority: 'Priority',
  label: 'Label',
  note: 'Description',
  verifying_users: 'Verify Users',
};

export const taskType = {
  timeBounded: 1,
  nonTimeBounded: 3,
};

export const taskAssignUserSelect = [
  'id',
  'first_name',
  'last_name',
  'gender',
  'image_url',
  'compressed_image_url',
];

export const CustomStatusSelectBoxStylesTaskList = {
  control: (style, state) => ({
    ...style,
    height: 25,
    minHeight: 20,
    display: 'flex',
    // padding: '0px 3px',
    alignItems: 'center',
    gap: 1,
    borderRadius: 100,
    border: state.isFocused ? '1px solid  #4a6dc6' : '1px solid var(--input-border)',
    background: '#FFF',
    minWidth: 'fit-content',
    padding: '0 12px 0 2px ',
    // minWidth: 100,
    cursor: 'pointer',
    boxShadow: state.isFocused ? '0 0 0 1px #4a6dc6' : 'none', // Optional: set box shadow to red when focused
    // '&:hover': {
    //   boxShadow: 'none', // Ensure background color remains red on hover if selected
    // },
  }),
  singleValue: (style) => ({ ...style, fontSize: 11 }),
  input: (style) => ({ ...style, margin: 0, padding: 0, fontSize: 11 }),
  indicatorSeparator: () => ({ width: 0 }),
  indicatorsContainer: (style) => ({
    ...style,
    padding: '0px !important',
    height: 'inherit',
    display: 'none',
  }),
  placeholder: (style) => ({ ...style, fontSize: 11 }),
  option: (style, state) => ({
    ...style,
    height: 30,
    fontSize: 11,
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    color: 'black',
    backgroundColor: state.isSelected ? 'none' : state.isFocused ? '#F0F1F3' : 'transparent', // Set background color to red for selected item
    '&:hover': {
      backgroundColor: '#F0F1F3',
    },
    position: 'relative',
    paddingLeft: state.isSelected ? '10px' : '10px', // Adjust padding to make space for the checkmark
  }),
  menu: (style) => ({
    ...style,
    minWidth: '200px',
  }),
};
export const imageTypes = [
  'png',
  'gif',
  'jpg',
  'jpeg',
  'jfif',
  'pjpeg',
  'pjp',
  'svg',
  'bmp',
  'ico',
  'tiff',
  'tif',
  'webp',
  'avif',
  'apng',
  'cur',
  'pcx',
  'cdr',
  'cmx',
  'dib',
  'emf',
  'eps',
  'fax',
  'fig',
  'gbr',
  'ico',
  'im1',
  'jp2',
  'jpc',
  'jpf',
  'jpx',
  'mac',
  'pbm',
  'pcd',
  'pct',
  'pgm',
  'pic',
  'pict',
  'png',
  'pnm',
  'ppm',
  'psd',
  'ras',
  'rgb',
  'sgi',
  'tga',
  'wmf',
  'xbm',
  'xpm',
];

export const videoTypes = [
  'mp4',
  'mpeg4',
  'avi',
  'mov',
  'wmv',
  'flv',
  'mkv',
  'webm',
  'ogv',
  'm4v',
  '3gp',
  '3g2',
];

export const documentTypes = [
  'pdf',
  'txt',
  'xls',
  'pptx',
  'html',
  'tiff',
  'doc',
  'docx',
  'xml',
  'zip',
  'rar',
  '7z',
  'odt',
  'ods',
  'odp',
  'rtf',
  'csv',
  'json',
  'db',
  'accdb',
  'epub',
  'fb2',
  'obi',
  'azw',
  'azw3',
  'chm',
  'djvu',
  'fbz',
  'ibooks',
  'indd',
  'key',
  'numbers',
  'pages',
  'pub',
  'xw',
  'wpd',
  'wps',
  'xlr',
  'xlsx',
  'xlsm',
  'xlsb',
  'xla',
  'xlam',
  'xll',
  'xlw',
];
export const documentTypesWithColor = {
  pdf: '#EA4D4D',
  txt: '#394056',
  xls: '#3EC595',
  pptx: '#FF7E47',
  html: '#F3F5F7',
  tiff: '#9747FF',
  doc: '#4786FF',
  docx: '#4786FF',
  xml: '#9747FF',
  zip: '#FA8101',
};

export const TASK_ACTIVITY = {
  CREATION: 'creation',
  STARTED: 'started',
  STOPPED: 'stopped',
  STATUS_CHANGE: 'status-change',
  // FAILED: 'failed', status change
  VERIFIED: 'verified',
  COMPLETED: 'completed',
  REPEAT: 'repeat',
  EDITED: 'edited',
  DELETED: 'deleted',
};
export const TASK_ACTIVITY_SENTENCE = {
  creation: `Created the `,
  started: 'Started the ',
  stopped: 'Stopped the ',
  'status-change': 'Changed the status of ',
  failed: 'Failed the ',
  verified: 'Verified the ',
  completed: 'Completed the ',
  repeat: 'Repeating the ',
  edited: 'Edited the',
};

export const TASK_LIST_EMPTY_MESSAGE = {
  subtask_empty: 'Your Subtasks are shows here.',
  reminder_empty: 'Your Reminders list are shows here.',
  related_task_empty: 'Your Related tasks are shows here.',
  log_empty: 'Your Logs are shows here.',
  dashboard_empty: 'Your widgets are shows here.',
};

export const taskLogField = {
  name: 'Task name',
  note: 'Description',
  task_start_date: 'Date`s',
  task_end_date: 'Date`s',
  project_id: 'Project',
  milestone_id: 'Milestone',
  status: 'Status',
  expected_hours: 'Expected time ',
  priority: 'Priority',
  assignee_id: 'Assignee',
  subtasks: 'Sub task',
  label: 'Label',
  order_seq: 'Flag',
  status_group_item_id: 'Status',
  template_id: 'Template',
  okr_parent_id: 'Related Okr',
};

export const STATUS_NAME = {
  DONE: 'Done',
  done: 'done',
  TODO: 'todo',
};

export const STATUS_ACTION = {
  DROPDOWN: 'DROPDOWN',
  BUTTON: 'BUTTON',
};

export const PROJECT_CHANGE_ITEMS = {
  project: 'project_id',
  milestone: 'milestone_id',
  status: 'status_group_item_id',
  template: 'template_id',
};

export const TASK_TABS = {
  TASKS: 'Tasks',
  CALENDAR: 'Calendar',
  BOARD: 'Board',
};
export const DEFAULT_STATUS = {
  TODO: 'todo',
  IN_PROGRESS: 'in-progress',
  DONE: 'done',
  FAILED: 'failed',
  PENDING: 'pending',
  TO_VERIFY: 'to-verify',
  UNDER_VERIFICATION: 'under-verification',
};

export const DEFAULT_STATUS_COLOR = {
  completed: '#1ca9771a',
  inprogress: '#ff8a001a',
  pending: '#00b2ff1a',
  to_verify: '#6f00ff1a',
  under_verification: '#fa00ff1a',
  failed: '#ea54401a',
};

export const TASK_LIST_VISIBILITY_TYPE = {
  INDIVIDUAL: 'individual',
  ALL: 'all',
};
