export const FileSystemConstant = {
  DIGITAL_DOCUMENT: 1,
  PHYSICAL_DOCUMENT: 2,
  ALL_DOCUMENT: 0,
};

export const SHARED_WITH_ALL_OPTION = {
  USER: { first_name: 'All', id: 0 },
  TEAM: { name: 'All', id: 0 },
  PROJECT: { name: 'All', id: 0 },
};
