//create search component

import { Search } from 'lucide-react';
import React from 'react';
export const SearchBar = ({ handleSearch, searchKey }) => {
  return (
    <div className={'search_contain '}>
      <input
        type='text'
        className='bodr_none'
        onChange={(e) => {
          handleSearch(e);
        }}
        value={searchKey}
        placeholder='Search'
      />
      <Search size={18} color='#87909E' />
    </div>
  );
};
