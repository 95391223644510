import React from 'react';

function RelatedTaskPreviewSection({ children }) {
  return (
    <section className='template-preview-section half template-container-horizontal-padding '>
      {children}
    </section>
  );
}

export default RelatedTaskPreviewSection;
