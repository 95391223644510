/* eslint-disable no-unused-vars */
import { Check, Pause, Repeat, X } from 'lucide-react';
import React, { useState } from 'react';
import ReactSelect from 'react-select';
import { CompletedDot, PlayIcon } from '../../Utils/SvgIcons';
import { Button } from '../../_components';
import ToolTip from '../../_components/Tooltip/ToolTip';
import { miscService } from '../../_services';
import { CustomOptionActionList } from '../TaskTableContent';
import {
  CustomStatusSelectBoxStylesTaskList,
  taskActionsType,
  taskButtonLabel,
  taskButtonName,
} from '../taskConstants';
import { StatusOption } from './CustomInputs';

export const ActionComponent = ({
  actionData,
  onSave = () => {},
  isLoading = { isLoad: false },
  isArchivedTask,
  taskStatus = null,
}) => {
  const [isConfirmationVisible, setIsConfirmationVisible] = useState(false);

  const [contextDropDown, setContextDropDown] = useState(false); //  dropdown context state
  const buttonActionsList =
    actionData?.find((action) => action?.type === taskActionsType.BUTTON)?.inputs || []; // for button list
  const dropDownActionsList =
    actionData?.find((action) => action?.type === taskActionsType.DROPDOWN)?.inputs || []; // for dropdown list
  const selectedDropDownItem = dropDownActionsList?.find((item) => item?.selected); // for show  selected dropdown item
  console.log(actionData, 'tasklist', selectedDropDownItem);

  //
  return (
    <div className='action-button-container pointer '>
      {!isConfirmationVisible && (
        <div
          role='button'
          className={`col d-flex  pointer ${dropDownActionsList?.length == 0 && 'd-none'}`}
        >
          <ReactSelect
            options={dropDownActionsList}
            value={dropDownActionsList.find(
              (status) =>
                parseInt(status?.status_group_item_id) ===
                parseInt(selectedDropDownItem?.status_group_item_id),
            )}
            menuPortalTarget={document.getElementById('MODAL')}
            onChange={(e) => {
              onSave({ ...e, type: taskActionsType.DROPDOWN });
            }}
            components={{ Option: CustomOptionActionList }}
            styles={{
              ...CustomStatusSelectBoxStylesTaskList,
              control: (style, state) => ({
                ...style,
                height: 30,
                minHeight: 30,
                background: miscService.hexToRgbAndReduceIntensity(selectedDropDownItem?.color, 10),

                display: 'flex',
                alignItems: 'center',
                gap: 1,
                borderRadius: 8,
                border: state.isFocused
                  ? '1px solid  #4a6dc6'
                  : '1px solid  ' +
                    miscService.hexToRgbAndReduceIntensity(selectedDropDownItem?.color, 6),

                padding: '0 12px 0 2px ',
                minWidth: 115,
                cursor: 'pointer',
                boxShadow: state.isFocused ? '0 0 0 1px #4a6dc6' : 'none',
              }),
            }}
            formatOptionLabel={(data, MetaData) => {
              return <StatusOption data={data} metaData={MetaData} />;
            }}
            classNames={{ menuPortal: () => 'z-index-100 fz-14px react-select-portal' }}
          />
        </div>
      )}
      <div
        role='button'
        className={`d-flex gap-1 position-relative`}
        onClick={(e) => {
          e.preventDefault();
          setContextDropDown(true);
        }}
      >
        {buttonActionsList.map((item, index) => {
          return (
            <ButtonWrapper
              key={index}
              item={item}
              onSave={onSave}
              isLoading={isLoading}
              isArchivedTask={isArchivedTask}
              isConfirmationVisible={isConfirmationVisible}
              setIsConfirmationVisible={setIsConfirmationVisible}
            />
          );
        })}
      </div>
    </div>
  );
};

const ButtonWrapper = ({
  item,
  onSave,
  isLoading,
  isArchivedTask,
  setIsConfirmationVisible,
  isConfirmationVisible,
}) => {
  const { type, label, value, color, disabled, name } = item;

  if (label == taskButtonLabel.COMPLETE || label == taskButtonLabel.Verify) {
    return (
      <>
        {!isConfirmationVisible && (
          <ToolTip tooltipText={label === 'Complete' ? 'Mark as Complete' : label} isModern>
            <Button.Container
              // style={{ backgroundColor: item?.color }}
              className={' btn action-button-complete-tick px-2 '}
              key={label}
              handleOk={() => {
                if (label === 'Complete') {
                  setIsConfirmationVisible(true);
                } else {
                  onSave({ ...item, type: taskActionsType.BUTTON });
                }
              }}
              isDisabled={disabled}
            >
              <Check size={18} strokeWidth={3} />
              {label !== 'Complete' && <Button.Title title={label}></Button.Title>}

              <Button.Loading
                isLoading={
                  isLoading?.isLoad &&
                  (isLoading?.value || isLoading?.value == 0) &&
                  item?.value == isLoading?.value
                }
                size={18}
                color='#2A2E34'
              />
            </Button.Container>
          </ToolTip>
        )}

        {isConfirmationVisible && (
          //   <Modal.Container>
          //   <Modal.View className={'status-delete'}>
          //     <Modal.Head
          //       containerClass={'delete-modal-head'}
          //       handleClose={() => setIsConfirmationVisible(false)}
          //       Heading={'Confirm Task Completion'}
          //     />
          //     <Modal.Description>
          //       <CircleCheck size={90} color='#fff' fill='#17c666' />
          //       <div className='del-description'>{DELETE_NOTE.TASK_COMPLETE}</div>
          //     </Modal.Description>
          //     <div className='modal_footer'>
          //       <Button.Container
          //         handleOk={() => setIsConfirmationVisible(false)}
          //         className={'outline-cancel-button'}
          //       >
          //         <Button.Title title={'Cancel'} />
          //       </Button.Container>
          //       <Button.Container
          //         handleOk={() => onSave({ ...item, type: taskActionsType.BUTTON })}
          //         className={'blue-btn'}
          //         isDisabled={disabled}
          //       >
          //         <Button.Title title={'Complete'} />
          //         <Button.Loading
          //           isLoading={
          //             isLoading?.isLoad &&
          //             (isLoading?.value || isLoading?.value == 0) &&
          //             item?.value == isLoading?.value
          //           }
          //           size={25}
          //         />
          //       </Button.Container>
          //     </div>
          //   </Modal.View>
          // </Modal.Container>
          <div style={{ display: 'flex', gap: 10 }}>
            <div className='icon-container'>
              <ToolTip isModern tooltipText={'Cancel'}>
                <X
                  onClick={(e) => {
                    e.stopPropagation();
                    setIsConfirmationVisible(false);
                  }}
                  size={25}
                />
              </ToolTip>
            </div>
            <div className='icon-container'>
              <ToolTip isModern tooltipText={'Confirm Task Completion'}>
                <Check
                  onClick={(e) => {
                    e.stopPropagation();
                    onSave({ ...item, type: taskActionsType.BUTTON });
                    setIsConfirmationVisible(false);
                  }}
                  size={25}
                />
              </ToolTip>
            </div>
          </div>
        )}
      </>
    );
  }
  if (!isArchivedTask && !isConfirmationVisible) {
    if (name == taskButtonName.START) {
      return (
        <Button.Container
          className={'btn action-button'}
          key={label}
          handleOk={() => onSave({ ...item, type: taskActionsType.BUTTON })}
          isDisabled={disabled}
        >
          <PlayIcon height={5} width={6} />
          <Button.Title title={label} className={'action-button-text  mb-0'}></Button.Title>
          <Button.Loading
            isLoading={
              isLoading?.isLoad &&
              (isLoading?.value || isLoading?.value == 0) &&
              item?.value == isLoading?.value
            }
            size={18}
            color='#2A2E34'
          />
        </Button.Container>
      );
    }
    if (label == taskButtonLabel.REPEAT) {
      return (
        <ToolTip tooltipText={label === 'Complete' ? 'Mark as Complete' : label} isModern>
          <Button.Container
            // style={{ backgroundColor: item?.color }}
            className={'btn action-button-repeat-tick px-2 '}
            key={label}
            handleOk={() => onSave({ ...item, type: taskActionsType.BUTTON })}
            isDisabled={disabled}
          >
            <Repeat width={18} height={16} strokeWidth={2} />
            <Button.Loading
              isLoading={
                isLoading?.isLoad &&
                (isLoading?.value || isLoading?.value == 0) &&
                item?.value == isLoading?.value
              }
              size={18}
              color='#2A2E34'
            />
          </Button.Container>
        </ToolTip>
      );
    }
    return (
      <Button.Container
        className={'btn action-button-stop action-button'}
        key={label}
        handleOk={() => onSave({ ...item, type: taskActionsType.BUTTON })}
        isDisabled={disabled}
      >
        {label === taskButtonLabel.PAUSE && <Pause fill='#ff3807' size={12} color='#ff3807' />}
        {label === taskButtonLabel.STOP && <CompletedDot color={'#3454D1'} />}
        <Button.Title title={label} className={'action-button-stop-text mb-0'}></Button.Title>
        <Button.Loading
          isLoading={
            isLoading?.isLoad &&
            (isLoading?.value || isLoading?.value == 0) &&
            item?.value == isLoading?.value
          }
          size={18}
          color='#2A2E34'
        />
      </Button.Container>
    );
  }
};
