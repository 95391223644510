/* eslint-disable react/react-in-jsx-scope */
import { FileText } from 'lucide-react';
import CircularAvatar from '../_components/Avatar/CircularAvatar';
import { apiConstants, USER_PLACEHOLDER } from '../_constants';
import { CHAT_UPDATE_TYPE } from '../_constants/chat.constants';
import { ProjectIcon } from '../Utils/SvgIcons';

export function generateSearchResult(resultType, result = {}) {
  let resultData = { icon: '', title: '' };

  if (resultType === TAB_VALUE_CONST.TASK.value) {
    resultData.title = TAB_VALUE_CONST.TASK.name;
    resultData.icon = (
      <span className='project-icon-container' style={{ background: '#3454d1' }}>
        <FileText size={17} color='#fff' />
      </span>
    );
  } else if (resultType === TAB_VALUE_CONST.PROJECT.value) {
    resultData.title = TAB_VALUE_CONST.PROJECT.name;

    resultData.icon = (
      <span className='project-icon-container'>
        <ProjectIcon width={17} height={17} color='#fff' />
      </span>
    );
  } else if (resultType === TAB_VALUE_CONST.FILE.value) {
    resultData.title = TAB_VALUE_CONST.FILE.name;
    resultData.icon = (
      <span className='project-icon-container' style={{ background: 'rgba(234, 77, 77, 0.1)' }}>
        <FileText size={18} color='#DD476A' />
      </span>
    );
  } else if (resultType === TAB_VALUE_CONST.CHAT.value) {
    resultData.title = TAB_VALUE_CONST.CHAT.name;

    resultData.icon = (
      <CircularAvatar
        size={46}
        // source={finalUrl}
        className='fz-none employee-img-with-low-border'
        placeholderSrc={USER_PLACEHOLDER.GROUP}
      />
    );
  } else if (resultType === TAB_VALUE_CONST.UPDATE.value) {
    resultData.title = TAB_VALUE_CONST.UPDATE.name;
    resultData.icon = (
      <span className='project-icon-container' style={{ background: '#3454d1' }}>
        <FileText size={20} color='#fff' />
      </span>
    );
  } else if (resultType === TAB_VALUE_CONST.USER.value) {
    resultData.title = TAB_VALUE_CONST.USER.name;

    const isMale = result?.user?.gender === 1;
    const isNeutral = result?.user?.gender === 3;
    const url = result?.user?.image_url;
    const finalUrl = url ? apiConstants.imgUrlS3 + url : null;

    resultData.icon = (
      <CircularAvatar
        size={38}
        className='fz-none zoom-in employee-img-with-low-border'
        source={finalUrl}
        isMale={isMale}
        isNeutral={isNeutral}
      />
    );
  }

  return resultData; // Ensure the function returns the resultData object
}

export const TAB_VALUE_CONST = {
  All: { name: 'All', value: 'all', title: 'All' },
  TASK: { name: 'TASK', value: 'tasks', title: 'Task' },
  PROJECT: { name: 'PROJECT', value: 'projects', title: 'Project' },
  CHAT: { name: 'CHAT', value: 'chats', title: 'Chat' },
  UPDATE: { name: 'UPDATE', value: 'updates', title: 'Update' },
  FILE: { name: 'FILE', value: 'files', title: 'File' },
  USER: { name: 'USER', value: 'users', title: 'User' },
};

export const generateIconBasedOnResult = (data, tab) => {
  if (tab === TAB_VALUE_CONST.USER.value) {
    const isMale = data?.gender === 1;
    const isNeutral = data?.gender === 3;
    const url = data?.image_url;
    const finalUrl = url ? apiConstants.imgUrlS3 + url : null;
    return (
      <CircularAvatar
        size={38}
        className='fz-none zoom-in employee-img-with-low-border'
        source={finalUrl}
        isMale={isMale}
        isNeutral={isNeutral}
      />
    );
  }

  if (data?.update_type === CHAT_UPDATE_TYPE.GROUP) {
    const url = data?.related_info?.logo;
    const finalUrl = url ? apiConstants.imgUrlS3 + url : null;

    return (
      <CircularAvatar
        size={38}
        source={finalUrl}
        className='fz-none employee-img-with-low-border'
        placeholderSrc={USER_PLACEHOLDER.GROUP}
      />
    );
  }
  if (data?.update_type === CHAT_UPDATE_TYPE.GROUP) {
    const url = data?.related_info?.logo?.image_url;
    const finalUrl = url ? apiConstants.imgUrlS3 + url : null;

    return (
      <CircularAvatar
        size={38}
        source={finalUrl}
        className='fz-none employee-img-with-low-border'
        placeholderSrc={USER_PLACEHOLDER.GROUP}
      />
    );
  }

  if (data?.update_type === CHAT_UPDATE_TYPE.PERSONAL) {
    const isMale = data?.related_info?.gender === 1;
    const isNeutral = data?.related_info?.gender === 3;
    const url = data?.related_info?.image_url;
    const finalUrl = url ? apiConstants.imgUrlS3 + url : null;
    return (
      <CircularAvatar
        size={38}
        className='fz-none zoom-in employee-img-with-low-border'
        source={finalUrl}
        isMale={isMale}
        isNeutral={isNeutral}
      />
    );
  }
  return null;
};
