/* eslint-disable no-unused-vars */
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import React, { useContext, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { PaginationComponent } from '../_components';
import { roleService } from '../_services';
import { permissionService } from '../_services/permission.service';
import { GlobalContext } from '../Utils/Contexts';

export function PermissionSettings() {
  //-- hooks area
  const { makeAlert, globalSettingsRefetch, getPermissions } = useContext(GlobalContext);
  const queryClient = useQueryClient();

  //-- state area

  const [searchParams, setSearchParams] = useSearchParams();
  const [query, setQuery] = useState({
    select: ['id', 'name', 'action', 'permitted_roles'],
    pageVo: {
      pageNo: parseInt(searchParams.get('pageNo')) || 1,
      noOfItems: 10,
    },
  });

  //-- fetch roles
  const { data: roleData } = useQuery({
    queryKey: ['roles'],
    queryFn: () => getAllRoles(),
  });

  //--fetch permission
  const { data: permissionData, isLoading } = useQuery({
    queryKey: ['permission', query.pageVo.pageNo],
    queryFn: () => getAllPermissions(query),
  });

  //-- get all roles
  const getAllRoles = async () => {
    const roleData = await roleService.roleList();

    return roleData;
  };

  // get all permissions
  const getAllPermissions = async (query) => {
    const permissionData = await permissionService.permissionList(query);
    return permissionData;
  };

  //-- when checkbox onchange
  const handlePermissionChange = (permission, role) => {
    if (permission?.permitted_roles?.includes(role?.number)) {
      //-- remove item from array
      let removedArray = permission?.permitted_roles.filter((r) => r !== role?.number);
      handleMutate({ id: permission.id, permitted_roles: removedArray });
    } else {
      //-- push id to array
      let addedArray = [...permission.permitted_roles];
      addedArray.push(role?.number);
      handleMutate({ id: permission.id, permitted_roles: addedArray });
    }
  };

  //-- handle update
  const handleUpdate = (data) => {
    permissionService.permissionAdd(data);
    makeAlert('Updated');
  };

  // --react query fot mutation function
  const { mutate: handleMutate } = useMutation({
    mutationFn: handleUpdate,
    onSuccess: (data) => {
      queryClient.invalidateQueries({ queryKey: ['permission'] });
      globalSettingsRefetch();
      getPermissions();
    },
  });

  //-- table data
  const tableData = permissionData?.data?.rows?.map((PERMISSION, index) => {
    const slNo = (permissionData?.data?.page - 1) * permissionData?.data?.limit + index + 1;
    return (
      <tr key={index}>
        <td>{slNo}</td>
        <td>{PERMISSION?.name}</td>
        {roleData?.data?.map((ROLE, rIndex) => {
          return (
            <td key={rIndex}>
              <input
                className='menu_check_box'
                type='checkbox'
                checked={PERMISSION?.permitted_roles?.includes(ROLE?.number)}
                onChange={() => handlePermissionChange(PERMISSION, ROLE)}
              />
            </td>
          );
        })}
      </tr>
    );
  });

  return (
    <div className='department_list sub_padding'>
      <section>
        <div className='tab_contain mt-2'>
          {/* <div className={'search_contain ' + (query.searchKey ? 'active' : '')}>
            <Search size={20} color='black' />
            <input type='text' className='bodr_none' />
          </div> */}
          {/* <div>Sorted</div> */}
        </div>

        <section className='mt-2'>
          {isLoading && <div>loading...</div>}
          <div className='table_outer'>
            <table className='table table-hover tbl mb-0'>
              <thead>
                <tr>
                  <th scope='col'>Sl.No</th>
                  <th scope='col'>Actions</th>
                  {roleData?.data?.map((role, index) => {
                    return (
                      <th key={index} scope='col'>
                        {role.name}
                      </th>
                    );
                  })}
                </tr>
              </thead>

              <tbody>{tableData}</tbody>
            </table>
          </div>
        </section>
        <section className='mt-2'>
          <PaginationComponent
            pageOnClick={(data) => {
              setQuery((prev) => ({
                ...prev,
                pageVo: {
                  ...prev.pageVo,
                  pageNo: data,
                },
              }));
              searchParams.set('pageNo', data);
              setSearchParams(searchParams);
            }}
            demoData={permissionData?.data}
          />
        </section>
      </section>
    </div>
  );
}
