import React from 'react';
import { fullNameUser } from '../Utils';
import { apiConstants } from '../_constants';
import CircularAvatar from './Avatar/CircularAvatar';

const UserDropDown = ({
  data,
  metaData,
  size = 30,
  gap = 15,
  hideDesignation = false,
  getNameByKey = null,
  showInitials = false,
}) => {
  const url = data?.compressed_image_url ? data?.compressed_image_url : data?.image_url;
  const finalUrl = url ? apiConstants.imgUrlS3 + url : null;
  const isMale = data.gender === 1;
  const isNeutral = data.gender === 3;
  const fullname = getNameByKey
    ? getNameByKey(data)
    : fullNameUser(data.first_name, data?.last_name);
  const isAvatarVisible = !data.hideAvatar;
  const Icon = data?.icon;

  if (metaData.context == 'value') {
    return fullname;
  }

  return (
    <div key={data?.id}>
      {' '}
      <div className='d-flex align-items-center' style={{ gap: { gap } }}>
        {isAvatarVisible && (
          <CircularAvatar
            key={url}
            source={finalUrl}
            size={size}
            isMale={isMale}
            isNeutral={isNeutral}
            name={showInitials ? fullname : null}
          />
        )}
        {Icon && !isAvatarVisible && (
          <Icon className={'mx-1'} size={size} color={data?.color ?? 'black'} />
        )}
        <div style={{ flex: 1 }}>
          <div style={{ fontWeight: '500' }}>{fullname}</div>
          {data.employee_code && (
            <div className='small-gret-text'>Employee ID: {data.employee_code}</div>
          )}
          {!hideDesignation && data?.['designation.name'] && (
            <div className='small-gret-text'>Designation: {data?.['designation.name']}</div>
          )}
          {data?.description && <div className='small-gret-text'>{data.description}</div>}
        </div>
      </div>
    </div>
  );
};

export default UserDropDown;
