import React, { useEffect, useRef } from 'react';

export const TruncatedText = ({ className, text }) => {
  const textRef = useRef(null);

  


  useEffect(() => {
    const element = textRef.current;

    if (element && element.scrollHeight > element.clientHeight) {
      truncateText(element);
    }
  }, [text]); // Dependency on text to re-truncate if text changes

  const truncateText = (element) => {
    const originalText = element.innerText;
    let truncatedText = originalText;
    element.innerText = truncatedText;
    while (element.scrollHeight > element.clientHeight && truncatedText.length > 0) {
      truncatedText = truncatedText.substring(0, truncatedText.length - 1);
      element.innerText = truncatedText + '...';
    }
    // Ensure the final truncated text ends with "..."
    if (truncatedText.length < originalText.length) {
      element.innerText = truncatedText.substring(0, truncatedText.length - 3) + '...';
    }
  };

  return (
    <p className={className} ref={textRef}>
      {text}
    </p>
  );
};
