import React, { useState } from 'react';
import EmailInput from './EmailInput';
import EmailVerification from './EmailVerification';
import NewPassword from './NewPassword';

const ForgotPassword = () => {
  const [value, setValue] = useState(0);
  const [email, setEmail] = useState({ email: '' });

  return (
    <div>
      {value == 0 ? (
        <EmailInput email={email} setEmail={setEmail} setValue={setValue} />
      ) : value == 1 ? (
        <EmailVerification email={email} setEmail={setEmail} setValue={setValue} />
      ) : (
        <NewPassword email={email} setEmail={setEmail} />
      )}
    </div>
  );
};

export default ForgotPassword;
