/* eslint-disable no-unused-vars */
import { useMutation } from '@tanstack/react-query';
import { CircleArrowRight, Eye, EyeOff } from 'lucide-react';
import React, { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from '../_components';
import { RT } from '../_constants';
import { authService } from '../_services';
import ErrorBlock from '../Template/SubComponents/ErrorBlock';
import { GlobalContext } from '../Utils';

export const InviteLoginPage = ({ setSearchParams, searchParams }) => {
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [isConfirmPasswordVisible, setIsConfirmPasswordVisible] = useState(false);
  const [signInData, setSignInData] = useState({});
  const [submitted, setSubmitted] = useState(false);
  const [loginIcon, setLoginIcon] = useState(false);
  const { makeAlert } = useContext(GlobalContext);
  const navigate = useNavigate();
  const handleChange = (event) => {
    const { name, value } = event.target;

    setSignInData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleNewUser = async (data) => {
    let payload = { ...data, ...signInData };
    let response = await authService.registerNewUser(payload);
    return response;
  };

  const {
    mutate: handleMutate,
    isPending,
    isError,
    error,
    isPaused, //-- true when network offline mode
  } = useMutation({
    mutationFn: handleNewUser,
    mutationKey: ['add-new-user'],
    onSuccess: (data) => {
      console.log(data, 'refetch');
      searchParams.set('view', 'signUp');
      setSearchParams(searchParams);

      // handleClose();
    },
    onError: (err) => {
      if (err) {
        if (err?.data?.navigate_to === 'login') {
          setLoginIcon(true);
        }
        makeAlert(err?.message || 'Registration failed');
      }
    },
  });

  const handleSubmit = (event) => {
    event.preventDefault();
    setSubmitted(true);

    if (
      !signInData?.password?.toString()?.trim() ||
      !signInData?.username?.toString()?.trim() ||
      !signInData?.confirm_password?.toString()?.trim()
    ) {
      return null;
    }
    if (
      signInData?.password?.toString()?.trim() !== signInData?.confirm_password?.toString()?.trim()
    ) {
      return null;
    }

    const encodedData = Array.from(searchParams.entries()).find(([key]) => key !== 'view')?.[0];

    let decodedData = {};
    if (encodedData) {
      try {
        decodedData = JSON.parse(decodeURIComponent(encodedData));
      } catch (error) {
        console.error('Error decoding data:', error);
        makeAlert('Invalid data provided in the URL.');
        return; // Exit the function if decoding fails
      }
    } else {
      makeAlert('No data provided in the URL.');
      return; // Exit if no encoded data is found
    }

    // Validate decodedData properties
    const { email_id, temp_pass } = decodedData;
    if (!email_id || !temp_pass) {
      makeAlert('Required data is missing in the URL.');
      return;
    }

    handleMutate({
      temp_password: decodedData.temp_pass,
      company_id: decodedData.company_id,
      email_id: decodedData.email_id,
    });
  };

  return (
    <div className='invite-accept-body '>
      <div className='invite-login-page'>
        <div className='invite-greeting mb-3'>Set Username & Password</div>
        {/* <div className='mb-2 row'>
          <div className='col padding-right-5'>
            <label className='mb-1 fz-13px-rem  label_input' style={{ fontWeight: 500 }}>
              First Name
            </label>
            <input
              type='text'
              className='input-box'
              name='name'
              value={signInData?.first_name}
              placeholder={`Enter first name`}
              onChange={(event) => {
                handleChange(event);
              }}
            />
      
          </div>
          <div className='col padding-left-5'>
            <label className='mb-1 fz-13px-rem  label_input' style={{ fontWeight: 500 }}>
              Last Name
            </label>
            <input
              type='text'
              className='input-box'
              name='name'
              value={signInData?.last_name}
              placeholder={`Enter last name`}
              onChange={(event) => {
                handleChange(event);
              }}
            />
        
          </div>
        </div> */}

        <div className='mb-2'>
          <label className='mb-1 fz-13px-rem  label_input' style={{ fontWeight: 500 }}>
            Username
          </label>
          <input
            type='text'
            className='input-box'
            name='username'
            value={signInData?.username}
            placeholder={`Enter user name`}
            onChange={(event) => {
              handleChange(event);
            }}
          />
          <ErrorBlock
            errorMsg={'Username required'}
            hasError={submitted && !signInData?.username?.toString()?.trim()}
          />
        </div>

        <div className='mb-2'>
          <label className='mb-1 fz-13px-rem  label_input' style={{ fontWeight: 500 }}>
            Password
          </label>
          <div className='password-block'>
            <div style={{ position: 'relative' }}>
              <input
                type={isPasswordVisible ? 'text' : 'password'}
                className='input-box'
                autoComplete='off'
                id='password'
                name='password'
                placeholder='Password'
                value={signInData?.password}
                onChange={(event) => {
                  handleChange(event);
                }}
              />
              <a className='password-icon' onClick={() => setIsPasswordVisible((prev) => !prev)}>
                {isPasswordVisible ? (
                  <Eye width={18} height={18} />
                ) : (
                  <EyeOff width={18} height={18} />
                )}
              </a>
            </div>
            <ErrorBlock
              errorMsg={'Password required'}
              hasError={submitted && !signInData?.password?.toString()?.trim()}
            />
          </div>
        </div>

        <div className='mb-2'>
          <label className='mb-1 fz-13px-rem  label_input' style={{ fontWeight: 500 }}>
            Confirm password
          </label>
          <div className='password-block'>
            <div style={{ position: 'relative' }}>
              <input
                type={isConfirmPasswordVisible ? 'text' : 'password'}
                className='input-box'
                autoComplete='off'
                id='password'
                name='confirm_password'
                placeholder='Password'
                value={signInData?.confirm_password}
                onChange={(event) => {
                  handleChange(event);
                }}
              />
              <a
                className='password-icon'
                onClick={() => setIsConfirmPasswordVisible((prev) => !prev)}
              >
                {isConfirmPasswordVisible ? (
                  <Eye width={18} height={18} />
                ) : (
                  <EyeOff width={18} height={18} />
                )}
              </a>
            </div>
            <ErrorBlock
              errorMsg={'Confirm password and password does not match'}
              hasError={
                submitted &&
                signInData?.password?.toString()?.trim() !==
                  signInData?.confirm_password?.toString()?.trim() &&
                signInData?.confirm_password?.toString().trim()
              }
            />
            <ErrorBlock
              errorMsg={'Confirm password required'}
              hasError={submitted && !signInData?.confirm_password?.toString().trim()}
            />
          </div>
        </div>

        <div className='invite-border-bottom mt-4' />
        <div className='d-flex justify-content-between align-items-center'>
          {loginIcon && (
            <a
              className='fz-13px'
              style={{ color: '#0d6efd', textDecoration: 'underline' }}
              onClick={() => navigate(`/${RT.LOGIN}`)}
            >
              Login <CircleArrowRight size={13} />
            </a>
          )}

          <div className='client-add-button-container mt-1'>
            <Button.Container
              className={'outline-cancel-button'}
              type='reset'
              handleOk={() => {
                searchParams.set('view', 'home');
                setSearchParams(searchParams);
              }}
            >
              <Button.Title title={'Cancel'} />
            </Button.Container>
            <Button.Container
              type='submit'
              className={'blue-btn'}
              // handleOk={() => {
              //   // searchParams.set('view', 'signUp');
              //   // setSearchParams(searchParams);
              //   handleSubmit();
              // }}
              handleOk={handleSubmit}
            >
              <Button.Title title={'Continue'} />
              <Button.Loading />
            </Button.Container>
          </div>
        </div>
      </div>
    </div>
  );
};
