import { Search } from 'lucide-react';
import React from 'react';

function ChatUpdateInputs({ searchKey, onSearch }) {
  return (
    <div className='d-flex justify-content-between gap-2 mt-3 update-padding'>
      <div className={'search_contain ' + (searchKey ? 'active' : '')} style={{ flex: 1 }}>
        <input
          type='text'
          value={searchKey}
          onChange={(event) => onSearch(event.target.value)}
          className='bodr_none'
          placeholder='Search...'
          style={{ flex: 1 }}
        />
        <Search width={13} height={13} color='#87909E' />
      </div>{' '}
      {/* <div className='list_top_card '>
        <MessageSquareDot size={15} />
      </div> */}
    </div>
  );
}

export default ChatUpdateInputs;
