import { useEffect, useRef, useState } from 'react';

/***
 * @typedef {Object} ReturnObject
 * @property {boolean} isPlaying - Will be true if currently, otherwise false
 * @property {number} playbackRate - THe rate at which the audio is currently playing
 * @property {number} duration - The total duration of the audio in milliseconds
 * @property {number} currentTime - The current time of the audio
 *
 */

function useImperativeAudio({ src }) {
  const audioRef = useRef(new Audio(src));

  const [isPlaying, setIsPlaying] = useState(false);
  const [playbackRate, setPlaybackRate] = useState(1);
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);

  useEffect(() => {
    const audio = audioRef.current;

    const handleTimeUpdate = () => {
      setCurrentTime(audio.currentTime);
    };

    const handleLoadedMetadata = () => {
      if (audio.duration) {
        setDuration(audio.duration);
      }
    };

    const handleDurationChange = () => {
      setDuration(audio.duration);
    };

    const handleEnded = () => {
      setIsPlaying(false);
      setCurrentTime(0);
      audio.currentTime = 0; // Reset the audio to the start
    };

    audio.addEventListener('timeupdate', handleTimeUpdate);
    audio.addEventListener('ended', handleEnded);
    audio.addEventListener('durationchange', handleDurationChange);
    audio.addEventListener('loadedmetadata', handleLoadedMetadata);

    return () => {
      audio.removeEventListener('timeupdate', handleTimeUpdate);
      audio.removeEventListener('durationchange', handleDurationChange);
      audio.removeEventListener('ended', handleEnded);
      audio.removeEventListener('loadedmetadata', handleLoadedMetadata);
    };
  }, []);

  const togglePlayPause = () => {
    const audio = audioRef.current;
    if (isPlaying) {
      try {
        audio.pause();
      } catch (err) {
        console.log('Error Found:', err);
      }
    } else {
      try {
        audio.play();
      } catch (err) {
        console.log('Error Found:', err);
      }
    }
    setIsPlaying(!isPlaying);
  };

  const handleSpeedChange = (speed) => {
    const rate = parseFloat(speed);
    setPlaybackRate(rate);
    audioRef.current.playbackRate = rate;
  };

  const handleSeek = (event) => {
    const time = parseFloat(event.target.value);
    if (Number.isFinite(time)) {
      audioRef.current.currentTime = time;
      setCurrentTime(time);
    }
  };

  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = Math.floor(time % 60);
    return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
  };

  const cycleSpeed = () => {
    const speeds = [0.5, 1, 1.5, 2];
    const currentIndex = speeds.indexOf(playbackRate);
    const nextIndex = (currentIndex + 1) % speeds.length;
    const nextRate = speeds[nextIndex];
    setPlaybackRate(nextRate);
    audioRef.current.playbackRate = nextRate;
  };

  return {
    cycleSpeed,
    formatTime,
    togglePlayPause,
    handleSeek,
    setPlaybackRate,
    handleSpeedChange,
    currentTime,
    duration,
    isPlaying,
    playbackRate,
    audioRef,
  };
}

export default useImperativeAudio;
