import { useRef, useState } from 'react';

/***
 * @callback DownloadCallback - Will download the file
 * @returns {null}
 */
/***
 * @callback AbortCallback - Will abort the download
 * @returns {Promise<null>}
 */

/***
 * For downloading file with progress status
 * @param {Object} param
 * @param {string} param.sourceUrl - The url of the file to be downloaded
 * @param {string} param.fileName - The filename used to save the file
 * @returns {{downloadFile: DownloadCallback,abortDownload:AbortCallback,downloadedBytes: number,progress:number,isDownloading:boolean}}
 */
function useProgressDownloader({ sourceUrl, fileName }) {
  const [isDownloading, setIsDownloading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [downloadedBytes, setDownloadedBytes] = useState(0);
  const abortControllerRef = useRef(null);

  const handleDownload = async () => {
    setIsDownloading(true);
    setProgress(0);

    try {
      abortControllerRef.current = new AbortController();
      const response = await fetch(sourceUrl, { signal: abortControllerRef.current.signal });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const totalBytes = parseInt(response.headers.get('Content-Length'), 10);
      let loadedBytes = 0;

      const reader = response.body.getReader();
      const stream = new ReadableStream({
        start(controller) {
          function push() {
            reader.read().then(({ done, value }) => {
              if (done) {
                controller.close();
                return;
              }
              loadedBytes += value.length;
              setDownloadedBytes(loadedBytes);
              setProgress((loadedBytes / totalBytes) * 100);
              controller.enqueue(value);
              push();
            });
          }
          push();
        },
      });

      const newResponse = new Response(stream);
      const blob = await newResponse.blob();
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.download = fileName;
      document.body.appendChild(link);
      link.click();
      link.remove();
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error('Error downloading the file:', error);
    } finally {
      setIsDownloading(false);
    }
  };

  const abortDownload = async () => {
    if (abortControllerRef.current) {
      try {
        abortControllerRef.current.abort();
        setIsDownloading(false);
      } catch (err) {
        if (err instanceof DOMException && err.name == 'AbortError') {
          console.log('Download Aborted');
        }
        console.log(err);
      }
    }
  };

  return { downloadFile: handleDownload, abortDownload, downloadedBytes, progress, isDownloading };
}

export default useProgressDownloader;
