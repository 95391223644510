import React from 'react';
import { FileSystemImage, getSize } from '../Utils';
import { X } from 'lucide-react';

export const SelectedFiles = ({ selectedFiles, fileRemove = () => {} }) => {
  return selectedFiles.map((FILE, index) => {
    return (
      <>
        <div className='file-uploaded-container'>
          <div className='d-flex align-items-center'>
            <div className='file-close' style={{ top: '5px' }} onClick={() => fileRemove(index)}>
              <X size={15} color='#87909E' strokeWidth={3} />
            </div>
            <div className='icon-contain'>
              <div className=''>
                <img
                  src={FileSystemImage.FileUploadedPreview}
                  height={35}
                  width={35}
                  alt='preview'
                />
              </div>
            </div>
            <div className='details-contain'>
              <div className='file-uploaded-name mb-1'>{FILE.name}</div>
              <div className='d-flex gap-3'>
                <div className='file-uploaded-size'>{getSize(FILE.size)}</div>
                {/* {FILE.isUploading && <div className='fz-11px-rem '>Uploading</div>} */}
                {FILE.isSuccess && (
                  <div className='fz-11px-rem  d-flex gap-1'>
                    <img src={FileSystemImage.Complete} alt='' />
                    Completed
                  </div>
                )}
              </div>
            </div>
          </div>
          {FILE.isUploading && (
            <div>
              <div className='file-upload-progress-container mt-1'>
                <div className='file-upload-progress-bar'></div>
              </div>
            </div>
          )}
        </div>
      </>
    );
  });
};
