import { useQueryClient } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';
import { TRIGGERS, WF_TYPE } from './workflow.constants';

export const getWorkFlowType = (type) => {
  if (type === WF_TYPE.ACTION) {
    return 'ActionNode';
  } else if (type === WF_TYPE.CONDITION) {
    return 'ConditionNode';
  } else if (type === WF_TYPE.DELAY) {
    return 'DelayNode';
  }
};

export const getWorkFlowLabel = (type) => {
  if (type === WF_TYPE.ACTION) {
    return 'Action Node';
  } else if (type === WF_TYPE.CONDITION) {
    return 'Condition Node';
  } else if (type === WF_TYPE.DELAY) {
    return 'Delay Node';
  }
};

export const getIconVisibility = (type) => {
  if (type === WF_TYPE.ACTION) {
    return false;
  } else if (type === WF_TYPE.CONDITION) {
    return true;
  } else if (type === WF_TYPE.DELAY) {
    return true;
  }
};

// Function to get trigger object by name
export const getTriggerByName = (triggerName) => {
  return TRIGGERS.find((trigger) => trigger.name === triggerName);
};

export const useTriggerNodeData = () => {
  const { wf_id } = useParams();
  const queryClient = useQueryClient();

  // Get cached data
  const cachedData = queryClient.getQueryData(['workflow-steps-list', wf_id]);

  // Find the trigger node data
  const triggerNodeData = cachedData?.data?.find((item) => item.type === 'TRIGGER');

  return triggerNodeData;
};

export const extractConditionsFromArray = (conditions) => {
  if (!conditions || !Array.isArray(conditions)) return [];

  // Map through the conditions array
  return conditions.map((condition) => {
    const key = Object.keys(condition)[0]; // Extract the key (e.g., project_id, priority, etc.)
    const value = condition[key]; // Extract the associated object

    return {
      label: value.label,
      id: key,
      type: value.type,
      tableType: value?.tableType,
      supportOperators: value.supportOperators || [], // Default to an empty array if not provided
      ...value, // Include any additional properties in the value object
    };
  });
};

export function formatTimeString(time) {
  const { day, hour, minute } = time;

  // Initialize an array to hold the parts of the time string
  const parts = [];

  // Check each property and add its formatted string if it exists
  if (day && parseInt(day) > 0) {
    parts.push(`${day} Day${day > 1 ? 's' : ''}`);
  }
  if (hour && parseInt(hour) > 0) {
    parts.push(`${hour} Hour${hour > 1 ? 's' : ''}`);
  }
  if (minute && parseInt(minute) > 0) {
    parts.push(`${minute} Minute${minute > 1 ? 's' : ''}`);
  }

  // Join all parts with spaces and return
  return parts.join(' ');
}
