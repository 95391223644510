import { keepPreviousData, useQuery } from '@tanstack/react-query';
import React, { useEffect, useState } from 'react';
import ReactSelect from 'react-select';
import { Button } from '../_components/CustomButton';
import { ErrorComponent } from '../_components/ErrorComponent';
import { CustomDropdownIndicator } from '../_components/SelectionDropDownIcon';
import { alertConstants, CommonDropDownStyle } from '../_constants';
import useDebounce from '../_helpers/useDebounce';
import { departmentService } from '../_services/department.service';
import { DepartmentState } from '../Utils/States';
import { Cancel } from '../Utils/SvgIcons';

export function DesignationAdd({
  id,
  handleClose = () => {},
  afterAdd = () => {},
  defaultDepartment = null,
}) {
  const [designation, setDesignation] = useState({
    ...DepartmentState,
    department_id: defaultDepartment,
  });
  const [isLoading, setIsLoading] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [error, setError] = useState({ common: '' });
  // const [organizationSearch, setOrganizationSearch] = useState('');
  const [departmentSearch, setDepartmentSearch] = useState('');

  const [query, setQuery] = useState({
    select: ['id', 'name', 'company_id', 'department_id', 'priority'],
    is_department: 0,
  });

  useEffect(() => {
    if (id && id != '0') {
      query.id = id;
      getAllDesignation(query);
    }
  }, [id]);

  /**
   * Get designation
   * */
  const getAllDesignation = async (queryTemp) => {
    let queryNew = queryTemp ? queryTemp : query;

    setQuery(queryNew);
    setIsLoading(true);

    try {
      const organization = await departmentService.departmentGet(queryNew);
      if (organization?.data?.length) {
        let department = organization?.data[0]?.parentDepartment;
        setDesignation({ ...organization?.data[0], department_id: department });
      }
    } catch (error) {
      setError({
        message: error?.message || alertConstants.SERVER_ERROR,
      });
    } finally {
      setIsLoading(false);
    }

    console.log(error);
  };
  // const debounceOrganization = useDebounce(organizationSearch, 500);
  // const { data: organizationData, error: organizationError } = useQuery({
  //   queryKey: ['organizationList', debounceOrganization],
  //   queryFn: () => organizationService.organizationList({ searchKey: organizationSearch }),
  //   placeholderData: keepPreviousData,
  // });

  //  list all departmentsF
  const debounceDeprt = useDebounce(departmentSearch, 500);

  const { data: departmentData, error: departmentError } = useQuery({
    queryKey: ['departmentList', debounceDeprt],
    queryFn: () =>
      departmentService.departmentList({
        searchKey: departmentSearch,
        select: ['is_department', 'name', 'id'],
        company_id: designation?.company_id,
        is_department: 1,
      }),
    placeholderData: keepPreviousData,
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    if (name === 'company_id') {
      setDesignation({
        ...designation,
        department_id: null,
        [name]: value,
      });
      return;
    }
    setDesignation({
      ...designation,
      [name]: value,
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    setSubmitted(true);

    if (
      designation?.name.toString().trim() === '' ||
      designation?.department_id == null ||
      !designation?.priority
    ) {
      setIsLoading(false);
      return null;
    }
    const payload = {
      ...designation,
      department_id: designation?.department_id?.id,
      is_department: 0,
    };
    try {
      await departmentService.departmentAdd(payload).then((data) => {
        afterAdd(data?.data);
        setSubmitted(false);
      });
      handleClose();
    } catch (error) {
      setError({
        common: error?.message || alertConstants.SERVER_ERROR,
      });
    } finally {
      setIsLoading(false);
      setSubmitted(false);
    }
  };

  return (
    <>
      <div className='modal_view_content'>
        {/* component will active when error occurs   */}
        <ErrorComponent error={error?.message || departmentError?.message} />

        <form action='' className='d-flex flex-column gap-2' onSubmit={handleSubmit}>
          <div className='row '>
            <div className='form-group'>
              <label htmlFor='name' className='form-input-label'>
                Name<span className='field-required'> *</span>
              </label>
              <input
                type='text'
                id='name'
                name='name'
                value={designation?.name}
                placeholder='Enter designation name '
                className='input-box'
                onChange={(event) => {
                  handleChange(event);
                }}
              />
              {submitted && !designation?.name.trim() && (
                <div className='help-block'>Designation name is required</div>
              )}
            </div>
          </div>

          {/* end of company  */}

          {/* Department selection  */}
          <div>
            <div className='form-input-label'>
              Department
              <span className='field-required'> *</span>
            </div>
            <ReactSelect
              name='department_id'
              styles={{
                ...CommonDropDownStyle,
                menuPortal: (base) => ({ ...base, zIndex: 99999 }),
              }}
              menuPortalTarget={document.body}
              components={{ DropdownIndicator: CustomDropdownIndicator }}
              getOptionLabel={(option) => {
                return option?.name;
              }}
              getOptionValue={(option) => {
                return option?.id;
              }}
              onInputChange={(inputString) => {
                setDepartmentSearch(inputString);
              }}
              filterOption={null}
              inputValue={departmentSearch}
              options={departmentData?.data?.rows}
              onChange={(selectedOption) => {
                handleChange({
                  target: {
                    name: 'department_id',
                    value: selectedOption,
                  },
                });
              }}
              value={designation?.department_id}
              // value={
              //   departmentData?.data?.rows.find(
              //     (option) => option.id === designation.department_id,
              //   ) || null
              // } // Set the value based on project.company_id
            />
            {submitted && !designation?.department_id && (
              <div className='help-block'>Department selection is required</div>
            )}
          </div>

          {/* end of Department  */}

          {/* priority  */}
          <div className=''>
            <label htmlFor='priority' className='form-input-label'>
              Priority <span className='field-required'> *</span>
            </label>
            <input
              type='number'
              name='priority'
              className='input-box'
              id='priority'
              value={designation?.priority}
              placeholder='Enter priority'
              min={0}
              onChange={(event) => {
                handleChange(event);
              }}
            />
            {submitted && !designation?.priority && (
              <div className='help-block'>Department priority is required</div>
            )}
          </div>

          {/* end of priority  */}
        </form>
      </div>
      <br />
      <div className='modal_footer'>
        <div className=' d-flex justify-content-end gap-2'>
          <Button.Container
            className={'cancel-btn'}
            type='reset'
            handleOk={() => {
              handleClose();
            }}
          >
            <Cancel />
            <Button.Title title={'Cancel'} />
          </Button.Container>

          <Button.Container handleOk={(e) => handleSubmit(e)} type='submit' isDisabled={isLoading}>
            <Button.Title title={'Save'} />
            <Button.Loading isLoading={isLoading} />
          </Button.Container>
        </div>
      </div>
    </>
  );
}
