/* eslint-disable no-unused-vars */
import React from 'react';

export const DelayComponent = ({ handleChange, nodeData, handleOperationDetailsChange }) => {
  const handleTimeChange = (e) => {
    const { name, value } = e.target;

    // Ensure the value is non-negative
    if (value === '' || parseInt(value) >= 0) {
      // Update only the `time` field in `operation_details`
      handleOperationDetailsChange('time', {
        ...nodeData?.operation_details?.time,
        [name]: value,
      });
    }
  };

  return (
    <>
      <div style={{ marginTop: 18 }}>
        <div style={{ fontSize: 13, marginBottom: 10, fontWeight: '500' }}>Time</div>
        <div style={{ display: 'flex', gap: 6 }}>
          <input
            placeholder='Day'
            style={{
              height: 34,
              width: 'calc(100% / 3)',
              fontSize: 13,
              borderRadius: 3,
              border: '.5px solid #d2d2d2',
              padding: '3px 3px',
            }}
            type='number'
            name='day'
            value={nodeData.operation_details.time?.day || ''}
            onChange={handleTimeChange}
          />
          <input
            placeholder='Hour'
            style={{
              height: 34,
              width: 'calc(100% / 3)',
              fontSize: 13,
              borderRadius: 3,
              border: '.5px solid #d2d2d2',
              padding: '3px 3px',
            }}
            type='number'
            name='hour'
            value={nodeData.operation_details.time?.hour || ''}
            onChange={handleTimeChange}
          />
          <input
            placeholder='Minute'
            style={{
              height: 34,
              width: 'calc(100% / 3)',
              fontSize: 13,
              borderRadius: 3,
              border: '.5px solid #d2d2d2',
              padding: '3px 3px',
            }}
            type='number'
            name='minute'
            value={nodeData.operation_details.time?.minute || ''}
            onChange={handleTimeChange}
          />
        </div>
      </div>
    </>
  );
};
