/* eslint-disable no-unused-vars */
import { Download, Eye, X } from 'lucide-react';
import moment from 'moment';
import React, { useContext, useEffect, useState } from 'react';
import StackedAvatars from '../_components/Avatar/StackedAvatars';
import { DetailsRow } from '../_components/DetailsRow';
import { ErrorComponent } from '../_components/ErrorComponent';
import ToolTip from '../_components/Tooltip/ToolTip';
import { alertConstants, apiConstants, MENU } from '../_constants';
import { POSITION } from '../_constants/contextMenu.constants';
import { PreviewFileTypesList } from '../_constants/filesystem.constant';
import { fileService } from '../_services';
import { FilePreviewModal } from '../File/FilePreviewModal';
import { fullNameUser, getSize, GlobalContext, renderUserDetails } from '../Utils';
import {
  Calendar,
  FileIcon,
  FileSize,
  FullFileIcon,
  LinkIcon,
  LoadingIcon,
  ShareIcon,
  User,
} from '../Utils/SvgIcons';
import { FileActivityList } from './FileActivityList';

export const FileDetails = ({ id, handleClose = () => {}, isDigital, setIsActivityUpdated }) => {
  const [file, setFile] = useState({});
  const [isActivityAdd, setIsActivityAdd] = useState(false);
  const [previewShow, setPreviewShow] = useState(false);
  const [previewImg, setPreviewImg] = useState({});

  const { globalSettings, getMenuLabelName } = useContext(GlobalContext);

  const menuSettings =
    globalSettings &&
    globalSettings.length &&
    globalSettings.find((settings) => settings?.name === 'menu-settings');

  const hasExist = (default_name) => {
    return (
      menuSettings?.labels &&
      menuSettings?.labels.some((label) => label.default_name === default_name)
    );
  };

  const LABEL_OF_PROJECT = getMenuLabelName(MENU.project.default_name);
  console.log(LABEL_OF_PROJECT, 'songg', useContext(GlobalContext));

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState({ message: '' });
  const [query, setQuery] = useState({
    select: [
      'id',
      'name',
      // 'description',
      'files_data',
      'created_time',
      'shared_with_team',
      'shared_with_user',
      'size',
      'file_type',
      'shared_with_project',
    ],
  });
  const [selectedTab, setSelectedTab] = useState('details');

  useEffect(() => {
    if (id) {
      setQuery((prevQuery) => ({ ...prevQuery, id }));
      getFile({
        ...query,
        id,
      });
    }
  }, [id]);

  /**
   * Get File
   */
  const getFile = async (queryTemp) => {
    let queryNew = queryTemp || query;
    setIsLoading(true);

    try {
      const file = await fileService.fileGet(queryNew);
      if (file?.data?.length) {
        const result = file.data[0];
        let issued_date = moment(parseInt(result?.issued_date)).format('MMM DD, YYYY');
        let expiry_date = result?.expiry_date
          ? moment(parseInt(result?.expiry_date)).format('MMM DD, YYYY')
          : '---';

        setFile({ ...result, expiry_date: expiry_date, issued_date: issued_date });
      }
    } catch (error) {
      setError({
        message: error?.message || alertConstants.SERVER_ERROR,
      });
    } finally {
      setIsLoading(false);
    }
  };

  // const { containerRef: detailsRef } = useOutsideClickHandler({
  //   onClose: () => {
  //     !isActivityAdd && handleClose();
  //   },
  //   dependencies: [isActivityAdd],
  //   customClassToCheck: '#MODAL',
  // });

  // Function to differentiate between pre-viewable and non-pre-viewable files
  const openImage = (url, type, name) => {
    if (PreviewFileTypesList?.includes(type)) {
      setPreviewImg({ url: url, type: type, name: name });
      setPreviewShow(true);
    } else {
      window.open(apiConstants.imgUrlS3 + url, '_blank');
    }
  };

  return (
    <div>
      <div
        // ref={detailsRef}
        // className='file-details-popup-children'
        style={{ minHeight: isDigital ? '350px' : '450px', position: 'relative' }}
      >
        <div
          style={{ position: 'absolute', right: 0, top: 0, cursor: 'pointer' }}
          onClick={(event) => {
            handleClose(event);
          }}
        >
          <ToolTip isModern tooltipText={'Close'}>
            <X size={17} />
          </ToolTip>
        </div>

        {isDigital && (
          <div className='filter-head'>
            <h6 className='menu-item-head'>File Details</h6>
          </div>
        )}
        {!isDigital && (
          <div>
            <div className='d-flex gap-1 align-items-center mb-1  '>
              <span>
                <FullFileIcon />
              </span>

              <h6
                className='menu-item-head'
                style={{
                  textTransform: 'capitalize',
                  marginBottom: 0,
                  lineBreak: 'anywhere',
                  maxWidth: '90%',
                }}
              >
                {file?.name}
              </h6>
            </div>
            <div className='filter-body'>
              <FileDetailsTabs
                selectedTab={selectedTab}
                setSelectedTab={(tab) => {
                  if (isActivityAdd) {
                    setIsActivityAdd(false);
                  }
                  setSelectedTab(tab);
                }}
              />
            </div>
          </div>
        )}

        {selectedTab === 'details' && (
          <div style={{ fontSize: 15, maxHeight: 450, overflow: 'scroll' }}>
            <ErrorComponent error={error?.message} />
            {isLoading && <LoadingIcon  size={60} />}

            {!isLoading && (
              <>
                <DetailsRow
                  right_col='col-md-8'
                  left_col='col-md-4'
                  heading={'File Name'}
                  value={file.name}
                  icon={<FileIcon color='#87909E' />}
                />
                <DetailsRow
                  right_col='col-md-8'
                  left_col='col-md-4'
                  heading={'Size'}
                  value={getSize(file?.size)}
                  icon={<FileSize />}
                />
                {!isDigital && (
                  <DetailsRow
                    right_col='col-md-8'
                    left_col='col-md-4'
                    heading={'Shelf Location'}
                    value={file?.location}
                    icon={<FileIcon color='#87909E' />}
                  />
                )}
                <DetailsRow
                  right_col='col-md-8'
                  left_col='col-md-4'
                  heading={'Owner'}
                  value={renderUserDetails([file?.owner])}
                  icon={<User />}
                />
                <DetailsRow
                  right_col='col-8'
                  left_col='col-4'
                  heading={'Creator'}
                  value={renderUserDetails([file?.creator])}
                  icon={<User />}
                />
                <DetailsRow
                  right_col='col-md-8'
                  left_col='col-md-4'
                  heading={'Issued On'}
                  value={file?.issued_date}
                  icon={<Calendar height={14} width={14} />}
                />
                <DetailsRow
                  right_col='col-md-8'
                  left_col='col-md-4'
                  heading={'Expired on'}
                  value={file?.expiry_date}
                  icon={<Calendar height={14} width={14} />}
                />
                {file?.shared_with_user?.length > 0 && (
                  <DetailsRow
                    right_col='col-md-8'
                    left_col='col-md-4'
                    heading={'Shared With User'}
                    value={
                      file?.shared_with_user?.includes(0) ? (
                        'All Users'
                      ) : (
                        <StackedAvatars
                          justifyPosition='justify-content-left'
                          getSourceByKey={(user) =>
                            user?.compressed_image_url ? user.compressed_image_url : user?.image_url
                          }
                          getNameByKey={(user) => fullNameUser(user?.first_name, user?.last_name)}
                          getGenderByKey={(user) => user?.gender === 1}
                          imageList={file?.members}
                          showInitials={false}
                          tooltipPosition={POSITION.TOP}
                          size={25}
                        />
                      )
                    }
                    icon={<ShareIcon />}
                  />
                )}
                {file?.shared_with_team?.length > 0 && (
                  <DetailsRow
                    right_col='col-md-8'
                    left_col='col-md-4'
                    heading={'Shared With Team'}
                    value={
                      file?.shared_with_team?.includes(0) ? (
                        'All Teams'
                      ) : (
                        <StackedAvatars
                          justifyPosition='justify-content-left'
                          getSourceByKey={(team) => team?.image_url}
                          getNameByKey={(team) => team?.name}
                          getGenderByKey={(team) => team?.gender === 1}
                          imageList={file?.teams}
                          showInitials={true}
                          tooltipPosition={POSITION.TOP}
                          size={25}
                        />
                      )
                    }
                    icon={<ShareIcon />}
                  />
                )}

                {file?.shared_with_project?.length > 0 && hasExist(MENU.project.default_name) && (
                  <DetailsRow
                    right_col='col-md-8'
                    left_col='col-md-4'
                    heading={`Shared With ${LABEL_OF_PROJECT}`}
                    value={
                      file?.shared_with_project?.includes(0) ? (
                        `All ${LABEL_OF_PROJECT}`
                      ) : (
                        <StackedAvatars
                          justifyPosition='justify-content-left'
                          getSourceByKey={(project) => project?.logo}
                          getNameByKey={(project) => project?.name}
                          // getGenderByKey={(project) => project?.gender === 1}
                          imageList={file?.projects}
                          showInitials={true}
                          tooltipPosition={POSITION.TOP}
                          size={25}
                        />
                      )
                    }
                    icon={<ShareIcon />}
                  />
                )}

                {/* file preview and download   section */}

                {file?.files_data?.length > 0 && (
                  <DetailsRow
                    right_col='col-md-8'
                    left_col='col-md-4'
                    heading={'Link'}
                    value={
                      <div>
                        {file.files_data.map((fileItem, index) => (
                          <div
                            key={index}
                            onClick={() =>
                              openImage(fileItem.url, fileItem?.extension, fileItem?.name)
                            }
                          >
                            <span style={{ cursor: 'pointer', color: 'blue' }}>
                              {PreviewFileTypesList.includes(fileItem?.extension) ? (
                                <Eye size={16} />
                              ) : (
                                <Download size={16} />
                              )}
                            </span>
                            <span style={{ cursor: 'default' }}> {fileItem.name} </span>
                          </div>
                        ))}
                      </div>
                    }
                    icon={<LinkIcon />}
                  />
                )}
              </>
            )}
          </div>
        )}

        {selectedTab === 'activity' && (
          <div>
            <FileActivityList
              fileId={id}
              fileName={file?.name}
              setIsActivityAdd={setIsActivityAdd}
              isActivityAdd={isActivityAdd}
              setIsActivityUpdated={setIsActivityUpdated}
            />
          </div>
        )}
      </div>
      <section>
        {previewShow && (
          <FilePreviewModal.Container>
            <FilePreviewModal.Head
              handleClose={() => {
                setPreviewShow(false);
              }}
              file={previewImg?.name}
              fileUrl={previewImg?.url}
            />
            <FilePreviewModal.FileView file={previewImg?.url} extension={previewImg?.type} />
          </FilePreviewModal.Container>
        )}
      </section>
    </div>
  );
};

const FileDetailsTabs = ({ selectedTab, setSelectedTab }) => {
  const TABS = [
    { name: 'Details', value: 'details' },
    { name: 'Activity', value: 'activity' },
  ];

  return (
    <section className='file_tabs '>
      <div className='file-tab-line' style={{ top: '35px' }} />
      <div className='file-tab-contain' style={{ padding: '0px 15px 15px 0px' }}>
        {TABS.map((item, index) => (
          <div
            key={index}
            style={{ zIndex: '2', padding: '1px 1px 12px 1px' }}
            className={selectedTab === item?.value ? 'tab_active' : 'tab_inactive'}
            onClick={() => setSelectedTab(item?.value)}
          >
            {item.name}
          </div>
        ))}
      </div>
    </section>
  );
};
