/* eslint-disable no-unused-vars */
import { useIsMutating, useMutation } from '@tanstack/react-query';
import React, { useContext, useEffect, useState } from 'react';
import { GlobalContext } from '../../Utils';
import { Button, ToggleButton } from '../../_components';
import { settingsService } from '../../_services';
import { HeadWithArrow } from '../UtilsInSettings';

export function AdditionalSettings({ taskSettingData, refetch }) {
  const [additionalSettings, setAdditionalSettings] = useState(taskSettingData?.additionalSettings);
  const [additional, setAdditional] = useState(true);
  const [hasChanged, setHasChanged] = useState(false);
  const { makeAlert } = useContext(GlobalContext);
  const isMutating = useIsMutating();

  useEffect(() => {
    setAdditionalSettings(taskSettingData?.additionalSettings);
  }, [taskSettingData?.additionalSettings]);

  useEffect(() => {
    setHasChanged(
      JSON.stringify(additionalSettings) !== JSON.stringify(taskSettingData?.additionalSettings),
    );
  }, [additionalSettings, taskSettingData]);

  const saveAdditionalSettings = async (updatedData) => {
    const allSettings = { ...taskSettingData, additionalSettings: updatedData };
    const saveData = {
      name: 'task-settings',
      labels: allSettings,
    };

    await settingsService.settingsAdd(saveData);

    refetch();
    makeAlert('Success');
    setHasChanged(false);
  };

  // --react query fot mutation function
  const {
    mutateAsync: handleMutate,
    isError,
    isPaused,
    isPending,
  } = useMutation({
    mutationFn: saveAdditionalSettings,
  });

  const handleToggle = (setting) => {
    setAdditionalSettings((prevSettings) => ({
      ...prevSettings,
      [setting]: {
        ...prevSettings[setting],
        is_needed: !prevSettings[setting].is_needed,
      },
    }));

    // handleSave();
  };

  const handleChange = (e, setting) => {
    const { value } = e.target;
    setAdditionalSettings((prevSettings) => ({
      ...prevSettings,
      [setting]: {
        ...prevSettings[setting],
        name: value,
      },
    }));
  };

  const handleSave = () => {
    if (isPaused) {
      makeAlert('No internet');
      return;
    }
    if (isPending) {
      return;
    }
    handleMutate(additionalSettings);
  };

  return (
    <div className='task_tabs_container'>
      <HeadWithArrow
        head={'Additional tabs'}
        isDown={!additional}
        handler={() => setAdditional(!additional)}
        note={`Show separate tabs for 'To Verify' and 'Under Verification'`}
      />
      {additional && (
        <>
          <div>
            <div style={{ marginBottom: 15 }}>
              <div className='d-flex align-items-center justify-content-between'>
                {/* <div>{additionalSettings?.under_verification?.heading}</div> */}
                {/* <div className='general_head'>Under Verification</div> */}
                <div className='general_head'>Not Verify</div>
                <ToggleButton
                  id={additionalSettings?.under_verification?.name}
                  isChecked={additionalSettings?.under_verification?.is_needed}
                  handleToggle={() => handleToggle('under_verification')}
                  size={35}
                />
              </div>
              {additionalSettings?.under_verification?.is_needed && (
                <input
                  type='text'
                  className='form-control task-status-input-text'
                  value={additionalSettings?.under_verification?.name}
                  onChange={(e) => handleChange(e, 'under_verification')}
                />
              )}
            </div>
            <div style={{ marginBottom: 15 }}>
              <div className='d-flex align-items-center justify-content-between'>
                <div className='general_head'>To Verify</div>
                <ToggleButton
                  id={additionalSettings?.to_verify?.name}
                  isChecked={additionalSettings?.to_verify?.is_needed}
                  handleToggle={() => handleToggle('to_verify')}
                  size={35}
                />
              </div>
              {additionalSettings?.to_verify?.is_needed && (
                <input
                  type='text'
                  className='form-control task-status-input-text'
                  value={additionalSettings?.to_verify?.name}
                  onChange={(e) => handleChange(e, 'to_verify')}
                />
              )}
            </div>

            <div style={{ marginBottom: 15 }}>
              <div className='d-flex align-items-center justify-content-between'>
                <div className='description-text'>
                  {additionalSettings?.hide_verification?.heading}
                </div>
                <ToggleButton
                  id={additionalSettings?.hide_verification?.name}
                  isChecked={additionalSettings?.hide_verification?.is_needed}
                  handleToggle={() => handleToggle('hide_verification')}
                  size={35}
                />
              </div>
            </div>
            {/* {additionalSettings?.verification_combined &&
              additionalSettings?.to_verify?.is_needed &&
              additionalSettings?.under_verification?.is_needed && (
                <div style={{ marginBottom: 15 }}>
                  <div className='d-flex align-items-center justify-content-between'>
                    <div className='description-text'>
                      {additionalSettings?.verification_combined?.heading}
                    </div>
                    <ToggleButton
                      id={additionalSettings?.verification_combined?.name}
                      isChecked={additionalSettings?.verification_combined?.is_needed}
                      handleToggle={() => handleToggle('verification_combined')}
                      size={35}
                    />
                  </div>
                </div>
              )} */}
          </div>
          {hasChanged && (
            <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 10 }}>
              <Button.Container className={'additional-save-btn'} handleOk={() => handleSave()}>
                <Button.Title title={'Save'} />
                <Button.Loading isLoading={isMutating && !isPaused} />
              </Button.Container>
            </div>
          )}
        </>
      )}
    </div>
  );
}
