import { RefreshCcw } from 'lucide-react';
import React from 'react';
import { useSearchParams } from 'react-router-dom';
import { LoadingIcon } from '../Utils/SvgIcons';

function MessageListWrapper({
  isError,
  error,
  refetchChat = () => {},
  isChatLoading,
  hasPageError = false,
  listClassName,
  children,
}) {
  const [searchParam] = useSearchParams();
  const isUserSelection = searchParam.has('chat_user_id'); //if url has user_id, mean it's just a preview before user selection

  if (isUserSelection) {
    return (
      <div className='d-flex fz-13px-rem justify-content-center align-items-center h-100 grey-text'>
        Send a message to get started{' '}
      </div>
    );
  }

  if (isChatLoading) {
    return (
      <div className={`${listClassName} d-flex justify-content-center align-items-center`}>
        <LoadingIcon size={50}  />
      </div>
    );
  }

  // If no cached data and has Error, show error.
  if (hasPageError) {
    return (
      <div className={`${listClassName} d-flex justify-content-center align-items-center fz-14px`}>
        {error?.message ?? 'Loading failed.'}
      </div>
    );
  }

  return (
    <>
      {isError && (
        <div className='chat-error-banner'>
          {error?.message ?? 'Loading failed.'}.{' '}
          <RefreshCcw role='button' onClick={() => refetchChat()} size={14} />
        </div>
      )}
      {children}
    </>
  );
}

export default MessageListWrapper;
