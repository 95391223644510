/* eslint-disable no-unused-vars */
import { useQuery } from '@tanstack/react-query';
import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import ReactSelect from 'react-select';
import { projectService } from '../../_services';
import { labelService } from '../../_services/label.service';
import { getPriorityByContextAPI } from '../../Task/taskValidation';
import { customStyles, INPUT_TYPE, TASK_FIELDS } from '../workflow.constants';

export const ValueSelectionComponent = ({ selectedCondition, handleOperationDetailsChange }) => {
  const [optionValues, setOptionsValues] = useState([]);
  const priorityLabel = getPriorityByContextAPI();

  const [searchParams, setSearchParams] = useSearchParams();

  const [query, setQuery] = useState({
    select: ['id', 'name', 'createdAt', 'updatedAt'],
    searchKey: '',
    pageVo: {
      pageNo: parseInt(searchParams.get('pageNo')) || 1,
      noOfItems: 10,
    },
  });

  useEffect(() => {
    setOptionsValues([]);
  }, [selectedCondition?.id]);

  useEffect(() => {
    if (selectedCondition?.id === TASK_FIELDS.PRIORITY) {
      setOptionsValues(priorityLabel);
    }
  }, [selectedCondition?.id]);

  //-- Function to get service function based on selected condition
  const getServiceFunction = (selectedId) => {
    let serviceFunction = null;
    if (selectedId === TASK_FIELDS.PROJECT_ID) {
      serviceFunction = projectService.projectListAdvanced;
    }
    if (selectedId === TASK_FIELDS.LABEL) {
      serviceFunction = labelService.labelList;
    }

    if (selectedId === TASK_FIELDS.ASSIGNEE_ID) {
      // serviceFunction = labelService.labelList;
    }

    return serviceFunction;
  };

  let serviceFunction = getServiceFunction(selectedCondition?.id);

  //-- Function to get list data based on selected condition
  const getListData = async (query) => {
    let response = await serviceFunction(query);

    if (selectedCondition?.id === TASK_FIELDS.PROJECT_ID) {
      let options = response.data.rows.map((project) => ({
        label: project.name,
        value: project.id,
      }));

      setOptionsValues(options);
    }
    if (selectedCondition?.id === TASK_FIELDS.LABEL) {
      let options = response.data.rows.map((project) => ({
        label: project.name,
        value: project.id,
      }));

      setOptionsValues(options);
    }

    return response.data;
  };

  const { data, refetch } = useQuery({
    queryKey: ['value-query', selectedCondition?.id],
    queryFn: () => getListData(query),
    enabled: serviceFunction ? true : false,
  });

  return (
    <>
      <div style={{ marginTop: 10 }}>
        {selectedCondition?.inputType === INPUT_TYPE.S_DROPDOWN && (
          <>
            <label htmlFor='trigger-type-select' className='condition-field-select'>
              Select value
            </label>

            <ReactSelect
              id='trigger-type-select'
              styles={customStyles} // Apply custom styles
              options={selectedCondition.dropDownValue} // Pass the options generated from conditions
              getOptionLabel={(option) => option?.label} // Use label for display
              getOptionValue={(option) => option?.id} // Use id as value
              // onChange={handleConditionChange} // Update state on change
              onChange={(data) => handleOperationDetailsChange('condition_value', data)}
              placeholder='Select a condition'
            />
          </>
        )}

        {selectedCondition?.inputType === INPUT_TYPE.D_DROPDOWN && (
          <>
            <label htmlFor='trigger-type-select' className='condition-field-select'>
              Select value
            </label>

            <ReactSelect
              id='trigger-type-select'
              styles={customStyles} // Apply custom styles
              options={optionValues} // Pass the options generated from conditions
              getOptionLabel={(option) => option?.label} // Use label for display
              getOptionValue={(option) => option?.id} // Use id as value
              // onChange={handleConditionChange} // Update state on change
              onChange={(data) => handleOperationDetailsChange('condition_value', data)}
              placeholder='Select a condition'
            />
          </>
        )}
      </div>
    </>
  );
};
