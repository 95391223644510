import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import useElapsedTime from '../../Hook/useElapsedTime';
import { GlobalContext } from '../../Utils';

export const TaskTimer = () => {
  const navigate = useNavigate();
  const { activeTask } = useContext(GlobalContext);

  const { hours, minutes, seconds } = useElapsedTime(parseInt(activeTask?.start_time));

  return (
    <div
      onDoubleClickCapture={() =>
        navigate(`task/${activeTask?.id}?tab=work-history&&status=in-progress`)
      }
      className='task-running'
    >
      <div className='task-running-name'>
        <span>
          {`${String(Math.max(hours, 0)).padStart(2, '0')}:${String(Math.max(minutes, 0)).padStart(
            2,
            '0',
          )}:${String(Math.max(seconds, 0)).padStart(2, '0')} `}
        </span>
      </div>
      <div className='task_time'>{activeTask?.name}</div>
      <div className='double-tab'>Double tap to go current running task</div>
    </div>
  );
};
