/* eslint-disable no-unused-vars */
import { useQuery } from '@tanstack/react-query';
import React, { useState } from 'react';
import useOutsideClickHandler from '../Hook/useOutsideClickHandler';
import { notificationService } from '../_services/notification.service';
import NotificationModalHead from './NotificationModalHead';
import TaskNotification from './TaskNotification';
import './notification.css';
import { NOTIFICATION_FROM } from './notificationConstant';

const NotificationModal = ({ setIsNotificationVisible }) => {
  const { containerRef } = useOutsideClickHandler({
    onClose: () => setIsNotificationVisible((prev) => (prev ? !prev : prev)),
    bubbling: true,
  });

  const [notificationQuery, setNotificationQuery] = useState({
    select: [
      'id',
      'note',
      'task_id',
      'reminder_id',
      'request_id',
      'user_id',
      'is_read',
      'mode',
      'message_id',
      'notification_type',
      'updated_time',
      'chat_group_id',
      'project_id',
      'team_id',
    ],
    notification_from: NOTIFICATION_FROM.ALL_NOTIFICATION,
    isUnReadFlag: false,
  });

  const {
    data: notificationData,
    isPending,
    isError,
    error,
  } = useQuery({
    queryKey: ['notification-list', notificationQuery.isUnReadFlag],
    queryFn: () => notificationService.notificationList(notificationQuery),
  });

  // for handling read or unread
  const onHandleUnread = () => {
    setNotificationQuery((prevState) => ({
      ...prevState,
      isUnReadFlag: !prevState.isUnReadFlag,
    }));
  };

  return (
    <div ref={containerRef} className='notification-modal-container'>
      <NotificationModalHead
        isUnReadFlag={notificationQuery?.isUnReadFlag}
        notificationQuery
        onHandleUnread={onHandleUnread}
        setIsNotificationVisible={setIsNotificationVisible}
      />

      {isPending ? <div style={{ fontSize: 13, textAlign: 'center' }}>Loading...</div> : null}
      {isError ? (
        <div style={{ fontSize: 13, textAlign: 'center' }}>
          {error?.message || 'Network failed'}
        </div>
      ) : null}

      {!isPending && !isError && (
        <div className='update_notification_modal_container'>
          <TaskNotification
            data={notificationData?.data?.rows}
            setIsNotificationVisible={setIsNotificationVisible}
          />
        </div>
      )}
    </div>
  );
};

export default NotificationModal;
