import { useEffect, useState } from 'react';

const useRecorder = () => {
  const [audioURL, setAudioURL] = useState('');
  const [isRecording, setIsRecording] = useState(false);
  const [recorder, setRecorder] = useState(null);
  const [isTimerR, setIsTimerR] = useState(false);
  const [timer, setTimer] = useState(0);
  let interval;

  useEffect(() => {
    // Lazily obtain recorder first time we're recording.
    if (recorder === null) {
      if (isRecording) {
        // Check whether the website is https or localhost, mic is only accessible on these sites
        const isLocalhost = window.location.hostname === 'localhost';
        const isHttps = window.location.protocol === 'https:';
        if (!isLocalhost && !isHttps) {
          alert('Microphone access is restricted to secure (HTTPS) connections only.');
          setIsRecording(false);
          return;
        }

        requestRecorder().then(setRecorder, (err) => {
          alert(`To record Voice Messages, App needs access to your computer's microphone`);
          console.error(err);
          setIsRecording(false);
        });
      }
      return;
    }

    // Manage recorder state.
    if (isRecording) {
      setIsTimerR(true);
      recorder.start();
      if (!interval) {
        interval = setInterval(() => {
          setTimer((prev) => prev + 1000);
        }, 1000);
      }
    } else {
      setTimer(0);
      clearInterval(interval);
      setIsTimerR(false);
      recorder.stop();
    }

    // Obtain the audio when ready.
    const handleData = (e) => {
      setAudioURL(e.data);
      setRecorder(null);
    };

    recorder.addEventListener('dataavailable', handleData);
    return () => {
      recorder.removeEventListener('dataavailable', handleData);
      clearInterval(interval);
    };
  }, [recorder, isRecording]);

  const startRecording = () => {
    setIsRecording(true);
  };

  const stopRecording = () => {
    setIsRecording(false);
    recorder.stream.getTracks()[0].stop();
    return audioURL;
  };

  const stopRecordingAsync = () => {
    return new Promise((resolve) => {
      const handleData = (e) => {
        console.log('audioUrl inside listener', e.data);
        setAudioURL(e.data);
        setRecorder(null);
        recorder.removeEventListener('dataavailable', handleData); // Remove listener
        resolve(e.data);
      };

      if (recorder) {
        setIsRecording(false);
        recorder.addEventListener('dataavailable', handleData);
        recorder.stream.getTracks()[0].stop();
      } else {
        resolve('');
      }
    });
  };

  return {
    audioURL,
    isRecording,
    stopRecordingAsync,
    startRecording,
    stopRecording,
    setAudioURL,
    isTimerR,
    setIsTimerR,
    timer,
  };
};

async function requestRecorder() {
  const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
  return new MediaRecorder(stream);
}
export default useRecorder;
