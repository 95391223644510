import React from 'react';
import { NavLink, Outlet } from 'react-router-dom';
import { RT } from '../_constants';
import { TaskListTypeConst } from '../BoardView/BoardView.constant';

export default function ClientLayout({ clientData }) {
  const PROJECT_TABS = [
    {
      name: 'Tasks',
      path: `${RT.CLIENT_TASK_LIST}?status=${'in-progress'}&list_type=${
        TaskListTypeConst.ContactTask
      }`,
      isVisible: true,
    },
    {
      name: 'Files',
      path: `${RT.FILELIST}?client=${clientData?.id}`,
      isVisible: true,
    },
  ];

  return (
    <section className=' project-layout'>
      <div className='org-nav-contain ' style={{ marginBottom: 10 }}>
        {PROJECT_TABS?.filter((item) => item?.isVisible == true)?.map((tab, index) => {
          return (
            <NavLink
              key={index}
              className={({ isActive }) => {
                return isActive ? 'project-tabs-active  ' : 'project-tabs-in-active';
              }}
              style={{ fontSize: 13 }}
              to={tab?.path}
            >
              {tab.name}
            </NavLink>
          );
        })}
      </div>
      <div className='project-separation  ' />
      <section>
        <Outlet context={{ clientData: clientData }} />
      </section>
    </section>
  );
}
