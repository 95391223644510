import moment from 'moment';
import React from 'react';
// import { recurFrequency } from '../../utils/constants';
import { recurFrequency } from '../../_constants/recurrence.constants';
import { days, formatDate } from '../Calendar/Utils/misc';
import CalendarDateCell from './CalendarDateCell';

function DateView({
  onSelect = () => {},
  monthIndex,
  year,
  // selectedDate,
  setSelectedDate,
  setStatus,
  pattern,
  width,
}) {
  // Ḍoing all data calculation outside map.
  const frequency = parseInt(pattern.frequency);
  const interval = pattern.interval;
  const endDate = pattern.end_date;
  const startDate = pattern.start_date;
  const daysOfWeek = pattern.days_of_week;
  const daysOfMonth = pattern.days_of_month;
  const monthlyRecurrenceType = parseInt(pattern.monthly_recurrence_type);
  const weekNumber = pattern.week_number;
  const isDaily = frequency === recurFrequency.DAY_RECURRENCE;
  const isWeekly = frequency === recurFrequency.WEEK_RECURRENCE;
  const isMonthly = frequency === recurFrequency.MONTH_RECURRENCE;
  const today = moment().startOf('day');
  const endDateInMoment = endDate && moment(endDate).startOf('day');

  const dates = formatDate({ monthIndex, year });
  return (
    <>
      <table className='date-view'>
        <thead>
          <tr>
            {days.map((day, index) => (
              <th className='day-text p-0' key={index}>
                {width < 300 ? day.slice(0, 1) : day}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {Object.keys(dates).map((key) => {
            return (
              <tr key={key}>
                {dates[key].map((day, indx) => {
                  return (
                    <CalendarDateCell
                      key={indx}
                      day={day}
                      isMonthly={isMonthly}
                      startDate={startDate}
                      daysOfWeek={daysOfWeek}
                      endDate={endDateInMoment}
                      interval={interval}
                      isDaily={isDaily}
                      isWeekly={isWeekly}
                      daysOfMonth={daysOfMonth}
                      monthIndex={monthIndex}
                      weekNumber={weekNumber}
                      onSelect={onSelect}
                      monthlyRecurrenceType={monthlyRecurrenceType}
                      setSelectedDate={setSelectedDate}
                      setStatus={setStatus}
                      today={today}
                      year={year}
                    />
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </>
  );
}

export default DateView;
