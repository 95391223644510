import { keepPreviousData, useQuery, useQueryClient } from '@tanstack/react-query';
import { Palette, Pen, Plus, Search, Trash } from 'lucide-react';
import React, { useContext, useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Table, Tbody, Td, Th, Thead, Tr } from 'react-super-responsive-table';
import { GlobalContext, Images } from '../Utils';
import { Calendar, Label, LoadingIcon } from '../Utils/SvgIcons';
import { Button, PaginationComponent, TableRow } from '../_components';
import { ErrorComponent } from '../_components/ErrorComponent';
import { Modal } from '../_components/Modal';
import ToolTip from '../_components/Tooltip/ToolTip';
import { DELETE_NOTE } from '../_constants/deletenote.constants';
import useDebounce from '../_helpers/useDebounce';
import { labelService } from '../_services/label.service';
import './Label.css';
import { LabelAdd } from './LabelAdd';
// const TABLE_COLUMN = ['Sl.No', 'Label', 'Label Color', 'Text Color', 'Created At', 'Status', ''];

const TABLE_HEADINGS = [
  { name: <img src={Images.SerialNo} height={14} width={14} />, minWidth: 50 },
  {
    name: (
      <div className='d-flex align-items-center gap-1'>
        <Label height={16} width={16} color='var(--second-text-color)' /> Label
      </div>
    ),
    minWidth: '10vw',
    maxWidth: '20vw',
  },
  {
    name: (
      <div className='d-flex align-items-center gap-1'>
        {/* <ProgressIcon /> Progress */}
        <Palette size={17} color='var(--second-text-color)' />
        Label Color
      </div>
    ),
    minWidth: 50,
  },
  {
    name: (
      <div className='d-flex align-items-center gap-1'>
        {/* <User /> */}
        <Palette size={17} color='var(--second-text-color)' />
        Text Color
      </div>
    ),
    minWidth: 50,
  },

  {
    name: (
      <div className='d-flex align-items-center gap-1'>
        <Calendar height={14} width={14} /> Created On
      </div>
    ),
    minWidth: 50,
  },

  {},
];

export function LabelList() {
  // hooks section
  const queryClient = useQueryClient();
  const [searchParams, setSearchParams] = useSearchParams();
  const { roleBasedPermission } = useContext(GlobalContext);

  // reference section
  const tableContainerRef = useRef(null);
  const reference = useRef(null);

  //  useState section

  const [editLabelID, setEditLabelID] = useState(null);
  const [query, setQuery] = useState({
    select: ['id', 'name', 'text_color', 'label_color', 'createdAt', 'status'],
    pageVo: {
      pageNo: parseInt(searchParams.get('pageNo')) || 1,
      noOfItems: 10,
    },
    searchKey: '',
  });
  const [addModal, setAddModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const debouncedSearch = useDebounce(query?.searchKey, 500);

  // Label list API call section

  const {
    data: labelData,
    error,
    refetch,
    isLoading,
    isSuccess,
  } = useQuery({
    queryKey: ['label', query?.pageVo?.pageNo, debouncedSearch],
    queryFn: () => labelService.labelList(query),
    placeholderData: keepPreviousData,
  });

  const modalClose = () => {
    setDeleteModal(false);
    setEditLabelID(null);
  };
  // section trigger when delete option selected

  const handleDeactivate = async (id) => {
    try {
      await labelService.labelDeactivate({ id });
      let isNeedToBack = labelData?.data?.rows.length === 1 && labelData?.data?.pages > 1;

      if (isNeedToBack) {
        // const newPageNo = query.pageVo.pageNo - 1;
        const page = searchParams.get('pageNo');
        searchParams.set('pageNo', page - 1);
        setSearchParams(searchParams);
        setQuery((prev) => ({
          ...prev,
          pageVo: {
            ...prev.pageVo,
            pageNo: prev.pageVo.pageNo - 1,
          },
        }));
      } else {
        refetch();
      }

      await queryClient.invalidateQueries('label');
      modalClose();
    } catch (error) {
      console.error('Error deleting label:', error);
    }
  };

  // edit or delete function activation section

  // const handleClickOption = (option) => {
  //   if (option == 'edit') {
  //     setAddModal(true);
  //   } else if (option == 'delete') {
  //     setDeleteModal(true);
  //   }
  // };

  // get the number of data of label
  const noOfData = labelData?.data?.rows.length;

  // table data definition section

  const tableData = labelData?.data?.rows?.map((label, index) => {
    const slNo = (labelData?.data?.page - 1) * labelData?.data?.limit + index + 1;

    return (
      <Tr key={index}>
        <Td>{slNo}</Td>
        <Td>{label?.name}</Td>
        <Td style={{ verticalAlign: 'middle' }}>
          <div className='label_color_view' style={{ backgroundColor: label.label_color }} />
        </Td>
        <Td style={{ verticalAlign: 'middle' }}>
          <div className='label_color_view' style={{ backgroundColor: label.text_color }} />
        </Td>

        <Td>
          {/* {label?.createdAt.split('T')[0]}{' '} */}
          {new Date(label?.createdAt).toLocaleDateString('en-US', {
            month: 'short',
            day: 'numeric',
            year: 'numeric',
          })}
        </Td>

        <Td
          style={{ verticalAlign: 'middle', cursor: 'pointer' }}
          ref={reference}
          onClick={(event) => {
            event.stopPropagation();
          }}
        >
          {roleBasedPermission?.ACTION_LABEL_ADD && (
            // <div
            //   className='ellipsis_icon'
            //   ref={reference}
            //   onClick={(event) => {
            //     event.stopPropagation();
            //   }}
            // >
            //   <div onClick={() => setContextMenuIndex(index)}>
            //     <Ellipsis />
            //   </div>
            //   <ContextMenu
            //     handleClick={(value) => {
            //       setEditLabelID(label?.id);
            //       handleClickOption(value);
            //     }}
            //     isContextVisible={contextMenuIndex === index}
            //     onRequestClose={() => {
            //       // setEditLabelID(null);
            //       setContextMenuIndex(null);
            //     }}
            //   />
            // </div>
            <div className='d-flex gap-2 justify-content-end icon-div invisible'>
              <ToolTip tooltipText={`Edit Label`} isModern popOver scrollRef={tableContainerRef}>
                <div
                  className='icon-bg'
                  onClick={(event) => {
                    event.stopPropagation();
                    setEditLabelID(label?.id);
                    setAddModal(true);
                  }}
                >
                  <Pen size={15} />
                </div>
              </ToolTip>

              <ToolTip tooltipText={`Delete Label`} isModern popOver scrollRef={tableContainerRef}>
                <div
                  className='icon-bg'
                  onClick={(event) => {
                    event.stopPropagation();
                    setEditLabelID(label?.id);
                    setDeleteModal(true);
                  }}
                >
                  <Trash size={15} />
                </div>
              </ToolTip>
            </div>
          )}
        </Td>
      </Tr>
    );
  });

  return (
    <div className=''>
      <ErrorComponent error={error?.message} />
      <section>
        <div className='tab_contain'>
          <div className={'search_contain ' + (query.searchKey ? 'active' : '')}>
            <input
              onChange={(event) => {
                searchParams.set('pageNo', 1);
                setSearchParams(searchParams);
                setQuery({
                  ...query,
                  searchKey: event.target.value,
                  pageVo: { ...query.pageVo, pageNo: 1 },
                });
              }}
              placeholder='Search...'
              type='text'
              className='bodr_none'
            />
            <Search size={18} color='#87909E' />
          </div>
          <div className='d-flex gap-2'>
            {roleBasedPermission?.ACTION_LABEL_ADD && (
              <ToolTip tooltipText={'Add Label'} isModern popOver>
                <div className='menu-item-outline' onClick={() => setAddModal(true)}>
                  <Plus size={16} color='#87909E' />
                </div>
              </ToolTip>
            )}
          </div>
        </div>

        <section className='mt-2'>
          <div
            className='project-table table-container no-padding'
            ref={tableContainerRef}
            style={{ maxHeight: '65vh' }}
          >
            <Table className='table table-hover tbl mb-0'>
              <Thead>
                <Tr className='table-head'>
                  {TABLE_HEADINGS.map((heading, index) => {
                    return (
                      <Th
                        key={index}
                        scope='col'
                        style={{ minWidth: heading.minWidth, maxWidth: heading.maxWidth }}
                      >
                        {heading.name}
                      </Th>
                    );
                  })}
                </Tr>
              </Thead>
              <Tbody>
                {isLoading && (
                  <TableRow noOfCols={7}>
                    <LoadingIcon size={60} />
                  </TableRow>
                )}
                {!noOfData && isSuccess && <TableRow noOfCols={7}>No data found</TableRow>}
                {noOfData > 0 && isSuccess && tableData}
              </Tbody>
            </Table>
          </div>
        </section>
        {noOfData > 0 && (
          <section className='mt-2'>
            <PaginationComponent
              pageOnClick={(data) => {
                setQuery((prev) => ({
                  ...prev,
                  pageVo: {
                    ...prev.pageVo,
                    pageNo: data,
                  },
                }));
                searchParams.set('pageNo', data);
                setSearchParams(searchParams);
              }}
              demoData={labelData?.data}
            />
          </section>
        )}
      </section>

      {addModal && (
        <Modal.Container
          handleClose={() => {
            setEditLabelID(null);
            setAddModal(false);
          }}
        >
          <Modal.View className={'modal-view-trigger '} containerClass={'view-end'}>
            {/* <Modal.Head
              Heading={editLabelID ? 'Edit Labels' : 'Add Labels'}
              handleClose={() => {
                setEditLabelID(null);
                setAddModal(false);
              }}
            /> */}
            <LabelAdd
              id={editLabelID}
              handleClose={() => {
                setAddModal(false);
                setEditLabelID(null);
                refetch();
              }}
            />
          </Modal.View>
        </Modal.Container>
      )}

      {deleteModal && (
        <Modal.Container>
          <Modal.View className={'status-delete'}>
            <Modal.Head handleClose={() => modalClose()} Heading={'Delete Confirmation'} />
            <Modal.Description>
              <img src={Images.DeleteBin} width={35} height={50} alt='' className='mb-3' />
              <div className='del-description'>{DELETE_NOTE.LABEL}</div>
            </Modal.Description>
            <div className='modal_footer'>
              <Button.Container handleOk={() => modalClose()} className={'status-del-cancel'}>
                <Button.Title title={'Cancel'} />
              </Button.Container>
              <Button.Container handleOk={() => handleDeactivate(editLabelID)}>
                <Button.Title title={'Delete'} />
              </Button.Container>
            </div>
          </Modal.View>
        </Modal.Container>
      )}
    </div>
  );
}
