import React, { useState } from 'react';
import { miscService } from '../_services';

export const SETTINGS_DATA = [
  'menu-settings',
  'project-settings',
  'employee-settings',
  'priority-label',
  'widgets',
  'permission',
  'general-settings',
  'personalization',
];

export function Init() {
  const [selectedItems, setSelectedItems] = useState([]);

  const handleCheckboxChange = (item) => {
    setSelectedItems((prevSelectedItems) => {
      if (prevSelectedItems.includes(item)) {
        return prevSelectedItems.filter((selectedItem) => selectedItem !== item);
      } else {
        return [...prevSelectedItems, item];
      }
    });
  };

  const callInitFun = async () => {
    try {
      await miscService.initFunction(selectedItems);
      window.location.reload();
    } catch (error) {
      console.error(error);
    }
  };
  return (
    <div>
      <div
        onClick={() => callInitFun()}
        style={{ marginTop: 50, textAlign: 'center', cursor: 'pointer' }}
      >
        GO INIT
      </div>

      <div>
        {SETTINGS_DATA.map((item, index) => (
          <div key={index} style={{ textAlign: 'left', margin: '10px auto', width: '200px' }}>
            <input
              type='checkbox'
              checked={selectedItems.includes(item)}
              onChange={() => handleCheckboxChange(item)}
            />
            <label style={{ marginLeft: 8 }}>{item}</label>
          </div>
        ))}
      </div>
    </div>
  );
}
