/* eslint-disable no-unused-vars */
import { Pen, Plus } from 'lucide-react';
import React, { useContext, useRef, useState } from 'react';
import { Table, Tbody, Th, Thead, Tr } from 'react-super-responsive-table';
import { Button, Modal, TableRow } from '../_components';
import ToolTip from '../_components/Tooltip/ToolTip';
import EditableField from '../Task/TaskComponents/EditableField';
import { FileSystemImage, GlobalContext } from '../Utils';
import { LoadingIcon } from '../Utils/SvgIcons';
import { AddTimeLog } from './AddTimeLog';
import { TimeSheetTableBody } from './TimeSheetTableBody';

export const TimeSheetGenerate = ({ payload, handleClose, handleSuccess }) => {
  const HEADS = ['#', 'Entries', 'From Date', 'To Date', 'Total Hours', 'Billing Hours', 'Action'];

  const [error, setError] = useState(false);
  const [timesheetData, setTimeSheetData] = useState(payload?.timeSheetEntries ?? []);
  const [isLoading, setIsLoading] = useState(false);
  const [showAddNewModal, setShowAddNewModal] = useState(false);
  const [editName, setEditName] = useState(false);
  const [timeSheetName, setTimeSheetName] = useState(payload.name ?? '');
  const { roleBasedPermission } = useContext(GlobalContext);

  const tableContainerRef = useRef(null);

  const handleAddNewTimeLog = (newData) => {
    console.log('NewTimeLog', newData);

    if (checkTaskExist(newData.task_id) !== -1) {
      const index = checkTaskExist(newData.task_id);
      console.log('ExistTimeLog', newData, index, timesheetData);
      timesheetData[index].time_logs.push(newData);
      timesheetData[index].total_hours =
        parseInt(timesheetData[index].total_hours) + parseInt(newData.total_hours);
      timesheetData[index].billing_hours =
        parseInt(timesheetData[index].billing_hours) + parseInt(newData.billing_hours);
    } else {
      const newObj = {
        task_id: newData.task_id,
        task_name: newData.task_name,
        from_date: newData.start_time,
        to_date: newData.end_time,
        total_hours: newData.total_hours,
        billing_hours: newData.billing_hours,
        time_logs: [newData],
      };
      timesheetData.push(newObj);
    }
    console.log('PayloadThing', timesheetData);

    setTimeSheetData(timesheetData);
    setShowAddNewModal(false);
  };

  const checkTaskExist = (task_id) => {
    return timesheetData?.findIndex((element) => element.task_id == task_id) ?? false;
  };

  const handleChangeName = (value) => {
    setTimeSheetName(value.name);
  };

  return (
    <div className='fade-entry  timesheet-data-container'>
      <div>
        <div className={payload.name ? '' : 'd-none'}>
          <div className='timesheet-tablemain-conatiner'>
            <section className='mt-2'>
              <div className='timesheet-title-container'>
                <div className='col-md-10'>
                  <div className='timesheet-name'>
                    <EditableField
                      onSave={(val) => handleChangeName(val)}
                      name='name'
                      type='input'
                      placeholder='Name'
                      isEditable={true}
                      data={{ name: timeSheetName }}
                      inputClassName='taskname-input w-100 form-control'
                    >
                      <div className='font-18'>
                        <>
                          {timeSheetName}{' '}
                          <Pen
                            size={15}
                            onClick={() => {
                              setEditName(true);
                            }}
                          />
                        </>
                      </div>
                    </EditableField>
                  </div>
                </div>
                <ToolTip tooltipText={`Add time log`} isModern>
                  <div
                    className='menu-item-outline'
                    onClick={() => {
                      setShowAddNewModal(true);
                    }}
                  >
                    <Plus size={16} color='#87909E' />
                  </div>
                </ToolTip>
              </div>
              <div
                className='timesheet-table timesheet-table-conatiner no-padding'
                ref={tableContainerRef}
                style={{ maxHeight: '65vh' }}
              >
                <Table className='table table-hover tbl mb-0'>
                  <Thead>
                    <Tr className='table-head'>
                      {HEADS.map((heading, index) => {
                        return (
                          <Th key={index} scope='col'>
                            {heading}
                          </Th>
                        );
                      })}
                    </Tr>
                  </Thead>
                  <Tbody style={{ verticalAlign: 'middle' }}>
                    {isLoading && (
                      <TableRow noOfCols={8}>
                        <LoadingIcon size={60} />
                      </TableRow>
                    )}

                    {!isLoading && timesheetData.length > 0 && (
                      <TimeSheetTableBody
                        timesheetData={timesheetData}
                        isEditable={true}
                        setTimeSheetData={setTimeSheetData}
                      />
                    )}
                  </Tbody>
                </Table>
                <div
                  className={
                    !isLoading && timesheetData.length == 0 ? 'timesheet-nodata' : 'd-none'
                  }
                >
                  <img src={FileSystemImage.EmptyDocument} className='timesheet-nodataiconbg' />
                  <div className='timesheet-nodatatxt'>No timesheet found</div>
                  {roleBasedPermission?.ACTION_TIME_SHEET_ADD && (
                    <div
                      className='timesheetgeneratebtn mt-2'
                      onClick={() => setShowAddNewModal(true)}
                    >
                      <Plus size={16} color='#87909E' /> Add
                    </div>
                  )}
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
      {showAddNewModal && (
        <Modal.Container>
          <Modal.View className={'team-list timesheet-add-log-modal'}>
            <AddTimeLog
              client_id={payload?.clientId}
              handleAdd={handleAddNewTimeLog}
              handleClose={() => setShowAddNewModal(false)}
              data={payload}
            />
          </Modal.View>
        </Modal.Container>
      )}
      <div className={`modal_footer`}>
        <div className='d-flex gap-2'>
          <Button.Container className='cancel-btn' handleOk={handleClose}>
            <Button.Title title='Close' />
          </Button.Container>
          <Button.Container
            type='submit'
            isDisabled={isLoading}
            handleOk={() => {
              if (timesheetData.length > 0)
                handleSuccess({ ...payload, timeSheetEntries: timesheetData, name: timeSheetName });
            }}
          >
            <Button.Title title={'Generate'} />
            <Button.Loading isLoading={isLoading} color='#FFF' size={20} />
          </Button.Container>
        </div>
      </div>
    </div>
  );
};
