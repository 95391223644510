import parse from 'html-react-parser';
import { Clock, Flag } from 'lucide-react';
import moment from 'moment';
import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import CircularAvatar from '../../_components/Avatar/CircularAvatar';
import { apiConstants, MENU, RT } from '../../_constants';
import { projectConstants } from '../../_constants/project.constant';
import {
  STATUS,
  TASK_ACTIVITY,
  TASK_ACTIVITY_SENTENCE,
  taskLogField,
} from '../../Task/taskConstants';
import { getPriorityByContextAPI, getTaskTime, LinkOptions } from '../../Task/taskValidation';
import { formatMillisecondFeatured, GlobalContext } from '../../Utils';
import { ArrowRight } from '../../Utils/SvgIcons';
import './activityreports.css';

export const ActivityContents = ({ data, isList }) => {
  return (
    <>
      {data?.map((log, index, array) => {
        return (
          <ActivityContainer key={index} log={log} index={index} array={array} isList={isList} />
        );
      })}
    </>
  );
};

// single log activity
const ActivityContainer = ({ log = [], index = null, array = [], isList = false }) => {
  const navigate = useNavigate();
  const { getMenuLabelName } = useContext(GlobalContext);
  const LABEL_OF_CLIENT = getMenuLabelName(MENU.contact.default_name);

  const previousValue = index > 0 ? array[index - 1] : null;
  console.log('activityContainer', array, index, previousValue);

  const action = log?.action;
  // for user
  const fullName = log?.['user.first_name'] + ' ' + log?.['user.last_name'];
  const taskName =
    log.action == TASK_ACTIVITY.DELETED ? log?.data?.deletedTaskName : log?.['task.name'];
  const url = log?.['user.compressed_image_url']
    ? log?.['user.compressed_image_url']
    : log?.['user.image_url'];
  const userImage = apiConstants.imgUrlS3 + url;
  const isMale = log?.['user.gender'] == 1;
  // for log time
  const logTime = moment(log?.createdAt).format('MMM DD, YYYY [at] h:mm A');
  let actionMessage = ''; // action message initialize
  const isEdited = log?.action == 'edited';

  const updatedTaskLogField = { ...taskLogField, client_id: LABEL_OF_CLIENT };

  if (log?.data) {
    const dataKeys = Object.keys(log?.data?.updatedData);
    const findKeyArray = dataKeys?.filter((key) => updatedTaskLogField[key]);
    actionMessage = log?.action == 'edited' ? updatedTaskLogField?.[findKeyArray?.[0]] : '';
    console.log('heyArt', actionMessage, findKeyArray, dataKeys);
  }

  if (actionMessage == updatedTaskLogField.milestone_id) {
    actionMessage =
      log?.data?.updatedData?.milestone_details?.[0]?.['Project.project_type'] ===
      projectConstants.MILESTONE
        ? 'Milestone'
        : 'Sprint';
  }

  console.log({ actionMessage });

  const taskId = log?.['task.id'];
  let navLink = `/${RT.TASK}/${taskId}?tab=work-history`;

  return (
    <div className='activity-container '>
      <div className='m-auto'>
        <CircularAvatar key={fullName} isMale={isMale} size={46} source={userImage} />
      </div>
      <div className='col'>
        <div className='d-flex justify-content-between gap-1'>
          <div className='activity-user'>
            <div className='activity-content '>
              <div className='d-flex flex-column gap-2'>
                <div>
                  <span className='capital-text '>{`${fullName} `}</span>
                  <span className='activity-timestamp'>{TASK_ACTIVITY_SENTENCE[action]}</span>
                  <span className='activity-timestamp pointer' hidden={isEdited}>
                    task
                  </span>
                  <span
                    className='pointer'
                    onClick={() => navigate(navLink)}
                    hidden={!isList}
                  >{` ${taskName}`}</span>
                  <span>{` ${actionMessage}`}</span>
                </div>
                <ActivityChange
                  actionData={log}
                  previousValue={previousValue}
                  actionMessage={actionMessage}
                />
              </div>
            </div>
          </div>
          <div className='d-flex flex-column gap-2 justify-content-center '>
            {log?.time_difference > 0 && (
              <div className='d-flex align-items-center gap-1 capital-text fz-12px justify-content-end'>
                {formatMillisecondFeatured(parseInt(log?.time_difference), true)}
                <Clock size={16} />
              </div>
            )}
            <div className='activity-timestamp ' style={{ color: '#87909e' }}>
              <div>{logTime}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

// the activity container
const ActivityChange = ({ actionData, previousValue, actionMessage }) => {
  const actionLog = {
    prev: null,
    current: null,
    prevColor: '#8190E',
    currentColor: '#8190E',
  };

  // switch for status change, start, stop, completed and verified prev and current value setting
  switch (actionData.action) {
    case TASK_ACTIVITY.STARTED:
      actionLog.prev =
        previousValue?.action != TASK_ACTIVITY.STATUS_CHANGE ? previousValue?.action : 'To Do';
      actionLog.current = 'Started';
      break;
    case TASK_ACTIVITY.COMPLETED:
      actionLog.prev = previousValue?.action ?? 'Stopped';
      actionLog.current = 'Completed';
      break;
    case TASK_ACTIVITY.STOPPED:
      actionLog.prev = 'Started';
      actionLog.current = 'Stopped';
      break;
    case TASK_ACTIVITY.STATUS_CHANGE:
      actionLog.prev = actionData['previous_status_group_item.name'];
      actionLog.prevColor = actionData['previous_status_group_item.color'];
      actionLog.current = actionData['current_status_group_item.name'];
      actionLog.currentColor = actionData['current_status_group_item.color'];
      break;
    case TASK_ACTIVITY.VERIFIED:
      actionLog.prev = previousValue?.action ?? 'Completed';
      actionLog.current = 'Verified';
      break;
    default:
      break;
  }

  let returnItem = <></>; // return item initialize

  if (actionData.action == TASK_ACTIVITY.CREATION) {
    // task creation
    console.log('hereReached1', returnItem);

    returnItem = <div className='capital-text in-progress'>{actionData.action}</div>;
  } else if (actionData.action == TASK_ACTIVITY.DELETED) {
    // task creation
    returnItem = <div className='capital-text in-progress'>{actionData.action}</div>;
  } else if (actionData.action == TASK_ACTIVITY.EDITED) {
    // only for task edited items show
    returnItem = <EditedItems data={actionData?.data} actionMessage={actionMessage} />;
    console.log('hereReached2', returnItem);
  } else if (
    actionData.action == TASK_ACTIVITY.STARTED ||
    actionData.action == TASK_ACTIVITY.STOPPED
  ) {
    returnItem = (
      <>
        <div className=' in-progress' hidden={!actionLog.prev}>
          <span
            hidden={actionData.action != TASK_ACTIVITY.STATUS_CHANGE}
            className='activity-status-dot'
            style={{ background: actionLog.prevColor }}
          ></span>
          {actionLog.prev}
        </div>
        {actionLog.prev && <ArrowRight width={9.001} height={8.307} color='#67677F' />}
        <div className=' in-progress'>
          <span
            hidden={actionData.action != TASK_ACTIVITY.STATUS_CHANGE}
            className='activity-status-dot'
            style={{ background: actionLog.currentColor }}
          ></span>
          {actionLog.current}
        </div>
      </>
    );
  } else {
    // to show prev to current status
    returnItem = (
      <>
        <div className='status in-progress' hidden={!actionLog.prev}>
          <span
            hidden={actionData.action != TASK_ACTIVITY.STATUS_CHANGE}
            className='activity-status-dot'
            style={{ background: actionLog.prevColor }}
          ></span>
          {actionLog.prev}
        </div>
        {actionLog.prev && <ArrowRight width={9.001} height={8.307} />}
        <div className='status in-progress'>
          <span
            hidden={actionData.action != TASK_ACTIVITY.STATUS_CHANGE}
            className='activity-status-dot'
            style={{ background: actionLog.currentColor }}
          ></span>
          {actionLog.current}
        </div>
      </>
    );
  }

  console.log(returnItem, 'hereReached');

  return <div className='status-change gap-2'>{returnItem}</div>;
};

// to show the edited fields in the task single view
const EditedItems = ({ data }) => {
  console.log('hey22');

  if (data?.previousData?.status) {
    if (
      data?.previousData?.status === STATUS.ACTIVE &&
      data?.updatedData?.status === STATUS.ARCHIVE
    ) {
      return (
        <>
          Task Archived
          {/* <span className='description-container'>
          <span className='activity-user'>{data?.previousData?.name}</span>
        </span>
        <ArrowRight width={9.001} height={8.307} />
        <span className='description-container'>
          <span className='activity-user'>{data?.updatedData?.name}</span>
        </span> */}
        </>
      );
    }
    if (
      data?.previousData?.status === STATUS.ARCHIVE &&
      data?.updatedData?.status === STATUS.ACTIVE
    ) {
      return (
        <>
          Task UnArchived
          {/* <span className='description-container'>
          <span className='activity-user'>{data?.previousData?.name}</span>
        </span>
        <ArrowRight width={9.001} height={8.307} />
        <span className='description-container'>
          <span className='activity-user'>{data?.updatedData?.name}</span>
        </span> */}
        </>
      );
    }
  }
  if (data?.previousData?.assignee_details) {
    // assignee view
    const previousFullName =
      data?.previousData?.assignee_details?.first_name +
      ' ' +
      data?.previousData?.assignee_details?.last_name;
    const previousUserImage =
      apiConstants.imgUrlS3 + data?.previousData?.assignee_details?.image_url;
    const previousIsMale = data?.previousData?.assignee_details?.gender == 1;
    const updatedFullName =
      data?.updatedData?.assignee_details?.first_name +
      ' ' +
      data?.updatedData?.assignee_details?.last_name;
    const updatedUserImage = apiConstants.imgUrlS3 + data?.updatedData?.assignee_details?.image_url;
    const updatedIsMale = data?.updatedData?.assignee_details?.gender == 1;
    return (
      <>
        <div className='d-flex align-items-center'>
          <CircularAvatar
            key={previousFullName}
            isMale={previousIsMale}
            size={20}
            source={previousUserImage}
          />
          <span className='activity-user capital-text'>{previousFullName}</span>
        </div>
        <ArrowRight width={9.001} height={8.307} />
        <div className='d-flex align-items-center'>
          <CircularAvatar
            key={updatedFullName}
            isMale={updatedIsMale}
            size={20}
            source={updatedUserImage}
          />
          <span className='activity-user capital-text'>{updatedFullName}</span>
        </div>
      </>
    );
  }
  if (data?.previousData?.okr_details || data?.updatedData?.okr_details) {
    return (
      <>
        {data?.previousData?.okr_details?.name && (
          <>
            <span className='status in-progress'>{data?.previousData?.okr_details.name}</span>
            <ArrowRight width={9.001} height={8.307} />
          </>
        )}
        {data?.updatedData?.okr_details?.name && (
          <span className='status in-progress'>{data?.updatedData?.okr_details.name}</span>
        )}
      </>
    );
  }
  if (data?.previousData?.label_details || data?.updatedData?.label_details) {
    // label view-previous list
    return (
      <div className='inline-task-label-container d-flex align-items-center gap-1'>
        {data?.previousData?.label_details?.length > 0 && (
          <>
            {data?.previousData?.label_details?.map((item, idx) => (
              <div
                key={idx}
                className='label-item-log'
                style={{
                  background: item?.label_color,
                  color: item?.text_color,
                  margin: ' 0 10px 0px 0',
                  border: `1px solid ${item?.text_color}`,
                }}
              >
                {item.name} {/* Display the name property of the label object */}
              </div>
            ))}
            <ArrowRight width={9.001} height={8.307} />
          </>
        )}
        {data?.updatedData?.label_details?.map((item, idx) => (
          <div
            key={idx}
            className='label-item-log'
            style={{
              background: item?.label_color,
              color: item?.text_color,
              margin: ' 0 10px 0px 0',
              border: `1px solid ${item?.text_color}`,
            }}
          >
            {item.name} {/* Display the name property of the label object */}
          </div>
        ))}
      </div>
    );
  }
  if (data?.previousData?.priority || data?.updatedData?.priority) {
    // priority view
    const options = getPriorityByContextAPI(); // to fetch the priority  label from context api
    const previousPriorityName = options?.find(
      (item) => item.value == data?.previousData?.priority,
    )?.label;
    const updatedPriorityName = options?.find(
      (item) => item.value == data?.updatedData?.priority,
    )?.label;
    return (
      <>
        {previousPriorityName && (
          <>
            <span className='priority-item-log'>{previousPriorityName}</span>
            <ArrowRight width={9.001} height={8.307} />
          </>
        )}
        <span className='priority-item-log'>{updatedPriorityName}</span>
      </>
    );
  }
  if (data?.previousData?.task_start_date) {
    // date change view
    return (
      <>
        <div className='status in-progress'>
          {moment(parseInt(data?.previousData?.task_start_date)).format('MMM DD')} {' - '}
          {moment(parseInt(data?.previousData?.task_end_date)).format('MMM DD')}
        </div>
        <ArrowRight width={9.001} height={8.307} />
        <div className='status in-progress'>
          {moment(parseInt(data?.updatedData?.task_start_date)).format('MMM DD')}
          {' - '}
          {moment(parseInt(data?.updatedData?.task_end_date)).format('MMM DD')}
        </div>
      </>
    );
  }
  if (data?.previousData?.expected_hours || data?.updatedData?.expected_hours) {
    // expected time view
    return (
      <>
        {data?.previousData?.expected_hours && (
          <>
            <span className='status in-progress'>
              {getTaskTime(data?.previousData?.expected_hours)}
            </span>
            <ArrowRight width={9.001} height={8.307} />
          </>
        )}
        <span className='status in-progress'>{getTaskTime(data?.updatedData?.expected_hours)}</span>
      </>
    );
  }
  if (data?.previousData?.name) {
    return (
      <>
        <span className='description-container'>
          <span className='activity-user'>{data?.previousData?.name}</span>
        </span>
        <ArrowRight width={9.001} height={8.307} />
        <span className='description-container'>
          <span className='activity-user'>{data?.updatedData?.name}</span>
        </span>
      </>
    );
  }
  if (data?.previousData?.note || data?.updatedData?.note) {
    return (
      <div className='d-flex gap-2 align-items-baseline'>
        {data?.previousData?.note && (
          <>
            <span className='description-container'>
              {parse(data?.previousData?.note, LinkOptions)}
            </span>
            <ArrowRight width={9.001} height={8.307} />
          </>
        )}
        <span className='description-container'>{parse(data?.updatedData?.note, LinkOptions)}</span>
      </div>
    );
  }
  if (data?.previousData?.milestone_details || data?.updatedData?.milestone_details) {
    return (
      <>
        {data?.previousData?.milestone_details?.[0]?.name && (
          <>
            <span className='status in-progress'>
              {data?.previousData?.milestone_details?.[0]?.name}
            </span>
            <ArrowRight width={9.001} height={8.307} />
          </>
        )}
        {data?.updatedData?.milestone_details?.[0]?.name && (
          <span className='status in-progress'>
            {data?.updatedData?.milestone_details?.[0]?.name}
          </span>
        )}
      </>
    );
  }

  if (data?.previousData?.client_name || data?.updatedData?.client_name) {
    return (
      <>
        {data?.previousData?.client_name && (
          <>
            <span className='status in-progress'>{data?.previousData?.client_name}</span>
            <ArrowRight width={9.001} height={8.307} />
          </>
        )}
        <span className='status in-progress'>{data?.updatedData?.client_name}</span>
      </>
    );
  }
  if (data?.previousData?.order_seq || data?.updatedData?.order_seq) {
    return (
      // default return now - flag - temporary
      <>
        <div className='task-single-menu-icon'>
          <Flag color='#879096' size={14} />
        </div>
        <span className='status in-progress'>{data?.previousData?.order_seq ?? 0}</span>
        <ArrowRight width={9.001} height={8.307} />
        <span className='status in-progress'>{data?.updatedData?.order_seq}</span>
      </>
    );
  }

  if (data?.previousData?.project_name || data?.updatedData?.project_name) {
    return (
      <>
        {data?.previousData?.project_name && (
          <>
            <span className='status in-progress'>{data?.previousData?.project_name}</span>
            <ArrowRight width={9.001} height={8.307} />
          </>
        )}
        <span className='status in-progress'>{data?.updatedData?.project_name}</span>
      </>
    );
  }
  if (data?.previousData?.template_name || data?.updatedData?.template_name) {
    return (
      <>
        {data?.previousData?.template_name && (
          <>
            <span className='status in-progress'>{data?.previousData?.template_name}</span>
            <ArrowRight width={9.001} height={8.307} />
          </>
        )}
        <span className='status in-progress'>{data?.updatedData?.template_name}</span>
      </>
    );
  }
  return (
    <>
      <span className='status in-progress'>{data?.previousData?.status_name}</span>
      <ArrowRight width={9.001} height={8.307} />
      <span className='status in-progress'>{data?.updatedData?.status_name}</span>
    </>
  );
};
