import React from 'react';
export const OwnerIcon = () => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='16' height='17' viewBox='0 0 16 17' fill='none'>
      <path
        d='M7.99957 6.77721C9.50301 6.77721 10.7218 5.55843 10.7218 4.05499C10.7218 2.55155 9.50301 1.33276 7.99957 1.33276C6.49612 1.33276 5.27734 2.55155 5.27734 4.05499C5.27734 5.55843 6.49612 6.77721 7.99957 6.77721Z'
        stroke='#87909E'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M1 14.866V15.3326H15V14.866C15 13.1236 15 12.2524 14.6609 11.5869C14.3627 11.0015 13.8867 10.5255 13.3013 10.2273C12.6358 9.88818 11.7646 9.88818 10.0222 9.88818H5.97778C4.2354 9.88818 3.36421 9.88818 2.69871 10.2273C2.1133 10.5255 1.63736 11.0015 1.33909 11.5869C1 12.2524 1 13.1236 1 14.866Z'
        stroke='#87909E'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};

export const ProjectIcon = () => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='15' height='16' viewBox='0 0 15 16' fill='none'>
      <path
        d='M9.20399 9.71802H5.10156'
        stroke='#87909E'
        strokeWidth='1.49989'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M1.5128 5.10229C1.3768 5.10229 1.24637 5.15632 1.1502 5.25249C1.05403 5.34866 1 5.47909 1 5.6151V12.5379C1 13.5071 1.82561 14.3328 2.79481 14.3328H11.5125C12.4817 14.3328 13.3073 13.5472 13.3073 12.578V5.6151C13.3073 5.47909 13.2532 5.34866 13.1571 5.25249C13.0609 5.15632 12.9305 5.10229 12.7945 5.10229H1.5128Z'
        stroke='#87909E'
        strokeWidth='1.49989'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M4.07812 5.10267V4.07706C4.07812 3.26104 4.40229 2.47844 4.9793 1.90142C5.55632 1.32441 6.33892 1.00024 7.15494 1.00024V1.00024C7.97097 1.00024 8.75357 1.32441 9.33058 1.90142C9.9076 2.47844 10.2318 3.26104 10.2318 4.07706V5.10267'
        stroke='#87909E'
        strokeWidth='1.49989'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};

export const PriorityIcon = () => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='10' height='16' viewBox='0 0 10 16' fill='none'>
      <path
        d='M7.66667 1.33276H2.33333C1.97971 1.33276 1.64057 1.4908 1.39052 1.7721C1.14048 2.05341 1 2.43494 1 2.83276V14.3328L5 10.3328L9 14.3328V2.83276C9 2.43494 8.85952 2.05341 8.60948 1.7721C8.35943 1.4908 8.02029 1.33276 7.66667 1.33276Z'
        stroke='#87909E'
        strokeWidth='1.49989'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};

export const TagsIcon = ({color = '#87909E', width = '16', height = '16'}) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={width}
      height={height}
      viewBox='0 0 16 16'
      fill='none'
    >
      <path
        d='M13.5642 1.33276H9.57626C9.45226 1.33267 9.33327 1.38167 9.24528 1.46905L1.27257 9.44176C1.098 9.61719 1 9.85461 1 10.1021C1 10.3496 1.098 10.587 1.27257 10.7624L5.0691 14.559C5.24453 14.7335 5.48195 14.8315 5.72944 14.8315C5.97693 14.8315 6.21435 14.7335 6.38977 14.559L14.3592 6.5895C14.4466 6.50151 14.4956 6.38252 14.4955 6.25852V2.2673C14.4961 2.14468 14.4725 2.02315 14.4259 1.90971C14.3794 1.79627 14.3108 1.69315 14.2243 1.6063C14.1377 1.51944 14.0349 1.45056 13.9216 1.40361C13.8083 1.35667 13.6869 1.33259 13.5642 1.33276Z'
        stroke={color}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M11.9016 4.96675C11.6963 4.96675 11.4955 4.90585 11.3248 4.79175C11.154 4.67766 11.0209 4.51548 10.9423 4.32575C10.8637 4.13601 10.8432 3.92723 10.8832 3.72581C10.9233 3.52438 11.0222 3.33936 11.1674 3.19415C11.3126 3.04893 11.4976 2.95003 11.6991 2.90997C11.9005 2.8699 12.1093 2.89046 12.299 2.96906C12.4888 3.04765 12.6509 3.18074 12.765 3.3515C12.8791 3.52226 12.94 3.72301 12.94 3.92838C12.94 4.20377 12.8306 4.46789 12.6359 4.66262C12.4412 4.85735 12.177 4.96675 11.9016 4.96675Z'
        fill={color}
      />
    </svg>
  );
};

export const EffortsIcon = () => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='17' height='17' viewBox='0 0 17 17' fill='none'>
      <path
        d='M8.25144 2.6352C8.43476 2.6201 8.62006 2.61255 8.80733 2.61255C12.5016 2.61255 15.4989 5.60982 15.4989 9.30413C15.4989 12.9984 12.5016 15.9957 8.80733 15.9957C7.38972 15.9957 6.00872 15.5456 4.86328 14.7104C3.71784 13.8752 2.86709 12.6979 2.43359 11.3482'
        stroke='#87909E'
        strokeWidth='1.49989'
        strokeMiterlimit='10'
        strokeLinecap='round'
      />
      <path
        d='M8.24922 1.4978C4.24543 1.4978 1.00001 4.74322 1.00001 8.74702C0.998487 9.76795 1.21337 10.7776 1.63048 11.7094L8.24922 8.74702V1.4978Z'
        stroke='#87909E'
        strokeWidth='1.49989'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};
