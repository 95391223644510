import React, { useEffect, useRef } from 'react';
import { CONTEXT_MENU_OPTIONS, POSITION } from '../_constants/contextMenu.constants';

export const ContextMenu = ({
  handleClick = () => {},
  isContextVisible,
  onRequestClose = () => {},
  children,
  position = POSITION.RIGHT,
  positionClass = '',
  popupWidth = '',
  isEditVisible = true,
  isDeleteVisible = true,
}) => {
  const contextRef = useRef();

  const positionClassName = position === POSITION.RIGHT ? 'right-30px' : 'left-30px';

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, false);
    return () => {
      document.removeEventListener('click', handleClickOutside, false);
    };
  }, []);

  const handleClickOutside = (event) => {
    if (!contextRef.current) {
      return;
    }

    const parentElement = contextRef.current.parentNode;
    if (parentElement && !parentElement.contains(event.target)) {
      onRequestClose();
    }
  };

  if (!isContextVisible) {
    return null;
  }

  return (
    <div
      ref={contextRef}
      onBlur={() => onRequestClose()}
      className={`menu_popup context-container ${
        positionClass ? positionClass : positionClassName
      } `}
      style={{ width: popupWidth ? `${popupWidth}px` : undefined }}
    >
      {children ? (
        children
      ) : (
        <>
          {isEditVisible && (
            <div
              className='menu_items'
              onClick={(event) => {
                event.stopPropagation();
                handleClick(CONTEXT_MENU_OPTIONS.EDIT);
                onRequestClose();
              }}
            >
              Edit
            </div>
          )}
          {isDeleteVisible && (
            <div
              className='menu_items'
              onClick={(event) => {
                event.stopPropagation();
                handleClick(CONTEXT_MENU_OPTIONS.DELETE);
                onRequestClose();
              }}
            >
              Delete
            </div>
          )}
        </>
      )}
    </div>
  );
};
