import { FileText, HomeIcon } from 'lucide-react';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import IconLabelSet from '../_components/IconLabelSet';
import { RemindersAddWithList } from './RemindersAddWithList';

function ReminderList() {
  const currentUser = JSON.parse(localStorage.getItem('user'))?.id;
  const navigate = useNavigate();

  return (
    <div className='fade-entry'>
      <div className=' url-container '>
        <div className='d-flex align-items-center  breadcrumbs'>
          <IconLabelSet
            label={'Home'}
            fontWeight={400}
            icon={<HomeIcon size={13} />}
            onClick={() => navigate(`/`)}
          />
          <img className='slash-img-icon-size ms-1 mt-1' src='/icons/Lineicon.png' alt='' />
          <IconLabelSet
            label={`Reminder`}
            fontWeight={500}
            icon={<FileText size={13} />}
            color={'#2A2E34'}
          />
        </div>
      </div>
      <div className='reminder-container pt-3'>
        <RemindersAddWithList isHeading={false} isListView userId={currentUser} />
      </div>
    </div>
  );
}

export default ReminderList;
