import { useCallback, useEffect, useState } from 'react';

const useSpeechSynthesis = (text = 'Hai') => {
  const [voices, setVoices] = useState([]);
  const [isSpeaking, setIsSpeaking] = useState(false);
  const synth = window.speechSynthesis;

  // Function to load voices
  const loadVoices = useCallback(() => {
    const availableVoices = synth.getVoices();
    if (availableVoices.length !== 0) {
      setVoices(availableVoices);
    }
  }, [synth]);

  useEffect(() => {
    // Load voices on mount
    loadVoices();

    // Some browsers (e.g., Chrome) might not have voices immediately available
    if (synth.onvoiceschanged !== undefined) {
      synth.onvoiceschanged = loadVoices;
    }
  }, [loadVoices, synth]);

  const speak = useCallback(() => {
    if ('speechSynthesis' in window) {
      window.speechSynthesis.cancel(); // Stop any currently speaking utterance

      const sentences = text.match(/[^.!?]+[.!?]+[\])'"`’”]*|.+$/g);
      

      if (sentences) {
        let index = 0;

        const speakNextSentence = () => {
          if (index < sentences.length) {
            const sentence = sentences[index].trim();
            const utterance = new SpeechSynthesisUtterance(sentence);

            // Select a female voice
            const femaleVoices = voices.filter(
              (voice) =>
                voice.name.toLowerCase().includes('female') ||
                voice.name.toLowerCase().includes('woman') ||
                voice.name.toLowerCase().includes('salli') ||
                voice.gender === 'female',
            );

            if (femaleVoices.length > 0) {
              utterance.voice = femaleVoices[0];
            } else {
              utterance.voice = voices[0]; // Fallback to default voice
            }

            // Adjust rate and pitch if needed
            utterance.rate = 1;
            utterance.pitch = 1;

            utterance.onend = () => {
              index++;
              speakNextSentence();
            };

            window.speechSynthesis.speak(utterance);
          } else {
            // All sentences have been spoken
            setIsSpeaking(false);
          }
        };

        // Start speaking and set isSpeaking to true
        setIsSpeaking(true);
        speakNextSentence();
      }
    } else {
      alert('Sorry, your browser does not support text-to-speech.');
    }
  }, [text, voices]);

  const stop = useCallback(() => {
    window.speechSynthesis.cancel();
    setIsSpeaking(false);
  }, []);

  return { isSpeaking, speak, stop };
};

export default useSpeechSynthesis;
