import { Pause, Volume2 } from 'lucide-react';
import React from 'react';

export function SpeakerView(props) {
  const { text } = props;
  const [voices, setVoices] = React.useState([]);
  const [isSpeaking, setIsSpeaking] = React.useState(false);

  React.useEffect(() => {
    const synth = window.speechSynthesis;

    // Function to load voices
    const loadVoices = () => {
      const availableVoices = synth.getVoices();
      if (availableVoices.length !== 0) {
        setVoices(availableVoices);
      }
    };

    // Load voices
    loadVoices();

    // Some browsers (e.g., Chrome) might not have voices immediately available.
    if (synth.onvoiceschanged !== undefined) {
      synth.onvoiceschanged = loadVoices;
    }
  }, []);

  const speak = () => {
    if ('speechSynthesis' in window) {
      window.speechSynthesis.cancel();

      const sentences = text.match(/[^.!?]+[.!?]+[\])'"`’”]*|.+$/g);

      if (sentences) {
        let index = 0;

        const speakNextSentence = () => {
          if (index < sentences.length) {
            const sentence = sentences[index].trim();
            const utterance = new SpeechSynthesisUtterance(sentence);

            // Select a female voice
            const femaleVoices = voices.filter(
              (voice) =>
                voice.name.toLowerCase().includes('female') ||
                voice.name.toLowerCase().includes('woman') ||
                voice.name.toLowerCase().includes('salli') ||
                voice.gender === 'female',
            );

            if (femaleVoices.length > 0) {
              utterance.voice = femaleVoices[0];
            } else {
              utterance.voice = voices[0]; // Fallback to default voice
            }

            // Adjust rate and pitch if needed
            utterance.rate = 1;
            utterance.pitch = 1;

            utterance.onend = () => {
              index++;
              speakNextSentence();
            };

            window.speechSynthesis.speak(utterance);
          } else {
            // All sentences have been spoken
            setIsSpeaking(false);
          }
        };

        // Start speaking and set isSpeaking to true
        setIsSpeaking(true);
        speakNextSentence();
      }
    } else {
      alert('Sorry, your browser does not support text-to-speech.');
    }
  };

  const stop = () => {
    window.speechSynthesis.cancel();
    setIsSpeaking(false);
  };

  return (
    <div>
      <button
        onClick={isSpeaking ? stop : speak}
        style={{ background: 'none', border: 'none', cursor: 'pointer' }}
        aria-label={isSpeaking ? 'Stop' : 'Play'}
      >
        {isSpeaking ? <Pause size={15} /> : <Volume2 size={15} />}
      </button>
    </div>
  );
}
