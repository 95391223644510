/* eslint-disable no-unused-vars */
import { File } from 'lucide-react';
import React, { useRef, useState } from 'react';
import { Table, Tbody, Th, Thead, Tr } from 'react-super-responsive-table';
import { Button, Modal, TableRow } from '../_components';
import { getHoursAndMinute } from '../Task/taskValidation';
import { FileSystemImage } from '../Utils';
import { LoadingIcon } from '../Utils/SvgIcons';
import { AddTimeLog } from './AddTimeLog';
import { TimeSheetTableBody } from './TimeSheetTableBody';

const LazyXLSX = React.lazy(() => import('xlsx'));

export const TimeSheetSingle = ({ payload, handleClose, handleSuccess }) => {
  const HEADS = ['#', 'Entries', 'From Date', 'To Date', 'Total Hours', 'Billing Hours'];
  const [error, setError] = useState(false);
  const [timesheetData, setTimeSheetData] = useState(payload?.timesheet_details ?? []);
  const [isLoading, setIsLoading] = useState(false);
  const [showAddNewModal, setShowAddNewModal] = useState(false);
  const [editName, setEditName] = useState(false);
  const [timeSheetName, setTimeSheetName] = useState(payload.timesheet_name ?? '');
  const [isExporting, setIsExporting] = useState(false);
  const tableContainerRef = useRef(null);

  const handleAddNewTimeLog = (newData) => {
    console.log({ handleAddNewTimeLog: newData });

    if (checkTaskExist(newData.task_id) !== -1) {
      const index = checkTaskExist(newData.task_id);
      console.log({ handleAddNewTimeLog333: newData });

      timesheetData[index].time_logs.push(newData);
      timesheetData[index].total_hours = timesheetData[index].total_hours + newData.total_hours;
      timesheetData[index].billing_hours =
        timesheetData[index].billing_hours + newData.billing_hours;
    } else {
      const newObj = {
        task_id: newData.task_id,
        task_name: newData.task_name,
        from_date: newData.start_time,
        to_date: newData.end_time,
        total_hours: newData.total_hours,
        billing_hours: newData.billing_hours,
        time_logs: [newData],
      };
      timesheetData.push(newObj);
    }

    setTimeSheetData(timesheetData);
    setShowAddNewModal(false);
  };

  const checkTaskExist = (task_id) => {
    return timesheetData?.findIndex((element) => element.task_id == task_id) ?? false;
  };

  // Function to export data to Excel
  const exportToExcel = async () => {
    setIsExporting(true);

    // Dynamically load the XLSX library
    const XLSX = await import('xlsx');

    const flattenedData = timesheetData.flatMap((detail, index) =>
      detail.time_logs.map((log) => ({
        '#': index + 1,
        'Task Name': detail.task_name,
        'From Date': new Date(detail.from_date).toLocaleString(),
        'To Date': new Date(detail.to_date).toLocaleString(),
        'Log Start Time': new Date(log.start_time).toLocaleString(),
        'Log End Time': new Date(log.end_time).toLocaleString(),
        'Total Hours': `${getHoursAndMinute(log.total_hours)?.hours} Hours:${
          getHoursAndMinute(log.total_hours)?.minutes
        } Minutes`,
        'Billing Hours': `${getHoursAndMinute(log.billing_hours)?.hours} Hours:${
          getHoursAndMinute(log.billing_hours)?.minutes
        } Minutes`,
      })),
    );

    const worksheet = XLSX.utils.json_to_sheet(flattenedData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Timesheet');

    XLSX.writeFile(workbook, `${timeSheetName || 'Timesheet'}.xlsx`);

    setIsExporting(false);
  };

  return (
    <div className='fade-entry  timesheet-data-container'>
      <div>
        {/* <ErrorComponent error={error?.message} /> */}
        <div className={timesheetData.length > 0 ? '' : 'd-none'}>
          <div className='timesheet-tablemain-conatiner'>
            <section className=''>
              <div className='timesheet-title-container'>
                <div className='col-md-10'>
                  <div className='timesheet-name'>{timeSheetName}</div>
                </div>
                <Button.Container
                  className='timesheet-addlog-btn d-flex align-items-center gap-1'
                  handleOk={exportToExcel}
                  disabled={isExporting} // Disable button while exporting
                >
                  <File size={16} />
                  {isExporting ? (
                    <Button.Title title='Exporting...' />
                  ) : (
                    <Button.Title title='Export to Excel' />
                  )}
                </Button.Container>
              </div>
              <div
                className='timesheet-table timesheet-table-conatiner no-padding'
                ref={tableContainerRef}
                style={{ maxHeight: '65vh' }}
              >
                <Table className='table table-hover tbl mb-0'>
                  <Thead>
                    <Tr className='table-head'>
                      {HEADS.map((heading, index) => {
                        return (
                          <Th
                            key={index}
                            scope='col'
                            // style={{ minWidth: heading.minWidth, maxWidth: heading.maxWidth }}
                          >
                            {heading}
                          </Th>
                        );
                      })}
                    </Tr>
                  </Thead>
                  <Tbody style={{ verticalAlign: 'middle' }}>
                    {isLoading && (
                      <TableRow noOfCols={8}>
                        <LoadingIcon size={60} />
                      </TableRow>
                    )}
                    {!isLoading && timesheetData.length == 0 && (
                      <TableRow noOfCols={8}>No data found</TableRow>
                    )}
                    {!isLoading && timesheetData.length > 0 && (
                      <TimeSheetTableBody timesheetData={timesheetData} isEditable={false} />
                    )}
                  </Tbody>
                </Table>
              </div>
            </section>
          </div>
        </div>
        <div className={timesheetData.length == 0 ? 'timesheet-nodata' : 'd-none'}>
          <img src={FileSystemImage.EmptyDocument} className='timesheet-nodataiconbg' />
          <div className='timesheet-nodatatxt'>No timesheet found</div>
        </div>
      </div>

      {showAddNewModal && (
        <Modal.Container>
          <Modal.View className={'team-list timesheet-add-log-modal'}>
            <AddTimeLog
              client_id={payload?.clientId}
              handleAdd={handleAddNewTimeLog}
              handleClose={() => setShowAddNewModal(false)}
              data={timesheetData}
            />
          </Modal.View>
        </Modal.Container>
      )}
      <div className={`modal_footer d-flex justify-content-between align-items-center`}>
        <button className='timesheet-addlog-btn' onClick={() => handleClose()}>
          Close
        </button>
        {/* <button
          className='timesheet-addlog-btn bluebtn'
          onClick={() => {
            handleSuccess({ ...payload, timeSheetEntries: timesheetData, name: timeSheetName });
          }}
        >
          Next
        </button> */}
      </div>
    </div>
  );
};
