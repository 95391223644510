export const miscConstants = {
  REQUEST: 1,
  SUCCESS: 2,
  FAILURE: 3,
  CLEAR: 0,
  FILE_REQUEST: 11,
  FILE_SUCCESS: 12,
  FILE_FAILURE: 13,
  FILE_CLEAR: 10,
  IS_MENU_OPEN: 'IS_MENU_OPEN',
  CURRENCY_SIGN: '£',
  CURRENCY: 'GBP',
};

export const PermissionData = {
  task_add: 'TASK-ADD',
  task_edit: 'TASK-EDIT',
  task_delete: 'TASK-DELETE',
  user_add: 'USER-ADD',
  user_edit: 'USER-EDIT',
  project_add: 'PROJECT-ADD',
  project_edit: 'PROJECT-EDIT',
  template_add: 'TEMPLATE-ADD',
  template_edit: 'TEMPLATE-EDIT',
  time_sheet: 'TIME-SHEET',
  project_report: 'PROJECT-REPORT',
  work_history: 'WORK-HISTORY',
  work_log: 'WORK-LOG',
  user_delete: 'USER-DELETE',
  activity_log: 'ACTIVITY-LOG',
};

export const TEMPLATE_PERMISSION_MESSAGE = {
  NO_PERMISSIONS_FOR_ADD_TEMPLATE: 'You have no permission to Add Template',
  NO_PERMISSIONS_FOR_EDIT_TEMPLATE: 'You have no permission to Edit Template',
};
