import React from 'react';
import { AIIcon } from '../Utils/SvgIcons';

function AiSuggestions({ handleSend }) {
  return (
    <section className='suggestions'>
      <button className='suggestion-btn'>
        <AIIcon />
        <span className='suggestion-text' onClick={() => handleSend('What are my pending task?')}>
          What are my pending task?
        </span>
      </button>
      <button className='suggestion-btn'>
        <AIIcon />
        <span
          className='suggestion-text'
          onClick={() => handleSend('What are my pending task today?')}
        >
          What are my pending task today?
        </span>
      </button>
      <button className='suggestion-btn'>
        <AIIcon />
        <span className='suggestion-text'>{`What are company updates? ( coming soon)`}</span>
      </button>
    </section>
  );
}

export default AiSuggestions;
