export const TIME_HISTORY_SELECTION_BOX = {
  control: (provided, state) => ({
    ...provided,
    background: '#fff',
    minHeight: '33px',
    borderColor: ' rgba(241, 241, 241, 1)',
    fontSize: '13px',
    boxShadow: state.isFocused ? 'none' : provided.boxShadow,
    minWidth: 'max-content',
    height: 33,
    cursor: 'pointer',
    '&:hover': {
      borderColor: 'rgba(135, 144, 158, 1)',
    },
  }),
  menu: (provided) => ({
    ...provided,
    minWidth: '250px',
    overflowWrap: 'break-word',
    wordBreak: 'break-word',
    whiteSpace: 'normal',
    overflow: 'auto',
  }),
  indicatorSeparator: (provided) => ({
    ...provided,
    display: 'none',
  }),
  valueContainer: (style) => ({
    ...style,
    scrollbarWidth: 'none',
    overflow: 'auto',
    flexWrap: 'nowrap',
  }),
  multiValue: (style) => ({
    ...style,
    minWidth: 'none',
  }),
  menuList: (style) => ({
    ...style,
    fontSize: '13px',
  }),
};

export const NEW_TIME_HISTORY_SELECTION_BOX = {
  control: (provided, state) => ({
    ...provided,
    background: '#fff',
    minHeight: '25px',
    borderColor: ' rgba(241, 241, 241, 1)',
    fontSize: '13px',
    boxShadow: state.isFocused ? 'none' : provided.boxShadow,
    minWidth: 'max-content',
    height: 28,
    cursor: 'pointer',
    padding: '0 5px 0 0',
    '&:hover': {
      borderColor: 'rgba(135, 144, 158, 1)',
    },
  }),
  menu: (provided) => ({
    ...provided,
    minWidth: '250px',
    overflowWrap: 'break-word',
    wordBreak: 'break-word',
    whiteSpace: 'normal',
    overflow: 'auto',
  }),
  indicatorSeparator: (provided) => ({
    ...provided,
    display: 'none',
  }),
  valueContainer: (style) => ({
    ...style,
    scrollbarWidth: 'none',
    overflow: 'auto',
    flexWrap: 'nowrap',
    fontWeight: 600,
  }),
  multiValue: (style) => ({
    ...style,
    minWidth: 'none',
  }),
  menuList: (style) => ({
    ...style,
    fontSize: '13px',
  }),
  input: (style) => ({
    ...style,
    padding: '0px',
    margin: 0,
  }),
};

export const GRAPHICAL_REPORT_TYPE = {
  BAR: 'bar',
  PIE: 'pie',
};
