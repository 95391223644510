import React from 'react';
import PositionedContextMenu from '../../_components/PositionedContextMenu';

function ChatContextMenu({ isVisible, selectedPosition, onClose, children }) {
  if (!isVisible) {
    return null;
  }

  return (
    <PositionedContextMenu
      isContextVisible={true}
      left={selectedPosition.x - 100}
      top={selectedPosition.y}
      onRequestClose={() => onClose()}
    >
      <div className='w-100 d-flex fz-14px flex-column justify-content-center align-items-center'>
        {children}
      </div>
    </PositionedContextMenu>
  );
}

export default ChatContextMenu;
