/* eslint-disable no-unused-vars */
import { keepPreviousData, useQuery } from '@tanstack/react-query';
import moment from 'moment';
import React, { useContext, useEffect, useState } from 'react';
import ReactSelect from 'react-select';
import { Button } from '../_components';
import { ErrorComponent } from '../_components/ErrorComponent';
import UserDropDown from '../_components/UserDropDown';
import { CommonDropDownStyle, MENU } from '../_constants';
import { POSITION } from '../_constants/contextMenu.constants';
import useDebounce from '../_helpers/useDebounce';
import { userService } from '../_services';
import { getStartDateMilliSecond } from '../Task/taskValidation';
import { GlobalContext, projectFilter } from '../Utils';

export const ProjectFilter = ({
  position = POSITION.RIGHT,
  handleClose = () => {},
  setSearchParams,
  searchParams,
}) => {
  const { getMenuLabelName } = useContext(GlobalContext);
  const LABEL_OF_PROJECT = getMenuLabelName(MENU.project.default_name);

  const positionClassName = position === POSITION.RIGHT ? 'right-30px' : 'left-30px';
  const [userSearch, setUserSearch] = useState(''); // state for  user list search
  const [isLoading, setIsLoading] = useState(false);
  const [filterData, setFilterData] = useState(projectFilter);
  let today = moment().format('YYYY-MM-DD');

  const debounceUser = useDebounce(userSearch, 500);
  const statusList = [
    { label: 'Active', value: 'active' },
    { label: 'In-active', value: 'inactive' },
  ];
  // get all user list
  const {
    data: userData,
    error: userError,
    isLoading: userLoading,
  } = useQuery({
    queryKey: ['userList', debounceUser],
    queryFn: () =>
      userService.userList({
        searchKey: debounceUser,
        select: ['id', 'first_name', 'last_name', 'image_url', 'gender', 'employee_code'],
      }),
    placeholderData: keepPreviousData,
  });

  //   code to get filtered values from url
  // useEffect(() => {
  //   const newFilterData = structuredClone(filterData);

  //   searchParams.forEach((value, key) => {
  //     if (key !== 'status') {
  //       if (key === 'start_date' || key === 'end_date') {
  //         const date = moment(parseInt(value));
  //         newFilterData[key] = date.format('YYYY-MM-DD');
  //       }
  //       else {
  //         newFilterData[key] = value;
  //       }
  //     } else {
  //       newFilterData[key] = value === 'active' ? 'active' : 'inactive';
  //     }
  //   });

  //   setFilterData(newFilterData);
  // }, []);

  useEffect(() => {
    const fetchFilterData = async () => {
      const newFilterData = structuredClone(filterData);
      const promises = [];

      searchParams.forEach((value, key) => {
        if (key !== 'status') {
          if (key === 'start_date' || key === 'end_date') {
            const date = moment(parseInt(value));
            newFilterData[key] = date.format('YYYY-MM-DD');
          } else if (key === 'project_owner' && value) {
            promises.push(
              userService
                .userGet({ id: value, select: ['first_name', 'last_name', 'id'] })
                .then((user) => {
                  newFilterData['project_owner'] = user?.data;
                }),
            );
          } else {
            newFilterData[key] = value;
          }
        } else {
          newFilterData[key] = value === 'active' ? 'active' : 'inactive';
        }
      });

      await Promise.all(promises);
      setFilterData(newFilterData);
    };

    fetchFilterData();
  }, [searchParams]);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFilterData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    setIsLoading(true);

    searchParams.delete('pageNo');
    // Create a copy of the current search parameters
    const newParams = { ...searchParams };

    let payload = { ...filterData, project_owner: filterData?.project_owner?.id };

    if (payload?.start_date) {
      payload.start_date = getStartDateMilliSecond(payload.start_date);
    }
    if (payload?.end_date) {
      payload.end_date = getStartDateMilliSecond(payload.end_date);
    }

    // Update the newParams with filterData
    Object.keys(payload).forEach((key) => {
      if (payload[key] && payload[key] !== 0) {
        newParams[key] = payload[key];
      } else {
        delete newParams[key];
      }
    });

    newParams.pageNo = 1;

    // Update the URL with the new parameters
    setSearchParams(newParams);

    setIsLoading(false);
    handleClose(event);
  };

  const handleClear = (event) => {
    // Create a copy of the current search parameters
    const newParams = { ...searchParams };

    // Remove filter-related parameters from the newParams
    Object.keys(projectFilter).forEach((key) => {
      delete newParams[key];
    });

    // Update the URL with the new parameters
    setSearchParams(newParams);

    // Clear the filter data
    setFilterData(projectFilter);
    handleClose(event);
  };

  // drop down style
  const DropDownStyle = {
    container: (styles) => {
      return {
        ...styles,
        fontSize: '13px',
        fontWeight: '400',
      };
    },

    control: (styles, { isFocused }) => {
      return {
        ...styles,
        minHeight: '25px',
        height: '34px',

        borderColor: isFocused ? '#3454D1' : '#87909E',
        boxShadow: isFocused ? '0 0 0 1px #3454D1' : styles.boxShadow,
        '&:hover': {
          borderColor: isFocused ? '#3454D1' : styles['&:hover']?.borderColor || styles.borderColor,
        },
      };
    },
    indicatorsContainer: (styles) => {
      return {
        ...styles,
        minHeight: '25px',
        height: '34px',
        color: '#87909E',
      };
    },
    menu: (styles) => {
      return {
        ...styles,
        minWidth: '170px',
      };
    },
    singleValue: (styles) => {
      return {
        ...styles,
        fontWeight: '500',
      };
    },
    indicatorSeparator: () => ({
      display: 'none',
    }),
    dropdownIndicator: (styles) => {
      return {
        ...styles,
        color: '#2A2E34',
        '&:hover': {
          color: '#2A2E34',
        },
      };
    },
  };

  return (
    <div className={`menu_popup filter-container`} style={{ padding: 20, right: 0 }}>
      <ErrorComponent error={userError?.message} />

      <form onSubmit={handleSubmit}>
        <div className='filter-head mb-2'>
          <h6 className='menu-item-head'>Filter</h6>
          <div
            className='menu-item-clear'
            style={{ fontSize: 12, cursor: 'pointer' }}
            onClick={handleClear}
          >
            Clear
          </div>
        </div>
        <div className='filter-body'>
          <div className='row mb-1'>
            <div className='filter-field-name'>{LABEL_OF_PROJECT} Owner</div>

            <ReactSelect
              placeholder='Choose'
              name='project_owner'
              styles={CommonDropDownStyle}
              formatOptionLabel={(data, metaData) => (
                <UserDropDown data={data} metaData={metaData} />
              )}
              getOptionValue={(option) => option?.id}
              onInputChange={(inputString) => {
                setUserSearch(inputString);
              }}
              filterOption={null}
              isLoading={userLoading}
              inputValue={userSearch}
              options={userData?.data?.rows}
              onChange={(selectedOption) => {
                handleChange({
                  target: {
                    name: 'project_owner',
                    value: selectedOption,
                  },
                });
              }}
              isClearable
              value={filterData?.project_owner}
            />
          </div>

          <div className='row mb-1'>
            <div className='filter-field-name'>Status</div>

            <ReactSelect
              placeholder='Choose'
              name='status'
              getOptionValue={(option) => option?.value}
              options={statusList}
              onChange={(selectedOption) => {
                handleChange({
                  target: {
                    name: 'status',
                    value: selectedOption?.value,
                  },
                });
              }}
              styles={CommonDropDownStyle}
              isClearable
              value={statusList.find((option) => option.value === filterData?.status) || null}
            />
          </div>

          <div className='row'>
            <div className='col-md-6'>
              <label className='filter-field-name'>Start Date</label>
              <input
                style={{ fontSize: '13px' }}
                type='date'
                className='input-box'
                placeholder={today}
                value={moment(filterData?.start_date).format('YYYY-MM-DD')}
                name='start_date'
                onChange={(e) => handleChange(e)}
              />
            </div>
            <div className='col-md-6'>
              <label className='filter-field-name'>End Date</label>
              <input
                style={{ fontSize: '13px' }}
                type='date'
                className='input-box'
                placeholder={today}
                min={moment(filterData?.start_date).format('YYYY-MM-DD')}
                value={moment(filterData?.end_date).format('YYYY-MM-DD')}
                name='end_date'
                onChange={(e) => handleChange(e)}
              />
            </div>
          </div>
        </div>
        <div className='row mt-3'>
          <div className='d-flex justify-content-end gap-2'>
            <Button.Container className={'cancel-btn'} type='button' handleOk={handleClose}>
              <Button.Title title={'Cancel'} />
            </Button.Container>

            <Button.Container type='submit' isDisabled={isLoading}>
              <Button.Title title={'Apply'} />
              <Button.Loading />
            </Button.Container>
          </div>
        </div>
      </form>
    </div>
  );
};
