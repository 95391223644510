import { useQuery } from '@tanstack/react-query';
import moment from 'moment';
import React, { useContext, useState } from 'react';
import { GlobalContext, fullNameUser } from '../../Utils';
import StackedAvatars from '../../_components/Avatar/StackedAvatars';
import { COMMON_USER_SELECT } from '../../_constants';
import { errorToString } from '../../_helpers/utilFunctions';
import { taskService, userService } from '../../_services';

function RecurringTest() {
  const { makeAlert } = useContext(GlobalContext);
  const [selectedDate, setSelectedDate] = useState(moment().format('YYYY-MM-DD'));
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const handleChange = (event) => {
    const { value } = event.target;
    setSelectedDate(value);
  };

  const { data: userOptions } = useQuery({
    queryKey: ['test-user-list'],
    queryFn: async () =>
      userService.getPermittedUser({
        select: COMMON_USER_SELECT,
      }),
    select: (resp) => resp.data.rows,
  });

  const handleSubmit = async () => {
    const selectedDateInMillis = moment(selectedDate).endOf('D').subtract(3, 'minutes').valueOf();
    try {
      setIsSubmitting(true);
      const response = await taskService.runTestRecurrence({
        date: selectedDateInMillis,
      });
      if (response.errorCode === 0) {
        // Success
        makeAlert('Success');
      }
    } catch (err) {
      // Handle error
      makeAlert(errorToString(err), 'error');
    } finally {
      setIsSubmitting(false);
    }
  };
  return (
    <>
      <div
        className='m-auto w-100 h-100'
        style={{
          height: '100vh !important',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          paddingTop: '62px',
          gap: '36px',
        }}
      >
        <div>
          <div>Select the date:</div>
          <input
            value={selectedDate}
            type='date'
            className='recur-dropdown'
            min={moment().format('YYYY-MM-DD')}
            onChange={handleChange}
          />
        </div>
        <div
          role='button'
          onClick={() => !isSubmitting && handleSubmit()}
          aria-disabled={isSubmitting}
          className='p-1 recur-fu-modal grey-btn'
        >
          {isSubmitting ? 'Running' : 'Run Cron'}
        </div>
        <StackedAvatars
          imageList={selectedUsers}
          getNameByKey={(user) => fullNameUser(user.first_name, user.last_name)}
          getSourceByKey={(user) => user.image_url}
          getGenderByKey={(user) => user.gender}
          size={50}
          options={userOptions}
          isAddable
          // addableLimit={7}
          showInitials={false}
          getValueByKey={(user) => user.id}
          onAdd={(selectedUsers) => {
            setSelectedUsers((prev) => [...prev, ...selectedUsers]);
          }}
        />
      </div>
    </>
  );
}

export default RecurringTest;
