import React, { useEffect, useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { CHAT_TABS } from '../../_constants/chat.constants';
import ChatUpdateHeader from './ChatUpdateHeader';

function ChatUpdateTabs({
  isTabActive,
  refetch,
  counts,
  isChatHeadVisible = true,
  filterTabs = () => true,
  initialTabIndex = null,
  onTabClick = () => {},
}) {
  const [searchParams] = useSearchParams();

  const currentTab = searchParams.get('tab');
  const tabRefs = useRef([]);
  const containerRef = useRef(null);
  const [stripStyle, setStripStyle] = useState({
    left: 0,
  });

  const totalCount = counts ? counts.total_count : 0;
  const groupCount = counts ? counts.group_count : 0;

  /***
   * For updating the chat tab strip animation
   */
  const updateStripPosition = (index) => {
    if (!containerRef.current || !tabRefs.current[index]) {
      return null;
    }
    const containerRect = containerRef.current.getBoundingClientRect();
    const tabRect = tabRefs.current[index].getBoundingClientRect();
    const relativeLeft = tabRect.left - containerRect.left;
    setStripStyle({
      left: relativeLeft,
      width: tabRect.width,
    });
  };

  useEffect(() => {
    if (!currentTab && initialTabIndex === null) {
      return;
    }
    const tabIndex = CHAT_TABS.find((tab) => tab.path === currentTab)?.index ?? 0;
    console.log('initIndex', initialTabIndex);
    updateStripPosition(initialTabIndex ?? tabIndex);
  }, [containerRef.current, currentTab, CHAT_TABS, groupCount, totalCount, initialTabIndex]);

  return (
    <div className='update-padding header-border-bottom user-select-none' style={{ height: 60 }}>
      <section
        ref={containerRef}
        className=' chat-tabs d-flex justify-content-between align-items-center position-relative'
        style={{ padding: '10px 0px', overflowY: 'visible', scrollbarWidth: 'none' }}
      >
        <div className='org-nav-contain '>
          {CHAT_TABS.filter(filterTabs).map((tab, idx) => {
            const countKey = tab.countKey;
            const count = counts ? counts[countKey] ?? 0 : 0;
            return (
              <UpdateTabItem
                onTabClick={onTabClick}
                updateStrip={updateStripPosition}
                tab={tab}
                count={count}
                tabRefs={tabRefs}
                index={idx}
                isTabActive={isTabActive}
                key={tab.id}
              />
            );
          })}
        </div>
        <div className='update-tab-strip accent-bg-color' style={stripStyle} />
        {isChatHeadVisible && <ChatUpdateHeader onGroupCreation={refetch} />}
      </section>
    </div>
  );
}

const UpdateTabItem = ({
  isTabActive = () => {},
  index,
  tab,
  onTabClick = () => {},
  tabRefs,
  updateStrip,
  count = 0,
}) => {
  return (
    <div
      className={`d-flex align-items-center ${
        isTabActive(tab.path) ? 'chat-tab_active' : 'chat-tab_inactive'
      }`}
      ref={(element) => {
        tabRefs.current[index] = element;
      }}
      style={{ gap: 6 }}
      onClick={() => {
        onTabClick(tab);
        updateStrip(index);
      }}
    >
      <img src={tab.image} width={14} height={14} className='chat-tab-icon' />
      <div className='chat-tab-name'> {tab.name}</div>
      {Boolean(count) && <div className='chat-tab-count'>{count > 99 ? '99+' : count}</div>}
    </div>
  );
};

export default ChatUpdateTabs;
