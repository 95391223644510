/* eslint-disable no-unused-vars */
import { useInfiniteQuery, useQuery } from '@tanstack/react-query';
import { Bell, Home } from 'lucide-react';
import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ToggleButton } from '../_components';
import IconLabelSet from '../_components/IconLabelSet';
import { NOTIFICATION_TYPE } from '../_constants';
import { notificationService } from '../_services/notification.service';
import useObserver from '../Hook/useObserver';
import { useUser } from '../Hook/useUser';
import { jsonToUrlEncoded, MyTeamIcon } from '../Utils';
import ChatNotification from './ChatNotification';
import { NOTIFICATION_FROM } from './notificationConstant';
import NotificationHead from './NotificationHead';
import { ReminderNotification } from './ReminderNotification';
import TaskNotification from './TaskNotification';
import UpdateNotification from './UpdateNotification';
const ModifiedNotificationFullView = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const user = useUser();

  const [isUnread, setIsUnread] = useState(false);
  const [isReaded, setIsReaded] = useState(null);

  const is_notification =
    id === NOTIFICATION_TYPE.TASK ||
    id === NOTIFICATION_TYPE.CHAT ||
    id === NOTIFICATION_TYPE.REMINDER;

  const [urlQuery, setULRQuery] = useState();

  //-- for all notification
  const [notificationQuery, setNotificationQuery] = useState({
    select: [
      'id',
      'note',
      'task_id',
      'reminder_id',
      'request_id',
      'user_id',
      'is_read',
      'mode',
      'message_id',
      'notification_type',
      'updated_time',
      'chat_group_id',
      'project_id',
      'team_id',
    ],
    notification_from:
      id === NOTIFICATION_TYPE.REMINDER
        ? NOTIFICATION_FROM.REMINDER
        : id === NOTIFICATION_TYPE.CHAT
        ? NOTIFICATION_FROM.CHAT
        : NOTIFICATION_FROM.ALL_NOTIFICATION,
    isUnReadFlag: false,
    // unread: isUnread, // added a unread prop for unread and read option
  });

  const [updateQuery, setUpdateQuery] = useState({
    select: [
      'id',
      'task_id',
      'last_message',
      'last_message_time',
      'message',
      'message_count_info',
      'update_type',
      'updated_time',
    ],
    update_type: 'task',
    unread: false, // added a unread prop for unread and read option
  });

  //-- only chat based notification non task chat
  const query = is_notification ? notificationQuery : updateQuery;

  const getPayLoad = (query) => {
    const encodedQuery = Object.keys(query).reduce((acc, key) => {
      acc[key] = typeof query[key] === 'object' ? JSON.stringify(query[key]) : query[key];
      return acc;
    }, {});
    setULRQuery(jsonToUrlEncoded(encodedQuery));
    return query;
  };

  const service = is_notification
    ? notificationService.notificationList
    : notificationService.updateList;

  const {
    data: notificationData,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  } = useInfiniteQuery({
    queryKey: ['notification-list', id, isUnread, isReaded, notificationQuery.isUnReadFlag],
    queryFn: ({ pageParam = 1 }) =>
      service(
        getPayLoad({
          ...query,
          ...(is_notification && {
            notification_from:
              id === NOTIFICATION_TYPE.REMINDER
                ? NOTIFICATION_FROM.REMINDER
                : id === NOTIFICATION_TYPE.CHAT
                ? NOTIFICATION_FROM.CHAT
                : NOTIFICATION_FROM.ALL_NOTIFICATION,
          }),
          pageVo: {
            pageNo: pageParam,
            noOfItems: 10,
            sort: id === NOTIFICATION_TYPE.TASK_CHAT ? 'last_message_time' : 'id', // different sort order for notification and updates list
            sort_order: 'DESC',
          },
        }),
      ),
    select: (data) => data.pages,
    enabled: Boolean(id),
    getNextPageParam: (res) => (res.data.page < res.data.pages ? res.data.page + 1 : undefined),
  });
  const { observerRef } = useObserver({
    onIntersection: () => {
      if (hasNextPage && !isFetchingNextPage) {
        fetchNextPage();
      }
    },
    dependency: [hasNextPage, isFetchingNextPage],
  });

  const { data: notificationCount, refetch } = useQuery({
    queryKey: ['notificationCount'],
    refetchInterval: 600000,
    queryFn: () => notificationService.getNotificationCount(),
    enabled: user?.id ? true : false,
  });

  // for handling read or unread
  const onHandleUnread = () => {
    setUpdateQuery({ ...updateQuery, unread: !isUnread });
    setIsUnread(!isUnread);
  };

  return (
    <div>
      <div className=' url-container cursor-pointer'>
        <div className='breadcrumbs d-flex' style={{ zIndex: 9 }}>
          <IconLabelSet
            label={'Home'}
            fontWeight={400}
            icon={<Home size={16} />}
            onClick={() => navigate(`/`)}
          />
          <img className='slash-img-icon-size ms-1 mt-1' src={MyTeamIcon.Slash} alt='' />
          <IconLabelSet
            label={'Notification'}
            color={'#2A2E34'}
            fontWeight={500}
            icon={<Bell size={16} />}
          />
        </div>
      </div>
      <div>
        <div className=' '>
          <div className='settings_head '>
            <NotificationHead id={id} counts={notificationCount?.data} />
          </div>
          {(id === NOTIFICATION_TYPE.TASK || id === NOTIFICATION_TYPE.CHAT) && (
            <div className=' col-md-7 notification-unread-button-container   d-flex align-items-center justify-content-end gap-1 mb-2'>
              <div className='un-read-text'>Only show unread</div>
              <ToggleButton
                size={28}
                id={'is_unlisted'}
                isChecked={notificationQuery?.isUnReadFlag}
                handleToggle={() =>
                  setNotificationQuery((prev) => ({
                    ...prev,
                    isUnReadFlag: !prev.isUnReadFlag,
                  }))
                }
              />
            </div>
          )}

          {id === NOTIFICATION_TYPE.TASK_CHAT && (
            <div className=' col-md-7 notification-unread-button-container d-flex align-items-center justify-content-end gap-1 mb-2'>
              <div className='un-read-text'>Only show unread</div>
              <ToggleButton
                key={1}
                id={1}
                isChecked={isUnread ? 1 : 0}
                handleToggle={onHandleUnread}
                size={28}
              />
            </div>
          )}

          {id === NOTIFICATION_TYPE.REMINDER && (
            <div className=' col-md-7 notification-unread-button-container d-flex align-items-center justify-content-end gap-1 mb-2'>
              <div className='un-read-text'>Only show unread</div>

              <ToggleButton
                size={28}
                id={'is_unlisted'}
                isChecked={notificationQuery?.isUnReadFlag}
                handleToggle={() =>
                  setNotificationQuery((prev) => ({
                    ...prev,
                    isUnReadFlag: !prev.isUnReadFlag,
                  }))
                }
              />
            </div>
          )}
        </div>
        <div style={{ overflow: 'auto', maxHeight: 'calc(100vh - 182px)' }}>
          <div className='col-md-7  notification-card'>
            {notificationData?.map((page, index) => {
              return (
                <div key={index}>
                  {id == NOTIFICATION_TYPE.REMINDER ? (
                    <>
                      <ReminderNotification data={page?.data?.rows} />
                    </>
                  ) : id == NOTIFICATION_TYPE.TASK ? (
                    <>
                      <TaskNotification
                        isFUllView={true}
                        data={page?.data?.rows}
                        count={page?.data?.count}
                      />
                    </>
                  ) : id == NOTIFICATION_TYPE.TASK_CHAT ? (
                    <>
                      <UpdateNotification
                        queryPayload={urlQuery}
                        data={page?.data}
                        count={page?.data?.count}
                        setIsNotificationVisible={() => {}}
                        isUnread={isUnread}
                      />
                    </>
                  ) : (
                    <ChatNotification data={page?.data?.rows} />
                  )}
                </div>
              );
            })}
            <div ref={observerRef} style={{ height: 5 }}></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModifiedNotificationFullView;
