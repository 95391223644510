import { useQuery } from '@tanstack/react-query';
import { X } from 'lucide-react';
import React from 'react';
import { miscService } from '../_services';

function InputLinkPreview({
  url,
  onLinkLoad = () => {},
  onLinkRemove = () => {},
  linkDetails = null,
}) {
  const getMetaData = async () => {
    const response = await miscService.getMetaDataFromUrl(url);
    response?.title && onLinkLoad({ ...response, url });
    return response;
  };

  const { data: metaData, isLoading } = useQuery({
    queryKey: ['url-meta-data', url],
    queryFn: () => getMetaData(),
    enabled: Boolean(url),
  });

  if (isLoading) {
    return (
      <div
        className='w-100 blink input-link-prev'
        style={{
          height: '50px',
          backgroundColor: '#eee',
        }}
      />
    );
  }

  if (linkDetails && (!url || !metaData)) {
    onLinkRemove();
  }

  if (!linkDetails) {
    return null;
  }

  if (!metaData) {
    return `Not found`;
  }

  const imageUrl = metaData.image
    ? metaData.image.startsWith('/')
      ? url + metaData.image
      : metaData.image
    : null;
  return (
    <div className='link-prev gap-2 input-link-prev d-flex position-relative'>
      <div className='close-btn btn-wrapper pe-1' onClick={onLinkRemove}>
        <X size={15} />
      </div>
      <img src={imageUrl} />
      <div className='w-100 input-link-text-container d-flex justify-content-center flex-column'>
        <div className='link-title'>{metaData.title}</div>
        <div className='link-description'>{metaData.description}</div>
      </div>
    </div>
  );
}

export default InputLinkPreview;
