import { apiConstants } from '../_constants';
import { authHeader,  handleResponse } from '../_helpers';
import { jsonToQueryString } from '../_helpers/utilFunctions';

export const documentTypeService = {
  documentTypeAdd,
  documentTypeList,
  documentTypeGet,
  documentTypeDeactivate
};

async function documentTypeList(data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(data),
  };

  return fetch(apiConstants.apiUrl + '/document_type_list', requestOptions).then(handleResponse);
}

function documentTypeGet(data) {
  const params = jsonToQueryString(data);
  const apiUrl = `${apiConstants.apiUrl}/get_document_type?${params}`;
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };
  return fetch(apiUrl, requestOptions).then(handleResponse);
}

function documentTypeAdd(data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(data),
  };
  return fetch(apiConstants.apiUrl + '/document_type_add', requestOptions).then(handleResponse);
}

async function documentTypeDeactivate(data) {
  const apiUrl = `${apiConstants.apiUrl}/document_type_deactivate`;
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(data),
  };

  return fetch(apiUrl, requestOptions).then(handleResponse);
}
