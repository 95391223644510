import { z } from 'zod';
import { MY_TEAM_ERROR_MSG, MY_TEAM_REQUIRED_FIELD_MSG } from './MyTeamConstant';

// eslint-disable-next-line no-unused-vars
export const myTeamSchema = (includeGenderValidation, id, isCredentialRequired) =>
  z.object({
    // Name should be required with more than 2 character
    first_name: z
      .string({
        required_error: MY_TEAM_REQUIRED_FIELD_MSG.FIRST_NAME,
        invalid_type_error: MY_TEAM_ERROR_MSG.FIRST_NAME,
      })
      .min(1, { message: MY_TEAM_ERROR_MSG.FIRST_NAME }),
    last_name: z
      .string({
        required_error: MY_TEAM_REQUIRED_FIELD_MSG.LAST_NAME,
        invalid_type_error: MY_TEAM_ERROR_MSG.LAST_NAME,
      })
      .min(1, { message: MY_TEAM_ERROR_MSG.LAST_NAME }),

    ...(includeGenderValidation
      ? {
          gender: z.number({
            required_error: MY_TEAM_REQUIRED_FIELD_MSG.GENDER,
            invalid_type_error: MY_TEAM_ERROR_MSG.GENDER,
          }),
        }
      : {}),

    // employee_code: z.union([
    //   z
    //     .string()
    //     .nonempty({ message: MY_TEAM_ERROR_MSG.EMPLOYEE_CODE })
    //     .refine((val) => val !== '0', { message: MY_TEAM_ERROR_MSG.EMPLOYEE_CODE }),
    //   z.number().refine((val) => val > 0, { message: MY_TEAM_ERROR_MSG.EMPLOYEE_CODE }),
    // ]),
    // department_id: z.union([
    //   z.string().nonempty({ message: MY_TEAM_ERROR_MSG.DEPARTMENT }),
    //   z.number().refine((val) => val > 0, { message: MY_TEAM_ERROR_MSG.DEPARTMENT }),
    // ]),
    // designation_id: z.union([
    //   z.string().nonempty({ message: MY_TEAM_ERROR_MSG.DESIGNATION }),
    //   z.number().refine((val) => val > 0, { message: MY_TEAM_ERROR_MSG.DESIGNATION }),
    // ]),
    // email_id: z.union([
    //   z.string().nonempty({ message: MY_TEAM_ERROR_MSG.EMAIL_ID }),
    //   z.number().refine((val) => val > 0, { message: MY_TEAM_ERROR_MSG.EMAIL_ID }),
    // ]),
    // role_id: z.union([
    //   z.string().nonempty({ message: MY_TEAM_ERROR_MSG.ROLE }),
    //   z.number().refine((val) => val > 0, { message: MY_TEAM_ERROR_MSG.ROLE }),
    // ]),

    role_default_name: z.union([
      z.string().nonempty({ message: MY_TEAM_ERROR_MSG.ROLE }),
      z.string().refine((val) => val > 0, { message: MY_TEAM_ERROR_MSG.ROLE }),
    ]),

    // reported_users: z.array(z.any()).min(1, { message: MY_TEAM_ERROR_MSG.REPORTED_USERS }),
    username: isCredentialRequired
      ? z
          .string({
            required_error: MY_TEAM_REQUIRED_FIELD_MSG.USERNAME,
            invalid_type_error: MY_TEAM_ERROR_MSG.USERNAME,
          })
          .min(2, { message: MY_TEAM_ERROR_MSG.USERNAME })
          .refine((val) => /^(?![0-9]+$).*$/.test(val), {
            message: MY_TEAM_ERROR_MSG.USERNAME,
          })
      : z.string().nullable().optional(),

    password: isCredentialRequired
      ? z
          .string({
            required_error: MY_TEAM_REQUIRED_FIELD_MSG.PASSWORD,
            invalid_type_error: MY_TEAM_ERROR_MSG.PASSWORD,
          })
          .min(2, { message: MY_TEAM_ERROR_MSG.PASSWORD })
      : z.string().nullable().optional(),
  });
