import { useNavigate, useSearchParams } from 'react-router-dom';
/* eslint-disable no-unused-vars */
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import React, { useRef, useState } from 'react';
import { Table, Tbody, Th, Thead, Tr } from 'react-super-responsive-table';

import { Calendar, Home, Plus, User } from 'lucide-react';
import moment from 'moment';
import { Button, Modal, PaginationComponent, TableRow } from '../_components';
import IconLabelSet from '../_components/IconLabelSet';
import ToolTip from '../_components/Tooltip/ToolTip';
import { workflowService } from '../_services';
import useErrorAlert from '../Hook/useErrorAlert';
import { Images, MyTeamIcon } from '../Utils';
import {
  Cancel,
  LoadingIcon,
  ProgressIcon,
  ProjectIcon,
  StatusIcon,
  Tick,
} from '../Utils/SvgIcons';
import './workflow.css';
const TABLE_COLUMN = ['Sl.No', 'Name', 'No.of tasks', 'No.of templates', 'Status', 'Created At'];

export function WorkflowList() {
  const navigate = useNavigate();
  const [submitted, setSubmitted] = useState(false);

  const { makeAlert } = useErrorAlert();
  const PROJECT_TABLE_HEADINGS = [
    { name: <img src={Images.SerialNo} height={14} width={14} />, minWidth: 50 },

    {
      name: (
        <div className='d-flex align-items-center gap-1'>
          <ProgressIcon /> Name
        </div>
      ),
      minWidth: 50,
    },
    {
      name: (
        <div className='d-flex align-items-center gap-1'>
          <User size={20} /> No.of tasks
        </div>
      ),
      minWidth: 50,
    },
    {
      name: (
        <div className='d-flex align-items-center gap-1'>
          <StatusIcon /> No.of templates
        </div>
      ),
      minWidth: 50,
    },
    {
      name: (
        <div className='d-flex align-items-center gap-1'>
          <Calendar height={14} width={14} /> Status
        </div>
      ),
      minWidth: 50,
    },
    {
      name: (
        <div className='d-flex align-items-center gap-1'>
          {' '}
          <Calendar height={14} width={14} /> Created At
        </div>
      ),
      minWidth: 50,
    },
    {
      name: <div></div>,
    },
  ];
  const [scrollFlag, setScrollFlag] = useState({ isTopReached: true, isBottomReached: false });

  const queryClient = useQueryClient();

  const tableContainerRef = useRef(null);

  const [searchParams, setSearchParams] = useSearchParams();

  const [addModal, setAddModal] = useState(false);
  const [view, setView] = useState('add');
  // useState section
  const [query, setQuery] = useState({
    select: ['id', 'name', 'createdAt', 'updatedAt'],
    searchKey: '',
    pageVo: {
      pageNo: parseInt(searchParams.get('pageNo')) || 1,
      noOfItems: 10,
    },
    // status: 1,
  });

  // Form state
  const [workflow, setWorkflow] = useState({ name: '', description: '' });

  // organization list API call section
  const {
    data: workflowData,
    error,
    isLoading,
    isSuccess,
    refetch,
  } = useQuery({
    queryKey: ['workflow-list', query?.pageVo?.pageNo],
    queryFn: () => workflowService.getWorkflowList(query),
  });

  // menu settings update
  const addWorkflow = async (data) => {
    await workflowService.addWorkflow(data);
  };

  // --react query fot mutation function
  const { mutate: handleMutate } = useMutation({
    mutationFn: addWorkflow,
    onSuccess: () => {
      queryClient.invalidateQueries(['workflow-list']);
      setAddModal(false);
      setWorkflow({ name: '', description: '' });
    },
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setWorkflow({ ...workflow, [name]: value });
    setSubmitted(false);
  };

  const handleSubmit = () => {
    setSubmitted(true);
    if (workflow.name.trim()) {
      handleMutate(workflow); // Trigger mutation
    } else {
      makeAlert('Please fill in all fields');
    }
  };

  const tableData = workflowData?.data?.rows?.map((workflow, index) => {
    const slNo = (workflowData?.data?.page - 1) * workflowData?.data?.limit + index + 1;

    const createdAtInString = moment(workflow.createdAt).format('MMM DD, YYYY');
    return (
      <tr onClick={() => navigate(`/wf/${workflow.id}`)} key={index}>
        <td>{slNo}</td>
        <td>{workflow?.name}</td>
        <td>{workflow.total_tasks_used}</td>
        <td>{workflow.total_templates_used}</td>
        <td>Live</td>
        <td>{createdAtInString}</td>
      </tr>
    );
  });

  const listItemCount = workflowData?.data?.rows.length;

  return (
    <>
      <div className=' url-container '>
        <div className='d-flex align-items-center  breadcrumbs url-navigation'>
          <IconLabelSet
            label={'Home'}
            fontWeight={400}
            icon={<Home size={13} />}
            // onClick={() => navigate(`/`)}
          />
          <img className='slash-img-icon-size ms-1 mt-1' src={MyTeamIcon.Slash} alt='' />
          <IconLabelSet
            label={'Workflow'}
            fontWeight={500}
            color={`#2A2E34`}
            icon={<ProjectIcon height={12} width={12} color='#2A2E34' />}
            // onClick={() => navigate(`/${RT.WORK_FLOW_LIST}`)}
          />
        </div>
      </div>

      <div className='project-table-container' style={{ maxWidth: '100%' }}>
        <div className='d-flex gap-2 justify-content-end'>
          <ToolTip tooltipText={'Add workflow'} isModern>
            <div
              className='menu-item-outline'
              onClick={() => {
                setAddModal(true);
              }}
            >
              <Plus size={16} color='#87909E' />
            </div>
          </ToolTip>
        </div>

        <section className='mt-2'>
          <div
            className='project-table table-container no-padding'
            ref={tableContainerRef}
            style={{ maxHeight: '65vh' }}
          >
            <Table className='table table-hover tbl mb-0'>
              <Thead>
                <Tr className='table-head'>
                  {PROJECT_TABLE_HEADINGS.map((heading, index) => {
                    return (
                      <Th
                        key={index}
                        scope='col'
                        style={{ minWidth: heading.minWidth, maxWidth: heading.maxWidth }}
                      >
                        {heading.name}
                      </Th>
                    );
                  })}
                </Tr>
              </Thead>
              <Tbody style={{ verticalAlign: 'middle' }}>
                {isLoading && (
                  <TableRow noOfCols={8}>
                    <LoadingIcon size={60} />
                  </TableRow>
                )}
                {!listItemCount && isSuccess && <TableRow noOfCols={8}>No data found</TableRow>}
                {listItemCount > 0 && isSuccess && tableData}
              </Tbody>
            </Table>
          </div>
        </section>
        {listItemCount > 0 && (
          <section className='mt-2'>
            <PaginationComponent
              pageOnClick={(data) => {
                setQuery((prev) => ({
                  ...prev,
                  pageVo: {
                    ...prev.pageVo,
                    pageNo: data,
                  },
                }));
                searchParams.set('pageNo', data);
                setSearchParams(searchParams);
              }}
              demoData={workflowData?.data}
            />
          </section>
        )}
      </div>

      <div style={{ padding: '5px 25px', display: 'none' }}>
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <div
            style={{
              background: '#d2d2d2',
              padding: '3px 9px',
              borderRadius: 4,
              marginTop: 15,
              cursor: 'pointer',
            }}
            onClick={() => setAddModal(true)}
          >
            Create
          </div>
        </div>

        <section className='mt-2'>
          <div className='table_outer'>
            <table className='table table-hover tbl mb-0'>
              <thead>
                <tr>
                  {TABLE_COLUMN.map((heading, index) => (
                    <th key={index} scope='col'>
                      {heading}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {/* {isLoading && (
                <tr>
                  <td colSpan={6} style={{ textAlign: 'center' }}>
                    Loading...
                  </td>
                </tr>
              )}
              {isError && (
                <tr>
                  <td colSpan={6} style={{ textAlign: 'center' }}>
                    Error fetching data
                  </td>
                </tr>
              )} */}
                {/* {!isLoading && workflows?.length === 0 && (
                <tr>
                  <td colSpan={6} style={{ textAlign: 'center' }}>
                    No data found
                  </td>
                </tr>
              )} */}

                {/* {orgRows} */}
              </tbody>
            </table>
          </div>
        </section>
      </div>

      {addModal && (
        <Modal.Container handleClose={() => setAddModal(false)}>
          <Modal.View className='modal-view-trigger' containerClass='view-end'>
            <div className='trigger-modal-container'>
              {/* Header */}
              <div className='trigger-modal-header'>
                <h2 className='trigger-modal-title'>Create workflow</h2>
                <p className='trigger-modal-subtitle'>Pick a trigger to start workflow</p>
              </div>

              {/* Tab Headers */}
              <div className='tabs-container'>
                {/* <div className='tabs-header'>
                  <div
                    className={`tab-label ${view === 'add' ? 'active' : ''}`}
                    onClick={() => setView('add')}
                  >
                    From the Scratch
                  </div>
                  <div
                    className={`tab-label ${view === 'list' ? 'active' : ''}`}
                    onClick={() => setView('list')}
                  >
                    Templates
                  </div>
                </div> */}

                {/* Tab Content */}
                {view === 'add' && (
                  <section className='tab-content'>
                    <form className='workflow-form'>
                      <label className='fz-13px-rem  label_input'>
                        Name <span className='field-required'>*</span>
                      </label>
                      <input
                        type='text'
                        className='input-box'
                        id='name'
                        name='name'
                        value={workflow?.name}
                        placeholder='Enter workflow name'
                        onChange={handleInputChange}
                      />

                      <label className='form-label' htmlFor='description'>
                        Description
                      </label>
                      <textarea
                        id='description'
                        name='description'
                        className='input-box'
                        style={{ height: 150 }}
                        placeholder='Enter workflow description'
                        value={workflow?.description}
                        onChange={handleInputChange}
                      ></textarea>
                    </form>
                  </section>
                )}

                {view === 'list' && (
                  <section className='tab-content'>
                    <div>Coming soon</div>
                  </section>
                )}
              </div>

              {/* Footer */}

              <div
                style={{
                  boxShadow: scrollFlag.isBottomReached
                    ? ''
                    : '0 -6px 5px -5px var(--shadow, #091e4229)',
                }}
                className='client-add-button-container modal_footer'
              >
                <Button.Container
                  className={'outline-cancel-button'}
                  type='reset'
                  handleOk={() => {
                    setAddModal(false);
                  }}
                >
                  <Cancel />
                  <Button.Title title={'Cancel'} />
                </Button.Container>

                <Button.Container
                  handleOk={(e) => handleSubmit(e)}
                  type='submit'
                  className={'blue-btn'}
                  isDisabled={isLoading}
                >
                  <Tick />
                  <Button.Title title={'Save'} />
                  <Button.Loading isLoading={isLoading} />
                </Button.Container>
              </div>
            </div>
          </Modal.View>
        </Modal.Container>
      )}
    </>
  );
}
