import { useQuery } from '@tanstack/react-query';
import { ArrowLeftIcon, Home, MessageCircle } from 'lucide-react';
import moment from 'moment';
import React, { useContext, useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import CircularAvatar from '../_components/Avatar/CircularAvatar';
import StackedAvatars from '../_components/Avatar/StackedAvatars';
import { ErrorComponent } from '../_components/ErrorComponent';
import IconLabelSet from '../_components/IconLabelSet';
import { apiConstants, MENU, RT } from '../_constants';
import { POSITION } from '../_constants/contextMenu.constants';
import { projectService } from '../_services';
import { TaskListTypeConst } from '../BoardView/BoardView.constant';
import { Favorite } from '../Favorites';
import { favTypes } from '../Favorites/favCostants';
import { fullNameUser, GlobalContext } from '../Utils';
import { Calendar, FileIcon, LoadingIcon, ProjectIcon } from '../Utils/SvgIcons';
import './project.css';
import ProjectLayout from './ProjectLayout';
export function Project() {
  const projectId = useParams();
  const [searchParams] = useSearchParams();

  // eslint-disable-next-line no-unused-vars
  const [query, setQuery] = useState({
    select: ['id', 'name', 'start_date', 'end_date', 'project_status', 'project_type'],
    pageVo: {
      pageNo: 1,
      noOfItems: 10,
    },
    id: projectId.id,
  });

  const navigate = useNavigate();
  const { globalSettings, getMenuLabelName } = useContext(GlobalContext);
  const LABEL_OF_PROJECT = getMenuLabelName(MENU.project.default_name);

  const projectSettings =
    globalSettings &&
    globalSettings.length &&
    globalSettings.find((settings) => settings.name === 'project-settings')?.labels;

  const hasExist = (name) => {
    const setting = projectSettings?.find((label) => label.label === name);
    return setting ? setting.value : undefined;
  };

  const {
    data: projectData,
    error,
    isLoading,
  } = useQuery({
    queryKey: ['project-get', query?.pageVo?.pageNo, projectId.id],
    queryFn: () => projectService.projectGet({ ...query, id: projectId?.id }),
  });

  const ownerName = fullNameUser(
    projectData?.data?.project_owner_details?.first_name,
    projectData?.data?.project_owner_details?.last_name,
  );
  const isMale = projectData?.data?.project_owner_details?.gender === 1;
  const isNeutral = projectData?.data?.project_owner_details?.gender === 3;
  const url = projectData?.data?.project_owner_details?.compressed_image_url
    ? projectData?.data?.project_owner_details?.compressed_image_url
    : projectData?.data?.project_owner_details?.image_url;

  const finalUrl = url ? apiConstants.imgUrlS3 + url : null;

  if (isLoading) {
    return (
      <div className='template-add-container page-height d-flex justify-content-center align-items-center'>
        <LoadingIcon size={60} />
      </div>
    );
  }

  const chatUpdateLogData = projectData?.data?.ChatUpdateLog;
  const chatLink = `/chat?tab=group&id=${chatUpdateLogData?.id}`;

  return (
    <div>
      {/* url section  */}
      <div className='d-flex  breadcrumbs url-inner-navigation'>
        <div
          className='back-button-container'
          onClick={() =>
            // navigate(
            //   `/${RT.PROJECT_LIST}${
            //     currentPath.includes(RT.MILESTONE)
            //       ? ''
            //       : `?pageNo=${searchParams.get('pageNo') ?? 1}`
            //   }`,
            // )
            navigate(-1)
          }
        >
          <ArrowLeftIcon strokeWidth={1.5} size={26} color='#87909E' />
        </div>
        <div className='d-flex align-items-center '>
          <IconLabelSet
            label={'Home'}
            fontWeight={400}
            icon={<Home strokeWidth={1.5} size={14} />}
            onClick={() => navigate('/')}
          />
          <img className='slash-img-icon-size ms-1 mt-1' src='/icons/Lineicon.png' alt='' />
          <IconLabelSet
            fontWeight={400}
            label={` ${LABEL_OF_PROJECT}`}
            // color={`#2A2E34`}
            icon={<ProjectIcon height={14} width={14} color='#2A2E34' />}
            onClick={() => navigate(`/${RT.PROJECT_LIST}?pageNo=1`)}
          />
          <img className='slash-img-icon-size ms-1 mt-1' src='/icons/Lineicon.png' alt='' />
          <IconLabelSet
            label={projectData?.data?.name ? projectData?.data?.name : '--'}
            fontWeight={500}
            color={`#2A2E34`}
            onClick={() =>
              navigate(
                `/${RT.PROJECT}/${projectId.id}/${RT.PROJECT_TASK_LIST}?status=${
                  projectData?.data?.navigation_id ?? 'in-progress'
                }&&list_type=${TaskListTypeConst.Project}&&pageNo=${
                  searchParams.get('pageNo') ?? 1
                }`,
              )
            }
          />
        </div>
      </div>
      {/* component will active when error occurs   */}
      {error?.message && <ErrorComponent error={error?.message} />}
      <div
        className='project-table-container '
        style={{ maxWidth: '80vw', padding: 0, position: 'relative' }}
      >
        {chatUpdateLogData && chatUpdateLogData?.id && (
          <div
            onClick={() => navigate(chatLink)}
            style={{ position: 'absolute', top: 20, right: 70, cursor: 'pointer' }}
          >
            <MessageCircle color='rgb(52, 84, 209)' />
            {chatUpdateLogData?.message_count_for_user ? (
              <div className='project-chat-count'>{chatUpdateLogData?.message_count_for_user}</div>
            ) : null}
          </div>
        )}
        <div className='project-pin-icon'>
          <Favorite projectId={projectId?.id} favType={favTypes.PROJECT} />
        </div>

        {/* project details  */}
        <div className='project-single-header-container'>
          {/* project name   */}
          <div className='project-heading'>{projectData?.data?.name}</div>
          <div className='project-details'>
            <ProjectDetailsBox
              icon={
                <CircularAvatar
                  size={30}
                  source={finalUrl}
                  className='fz-none zoom-in employee-img-with-low-border ms-0 me-0'
                  isMale={isMale}
                  isNeutral={isNeutral}
                />
              }
              heading={'Owner'}
              value={ownerName}
            />
            {/* <ProjectDetailsBox
              icon={
                <div className='first_Letter_bg'>
                  <CompanyIcon />
                </div>
              }
              heading={'Company'}
              value={projectData?.data?.Company?.name}
            /> */}
            {/* {projectData?.data?.start_date && hasExist('Start Date') && (
              <ProjectDetailsBox
                icon={
                  <div className='first_Letter_bg'>
                    <Calendar color='#2A2E34' height={15} width={15} />
                  </div>
                }
                heading={'Start Date'}
                value={
                  projectData?.data?.start_date
                    ? moment(parseInt(projectData?.data?.start_date)).format('DD MMM YYYY')
                    : '--'
                }
              />
            )}
            {projectData?.data?.end_date && hasExist('End Date') && (
              <ProjectDetailsBox
                icon={
                  <div className='first_Letter_bg'>
                    <Calendar color='#2A2E34' height={15} width={15} />
                  </div>
                }
                heading={'End Date'}
                value={
                  projectData?.data?.end_date
                    ? moment(parseInt(projectData?.data?.end_date)).format('DD MMM YYYY')
                    : '--'
                }
              />
            )} */}
            {projectData?.data?.start_date &&
              hasExist('Start Date') &&
              projectData?.data?.end_date &&
              hasExist('End Date') && (
                <ProjectDetailsBox
                  icon={
                    <div className='first_Letter_bg'>
                      <Calendar color='#2A2E34' height={15} width={15} />
                    </div>
                  }
                  heading={'Duration'}
                  value={`${moment(parseInt(projectData?.data?.start_date)).format(
                    'DD MMM',
                  )} - ${moment(parseInt(projectData?.data?.end_date)).format('DD MMM')} `}
                />
              )}
            <ProjectDetailsBox
              icon={
                <div className='first_Letter_bg'>
                  <FileIcon />
                </div>
              }
              heading={'Task Status'}
              value={`
                ${projectData?.data?.completed_task_count} of ${projectData?.data?.total_task_count} completed`}
            />
            {projectData?.data?.team_lead?.length > 0 && (
              <ProjectDetailsBox
                heading={'Team Lead'}
                value={
                  <StackedAvatars
                    justifyPosition='justify-content-left'
                    getSourceByKey={(user) => user?.image_url}
                    getNameByKey={(user) => fullNameUser(user?.first_name, user?.last_name)}
                    getGenderByKey={(user) => user?.gender === 1}
                    imageList={projectData?.data?.project_team_lead_details}
                    showInitials={false}
                    tooltipPosition={POSITION.TOP}
                    size={25}
                    limit={3}
                  />
                }
              />
            )}
            {projectData?.data?.allocated_users?.length > 0 && (
              <ProjectDetailsBox
                heading={'Allocated Users'}
                value={
                  <StackedAvatars
                    justifyPosition='justify-content-left'
                    getSourceByKey={(user) => user?.image_url}
                    getNameByKey={(user) => fullNameUser(user?.first_name, user?.last_name)}
                    getGenderByKey={(user) => user?.gender === 1}
                    imageList={projectData?.data?.project_allocated_user}
                    showInitials={false}
                    tooltipPosition={POSITION.TOP}
                    size={25}
                    limit={3}
                  />
                }
              />
            )}
          </div>
        </div>
        <div className='project-separation'></div>
        <ProjectLayout projectData={projectData?.data} />
      </div>
    </div>
  );
}

export const ProjectDetailsBox = ({ icon, heading, value }) => {
  return (
    <div className='details-outer '>
      <div className=''>{icon}</div>
      <div className=''>
        <div className='details-heading'>{heading}</div>
        <div className='details-value'>{value}</div>
      </div>
    </div>
  );
};
