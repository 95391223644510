import { X } from 'lucide-react';
import React from 'react';
import '../ai.css';

export const ModalHead = ({ setOpenAudioModal, setLatestResponse }) => {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        padding: '10px 10px 5px 10px',
      }}
    >
      <div></div>
      <X
        className='x-wrapper'
        cursor={'pointer'}
        onClick={() => {
          setLatestResponse();
          setOpenAudioModal(false);
        }}
        size={30}
      />
    </div>
  );
};
