/* eslint-disable no-unused-vars */
import { Calendar, Hourglass, Play, Trash, Zap } from 'lucide-react';
import moment from 'moment';
import React, { useEffect, useMemo, useState } from 'react';
import CircularAvatar from '../../_components/Avatar/CircularAvatar';
import ToolTip from '../../_components/Tooltip/ToolTip';
import { apiConstants } from '../../_constants';
import { START_MODE, VALIDATION_VALUES } from '../../_constants/template.constants';
import { fullNameUser, validateTaskWithTemplateTest } from '../../Utils';
import { Label, Priority } from '../../Utils/SvgIcons';
import { getExpectedTime, getPriorityByContextAPI } from '../taskValidation';
import InlineRelatedTaskItemExpand from './InlineRelatedTaskItemExpand';

export function InlineRelatedTaskItem({
  templateData, // selectedTemplate
  relatedTaskData,
  setEditTaskIndex,
  index,
  setDeleteModal,
  onSubmitSubtask,
  allRelatedTask,
  validation_fields,
  isMenuSubTaskOn = false,
  isMenuLabelOn = false,
  setLabelSearch = () => {},
  setUserSearch = () => {},
  labelSearch,
  userSearch,
  userList = [],
  labelList = [],
  setTaskData = () => {},
  scrollRef,
  expandAll = false,
  setExpandAll = () => {},
}) {
  const [isExpanded, setIsExpanded] = useState(false);
  const [validationData, setValidationData] = useState([]); //state for add tags visibility

  const priorityLabelList = getPriorityByContextAPI(); // to fetch the priority  label from context api
  const priorityLabel = priorityLabelList.filter(
    (item) => parseInt(item.id) === parseInt(relatedTaskData?.priority),
  );
  const StartDate = moment(relatedTaskData?.task_start_date).format('MMM DD');
  const EndDate = moment(relatedTaskData?.task_end_date).format('MMM DD');
  const ExpectedTime = getExpectedTime(
    parseInt(relatedTaskData?.expected_hours),
    parseInt(relatedTaskData?.expected_minutes),
  );
  const isNeutral = relatedTaskData?.assigned_user?.gender === 3;
  const isMale = relatedTaskData?.assigned_user?.gender === 1;
  const url = relatedTaskData?.assigned_user?.image_url;
  const finalUrl = url ? apiConstants.imgUrlS3 + url : null;
  const fullName = fullNameUser(
    relatedTaskData?.assigned_user.first_name,
    relatedTaskData?.assigned_user.last_name,
  );

  const precedingTask =
    relatedTaskData?.preceding_task_id > 0
      ? allRelatedTask.find((item) => item.tempId == relatedTaskData?.preceding_task_id)
      : '';
  const hasRequestTime =
    Boolean(parseInt(relatedTaskData?.expected_hours)) ||
    Boolean(parseInt(relatedTaskData?.expected_minutes));
  useEffect(() => {
    let validationFields = { ...validation_fields };
    const validationError = validateTaskWithTemplateTest(validationFields, [relatedTaskData]);
    if (!validationError?.isValid) {
      setValidationData(validationError?.requiredFields);
      return;
    }
  }, []);

  useMemo(() => {
    if (expandAll) {
      setIsExpanded(expandAll);
    } else {
      setIsExpanded(expandAll);
      setExpandAll(false);
    }
  }, [expandAll]);

  return (
    <div className={'inline-task-container w-100'}>
      <div className='d-flex flex-wrap gap-2  justify-content-between '>
        <div className='d-flex gap-2  align-items-center align-items-center ms-2 flex-1'>
          <div className='d-flex' style={{ zIndex: 100 }}>
            <Play
              color={`${isExpanded ? '#3454D1' : '#87909E'}`}
              fill={`${isExpanded ? '#3454D1' : '#87909E'}`}
              size={10}
              className={`cursor-pointer ${
                isExpanded ? 'rotate-down-related ' : 'rotate-up-related'
              }`}
              onClick={() => setIsExpanded((prev) => !prev)}
            />{' '}
          </div>
          <div className='mb-1'>
            <div className='inline-related-task-name'>
              {relatedTaskData?.name.length > 25
                ? !isExpanded && relatedTaskData?.name?.substring(0, 25) + '...'
                : !isExpanded && relatedTaskData?.name}
            </div>{' '}
          </div>
        </div>
        <div className='d-flex gap-2 justify-content-center align-items-center w-fit-content icon-div invisible'>
          {!isExpanded && (
            <>
              {/* section of priority  */}
              <ToolTip tooltipText={priorityLabel[0]?.label} isModern>
                <div
                  role='button'
                  className={`col-md-1 inline-related-task-item-container custom-icon ${
                    validationData?.includes('priority')
                      ? ' has-error'
                      : relatedTaskData?.priority > 0
                      ? 'has-filled'
                      : ''
                  }`}
                >
                  <Priority height={12} width={7} color={'#87909E'} />
                </div>
              </ToolTip>{' '}
              {/* end of section  priority  */}
              <ToolTip tooltipText={`${StartDate} - ${EndDate}`} isModern>
                <div
                  role='button'
                  className={` inline-related-task-item-container custom-icon ${
                    validation_fields?.task_start_date == VALIDATION_VALUES.HIDE &&
                    validation_fields?.task_end_date == VALIDATION_VALUES.HIDE &&
                    'd-none'
                  } ${
                    validationData?.includes('task_start_date') ||
                    validationData?.includes('task_end_date')
                      ? ' has-error'
                      : 'has-filled'
                  }`}
                >
                  <Calendar height={11} width={11} color={'#87909E'} />
                </div>
              </ToolTip>
              <ToolTip
                tooltipText={ExpectedTime}
                isModern
                hideTooltip={validation_fields?.expected_hours == VALIDATION_VALUES.HIDE}
              >
                <div
                  role='button'
                  className={` inline-related-task-item-container custom-icon
                  ${validation_fields?.expected_hours == VALIDATION_VALUES.HIDE && 'd-none'}
                  ${
                    validationData?.includes('expected_hours') && !hasRequestTime
                      ? ' has-error'
                      : relatedTaskData?.expected_hours || relatedTaskData?.expected_minutes
                      ? 'has-filled'
                      : ''
                  }`}
                >
                  <Hourglass color={'#87909E'} width={11} height={15} />
                </div>
              </ToolTip>
              <ToolTip tooltipText={fullName} isModern>
                <div
                  role='button'
                  className={`col-md-1 inline-related-task-item-container custom-icon ${
                    validationData?.includes('assignee_id')
                      ? 'has-error'
                      : relatedTaskData?.assignee_id
                      ? 'has-filled'
                      : ''
                  }`}
                >
                  <div className='d-flex justify-content-center align-items-center'>
                    <CircularAvatar
                      size={12}
                      source={finalUrl}
                      isMale={isMale}
                      isNeutral={isNeutral}
                      name={fullName}
                    />
                  </div>
                </div>
              </ToolTip>
              {/* label selection section  */}
              {isMenuLabelOn && (
                <ToolTip
                  tooltipText={relatedTaskData?.label_details?.map((itm) => itm.name + ', \n')}
                  isModern
                >
                  <div
                    role='button'
                    className={`col-md-1 inline-related-task-item-container custom-icon ${
                      validationData?.includes('label')
                        ? ' has-error'
                        : relatedTaskData?.label?.length > 0
                        ? 'has-filled'
                        : ''
                    }`}
                  >
                    <Label width={10} height={10} color={'#87909E'} />
                  </div>
                </ToolTip>
              )}
              <ToolTip
                tooltipText={`${START_MODE[relatedTaskData?.task_start_mode]} ${
                  precedingTask.name ? precedingTask.name : ''
                }`}
                isModern
              >
                <div
                  role='button'
                  className={`col-md-1 inline-related-task-item-container custom-icon `}
                >
                  <Zap width={10} height={10} color={'#87909E'} />
                </div>
              </ToolTip>
            </>
          )}

          {/* <ToolTip tooltipText={'Edit'} isModern>
            <div
              role='button'
              className={`col-md-1 inline-related-task-item-container custom-icon `}
              onClick={(e) => {
                e.stopPropagation();
                setEditTaskIndex(index);
              }}
            >
              <Pen width={10} height={10} color={'#87909E'} />
            </div>
          </ToolTip> */}
          <ToolTip tooltipText={'Delete'} isModern>
            <div
              role='button'
              className={`col-md-1 inline-related-task-item-container custom-icon `}
              onClick={(e) => {
                e.stopPropagation();
                setDeleteModal(true);
              }}
            >
              <Trash width={10} height={10} color={'#87909E'} />
            </div>
          </ToolTip>
        </div>{' '}
      </div>

      <InlineRelatedTaskItemExpand
        validation_fields={validation_fields}
        relatedTaskData={relatedTaskData}
        onSubmitSubtask={onSubmitSubtask}
        isExpanded={isExpanded}
        precedingTask={precedingTask}
        validationFields={validation_fields}
        setLabelSearch={setLabelSearch}
        setUserSearch={setUserSearch}
        isMenuLabelOn={isMenuLabelOn}
        isMenuSubTaskOn={isMenuSubTaskOn}
        labelSearch={labelSearch}
        userSearch={userSearch}
        userList={userList}
        labelList={labelList}
        setRelatedTaskData={setTaskData}
        scrollRef={scrollRef}
        precedingTaskName={
          allRelatedTask.find(
            (task) => (task.tempId ?? task.id) === relatedTaskData.preceding_task_id,
          )?.name ?? ''
        }
      />
    </div>
  );
}
