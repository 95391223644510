import { useEffect, useRef, useState } from 'react';

const useTimer = (limit) => {
  const [timer, setTimer] = useState(60000); // 1 minute
  // eslint-disable-next-line no-unused-vars
  const [isActive, setIsActive] = useState(false);
  const intervalRef = useRef(null);

  useEffect(() => {
    return () => {
      clearInterval(intervalRef.current);
    };
  }, []);

  // useEffect(()=>{
  //     let interval = null
  //     if(isActive){
  //         let time =limit  * 1000
  //         interval = setInterval(()=>{
  //             time -= 1000
  //             setTimer(time)
  //             if(time <= 0){
  //                 setIsActive(false)
  //                 setTimer(0)
  //             }
  //         },1000)

  //     }else{
  //         clearInterval(interval)
  //     }

  //     return ()=>clearInterval(interval)

  // },[isActive])

  const startTimer = () => {
    // setIsActive(true)
    let time = limit * 1000;
    clearInterval(intervalRef.current);
    intervalRef.current = setInterval(() => {
      time -= 1000;
      setTimer(time);
      if (time <= 0) {
        stopTimer();
      }
    }, 1000);
  };

  const stopTimer = () => {
    // setIsActive(false)
    setTimer(0);
    clearInterval(intervalRef.current);
  };

  return [startTimer, timer];
};

export default useTimer;
