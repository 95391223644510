/* eslint-disable no-unused-vars */
import { useIsMutating, useMutation, useQuery } from '@tanstack/react-query';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { GlobalContext } from '../../Utils';
import { Button } from '../../_components';
import { settingsService } from '../../_services';
import { HeadWithArrow } from '../UtilsInSettings';

export function PriorityComponent() {
  const [query] = useState({ select: ['id', 'name', 'labels'], name: 'priority-label' }); //state for get label select fields
  const {
    data: priorityLabel,
    isLoading,
    isSuccess,
    refetch,
  } = useQuery({
    queryKey: ['priority-label'],
    queryFn: () => settingsService.settingsGet(query),
    select: (data) => {
      return data?.data[0].labels;
    },
  });

  const [prioritySettings, setprioritySettings] = useState({}); // priority settings state
  const [priority, setpriority] = useState(true);
  const [hasChanged, setHasChanged] = useState(false);
  const { makeAlert } = useContext(GlobalContext);
  const isMutating = useIsMutating();
  // to set priority settings state when priority labels loaded
  useMemo(() => {
    setprioritySettings(priorityLabel);
  }, [priorityLabel]);

  // to manage the change
  useEffect(() => {
    setHasChanged(JSON.stringify(priorityLabel) !== JSON.stringify(prioritySettings));
  }, [prioritySettings, prioritySettings]);
  // to save priority settings
  const savePrioritySettings = async (updatedData) => {
    const saveData = {
      name: 'priority-label',
      labels: updatedData,
    };

    await settingsService.settingsAdd(saveData);

    refetch();
    makeAlert('Success');
    setHasChanged(false);
  };

  // --react query fot mutation function
  const {
    mutateAsync: handleMutate,
    isError,
    isPaused,
    isPending,
  } = useMutation({
    mutationFn: savePrioritySettings,
  });

  // to manage change
  const handleChange = (value, name, setting) => {
    setprioritySettings({
      ...prioritySettings,
      [setting]: {
        ...prioritySettings[setting],
        [name]: value,
      },
    });
  };
  // save handler
  const handleSave = () => {
    if (isPaused) {
      makeAlert('No internet');
      return;
    }
    if (isPending) {
      return;
    }
    handleMutate(prioritySettings);
  };

  return (
    <div>
      <HeadWithArrow head={'Priority'} isDown={!priority} handler={() => setpriority(!priority)} />
      {priority && (
        <>
          <div style={{ width: '100%' }}>
            {prioritySettings &&
              Object.keys(prioritySettings).map((key) => {
                return (
                  <div key={key} className='priority-wrap d-flex justify-content-between'>
                    <input
                      key={key}
                      type='text'
                      className='menu-tab'
                      value={prioritySettings?.[key]?.text}
                      style={{ fontSize: 14, width: '100%' }}
                      onChange={(e) => handleChange(e.target.value, 'text', key)}
                    />
                    <div className=' group-color'>
                      <input
                        onChange={(e) => handleChange(e.target.value, 'color', key)}
                        type='color'
                        className='color_picker pointer'
                        value={prioritySettings?.[key]?.color || '#000000'}
                      />
                    </div>
                  </div>
                );
              })}
          </div>
          {/* only show if any change of priority label names */}
          {hasChanged && (
            <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 10 }}>
              <Button.Container className={'additional-save-btn'} handleOk={() => handleSave()}>
                <Button.Title title={'Save'} />
                <Button.Loading isLoading={isMutating && !isPaused} />
              </Button.Container>
            </div>
          )}
        </>
      )}
    </div>
  );
}
