import { keepPreviousData, useQuery, useQueryClient } from '@tanstack/react-query';
import { FileIcon, Home, Pen, Plus, Trash } from 'lucide-react';
import React, { useContext, useRef, useState } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { Table, Tbody, Td, Th, Thead, Tr } from 'react-super-responsive-table';
import { TaskListTypeConst } from '../BoardView/BoardView.constant';
import { GlobalContext, Images, MyTeamIcon } from '../Utils';
import {
  ClientIcon,
  Folder,
  LoadingIcon,
  OverDueIcon,
  ProgressIcon,
  ProjectIcon,
} from '../Utils/SvgIcons';
import { Button, PaginationComponent, TableRow } from '../_components';
import IconLabelSet from '../_components/IconLabelSet';
import { Modal } from '../_components/Modal';
import NoPermission from '../_components/NoPermission';
import { SearchBar } from '../_components/Search';
import ToolTip from '../_components/Tooltip/ToolTip';
import { LIST_PERMISSION_VALUE, MENU, RT } from '../_constants';
import { DELETE_NOTE } from '../_constants/deletenote.constants';
import useDebounce from '../_helpers/useDebounce';
import { clientService } from '../_services';
import './Client.css';
import { ClientAdd } from './ClientAdd';

export function ClientList() {
  const { getMenuLabelName, makeAlert, roleBasedPermission } = useContext(GlobalContext);
  const LABEL_OF_CLIENT = getMenuLabelName(MENU.contact.default_name);

  const TABLE_HEADINGS = [
    { name: <img src={Images.SerialNo} height={14} width={14} />, minWidth: 50 },
    {
      name: (
        <div className='d-flex align-items-center gap-1'>
          <ProjectIcon height={16} width={16} color='#87909E' /> {LABEL_OF_CLIENT}
        </div>
      ),
      minWidth: '10vw',
      maxWidth: '20vw',
    },
    {
      name: (
        <div className='d-flex align-items-center gap-1'>
          <ProgressIcon /> Company name
        </div>
      ),
      minWidth: 50,
    },
    {
      name: (
        <div className='d-flex align-items-center gap-1'>
          <FileIcon size={16} /> Total Tasks
        </div>
      ),
      minWidth: 50,
    },
    {
      name: (
        <div className='d-flex align-items-center gap-1'>
          <Folder height={19} width={15} color='#87909E' /> Total Files
        </div>
      ),
      minWidth: 50,
    },
    {
      name: (
        <div className='d-flex align-items-center gap-1'>
          <OverDueIcon /> Due tasks
        </div>
      ),
      minWidth: 50,
    },
    {},
  ];

  const queryClient = useQueryClient();
  const [editClientId, setEditClientID] = useState(null);
  const location = useLocation();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);
  const [searchParams, setSearchParams] = useSearchParams();
  const status = queryParams.get('status');

  const [query, setQuery] = useState({
    select: ['id', 'name', 'email', 'phone', 'company_name', 'description', 'pin_code', 'address'],
    pageVo: {
      pageNo: parseInt(searchParams.get('pageNo')) || 1,
      noOfItems: 10,
    },
    searchKey: '',
  });
  const [addModal, setAddModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const debouncedSearch = useDebounce(query?.searchKey, 500);

  const tableContainerRef = useRef(null);
  const reference = useRef(null);

  // to get clients using react query
  const {
    data: clientData,
    isLoading,
    isSuccess,
    refetch,
  } = useQuery({
    queryKey: ['clientList', status, debouncedSearch, query?.pageVo?.pageNo],
    queryFn: () =>
      clientService.clientList({
        ...query,
      }),
    enabled: roleBasedPermission?.ACTION_CLIENT_LIST !== LIST_PERMISSION_VALUE.NO_ACCESS,
    placeholderData: keepPreviousData,
  });

  const modalClose = () => {
    setDeleteModal(false);
    setEditClientID(null);
  };
  const handleDeactivate = async (id) => {
    try {
      await clientService.clientDeactivate({ id });
      let isNeedToBack = clientData?.data?.rows.length === 1 && clientData?.data?.pages > 1;

      if (isNeedToBack) {
        const page = searchParams.get('pageNo');
        searchParams.set('pageNo', page - 1);
        setSearchParams(searchParams);
        setQuery((prev) => ({
          ...prev,
          pageVo: {
            ...prev.pageVo,
            pageNo: prev.pageVo.pageNo - 1,
          },
        }));
      } else {
        refetch();
      }
      await queryClient.invalidateQueries('clientList');
      modalClose();

      makeAlert('Deleted Successfully');
    } catch (error) {
      makeAlert(error?.message || 'Failed to delete');
    }
  };

  // NO ACCESS MANAGE
  if (roleBasedPermission?.ACTION_CLIENT_LIST === LIST_PERMISSION_VALUE.NO_ACCESS) {
    return <NoPermission />;
  }

  const listItemCount = clientData?.data?.rows?.length;

  const tableData = clientData?.data?.rows?.map((client, index) => {
    const slNo = (clientData?.data?.page - 1) * clientData?.data?.limit + index + 1;
    return (
      <Tr
        key={index}
        className='pointer'
        onClick={() => {
          navigate(
            `/${RT.CLIENT_SINGLE_PAGE}/${client?.id}/${
              RT.CLIENT_TASK_LIST
            }?status=${'in-progress'}&list_type=${TaskListTypeConst.ContactTask}`,
          );
        }}
      >
        <Td>{slNo}</Td>
        <Td>
          <div>{client?.name}</div>
        </Td>
        <Td>{client?.company_name ? client?.company_name : '---'}</Td>
        <Td>{client?.total_task_count} tasks</Td>
        <Td>{client?.total_file_count} files</Td>
        <Td>{client?.due_task_count}</Td>
        <Td
          style={{ verticalAlign: 'middle', cursor: 'pointer' }}
          ref={reference}
          onClick={(event) => {
            event.stopPropagation();
          }}
        >
          {(roleBasedPermission?.ACTION_CLIENT_DELETE ||
            roleBasedPermission?.ACTION_CLIENT_EDIT) && (
            <div className='d-flex gap-2 justify-content-end icon-div invisible'>
              {roleBasedPermission?.ACTION_CLIENT_EDIT && (
                <ToolTip
                  tooltipText={`Edit ${LABEL_OF_CLIENT}`}
                  isModern
                  popOver
                  scrollRef={tableContainerRef}
                >
                  <div
                    className='icon-bg'
                    onClick={(event) => {
                      event.stopPropagation();
                      setEditClientID(client?.id);
                      setAddModal(true);
                    }}
                  >
                    <Pen size={15} />
                  </div>
                </ToolTip>
              )}
              {roleBasedPermission?.ACTION_CLIENT_DELETE && (
                <ToolTip
                  tooltipText={`Delete ${LABEL_OF_CLIENT}`}
                  isModern
                  popOver
                  scrollRef={tableContainerRef}
                >
                  <div
                    className='icon-bg'
                    onClick={(event) => {
                      event.stopPropagation();
                      setEditClientID(client?.id);
                      setDeleteModal(true);
                    }}
                  >
                    <Trash size={15} />
                  </div>
                </ToolTip>
              )}
            </div>
          )}
        </Td>
      </Tr>
    );
  });

  return (
    <div>
      <section>
        <div className=' url-container '>
          <div className='d-flex align-items-center breadcrumbs'>
            <IconLabelSet
              label={'Home'}
              fontWeight={400}
              icon={<Home size={13} />}
              onClick={() => navigate(`/`)}
            />
            <img className='slash-img-icon-size ms-1 mt-1' src={MyTeamIcon.Slash} alt='' />
            <IconLabelSet
              label={LABEL_OF_CLIENT}
              fontWeight={500}
              icon={<ClientIcon color='#2A2E34' height={16} width={15} />}
              onClick={() => {
                navigate(`/${RT.CLIENT}`);
              }}
            />
          </div>
        </div>
      </section>
      <div className='client-list-container'>
        <section>
          <div className='tab_contain client-search-container'>
            <SearchBar
              handleSearch={(event) => {
                searchParams.set('pageNo', 1);
                setSearchParams(searchParams);
                setQuery({
                  ...query,
                  searchKey: event.target.value,
                  pageVo: { ...query.pageVo, pageNo: 1 },
                });
              }}
              searchKey={query.searchKey}
            />

            <div className='d-flex gap-2'>
              {roleBasedPermission?.ACTION_CLIENT_ADD && (
                <ToolTip tooltipText={`Add ${LABEL_OF_CLIENT}`} isModern>
                  <div
                    className='menu-item-outline'
                    onClick={() => {
                      setAddModal(true);
                    }}
                  >
                    <Plus size={16} color='#87909E' />
                  </div>
                </ToolTip>
              )}
            </div>
          </div>

          <section className='mt-2'>
            <div
              className='project-table table-container no-padding'
              ref={tableContainerRef}
              style={{ maxHeight: '65vh' }}
            >
              <Table className='table table-hover tbl mb-0'>
                <Thead>
                  <Tr className='table-head'>
                    {TABLE_HEADINGS.map((heading, index) => {
                      return (
                        <Th
                          key={index}
                          scope='col'
                          style={{ minWidth: heading.minWidth, maxWidth: heading.maxWidth }}
                        >
                          {heading.name}
                        </Th>
                      );
                    })}
                  </Tr>
                </Thead>
                <Tbody style={{ verticalAlign: 'middle' }}>
                  {isLoading && (
                    <TableRow noOfCols={8}>
                      <LoadingIcon color='blue' size={60} />
                    </TableRow>
                  )}
                  {!listItemCount && isSuccess && <TableRow noOfCols={8}>No data found</TableRow>}
                  {listItemCount > 0 && isSuccess && tableData}
                </Tbody>
              </Table>
            </div>
          </section>
          <section className='mt-2'>
            <PaginationComponent
              pageOnClick={(data) => {
                setQuery((prev) => ({
                  ...prev,
                  pageVo: {
                    ...prev.pageVo,
                    pageNo: data,
                  },
                }));
                searchParams.set('pageNo', data);
                setSearchParams(searchParams);
              }}
              demoData={clientData?.data}
            />
          </section>
        </section>
        {addModal && (
          <Modal.Container handleClose={() => setAddModal(false)}>
            <Modal.View className={'modal_view_label_end modal_end'} containerClass={'view-end'}>
              <ClientAdd
                id={editClientId}
                handleClose={() => {
                  setAddModal(false);
                  setEditClientID(null);
                }}
                afterClientAdd={() => {
                  refetch();
                }}
              />
            </Modal.View>
          </Modal.Container>
        )}

        {deleteModal && (
          <Modal.Container>
            <Modal.View className={'status-delete'}>
              <Modal.Head handleClose={() => modalClose()} Heading={'Delete Confirmation'} />
              <Modal.Description>
                <img src={Images.DeleteBin} width={35} height={50} alt='' className='mb-3' />
                <div className='del-description'>{DELETE_NOTE.CLIENT}</div>
              </Modal.Description>
              <div className='modal_footer'>
                <Button.Container handleOk={() => modalClose()} className={'status-del-cancel'}>
                  <Button.Title title={'Cancel'} />
                </Button.Container>
                <Button.Container handleOk={() => handleDeactivate(editClientId)}>
                  <Button.Title title={'Delete'} />
                </Button.Container>
              </div>
            </Modal.View>
          </Modal.Container>
        )}
      </div>
    </div>
  );
}
