/* eslint-disable no-unused-vars */
import { keepPreviousData, useQuery } from '@tanstack/react-query';
import { Eye, EyeOff, Trash2 } from 'lucide-react';
import moment from 'moment';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import ReactSelect from 'react-select';
import { DropDownStyle } from '../Milestone/milestone.constants';
import ErrorBlock from '../Template/SubComponents/ErrorBlock';
import {
  EmployeeState,
  GlobalContext,
  Images,
  fullNameUser,
  phoneNumberValidation,
} from '../Utils';
import { LoadingIcon } from '../Utils/SvgIcons';
import { Button, CustomFileUpload, ToggleButton } from '../_components';
import { ErrorComponent } from '../_components/ErrorComponent';
import UserDropDown from '../_components/UserDropDown';
import { COMMON_USER_SELECT, RT, apiConstants } from '../_constants';
import useDebounce from '../_helpers/useDebounce';
import { roleService, userService } from '../_services';
import { departmentService } from '../_services/department.service';
import { MY_TEAM_ERROR_MSG, MY_TEAM_REQUIRED_FIELD_MSG } from './MyTeamConstant';
import './MyTeamCss.css';
import { myTeamSchema } from './MyTeamValidation';
export function MyTeamAdd({ handleClose = () => {} }) {
  const { globalSettings } = useContext(GlobalContext);
  const employeeSettings =
    globalSettings &&
    globalSettings.length &&
    globalSettings.find((settings) => settings.name === 'employee-settings')?.labels;

  // eslint-disable-next-line no-prototype-builtins
  const customFields = employeeSettings?.filter((obj) => obj.hasOwnProperty('isCustom'));

  const hasExist = (name) => {
    const setting = employeeSettings?.find((label) => label.label === name);
    return setting ? setting.value : undefined;
  };

  // hooks section
  const { id } = useParams(); // Access the id parameter from the URL
  const navigate = useNavigate();
  const { makeAlert } = useContext(GlobalContext);

  // useState section

  const defaultEmployee = {
    ...EmployeeState,
    custom_fields:
      (customFields ?? []).reduce((acc, curr) => {
        return { ...acc, [curr.label]: '' };
      }, {}) ?? {},
  };

  const [employee, setEmployee] = useState(EmployeeState);
  const [submitted, setSubmitted] = useState(false);
  const [departmentSearch, setDepartmentSearch] = useState('');
  const [designationSearch, setDesignationSearch] = useState('');
  const [userSearch, setUserSearch] = useState(''); // state for  user list organizationSearch
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [teamError, setTeamError] = useState({ message: '' });
  const [selectedUser, setSelectedUser] = useState(null);

  const [query, setQuery] = useState({
    select: [
      'id',
      'first_name',
      'last_name',
      'gender',
      'company_id',
      'department_id',
      'designation_id',
      'email_id',
      'phone_no',
      'dob',
      'role',
      'username',
      'reported_users',
      'image_url',
      'address',
      'employee_code',
      'office_number',
      'office_mail',
      'custom_fields',
    ],
  });

  useEffect(() => {
    if (id && id != '0') {
      query.id = id;
      getAllUser(query);
    }
  }, [id]);

  // gender selection list definition

  const genderList = [
    { label: 'Male', value: 1 },
    { label: 'Female', value: 2 },
    { label: 'Others', value: 3 },
  ];

  // today date
  let today = moment().format('YYYY-MM-DD');

  /**
   * Get user
   * */
  const getAllUser = async (queryTemp) => {
    let queryNew = queryTemp ? queryTemp : query;

    setQuery(queryNew);
    // setIsLoading(true);

    try {
      const user = await userService.userGet(queryNew);
      let organizationDOb = moment(parseInt(user.data?.dob)).format('YYYY-MM-DD');
      // let role = user.data.role_details;
      let department_id = user.data.department;
      let designation_id = user.data.designation;
      const result = user?.data;
      setEmployee((prev) => ({
        ...prev,
        ...result,
        dob: organizationDOb,
        designation_id: designation_id,
        department_id: department_id,
        // role: role,
      }));
    } catch (teamError) {
      console.log(teamError);
    } finally {
      // setIsLoading(false);
    }
  };

  const debounceDepartment = useDebounce(departmentSearch, 500);

  //  list all departments

  const {
    data: departmentData,
    error: departmentError,
    isLoading: departmentLoading,
  } = useQuery({
    queryKey: ['departmentList', debounceDepartment],
    queryFn: () =>
      departmentService.departmentList({
        searchKey: departmentSearch,
        select: ['is_department', 'name', 'id'],
        is_department: 1,
      }),
    placeholderData: keepPreviousData,
  });

  const debounceDesignation = useDebounce(designationSearch, 500);

  //  list all designation

  const {
    data: designationData,
    error: designationError,
    isLoading: designationLoading,
  } = useQuery({
    queryKey: ['designationList', debounceDesignation, employee?.department_id],
    queryFn: () =>
      departmentService.departmentList({
        searchKey: designationSearch,
        select: ['is_department', 'name', 'id'],
        department_id: employee?.department_id?.id,
        is_department: 0,
      }),
    placeholderData: keepPreviousData,
  });

  // role list
  const {
    data: roleData,
    error: roleError,
    isLoading: roleLoading,
  } = useQuery({
    queryKey: ['roleList'],
    queryFn: () => roleService.roleList(),
    placeholderData: keepPreviousData,
  });

  const debounceUser = useDebounce(userSearch, 500);

  // Function to format reporting user fields , pass true for isAdmin if the user is Admin
  const reportingUserFields = (user, isSuperAdmin = false) => {
    if (!user) {
      return null;
    }

    return {
      id: user?.id,
      name: fullNameUser(user?.first_name, user?.last_name),
      is_immediate: 0,
      is_enable_notification: 1,
      is_admin: user?.role === 1 ? true : false,
      is_super_admin: isSuperAdmin,
    };
  };

  const userListQueryFn = async () => {
    const res = await userService.getRoleBasedUserList({
      searchKey: userSearch,
      select: COMMON_USER_SELECT,
      action: 'USER-ADD',
    });
    const adminDetails = reportingUserFields(res.data.admin, true);

    const allUsers =
      adminDetails && !employee?.reported_users?.some((item) => item.id === adminDetails.id)
        ? [adminDetails, ...employee.reported_users]
        : employee.reported_users;
    setEmployee((prev) => ({
      ...prev,
      reported_users: allUsers,
    }));
    return res;
  };

  // user list
  const {
    data: userData,
    error: userError,
    isLoading: userLoading,
  } = useQuery({
    queryKey: ['userList-ReportingUser', debounceUser, employee?.id],
    queryFn: () => userListQueryFn(),
    enabled: !parseInt(id) || Boolean(employee?.id),
  });

  // function for update the user list for reported-to selection field.
  const filteredUserData = useMemo(() => {
    // code for remove selected user from user list
    if (!userData?.data || !employee?.reported_users) return [];

    const reportedUserIds = employee.reported_users.map((user) => user.id);

    // Note: Ensure `currentUser` is not listed in the User list
    return userData.data.rows.filter(
      (option) => !reportedUserIds.includes(option.id) && option.id !== id,
    );
  }, [userData, employee, id]);

  // handleChange image upload
  const handleImageUpload = (file, name) => {
    setEmployee((prev) => ({
      ...prev,
      [name]: file.pop(),
    }));
  };

  // delete a user from reporting user
  const handleDelete = (index) => {
    const updatedReportedUsers = [...employee.reported_users];
    updatedReportedUsers.splice(index, 1);
    setEmployee((prev) => ({
      ...prev,
      reported_users: updatedReportedUsers,
    }));
  };

  // handle change of checkbox  - reporting user list table
  const handleReportingUserFeatureChange = (option, event) => {
    const { checked, name } = event.target;

    const update = (employee?.reported_users || []).map((itm, idx) => ({
      ...itm,
      [name]:
        name === 'is_immediate'
          ? option === idx
            ? 1
            : 0
          : option === idx
          ? checked
            ? 1
            : 0
          : itm[name],
    }));

    setEmployee((prev) => ({
      ...prev,
      reported_users: update,
    }));
  };

  // handleChange of Reporting user select
  const handleReportingUserSelect = (user) => {
    const reporting_user_options = reportingUserFields(user);
    setEmployee((prev) => ({
      ...prev,
      reported_users: [...prev.reported_users, reporting_user_options],
    }));
  };

  const handleChange = (event) => {
    const { name, value, type } = event.target;

    if (type === 'department') {
      setEmployee((prev) => ({
        ...prev,
        [name]: value,
        designation_id: 0,
      }));
      return;
    }

    if (type === 'organization') {
      setEmployee((prev) => ({
        ...prev,
        [name]: value,
        department_id: 0,
        designation_id: 0,
      }));
      return;
    }

    setEmployee((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleChangeCustomField = (event) => {
    const { name, value } = event.target;
    setEmployee((prev) => ({
      ...prev,
      custom_fields: { ...employee?.custom_fields, [name]: value },
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    setSubmitted(true);

    const { password, ...rest } = employee;
    const isImmediateUserPresent = employee?.reported_users.some(
      (user) => user?.is_immediate === 1,
    );

    if (!isImmediateUserPresent) {
      makeAlert(MY_TEAM_ERROR_MSG.IMMEDIATE_USER_REQUIRED);

      return;
    }

    const isPhoneValid = employee?.phone_no ? phoneNumberValidation(employee?.phone_no) : true;
    if (!isPhoneValid) {
      makeAlert(MY_TEAM_ERROR_MSG.PHONE_NUMBER);
      setIsLoading(false);
      return;
    }

    const dateOfBirth = new Date(employee?.dob).getTime();
    const reported_users_ids = employee?.reported_users?.map((user) => parseInt(user?.id));

    let payload = {
      ...rest,
      dob: dateOfBirth,
      first_name: rest.first_name.toString().trim(),
      last_name: rest.last_name.toString().trim(),
      username: rest.username.toString().trim(),
      reported_users_ids: reported_users_ids,
      // role: parseInt(rest?.role?.number),
      designation_id: parseInt(rest?.designation_id?.id),
      department_id: parseInt(rest?.department_id?.id),
    };

    if (id == 0) {
      // Only include password in payload if id is 0 (for adding new employee)
      payload.password = password.toString().trim();
    }
    // Validate with dynamic schema based on the id

    const validationResult = myTeamSchema(hasExist('Gender'), id).safeParse(payload);
    if (!validationResult.success) {
      scrollToErrorElement();
      return;
    }

    try {
      setIsLoading(true);
      await userService.userAdd(payload);
      navigate(`/${RT.MY_TEAM_LIST}`);
      setIsLoading(false);
    } catch (error) {
      makeAlert(error?.message);
      setIsLoading(false);
    } finally {
      setSubmitted(false);
    }
  };

  /***
   * To scroll to the first found error element. 'help-block' class is considered as the error element. It will scroll to the first found parent of help-block class.
   */

  const scrollToErrorElement = () => {
    window.requestAnimationFrame(() => {
      const element = document.querySelector('.error-block');
      if (!element) {
        return;
      }
      const parentElement = element.parentElement;
      parentElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
    });
  };

  const message =
    designationError?.message ||
    roleError?.message ||
    departmentError?.message ||
    userError?.message ||
    teamError?.message;

  return (
    <>
      {message && <ErrorComponent error={message} />}
      <div className='p-3 my-team-head modal_header'>{id == 0 ? 'Add' : 'Edit'} Employee </div>
      <div className='row mb-2 myteam-add-container modal_view_content'>
        <form action='' className='d-flex flex-column gap-2 ' onSubmit={handleSubmit}>
          <div className='col-md-6 ' style={{ borderRight: '1px solid #DBDBDB ' }}>
            {/* component will active when error occur   */}

            {hasExist('Employee Picture') && (
              <div className='mb-3 ' style={{ width: 'fit-content' }}>
                {employee?.image_url ? (
                  <img
                    src={apiConstants.imgUrlS3 + employee?.image_url}
                    width={'100px'}
                    height={'100px'}
                  />
                ) : (
                  <img className='' src={Images.ImagePreview} width={'100px'} height={'100px'} />
                )}

                <CustomFileUpload
                  name='image_url'
                  accept='image'
                  isMulti={false}
                  handleOK={handleImageUpload}
                  CustomView={
                    <div className=' text-decoration-underline' style={{ cursor: 'pointer' }}>
                      Upload Image
                    </div>
                  }
                />
              </div>
            )}

            <div className='row mb-2'>
              <div className='col-md-6'>
                <div>
                  <label className='mb-1 fs-13'>
                    First Name<span className='field-required'> *</span>
                  </label>
                  <input
                    type='text'
                    className='input-box'
                    name='first_name'
                    value={employee?.first_name}
                    placeholder='Employee name'
                    onChange={(event) => {
                      handleChange(event);
                    }}
                  />
                  <ErrorBlock
                    errorMsg={MY_TEAM_REQUIRED_FIELD_MSG.FIRST_NAME}
                    hasError={submitted && employee?.first_name.toString().trim() === ''}
                  />
                </div>
              </div>
              <div className='col-md-6'>
                <div>
                  <label className='mb-1 fs-13'>
                    Last Name<span className='field-required'> *</span>
                  </label>
                  <input
                    type='text'
                    className='input-box'
                    name='last_name'
                    value={employee?.last_name}
                    placeholder='Last name '
                    onChange={(event) => {
                      handleChange(event);
                    }}
                  />

                  <ErrorBlock
                    errorMsg={MY_TEAM_REQUIRED_FIELD_MSG.LAST_NAME}
                    hasError={submitted && employee?.last_name.toString().trim() === ''}
                  />
                </div>
              </div>
            </div>
            <div className='row mb-2'>
              <div className='col-md-6'>
                <div className='row mb-1 '>
                  {hasExist('Gender') && (
                    <div className='col '>
                      <div className='mb-1'>
                        Gender<span className='field-required'> *</span>
                      </div>
                      <ReactSelect
                        styles={DropDownStyle}
                        options={genderList}
                        getOptionLabel={(option) => {
                          return option?.label;
                        }}
                        getOptionValue={(option) => {
                          return option?.value;
                        }}
                        onChange={(selectedOption) => {
                          handleChange({
                            target: {
                              name: 'gender',
                              value: selectedOption.value,
                            },
                          });
                        }}
                        value={
                          genderList.find((option) => option?.value === employee?.gender) || null
                        }
                      />
                      <ErrorBlock
                        errorMsg={MY_TEAM_REQUIRED_FIELD_MSG.GENDER}
                        hasError={submitted && !employee?.gender}
                      />
                    </div>
                  )}
                  {hasExist('Date of Birth') && (
                    <div className='col-md-6' style={{ paddingLeft: '0px' }}>
                      <div>
                        <label className='mb-1 fs-13'>Date of Birth</label>
                        <input
                          type='date'
                          className='input-box'
                          max={today}
                          name='dob'
                          min={0}
                          value={employee?.dob}
                          placeholder='Enter PIN '
                          onChange={(event) => {
                            handleChange(event);
                          }}
                        />
                      </div>
                    </div>
                  )}
                </div>
                <div>
                  <label className='mb-1 fs-13'>
                    Employee Code <span className='field-required'> *</span>
                  </label>
                  <input
                    type='text'
                    className='input-box'
                    name='employee_code'
                    value={employee?.employee_code}
                    placeholder='Employee code'
                    onChange={(event) => {
                      handleChange(event);
                    }}
                  />
                  <ErrorBlock
                    errorMsg={MY_TEAM_REQUIRED_FIELD_MSG.EMPLOYEE_CODE}
                    hasError={submitted && employee?.employee_code.toString().trim() === ''}
                  />
                </div>
              </div>
              {hasExist('Address') && (
                <div className='col'>
                  <div className='form-group'>
                    <label htmlFor='address' className='mb-1'>
                      Address
                    </label>
                    <textarea
                      rows={4}
                      style={{ fontSize: '13px' }}
                      className='form-control'
                      id='address'
                      name='address'
                      value={employee?.address}
                      placeholder='Enter address '
                      onChange={(event) => {
                        handleChange(event);
                      }}
                    />
                  </div>
                </div>
              )}
            </div>
            <div className='row mb-2'>
              <div className='col-md-6'>
                <div>
                  <div className='mb-1'>
                    Department
                    <span style={{ color: 'black' }}> *</span>
                  </div>
                  <ReactSelect
                    name='department_id'
                    styles={{
                      control: (styles) => {
                        return {
                          ...styles,
                          minHeight: '33px',
                        };
                      },
                    }}
                    getOptionLabel={(option) => {
                      return option?.name;
                    }}
                    getOptionValue={(option) => {
                      return option?.id;
                    }}
                    onInputChange={(inputString) => {
                      setDepartmentSearch(inputString);
                    }}
                    isLoading={departmentLoading}
                    filterOption={null}
                    inputValue={departmentSearch}
                    options={departmentData?.data?.rows}
                    onChange={(selectedOption) => {
                      handleChange({
                        target: {
                          type: 'department',

                          name: 'department_id',
                          value: selectedOption,
                        },
                      });
                    }}
                    value={employee?.department_id}
                  />
                  <ErrorBlock
                    errorMsg={MY_TEAM_REQUIRED_FIELD_MSG.DEPARTMENT}
                    hasError={submitted && !employee?.department_id}
                  />
                </div>
              </div>
              <div className='col-md-6'>
                <div className=''>
                  <div className='mb-1'>
                    Designation
                    <span style={{ color: 'black' }}> *</span>
                  </div>
                  <ReactSelect
                    name='designation_id'
                    styles={{
                      control: (styles) => {
                        return {
                          ...styles,
                          minHeight: '33px',
                        };
                      },
                    }}
                    getOptionLabel={(option) => {
                      return option?.name;
                    }}
                    getOptionValue={(option) => {
                      return option?.id;
                    }}
                    onInputChange={(inputString) => {
                      setDesignationSearch(inputString);
                    }}
                    filterOption={null}
                    inputValue={designationSearch}
                    options={designationData?.data?.rows}
                    onChange={(selectedOption) => {
                      handleChange({
                        target: {
                          name: 'designation_id',
                          value: selectedOption,
                        },
                      });
                    }}
                    value={employee?.designation_id}
                    isLoading={designationLoading}
                  />
                  <ErrorBlock
                    errorMsg={MY_TEAM_REQUIRED_FIELD_MSG.DESIGNATION}
                    hasError={submitted && !employee?.designation_id}
                  />
                </div>
              </div>
            </div>
            <div className='row mb-2'>
              <div className='col-md-12'>
                <div className=''>
                  {/* Role selection section  */}
                  <div className='mb-1 '>
                    Role
                    <span style={{ color: 'black' }}> *</span>
                  </div>
                  <ReactSelect
                    name='role'
                    styles={{
                      control: (styles) => {
                        return {
                          ...styles,
                          minHeight: '33px',
                        };
                      },
                    }}
                    getOptionLabel={(option) => {
                      return option?.name;
                    }}
                    getOptionValue={(option) => {
                      return option?.number;
                    }}
                    filterOption={null}
                    options={roleData?.data}
                    onChange={(selectedOption) => {
                      handleChange({
                        target: {
                          name: 'role',
                          value: selectedOption.number,
                        },
                      });
                    }}
                    isLoading={roleLoading}
                    // value={employee?.role}
                    value={
                      roleData?.data?.find((option) => option.number === employee?.role) || null
                    } // Set the value based on project.company_id
                  />
                  <ErrorBlock
                    errorMsg={MY_TEAM_REQUIRED_FIELD_MSG.ROLE}
                    hasError={submitted && !employee?.role}
                  />
                  {/* End of role selection section  */}
                </div>
              </div>
            </div>
            <div className='row mb-2'>
              {hasExist('Email ID') && (
                <div className='col'>
                  <div>
                    <label className='mb-1 fs-13'>Email</label>
                    <input
                      type='email'
                      className='input-box'
                      name='email_id'
                      value={employee?.email_id}
                      placeholder='Enter email address '
                      onChange={(event) => {
                        handleChange(event);
                      }}
                    />
                  </div>
                </div>
              )}
              {hasExist('Contact Number') && (
                <div className='col'>
                  <div>
                    <label className='mb-1 fs-13'>Phone Number</label>
                    <input
                      type='text'
                      className='input-box'
                      name='phone_no'
                      value={employee?.phone_no}
                      placeholder='Enter phone number '
                      onChange={(event) => {
                        handleChange(event);
                      }}
                    />
                  </div>
                </div>
              )}
            </div>
            <div className='row mb-2'>
              {hasExist('Office Mail') && (
                <div className='col'>
                  <div>
                    <label className='mb-1 fs-13'>Office Mail</label>
                    <input
                      type='email'
                      className='input-box'
                      name='office_mail'
                      value={employee?.office_mail}
                      placeholder='Enter office mail address '
                      onChange={(event) => {
                        handleChange(event);
                      }}
                    />
                  </div>
                </div>
              )}
              {hasExist('Office Number') && (
                <div className='col'>
                  <div>
                    <label className='mb-1 fs-13'>Office Number</label>
                    <input
                      type='text'
                      className='input-box'
                      name='office_number'
                      value={employee?.office_number}
                      placeholder='Enter office phone number '
                      onChange={(event) => {
                        handleChange(event);
                      }}
                    />
                  </div>
                </div>
              )}
            </div>

            {/* custom fields section  */}
            <div className='row'>
              {customFields?.map((item, index) => {
                return hasExist(item?.label) ? (
                  <div className='col-md-6 mb-2' key={index}>
                    <div>
                      <label htmlFor={item?.label} className='mb-1 fs-13'>
                        {item?.label}
                      </label>
                      <input
                        type={item?.fieldType}
                        className='input-box'
                        id={item?.label}
                        name={item?.label}
                        value={employee?.custom_fields[item?.label]}
                        placeholder={'Enter ' + item?.label}
                        onChange={(event) => {
                          handleChangeCustomField(event);
                        }}
                      />
                    </div>
                  </div>
                ) : (
                  <></>
                );
              })}
            </div>

            {/* end of custom fields section  */}

            <div className='row mb-2'>
              <div className='col-md-6'>
                <div>
                  <label className='mb-1 fs-13'>
                    Username <span className='field-required'> *</span>
                  </label>
                  <input
                    type='text'
                    className='input-box'
                    name='username'
                    value={employee?.username}
                    placeholder='Username'
                    onChange={(event) => {
                      handleChange(event);
                    }}
                  />
                  <ErrorBlock
                    errorMsg={MY_TEAM_REQUIRED_FIELD_MSG.USERNAME}
                    hasError={submitted && employee?.username.toString().trim() === ''}
                  />
                </div>
              </div>
              <div className='col-md-6'>
                {id == 0 && (
                  <div>
                    <label htmlFor='password' className='mb-1'>
                      Password <span className='field-required'> *</span>
                    </label>
                    <div className='password-block'>
                      <div style={{ position: 'relative' }}>
                        <input
                          type={isPasswordVisible ? 'text' : 'password'}
                          className='input-box'
                          autoComplete='off'
                          id='password'
                          name='password'
                          placeholder='Password'
                          value={employee?.password}
                          onChange={(event) => {
                            handleChange(event);
                          }}
                        />
                        <a
                          className='password-icon'
                          onClick={() => setIsPasswordVisible((prev) => !prev)}
                        >
                          {isPasswordVisible ? (
                            <Eye width={18} height={18} />
                          ) : (
                            <EyeOff width={18} height={18} />
                          )}
                        </a>
                      </div>
                      <ErrorBlock
                        errorMsg={MY_TEAM_REQUIRED_FIELD_MSG.PASSWORD}
                        hasError={submitted && employee?.password.toString().trim() === ''}
                      />
                    </div>
                  </div>
                )}
              </div>{' '}
            </div>
          </div>
          <div className='col-md-6 d-flex justify-content-between flex-column'>
            <div>
              {/* report to selection section  */}
              <div className='col mb-2'>
                <div className='mb-1'>
                  Reported To
                  <span style={{ color: 'black' }}> *</span>
                </div>

                <ReactSelect
                  name='reported to'
                  styles={{
                    control: (styles) => {
                      return {
                        ...styles,
                        minHeight: '33px',
                      };
                    },
                  }}
                  formatOptionLabel={(data, metaData) => (
                    <UserDropDown data={data} metaData={metaData} />
                  )}
                  getOptionValue={(option) => {
                    return option?.id;
                  }}
                  onInputChange={(inputString) => {
                    setUserSearch(inputString);
                  }}
                  filterOption={null}
                  inputValue={userSearch}
                  options={filteredUserData}
                  onChange={(selectedOption) => {
                    handleReportingUserSelect(selectedOption);
                    setSelectedUser(null);
                  }}
                  value={selectedUser}
                />

                <ErrorBlock
                  errorMsg={MY_TEAM_ERROR_MSG.REPORTED_USERS}
                  hasError={submitted && employee?.reported_users?.length <= 0}
                />
              </div>
              {/* end of report to selection section  */}

              {/* Reported user table  */}
              <div className='col-md-12 mt-4  table_outer table-reportinguser'>
                <table className='table table-hover tbl mb-0'>
                  <thead>
                    <tr>
                      <th style={{ width: '300px' }}>Reporting User</th>
                      <th>Notification</th>
                      <th>Make Immediate</th>
                      <th></th>
                    </tr>
                  </thead>

                  <tbody>
                    {userLoading && <LoadingIcon size={20} />}

                    {employee?.reported_users?.map((item, index) => {
                      return (
                        <SelectedReportingUsers
                          key={item.id}
                          index={index}
                          item={item}
                          handleDelete={handleDelete}
                          handleReportingUserFeatureChange={handleReportingUserFeatureChange}
                        />
                      );
                    })}
                  </tbody>
                </table>
              </div>

              <ErrorBlock
                errorMsg={MY_TEAM_ERROR_MSG.IMMEDIATE_USER_REQUIRED}
                hasError={
                  submitted &&
                  employee?.reported_users?.length > 0 &&
                  employee?.reported_users?.every((user) => user?.is_immediate === 0)
                }
              />

              {/* end of Reported user table  */}
            </div>
          </div>
        </form>
      </div>
      <div className='mt-30px px-30px d-flex top-light-shadow justify-content-between align-items-center apply-btn-container'>
        <a
          className='reset-button'
          onClick={() => {
            setSubmitted(false);
            setIsLoading(false);

            const adminDetails = reportingUserFields(userData?.data?.admin, true);

            setEmployee((prev) => ({
              ...defaultEmployee,
              reported_users: [adminDetails],
            }));
          }}
        >
          Reset All
        </a>
        <div className='d-flex gap-2'>
          <Button.Container type='button' className='cancel-btn'>
            <Button.Title title='Cancel' />
          </Button.Container>
          <Button.Container type='submit' isDisabled={isLoading}>
            <Button.Title title={'Apply'} />
            <Button.Loading isLoading={isLoading} />
          </Button.Container>
        </div>
      </div>
    </>
  );
}

const SelectedReportingUsers = ({
  index,
  item,
  handleReportingUserFeatureChange,
  handleDelete,
}) => {
  return (
    <tr key={index}>
      <td>{item?.name}</td>
      <td>
        <ToggleButton
          id={index}
          isChecked={item?.is_enable_notification}
          size={30}
          handleToggle={(event) => {
            handleReportingUserFeatureChange(index, {
              target: {
                name: 'is_enable_notification',
                checked: event.target.checked,
              },
            });
          }}
        />
      </td>
      <td>
        <input
          className='form-check-input'
          type='radio'
          name='is_immediate'
          id={index}
          checked={item?.is_immediate}
          onChange={(event) => handleReportingUserFeatureChange(index, event)}
        />
      </td>
      <td>
        {!item.is_super_admin && (
          <Trash2
            style={{ width: '15px' }}
            onClick={() => {
              handleDelete(index);
            }}
          />
        )}
      </td>
    </tr>
  );
};
