/* eslint-disable no-unused-vars */
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import React, { useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { workflowService } from '../_services';

export function WorkflowHead() {
  const { wf_id } = useParams();
  const queryClient = useQueryClient();

  const [isEditing, setIsEditing] = useState({ name: false, description: false });
  const [workflow, setWorkflow] = useState({ name: '--', description: '--' }); // Default state with placeholder

  const nameInputRef = useRef(null); // Ref for the name input
  const descriptionTextareaRef = useRef(null); // Ref for the description textarea

  const handleFieldChange = (field, value) => {
    setWorkflow((prev) => ({ ...prev, [field]: value }));
  };

  const handleBlur = (field) => {
    setIsEditing((prev) => ({ ...prev, [field]: false }));
    handleMutate({ ...workflow, id: wf_id });
  };

  const handleKeyPress = (e, field) => {
    if (e.key === 'Enter') {
      handleBlur(field);
    }
  };

  const handleEditField = (field) => {
    setIsEditing((prev) => ({ ...prev, [field]: true }));
    setTimeout(() => {
      (field === 'name' ? nameInputRef.current : descriptionTextareaRef.current)?.focus();
    }, 0);
  };

  const getWorkflow = async () => {
    let workflowData = await workflowService.getSingleWorkflow({ id: wf_id });
    let data = workflowData?.data[0];
    setWorkflow({ name: data?.name || '--', description: data?.description || '--' });
    return data;
  };

  const { data, error, isLoading, isSuccess } = useQuery({
    queryKey: ['workflow-list', wf_id],
    queryFn: () => getWorkflow(),
  });

  // menu settings update
  const addWorkflow = async (data) => {
    await workflowService.addWorkflow(data);
  };

  // --react query fot mutation function
  const { mutate: handleMutate } = useMutation({
    mutationFn: addWorkflow,
  });

  return (
    <div
      style={{
        minHeight: '10vh',
        background: '#f1f1f1',
        width: '100%',
        padding: '3px 3px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        boxShadow: '0 1px 4px rgba(0, 0, 0, 0.1)',
      }}
    >
      {isLoading ? (
        <p>Loading...</p>
      ) : error ? (
        <p style={{ color: 'red' }}>Error loading workflow details.</p>
      ) : (
        <>
          {/* Name Field */}
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              background: isEditing.name ? '#e8e8e8' : 'transparent',
              padding: '3px 10px',
              borderRadius: '4px',
              cursor: 'pointer',
            }}
            onMouseEnter={(e) => (e.currentTarget.style.backgroundColor = '#e8e8e8')}
            onMouseLeave={(e) => (e.currentTarget.style.backgroundColor = 'transparent')}
            onClick={() => handleEditField('name')}
          >
            {isEditing.name ? (
              <input
                type='text'
                value={workflow.name}
                onChange={(e) => handleFieldChange('name', e.target.value)}
                onBlur={() => handleBlur('name')}
                onKeyPress={(e) => handleKeyPress(e, 'name')}
                ref={nameInputRef} // Attach ref to input
                style={{
                  fontSize: '18px',
                  fontWeight: 'bold',
                  color: '#333',
                  border: 'none',
                  outline: 'none',
                  width: '100%',
                  backgroundColor: '#f1f1f1',
                }}
              />
            ) : (
              <h2 style={{ fontSize: '18px', fontWeight: 'bold', color: '#333', margin: 0 }}>
                {workflow.name}
              </h2>
            )}
          </div>

          {/* Description Field */}
          <div
            style={{
              marginTop: '5px',
              background: isEditing.description ? '#e8e8e8' : 'transparent',
              padding: '5px 10px',
              borderRadius: '4px',
              cursor: 'pointer',
            }}
            onMouseEnter={(e) => (e.currentTarget.style.backgroundColor = '#e8e8e8')}
            onMouseLeave={(e) => (e.currentTarget.style.backgroundColor = 'transparent')}
            onClick={() => handleEditField('description')}
          >
            {isEditing.description ? (
              <textarea
                value={workflow.description}
                onChange={(e) => handleFieldChange('description', e.target.value)}
                onBlur={() => handleBlur('description')}
                onKeyPress={(e) => handleKeyPress(e, 'description')}
                ref={descriptionTextareaRef} // Attach ref to textarea
                style={{
                  fontSize: '14px',
                  color: '#555',
                  border: 'none',
                  outline: 'none',
                  width: '100%',
                  resize: 'none',
                  height: '40px',
                  backgroundColor: '#f1f1f1',
                }}
              />
            ) : (
              <p style={{ fontSize: '14px', color: '#555', margin: 0 }}>{workflow.description}</p>
            )}
          </div>
        </>
      )}
    </div>
  );
}
