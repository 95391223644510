/* eslint-disable no-unused-vars */
import { Mail, Navigation, Plus, Zap } from 'lucide-react';
import React, { useMemo } from 'react';
import { Handle } from 'reactflow';
import { Modal } from '../_components';
import { NodeAddComponent } from './NodeAddComponent';
import { ACTION_TYPE } from './workflow.constants';

export function ActionNode(props) {
  const { data, ...rest } = props;
  const [addModal, setAddModal] = React.useState(false);
  const labelText = data.label || 'NODE';
  const iconElement = <Zap size={18} />;
  const addIconNeed = data?.addIconNeed;

  console.log('data->', data.operation_details.template_details.label);

  const actionLabel = useMemo(() => {
    let action = '';
    if (data?.operation === ACTION_TYPE.MAIL) {
      action = 'Sent mail';
    }

    return action;
  }, []);

  return (
    <>
      <div className='nd-container'>
        <div className='nd-header'>
          <Navigation className='nd-header-icon' size={14} />
          <span className='nd-header-text'>Action</span>
        </div>

        <div>
          <div className='nd-content'>
            <Mail className='nd-content-icon' size={16} />
            <span className='nd-content-text'>{actionLabel}</span>
          </div>
          <div className='nd-divider'></div>
          <div className='nd-description'>
            Send mail using <span className='nd-description-bold'>sample</span> template
          </div>
        </div>

        <Handle type='target' position='top' id='trigger-output' className='nd-handle' />
        <Handle type='source' position='bottom' id='trigger-output' className='nd-handle' />

        {addIconNeed && (
          <div onClick={() => setAddModal(true)} className='nd-add-icon'>
            <Plus size={13} />
          </div>
        )}
      </div>
      {addModal && (
        <Modal.Container handleClose={() => setAddModal(false)}>
          <Modal.View className='modal-view-trigger' containerClass='view-end'>
            <NodeAddComponent
              rest={rest}
              data={data}
              addModal={addModal}
              setAddModal={setAddModal}
            />
          </Modal.View>
        </Modal.Container>
      )}
    </>
  );
}
