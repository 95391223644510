export function jsonToQueryString(json, encode = true) {
  let queryString = Object.keys(json)
    .map((key) => {
      if (key === 'select') {
        const value = encode ? json[key].join(',') : json[key].join('%2C');
        return `${encodeURIComponent(key)}=${encodeURIComponent(value)}`;
      } else if (Array.isArray(json[key])) {
        return json[key]
          .map((value) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
          .join('&');
      } else {
        return `${encodeURIComponent(key)}=${encodeURIComponent(json[key])}`;
      }
    })
    .join('&');

  return queryString;
}

export function errorToString(err) {
  if (typeof err === 'string') {
    return err;
  }

  if (typeof err?.message === 'string') {
    if (err instanceof TypeError && err.message.includes('NetworkError')) {
      return 'No internet connection. Please check your network settings.';
    }
    if (err instanceof TypeError) {
      return 'An unexpected error occurred. Please try again.';
    }
    if (err instanceof SyntaxError) {
      return 'There is a syntax error in the code. Please check your input.';
    }
    return err.message;
  }

  if (typeof err?.errorMessage === 'string') {
    return err.errorMessage;
  }

  return 'Something Went Wrong';
}
