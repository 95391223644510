import { useInfiniteQuery, useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import React, { useContext, useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import useChatUtils from '../Hook/useChatUtils';
import { STATUS } from '../Task/taskConstants';
import {
  extractUrls,
  filterNullValues,
  focusInputByClassName,
  getFileType,
  GlobalContext,
  sanitizeFileName,
} from '../Utils';
import useAlert from '../_components/ActionPopup/useAlert';
import {
  CHAT_TEXTAREA_CLASS_NAME,
  CHAT_UPDATE_TYPE,
  MESSAGE_TYPES,
  MIME_TYPE_MAPPING,
} from '../_constants/chat.constants';
import useDebounce from '../_helpers/useDebounce';
import { errorToString } from '../_helpers/utilFunctions';
import { miscService } from '../_services';
import { chatService } from '../_services/chat.service';
import ChatHeader from './ChatHeader';
import ChatInputBox from './ChatInputBox';
import ChatProvider from './ChatProvider';
import FileDragDrop from './FileDragDrop';
import InputLinkPreview from './InputLinkPreview';
import MediaPreview from './MediaPreview';
import MessageListWrapper from './MessageListWrapper';
import MessagesList from './MessagesList';
import ReplyContainer from './ReplyContainer';
import './chat.css';

const ChatContainer = ({
  containerClassName = '',
  listClassName = 'chat-content',
  headerVisibility,
  initialHeight = 0,
  type = CHAT_UPDATE_TYPE.TASK,
  activeUpdate,
  listPayload,
  updateRefetch = () => {},
  inputPayload,
  onMessageSendSuccess = () => {},
  isInTask = false,
}) => {
  console.log({ ChatType: location });

  const chatSelect = [
    'id',
    'message',
    'type',
    'files',
    'createdAt',
    'reactions_count',
    'reply_chat_id',
    'link_details',
  ];
  // const { makeAlert } = useContext(GlobalContext);
  const { makeActionAlert } = useAlert();
  const { makeAlert } = useContext(GlobalContext);
  const listBottomRef = useRef();
  const queryClient = useQueryClient();
  const messageListRef = useRef();
  // Put all the const payloads available on load here, It will bes passed along when fetching chat list
  const filteredPayloads = filterNullValues(listPayload); //Will avoid all blank values in the listPayload
  const filteredInputPayload = filterNullValues(inputPayload);
  const initChatPayload = Object.freeze({
    ...filteredInputPayload,
    message: '',
    is_you: true,
    files: [],
  });

  const [searchParam, setSearchParam] = useSearchParams();
  const [tempMessages, setTempMessages] = useState([]); //For saving the temp messages
  const [replyItem, setReplyItem] = useState(null);
  const [payloads, setPayloads] = useState([structuredClone(initChatPayload)]); //Chat payload send on message add

  const chatListQueryKey = ['chat-list', ...Object.values(filteredPayloads)];
  const { deleteFromUseQuery, recoverMessageFromUseQuery, updateMessage } = useChatUtils({
    chatListQueryKey,
    messages: tempMessages,
    onMessageUpdate: setTempMessages,
  });

  const debouncedText = useDebounce(payloads[0].message, 500);
  const urlsInInput = debouncedText ? extractUrls(debouncedText) : []; // Will fetch the url on input text

  const { mutateAsync, isPending } = useMutation({
    mutationFn: (payload) =>
      chatService.addChatMessage({
        payload: { ...payload, update_type: type, type: MESSAGE_TYPES.TEXT },
      }),
    onSuccess: async (response, payload) => {
      if (searchParam.has('chat_user_id')) {
        onMessageSendSuccess(response, payload, false);
        searchParam.delete('chat_user_id');
        searchParam.set('id', response.data.update_id);
        searchParam.set('tab', 'all');
        setSearchParam(searchParam);
        await queryClient.resetQueries({ queryKey: ['chat-update-list'] });
        return;
      } else {
        onMessageSendSuccess(response, payload);
      }
    },
  });

  const deleteMessageFromDb = async (item, deletedIndexObj, index) => {
    try {
      await chatService.deleteChatMessage({ payload: { id: item.id, isLastMsg: index === 0 } });
      updateRefetch();
    } catch (err) {
      makeActionAlert({ message: errorToString(err), isSuccess: false, showButton: false });
      recoverMessageFromUseQuery(deletedIndexObj, item);
    }
  };

  const getChatList = async (pageParam) => {
    const response = await chatService.getChatList({
      payload: {
        ...filteredPayloads,
        select: chatSelect,
        status: STATUS.ACTIVE,
        pageVo: {
          pageNo: pageParam,
          noOfItems: 10,
        },
        additional_offset: tempMessages.length,
        shouldUpdateNotifications: true,
      },
    });
    queryClient.invalidateQueries({ queryKey: ['chat-update-list'] });
    queryClient.invalidateQueries({ queryKey: ['update-tab-counts'] });
    return response;
  };

  // Main chat list
  const {
    data: chatList,
    isLoading,
    isSuccess: isChatMsgsSuccess,
    hasNextPage,
    isError,
    error,
    isFetchingNextPage,
    fetchNextPage,
    refetch,
  } = useInfiniteQuery({
    queryKey: chatListQueryKey,
    queryFn: ({ pageParam = 1 }) => {
      return getChatList(pageParam);
    },
    select: (response) => response.pages,
    getNextPageParam: (lastPage) =>
      lastPage.data.page < lastPage.data.pages ? lastPage.data.page + 1 : undefined,
    enabled: Object.keys(filteredPayloads).length > 0,
    // staleTime: Infinity,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false, //Later TODO: Only fetch if there is an error. Pass a function to check it.
  });

  const isNotEnabled = Object.keys(filteredPayloads).length <= 0;
  const isChatListError = isError || isNotEnabled;

  const getLatestMessages = async () => {
    const lastTempMsgId = tempMessages.find((item) => Boolean(item.id))?.id;
    const lastMsgListId = chatList[0].data.rows?.find((item) => Boolean(item.id))?.id;

    const lastMessageId = lastTempMsgId || lastMsgListId ? lastTempMsgId ?? lastMsgListId : 0;

    const response = await chatService.getLatestMessages({
      payload: { last_id: lastMessageId, select: chatSelect, ...filteredPayloads },
    });
    if (response.data.rows.length > 0) {
      scrollToBottom();
    }
    setTempMessages((prev) => [...response.data.rows, ...prev]);

    return response;
  };

  // For getting latest messages from other users
  useQuery({
    queryKey: ['latest-messages', ...Object.values(filteredPayloads)],
    queryFn: () => getLatestMessages(),
    select: (res) => res.data.rows,
    enabled: isChatMsgsSuccess,
    refetchInterval: 5000,
    refetchIntervalInBackground: true,
    staleTime: Infinity,
  });

  // On input change
  const handleChatInputChange = (event, index = 0) => {
    const { value } = event.target;
    setPayloads((prev) =>
      prev.map((payload, idx) => {
        if (idx === index) {
          return {
            ...payload,
            message: value,
          };
        }
        return payload;
      }),
    );
  };

  const scrollToBottom = () => {
    window.requestAnimationFrame(() => {
      if (listBottomRef.current) {
        listBottomRef.current.scrollIntoView({ block: 'end' });
      }
    });
  };

  // On file selection
  const handleFileChange = (files) => {
    const hasFileOnFirstPayload = payloads?.[0]?.files?.length > 0;
    // console.log(hasFileOnFirstPayload, payloads, 'CHECKFILEUPLOAD');

    if (!hasFileOnFirstPayload) {
      const filePayloads = files.slice(1).map((file) => {
        return { ...initChatPayload, files: [file] };
      });
      setPayloads((prev) => [{ ...prev[0], files: [files[0]] }, ...filePayloads]);
      return;
    }
    const filePayloads = files.map((file) => {
      return { ...initChatPayload, files: [file] };
    });
    setPayloads((prev) => [...prev, ...filePayloads]);
  };
  /***
   * Update temp Message status - Will be used to change the success, failed and upload status
   */
  const updateTempMessages = ({ index, updateObj, id }) => {
    setTempMessages((prev) =>
      prev.map((item, idx) =>
        (id ? id === item.tempId : index === idx) ? { ...item, ...updateObj } : item,
      ),
    );
  };
  /***
   * For updating the file status
   * @param {number} index - The index of the file to be updated
   * @param {Object} updateObj - The value to be added to the file
   * @param {number} tempId - The id of the chat in the tempMessages
   */
  const updateFileStatus = ({ index, updateObj, tempId }) => {
    // Check for the required msg in tempMessages and updated it with updateObj
    setTempMessages((prev) =>
      prev.map((item) => {
        if (item.tempId === tempId) {
          const updatedFiles = item.files.map((file, fileIndex) => {
            if (fileIndex === parseInt(index)) {
              return { ...file, ...updateObj };
            }
            return file;
          });

          return { ...item, files: updatedFiles };
        }
        return item;
      }),
    );
  };

  // Handle File Upload
  const handleFileUpload = async (payload) => {
    const files = payload.files;
    const successObj = { isFailed: false, isUploading: false, isSuccess: true };
    for (let index in files) {
      const file = files[index];
      // On retries, we don't want to upload the success files again
      if (!file?.isSuccess) {
        try {
          // upload file
          let signedData = await miscService.createSignedUploadUrl({
            type: file.fileType, //--video,image,audio
            ext: file.extension, //--jpg or mp4
            name: file.sanitizeName,
          });

          if (signedData?.data?.signedUrl) {
            let signedUrl = signedData.data.signedUrl;
            let fileName = signedData.data.filename;
            // TODO: Need to move this waterfall update to parallel upload later
            await fetch(signedUrl, {
              method: 'PUT',
              headers: { 'Content-Type': 'multipart/form-data' },
              body: file.file,
            });
            // Update the status
            file.uploaded_path = fileName;
            updateFileStatus({
              index,
              updateObj: { ...successObj, uploaded_name: fileName },
              tempId: payload.tempId,
            });
          }
        } catch (err) {
          updateFileStatus({
            index,
            updateObj: { isFailed: true, isUploading: false, isSuccess: false },
            tempId: payload.tempId,
          });
          throw err;
        }
      }
    }

    return { ...payload, files: payload.files.map((file) => ({ ...file, ...successObj })) };
  };

  /*** Will upload the files first, then save the chat with the text and files. */
  const uploadFileAndSaveChat = async (messageObjList) => {
    messageObjList.map(async (messageObj) => {
      try {
        const updatedPayload =
          messageObjList[0].files.length > 0 ? await handleFileUpload(messageObj) : messageObj;
        // Sending msg
        const response = await mutateAsync(updatedPayload);
        updateTempMessages({
          updateObj: {
            isSuccess: true,
            isFailed: false,
            isUploading: false,
            id: response?.data?.id,
            is_you: true,
          },
          id: messageObj.tempId,
        });
      } catch (err) {
        updateTempMessages({
          updateObj: { isSuccess: false, isFailed: true, isUploading: false, is_you: true },
          id: messageObj.tempId,
        });
      }
    });

    // handle file upload and return updated payload
  };

  // On chat submit
  const handleChatSubmit = async () => {
    const isPayloadInValid = payloads.some(
      (payload) => payload.files.length <= 0 && !payload.message.trim(),
    );

    if (isError || isPayloadInValid) {
      return;
    }

    const {
      first_name,
      last_name,
      gender = 1,
      image_url = '',
      id,
    } = JSON.parse(localStorage.getItem('user'));

    const currentTempMsgs = payloads.map((payload, idx) => {
      return {
        ...payload,
        tempId: new Date().getTime() + idx,
        createdAt: new Date(),
        type: MESSAGE_TYPES.TEXT,
        creator_details: { first_name, last_name, gender, image_url, id },
        isUploading: true,
        isFailed: false,
        isSuccess: false,
      };
    });

    // If contains reply, add it's id
    if (replyItem?.id) {
      currentTempMsgs[0].reply_chat_id = replyItem.id;
      currentTempMsgs[0].replyDetails = replyItem;
      setReplyItem(null);
    }

    setTempMessages((prev) => [...currentTempMsgs.reverse(), ...prev]);
    setPayloads([{ ...structuredClone(initChatPayload), files: [] }]);

    scrollToBottom();
    uploadFileAndSaveChat(currentTempMsgs);
  };
  /***CHances for hitting an error in mutation is less, since they will pause the mutation until the connection is restored
   * So the chances for appearing retry is less
   */
  const handleRetry = async (item) => {
    const updatedItem = [{ ...item, isUploading: true, isSuccess: false, isFailed: false }];
    updateTempMessages({
      updateObj: { isUploading: true, isSuccess: false, isFailed: false },
      id: item.tempId,
    });
    await uploadFileAndSaveChat(updatedItem);
  };

  const handleReply = (item) => {
    // focus the textarea
    focusInputByClassName(CHAT_TEXTAREA_CLASS_NAME);

    // eslint-disable-next-line no-unused-vars
    const { replyDetails, ...rest } = item;
    setReplyItem(rest);
  };

  const handleAttachmentRemove = (index) => {
    setPayloads((prev) =>
      prev.map((payload, idx) => {
        if (index === idx) {
          return { ...payload, files: [] };
        }
        return payload;
      }),
    );
  };

  const handleInputPaste = async (e) => {
    if (!e.clipboardData && !window.clipboardData) {
      return;
    }
    const items = (e.clipboardData || window.clipboardData)?.items;
    if (!items || items.length <= 0) {
      return;
    }

    const doesContainFile = Array.from(items).filter((item) => item.kind === 'file').length > 0;

    if (!doesContainFile) {
      return;
    }

    const filedDataList = Array.from(items)
      .map((item) => {
        const file = item.getAsFile();
        if (!file) {
          makeAlert('File not supported');
          return null;
        }

        const attachmentType = MIME_TYPE_MAPPING[file.type] || 2;
        return {
          name: file.name.replace(/[^\w.-]|[\s&]/g, ''),
          sanitizeName: sanitizeFileName(file.name),
          fileType: getFileType(file.name.split('.').pop()),
          size: file.size,
          extension: file.name.split('.').pop(),
          file: file,
          attachedType: attachmentType,
          isUploading: true,
          isFailed: false,
          isSuccess: false,
        };
      })
      .filter(Boolean);

    handleFileChange(filedDataList ?? []);
  };

  // functions to manage drag and drop

  const [isDragOver, setIsDragOver] = useState(false);

  const onDragSubmit = (files) => {
    const filedDataList = Array.from(files).map((file) => {
      const attachmentType = MIME_TYPE_MAPPING[file.type] || 2;
      return {
        name: file.name.replace(/[^\w.-]|[\s&]/g, ''),
        sanitizeName: sanitizeFileName(file.name),
        fileType: getFileType(file.name.split('.').pop()),
        size: file.size,
        extension: file.name.split('.').pop(),
        file: file,
        attachedType: attachmentType,
        isUploading: true,
        isFailed: false,
        isSuccess: false,
      };
    });

    handleFileChange(filedDataList ?? []);
  };

  const handleDrop = (event) => {
    event.preventDefault();
    setIsDragOver(false);
    const files = event.dataTransfer.files;
    if (files.length > 0) {
      onDragSubmit(files);
    }
  };

  const handleDragOver = (event) => {
    event.preventDefault();
    event.dataTransfer.dropEffect = 'copy';
    event.dataTransfer.effectAllowed = 'copyMove';
    !isDragOver && setIsDragOver(true);
  };

  const handleDragLeave = () => {
    isDragOver && setIsDragOver(false);
  };

  const onMediaPreviewClose = () => {
    setPayloads([
      {
        ...payloads[0],
        files: [],
      },
    ]);
  };

  return (
    <ChatProvider value={{ messageListRef, type }}>
      {location.pathname != '/chat' && (
        <section className='responsive-comments-container'>Comments</section>
      )}
      <div
        className={`col chat-bg chat-list-content p-0 ${containerClassName}`}
        style={{
          height: `max(calc(100vh - var(--top-bar-height) - 82px) , ${initialHeight}px - var(--top-bar-height))`,
        }}
        onDrop={handleDrop}
        onDragOver={handleDragOver}
        onDragLeave={handleDragLeave}
      >
        {/* drag and drop  */}
        <FileDragDrop isDragOver={isDragOver} />

        <div style={{ flex: 1, overflow: 'hidden' }} className='message-list-wrapper'>
          {/* Chat header */}
          {headerVisibility && (
            <ChatHeader activeUpdate={activeUpdate} updateRefetch={updateRefetch} />
          )}
          {/* Message list */}

          <MessageListWrapper
            error={error}
            isError={isError}
            isChatLoading={isLoading}
            listClassName={listClassName}
            hasPageError={isError && !chatList}
            refetchChat={refetch}
          >
            <MessagesList
              messageListRef={messageListRef}
              onReactionUpdate={updateMessage}
              isFetchingNextPage={isFetchingNextPage}
              listClassName={listClassName}
              chatList={chatList}
              recoverMessageFromUseQuery={recoverMessageFromUseQuery}
              deleteFromUseQuery={deleteFromUseQuery}
              tempMessages={tempMessages}
              listBottomRef={listBottomRef}
              onDelete={deleteMessageFromDb}
              onReply={handleReply}
              onTopReached={() => hasNextPage && fetchNextPage()}
              onReplySelect={handleReply}
              onRetry={handleRetry}
              handleFileChange={handleFileChange}
            />
          </MessageListWrapper>
        </div>
        <div className='chat-input-container'>
          {/* Reply container on input */}
          <ReplyContainer selectedItem={replyItem} onRemove={() => setReplyItem(null)} />
          {/* Link Preview */}

          <InputLinkPreview
            linkDetails={payloads[0]?.link_details}
            url={urlsInInput[0]}
            onLinkLoad={(metaData) => {
              setPayloads((prev) =>
                prev.map((payload, index) =>
                  index === 0 ? { ...payload, link_details: metaData } : payload,
                ),
              );
            }}
            onLinkRemove={() => {
              setPayloads((prev) =>
                prev.map((payload, index) =>
                  index === 0 ? { ...payload, link_details: null } : payload,
                ),
              );
            }}
          />

          {/* Input section */}
          <ChatInputBox
            onSubmit={handleChatSubmit}
            onFileChange={handleFileChange}
            onAttachmentRemove={handleAttachmentRemove}
            onInputChange={handleChatInputChange}
            initialObject={payloads[0]}
            isPending={isPending}
            onPaste={handleInputPaste}
            isChatListError={isChatListError}
            isInTask={isInTask}
          />
        </div>
        {/* Media preview if files are selected, will avoid recorded audio, since they have another UI */}
        {payloads[0].files?.filter((item) => item.attachedType !== MESSAGE_TYPES.RECORDED_AUDIO)
          .length > 0 && (
          <MediaPreview
            files={payloads.flatMap((payload) => payload.files)}
            isInput
            createdTime={payloads[0]?.createdAt}
            onInputChange={handleChatInputChange}
            onSubmit={handleChatSubmit}
            payloads={payloads}
            isLocal
            isPrevBeforeSend={payloads[0].files.length > 0}
            onDelete={(idx, fileCount) =>
              fileCount <= 0
                ? onMediaPreviewClose()
                : setPayloads((prev) => prev.filter((_, index) => idx !== index))
            }
            onFileChange={handleFileChange}
            onClose={onMediaPreviewClose}
          />
        )}
      </div>
    </ChatProvider>
  );
};

export default ChatContainer;
