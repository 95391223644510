/* eslint-disable no-unused-vars */
import React from 'react';
import { UpdatesListItem } from '../Updates/UpdatesListItem';

const UpdateNotification = ({ data, queryPayload }) => {
  return (
    <>
      <UpdatesListItem updatesList={data} queryPayload={queryPayload} />
    </>
    // <div>
    //   {data?.length ? (
    //     data?.map((item, index) => {
    //       // const currentColor = bgColors[randomIntFromInterval(0, 3)];

    //       return (
    //         <UpdateNotificationItem
    //           key={index}
    //           isFUllView={isFUllView}
    //           count={count}
    //           currentColor={currentColor}
    //           data={item}
    //           setIsNotificationVisible={setIsNotificationVisible}
    //         />
    //       );
    //     })
    //   ) : (
    //     <CustomEmptyContainer
    //       Icon={() => {
    //         return (
    //           <div className='notification-icon-empty-container'>
    //             <RefreshCcw size={19} color='#87909E' />
    //           </div>
    //         );
    //       }}
    //       text={
    //         isUnread
    //           ? NOTIFICATION_EMPTY_TEXTS.UPDATES_UNREAD
    //           : NOTIFICATION_EMPTY_TEXTS.UPDATES_UNREAD
    //       }
    //       disableAdd
    //     />
    //   )}
    // </div>
  );
};

export default UpdateNotification;
