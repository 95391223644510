import React from 'react';
import { useParams } from 'react-router-dom';
import TimeBoxCalendar from '../Calander/TimeBoxCalendar';

const ProjectCalendar = () => {
  const { id } = useParams();

  return (
    <div className='mt-2'>
      <TimeBoxCalendar
        defaultPayload={{ project_id: id }}
        isNeedProject={false}
        isNeedTemplate={true}
      />
    </div>
  );
};

export default ProjectCalendar;
