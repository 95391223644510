import { apiConstants } from '../_constants';
import { authHeader, handleResponse } from '../_helpers';
import { jsonToQueryString } from '../_helpers/utilFunctions';
export const aiService = {
  aiGet,
  generateDescription,
  generateResponse,
  aiChatAdd,
  aiChatList,
};

function aiChatAdd(data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(data),
  };
  return fetch(apiConstants.apiUrl + '/demo_add', requestOptions).then(handleResponse);
}

function aiChatList(data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(data),
  };
  return fetch(apiConstants.apiUrl + '/ai-chat-list', requestOptions).then(handleResponse);
}

function aiGet(data) {
  const params = jsonToQueryString(data);
  const apiUrl = `${apiConstants.apiUrl}/ai_chat?${params}`;
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };
  return fetch(apiUrl, requestOptions).then(handleResponse);
}

function generateDescription(data) {
  const apiUrl = `${apiConstants.apiUrl}/generate-task-description`;
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(data),
  };
  return fetch(apiUrl, requestOptions).then(handleResponse);
}
function generateResponse(data) {
  const apiUrl = `${apiConstants.apiUrl}/generate-ai-response`;
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(data),
  };
  return fetch(apiUrl, requestOptions).then(handleResponse);
}
