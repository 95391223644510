import { useIsMutating, useMutation } from '@tanstack/react-query';
import React, { useContext, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Button } from '../../_components';
import { settingsService } from '../../_services';
import { GlobalContext } from '../../Utils';
import { Cancel, GmailIcon, Tick } from '../../Utils/SvgIcons';
import ConfigApiForm from './ConfigApiForm';
import { defaultGmailState, EMAIL_CONFIG_TABS } from './configitems';

export default function GmailConfigSettings() {
  const { makeAlert, globalSettings, globalSettingsRefetch } = useContext(GlobalContext);
  const configSettings =
    globalSettings &&
    globalSettings.length &&
    globalSettings.find((settings) => settings?.name === 'config-settings');

  console.log({ configSettings });

  const isMutating = useIsMutating();

  const [hasChanged, setHasChanged] = useState(false);

  const [searchParams, setSearchParams] = useSearchParams();
  const tabName = searchParams.get('sub');
  const apiTabName = searchParams.get('api_tab');
  // config state - gmail
  const [gmailConfig, setGmailConfig] = useState(
    configSettings?.labels?.gmail || defaultGmailState,
  );
  // change tab handler
  const changeTab = (name) => {
    searchParams.set('sub', name);
    setSearchParams(searchParams, { replace: true });
  };

  // change tab handler
  const changeAPITab = (name) => {
    searchParams.set('api_tab', name);
    setSearchParams(searchParams, { replace: true });
  };

  // handle for add header -- add additional header properties
  const handleAddHeader = () => {
    const headerCopy = gmailConfig?.api?.headers;
    headerCopy.push({ key: '', value: '' });
    setGmailConfig((prev) => ({
      ...prev,
      api: {
        ...prev.api,
        headers: headerCopy,
      },
    }));
  };

  // delete the existing header if no need by index
  const handleDeleteHeader = (index) => {
    const headerCopy = gmailConfig?.api?.headers;
    headerCopy.splice(index, 1);
    setGmailConfig((prev) => ({
      ...prev,
      api: {
        ...prev.api,
        headers: headerCopy,
      },
    }));
  };

  // On change handler for header values - key and value
  const handleHeaderChange = (index, key, value) => {
    setGmailConfig((prev) => {
      const updatedHeaders = prev.api.headers.map((header, i) => {
        if (i === index) {
          return { ...header, [key]: value }; // Update the specific header object
        }
        return header; // Return the unchanged header
      });
      return {
        ...prev,
        api: {
          ...prev.api,
          headers: updatedHeaders, // Set the updated headers array
        },
      };
    });
  };

  // on input change handler
  const onChange = (e) => {
    const { name, value } = e.target;
    setGmailConfig((prev) => ({
      ...prev,
      api: {
        ...prev.api,
        [name]: value,
      },
    }));
  };

  // on input change handler for smtp details
  const onChangeSMTPData = (e) => {
    const { name, value } = e.target;
    setGmailConfig((prev) => ({
      ...prev,
      smtp: {
        ...prev.smtp,
        [name]: value,
      },
    }));
  };

  // config settings update
  const handleSettingUpdate = async (data) => {
    console.log('HERE', data);

    let newQuery = {};
    newQuery.labels = { ...configSettings?.labels, gmail: data };
    newQuery.name = 'config-settings';
    await settingsService.settingsAdd(newQuery);
    makeAlert('Success');
    setHasChanged(false);

    globalSettingsRefetch();
    return newQuery;
  };

  // --react query for mutation function
  const { mutate: handleMutate } = useMutation({
    mutationFn: handleSettingUpdate,
  });

  return (
    <div className='config_layout'>
      <div className='config_heading_container'>
        <section className='icon_container ps-1'>
          <GmailIcon height={20} width={29} />
        </section>
        <span className='general_head'>Email</span>
      </div>
      <section className='task-menu task-single-tabs m-0  config_heading_container'>
        {EMAIL_CONFIG_TABS.map((tab) => {
          return (
            <div
              key={tab.name}
              className={`config_child_tab ${tabName === tab.value ? ' child_tab_active' : ''}`}
              onClick={() => changeTab(tab.value)}
              role='button'
            >
              {tab.name}
            </div>
          );
        })}
      </section>
      <div>
        {tabName === 'smtp' && (
          <>
            <section className='d-flex gap-2 mt-2'>
              <div className='form-group col-md-9'>
                <label htmlFor='smtp server' className='label-text'>
                  SMTP Server
                </label>
                <input
                  type='text'
                  className='input-box border-f0'
                  name='ip'
                  placeholder={`Enter Ip address`}
                  value={gmailConfig?.smtp?.ip}
                  onChange={onChangeSMTPData}
                />
              </div>
              <div className='form-group col'>
                <label htmlFor='port' className='label-text'>
                  Port
                </label>
                <input
                  type='text'
                  className='input-box border-f0'
                  style={{ height: '34px' }}
                  name='port'
                  placeholder={`Enter port`}
                  value={gmailConfig?.smtp?.port}
                  onChange={onChangeSMTPData}
                />
              </div>
            </section>
            <section className='d-flex gap-2 mt-2'>
              <div className='form-group col'>
                <label htmlFor='mail-from' className='label-text'>
                  Mail from
                </label>
                <input
                  type='email'
                  className='input-box border-f0'
                  style={{ height: '34px' }}
                  name='mail'
                  placeholder={`Enter mail`}
                  value={gmailConfig?.smtp?.mail}
                  onChange={onChangeSMTPData}
                />
              </div>
            </section>
            <section className='d-flex gap-2 mt-2'>
              <div className='form-group col'>
                <label htmlFor='mail-from' className='label-text'>
                  Username
                </label>
                <input
                  type='text'
                  className='input-box border-f0'
                  style={{ height: '34px' }}
                  name='username'
                  placeholder={`Enter username`}
                  value={gmailConfig?.smtp?.username}
                  onChange={onChangeSMTPData}
                />
              </div>
            </section>
            <section className='d-flex gap-2 mt-2'>
              <div className='form-group col'>
                <label htmlFor='mail-from' className='label-text'>
                  Password
                </label>
                <input
                  type='text'
                  className='input-box border-f0'
                  style={{ height: '34px' }}
                  name='password'
                  placeholder={`Enter password`}
                  value={gmailConfig?.smtp?.password}
                  onChange={onChangeSMTPData}
                />
              </div>
            </section>
          </>
        )}
        {tabName === 'api' && (
          <ConfigApiForm
            config={gmailConfig.api}
            handleDeleteHeader={handleDeleteHeader}
            handleAddHeader={handleAddHeader}
            tabName={apiTabName}
            changeTab={changeAPITab}
            handleHeaderChange={handleHeaderChange}
            onChange={onChange}
          />
        )}
        <section className='mt-2 d-flex justify-content-end gap-1'>
          <Button.Container handleOk={() => {}} className={'cancel-btn'}>
            <Cancel />
            <Button.Title title={'Delete'} />
          </Button.Container>
          <Button.Container isDisabled={hasChanged} handleOk={() => handleMutate(gmailConfig)}>
            <Tick />
            <Button.Title title={'Update'} />
            <Button.Loading isLoading={isMutating} />
          </Button.Container>
        </section>
      </div>
    </div>
  );
}
