import React from 'react';

function YearView({ year, setYear, setStatus, setSelectedDate, gridCol }) {
  const startYear = year - (year % 10);
  const endYear = startYear + 9;

  const arrayRange = (start, stop, step) =>
    Array.from(
      { length: (stop - start) / step + 1 },
      (value, index) => start + index * step,
    );

  return (
    <div
      className="view year-view"
      style={{ gridTemplateColumns: `repeat(${gridCol}, 1fr)` }}
    >
      {arrayRange(startYear, endYear, 1).map((yr, index) => (
        <a
          onClick={() => {
            setSelectedDate(prev => ({ ...prev, year: yr }));
            setStatus('month');
            setYear(yr);
          }}
          className={`${year === yr ? 'selected' : ''} block`}
          key={index}
        >
          {yr}
        </a>
      ))}
    </div>
  );
}

export default YearView;
