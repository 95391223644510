/* eslint-disable no-unused-vars */
import { ArrowLeft } from 'lucide-react';
import React, { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import IconLabelSet from '../../_components/IconLabelSet';
import { RT } from '../../_constants';
import { authService } from '../../_services';
import { GlobalContext } from '../../Utils';

const EmailInput = ({ setValue, email, setEmail }) => {
  let navigate = useNavigate();
  const { makeAlert } = useContext(GlobalContext);

  const [error, setError] = useState();
  const [submitted, setSubmitted] = useState(false);
  const handleChange = (e) => {
    const { name, value } = e.target;

    setEmail({
      [name]: value,
    });
  };

  //function use to sent otp to mail
  const sentOtpMail = async (e) => {
    e.preventDefault();
    setSubmitted(true);
    if (email?.email.toString().trim() == '') {
      return;
    }

    setTimeout(() => {
      setError('');
    }, 3000);
    try {
      const response = await authService.sentOtpMail(email);

      if (response.error_code == 0) {
        setValue(1);
      }
    } catch (error) {
      if (error.message) {
        setError(error.message);
        makeAlert(error.message);
      }
    } finally {
      setSubmitted(false);
    }
  };

  return (
    <div>
      <div className='login_header_contain'>
        {' '}
        <div className='login_company_icon_contain'>
          <img src='/logo.svg' />
        </div>
      </div>
      <div className='login_body_container'>
        <div className='row d-flex' style={{ gap: '100px' }}>
          <div className='col-md-5'>
            <div className='mt-5'>
              <div>
                <div className='login-welcome-txt'>Forgot Password?</div>
                <div className='forgot_second_txt'>
                  All good. Enter your account’s and we’ll send you a link to reset your password
                </div>
              </div>

              <form name='form' onSubmit={sentOtpMail}>
                <div className='card_Container mt-3'>
                  <div className={'form-group'}>
                    <label className='mt-2 login_label mb-1' htmlFor='username'>
                      Email
                    </label>
                    <input
                      type='email'
                      className={
                        'form-control inputbox ' + (submitted && !email.email ? ' has-error' : '')
                      }
                      // className='form-control inputbox'
                      name='email'
                      //   value={user.username}
                      onChange={handleChange}
                    />
                    {/* {submitted && !user.username && (
                      <div className='help-block'>Username is required</div>
                    )} */}
                  </div>
                  <div className='form-group   mt-3'>
                    <button className='btn login_Button width-100'> Send OTP</button>
                  </div>
                  <div className='mt-2'>
                    <div className='d-flex align-items-center justify-content-center'>
                      <IconLabelSet
                        fontSize={13}
                        fontWeight={500}
                        className='cursor-pointer'
                        color={'#1E3DB9'}
                        icon={<ArrowLeft size={14} />}
                        label={'Return to login'}
                        onClick={() => navigate(`/${RT.LOGIN}`)}
                      />
                    </div>
                  </div>
                  <div className='d-flex justify-content-center error-txt'>{error}</div>{' '}
                </div>
              </form>
            </div>
          </div>
          <div className='col-md-5'>
            <div className='login_img_container'>
              <img src='/icons/forgotpasswordemail.png' style={{ height: '450px' }} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EmailInput;
