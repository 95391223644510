import { useSearchParams } from 'react-router-dom';
/* eslint-disable no-unused-vars */
import { useQuery } from '@tanstack/react-query';
import React, { useContext, useRef, useState } from 'react';

import { Mail, Pen, Plus, Trash } from 'lucide-react';
import { Table, Tbody, Td, Th, Thead, Tr } from 'react-super-responsive-table';
import { Button, Modal, PaginationComponent, TableRow } from '../_components';
import { SearchBar } from '../_components/Search';
import ToolTip from '../_components/Tooltip/ToolTip';
import { DELETE_NOTE } from '../_constants/deletenote.constants';
import { emailTemplateService } from '../_services/emailTemplate.service';
import { STATUS } from '../Task/taskConstants';
import { GlobalContext, Images } from '../Utils';
import { Calendar, Cancel, LoadingIcon, StatusIcon, Tick } from '../Utils/SvgIcons';
import EmailTemplateForm from './EmailTemplateForm';

const TABLE_COLUMN = ['Sl.No', 'Name', 'Type', 'Status', 'Created At'];

export function EmailTemplateList() {
  const TABLE_HEADINGS = [
    { name: <img src={Images.SerialNo} height={14} width={14} />, minWidth: 50 },
    {
      name: (
        <div className='d-flex align-items-center gap-1'>
          <Mail size={15} stroke='var(--second-text-color)' /> Name
        </div>
      ),
      minWidth: '10vw',
      maxWidth: '20vw',
    },
    {
      name: (
        <div className='d-flex align-items-center gap-1'>
          <Mail size={15} stroke='var(--second-text-color)' /> Type
        </div>
      ),
      minWidth: 50,
    },
    {
      name: (
        <div className='d-flex align-items-center gap-1'>
          <StatusIcon /> Status
        </div>
      ),
      minWidth: 50,
    },
    {
      name: (
        <div className='d-flex align-items-center gap-1'>
          <Calendar height={14} width={14} />
          Created on
        </div>
      ),
      minWidth: 50,
    },

    {},
  ];

  const { makeAlert } = useContext(GlobalContext);

  const [searchParams, setSearchParams] = useSearchParams();
  const [selectedId, setSelectedId] = useState(null);

  const [addModal, setAddModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);

  const [view, setView] = useState('add');
  // useState section
  const [query, setQuery] = useState({
    select: ['id', 'name', 'createdAt', 'updatedAt', 'status', 'type'],
    searchKey: '',
    pageVo: {
      pageNo: parseInt(searchParams.get('pageNo')) || 1,
      noOfItems: 10,
    },
    // status: 1,
  });

  // reference section
  const tableContainerRef = useRef(null);
  const reference = useRef(null);

  const modalClose = () => {
    setDeleteModal(false);
    setSelectedId(null);
  };

  // section trigger when delete option selected

  const handleDeactivate = async () => {
    try {
      await emailTemplateService.emailTemplateDelete({ id: selectedId });
      let isNeedToBack =
        emailTemplateData?.data?.rows.length === 1 && emailTemplateData?.data?.pages > 1;

      if (isNeedToBack) {
        const page = searchParams.get('pageNo');
        searchParams.set('pageNo', page - 1);
        setSearchParams(searchParams);
        setQuery((prev) => ({
          ...prev,
          pageVo: {
            ...prev.pageVo,
            pageNo: prev.pageVo.pageNo - 1,
          },
        }));
      } else {
        refetch();
      }
      // await queryClient.invalidateQueries('projectList');
      modalClose();

      makeAlert('Deleted Successfully');
    } catch (error) {
      makeAlert(error?.message || 'Failed to delete');

      console.error('Error deleting project :', error);
    }
  };

  // email template list API call section
  const {
    data: emailTemplateData,
    error,
    isLoading,
    isSuccess,
    refetch,
  } = useQuery({
    queryKey: ['email-template-list', query?.pageVo?.pageNo],
    queryFn: () => emailTemplateService.emailTemplateList(query),
  });

  const emailTemplateRows = emailTemplateData?.data?.rows?.map((workflow, index) => {
    const slNo = (emailTemplateData?.data?.page - 1) * emailTemplateData?.data?.limit + index + 1;
    return (
      <Tr key={index}>
        <Td>{slNo}</Td>
        <Td>{workflow?.name}</Td>
        <Td>{workflow?.type}</Td>
        <Td>{workflow?.status === STATUS.ACTIVE ? 'Active' : 'In-Active'}</Td>
        <Td>
          {new Date(workflow?.createdAt).toLocaleDateString('en-US', {
            month: 'short',
            day: 'numeric',
            year: 'numeric',
          })}
        </Td>
        <Td
          style={{ verticalAlign: 'middle', cursor: 'pointer' }}
          ref={reference}
          onClick={(event) => {
            event.stopPropagation();
          }}
        >
          <div className='d-flex gap-2 justify-content-end icon-div invisible'>
            <ToolTip
              tooltipText={`Edit email template`}
              isModern
              popOver
              scrollRef={tableContainerRef}
            >
              <div
                className='icon-bg'
                onClick={(event) => {
                  event.stopPropagation();
                  setSelectedId(workflow?.id), setAddModal(true);
                  // setEditProjectId(project?.id);
                }}
              >
                <Pen size={15} />
              </div>
            </ToolTip>

            <ToolTip
              tooltipText={`Delete email template`}
              isModern
              popOver
              scrollRef={tableContainerRef}
            >
              <div
                className='icon-bg'
                onClick={(event) => {
                  event.stopPropagation();
                  setSelectedId(workflow?.id);
                  setDeleteModal(true);
                }}
              >
                <Trash size={15} />
              </div>
            </ToolTip>
          </div>
        </Td>
      </Tr>
    );
  });
  const closeModal = () => {
    setAddModal(false);
  };
  const listItemCount = emailTemplateData?.data?.rows.length;

  return (
    <>
      <div className='client-list-container'>
        {/* <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <div
            style={{
              background: '#d2d2d2',
              padding: '3px 9px',
              borderRadius: 4,
              marginTop: 15,
              cursor: 'pointer',
            }}
            onClick={() => setAddModal(true)}
          >
            Create
          </div>
        </div> */}
        <div className='tab_contain '>
          <SearchBar
            handleSearch={(event) => {
              searchParams.set('pageNo', 1);
              setSearchParams(searchParams);
              setQuery({
                ...query,
                searchKey: event.target.value,
                pageVo: { ...query.pageVo, pageNo: 1 },
              });
            }}
            searchKey={query.searchKey}
          />

          <div className='d-flex gap-2'>
            <ToolTip tooltipText={`Add Email Template`} isModern>
              <div
                className='menu-item-outline'
                onClick={() => {
                  setAddModal(true);
                }}
              >
                <Plus size={16} color='#87909E' />
              </div>
            </ToolTip>
          </div>
        </div>

        <section className='mt-2'>
          <div
            className='project-table table-container no-padding'
            // eslint-disable-next-line no-undef
            // ref={tableContainerRef}
            style={{ maxHeight: '65vh' }}
          >
            <Table className='table table-hover tbl mb-0'>
              <Thead>
                <Tr className='table-head'>
                  {TABLE_HEADINGS.map((heading, index) => {
                    return (
                      <Th
                        key={index}
                        scope='col'
                        style={{ minWidth: heading.minWidth, maxWidth: heading.maxWidth }}
                      >
                        {heading.name}
                      </Th>
                    );
                  })}
                </Tr>
              </Thead>
              <Tbody style={{ verticalAlign: 'middle' }}>
                {isLoading && (
                  <TableRow noOfCols={8}>
                    <LoadingIcon size={60} />
                  </TableRow>
                )}
                {!listItemCount && isSuccess && <TableRow noOfCols={8}>No data found</TableRow>}
                {listItemCount > 0 && isSuccess && emailTemplateRows}
              </Tbody>
            </Table>
          </div>
        </section>

        {listItemCount > 0 && (
          <section className='mt-2'>
            <PaginationComponent
              pageOnClick={(data) => {
                setQuery((prev) => ({
                  ...prev,
                  pageVo: {
                    ...prev.pageVo,
                    pageNo: data,
                  },
                }));
                searchParams.set('pageNo', data);
                setSearchParams(searchParams);
              }}
              demoData={emailTemplateData?.data}
            />
          </section>
        )}
      </div>
      {addModal && (
        <Modal.Container handleClose={() => closeModal()}>
          <Modal.View className='modal-view-trigger ' containerClass='view-end'>
            <EmailTemplateForm
              selectedId={selectedId}
              onClose={() => {
                closeModal(), setSelectedId();
              }}
            />
          </Modal.View>
        </Modal.Container>
      )}

      {deleteModal && (
        <Modal.Container>
          <Modal.View className={'status-delete'}>
            <Modal.Head handleClose={() => modalClose()} Heading={'Delete Confirmation'} />
            <Modal.Description>
              <img src={Images.DeleteBin} width={35} height={50} alt='' className='mb-3' />
              <div className='del-description'>{DELETE_NOTE.COMMON}</div>
            </Modal.Description>
            <div className='modal_footer'>
              <Button.Container handleOk={() => modalClose()} className={'status-del-cancel'}>
                <Cancel /> <Button.Title title={'Cancel'} />
              </Button.Container>
              <Button.Container handleOk={() => handleDeactivate()}>
                <Tick /> <Button.Title title={'Delete'} />
              </Button.Container>
            </div>
          </Modal.View>
        </Modal.Container>
      )}
    </>
  );
}
