/* eslint-disable no-unused-vars */
import { Play, Trash } from 'lucide-react';
import moment from 'moment';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { Td, Tr } from 'react-super-responsive-table';
import { Button, Modal } from '../_components';
import { DELETE_NOTE } from '../_constants/deletenote.constants';
import { getHoursAndMinute } from '../Task/taskValidation';
import { GlobalContext, Images } from '../Utils';
import {
  calculateHourDifference,
  convertMillisecondsToHoursAndMinutes,
  convertMillisecondsToTimeDetails,
} from './timesheetMisc';

export const TimeSheetTableBody = ({
  timesheetData,
  isEditable = false,
  setTimeSheetData = () => {},
}) => {
  const { makeAlert, roleBasedPermission } = useContext(GlobalContext);
  const [expandedRows, setExpandedRows] = useState([]);
  const [editableFromDate, setEditableFromDate] = useState(false);
  const [editableToDate, setEditableToDate] = useState(false);
  const [editDateModalData, setEditDateModalData] = useState(false);
  const [isRemoveModal, setIsRemoveModal] = useState({
    isRemove: false,
    index: null,
    isLog: false,
    logIndex: null,
  });
  const [contextTime, setContextTime] = useState({ isEdit: false, index: null });
  const [contextParentTime, setContextParentTime] = useState({ isEdit: false, index: null });

  const [timesheetRows, setTimesheetRows] = useState(timesheetData ?? []); // remove this timesheet rows later
  const inputRefFromDate = useRef(null); // Add a ref for the input
  const inputRefToDate = useRef(null); // Add a ref for the input

  useEffect(() => {
    setTimesheetRows(timesheetData);
  }, [timesheetData]);

  const handleClickOutside = (event) => {
    // Check if the click is outside both input fields and the expanded rows
    if (inputRefFromDate.current && !inputRefFromDate.current.contains(event.target)) {
      setEditableFromDate(false); // Set to false when clicking outside
    }
    if (inputRefToDate.current && !inputRefToDate.current.contains(event.target)) {
      setEditableToDate(false); // Set to false when clicking outside
    }
    // Add additional check for expanded rows if necessary
    // Example: if (expandedRows.some(rowId => ...)) { ... }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleToggleRow = (id) => {
    if (expandedRows.includes(id)) {
      // Remove id from expandedRows
      let newExpandedRows = expandedRows.filter((expandedId) => expandedId !== id);
      setExpandedRows(newExpandedRows);
    } else {
      // Add id to expandedRows
      const newExpandRows = [...expandedRows, id];
      setExpandedRows(newExpandRows);
    }
    // setIsExpanded(!isExpanded) // Uncomment if you want to manage expanded state
  };
  console.log('timesheeeeeet', timesheetData);

  // const handleChangeDate = (e, index, idx) => {
  //   const { name, value } = e.target;
  //   const tempTimeRows = [...timesheetRows];

  //   const newDateTimeValue = moment(value).valueOf();

  //   // // Update the respective date field based on the input name
  //   let secondDate;
  //   if (name === 'start_time') {
  //     if (newDateTimeValue >= tempTimeRows[index].time_logs[idx].end_time) {
  //       makeAlert('Start Time should be before End Time');
  //       return;
  //     }
  //     tempTimeRows[index].time_logs[idx].start_time = newDateTimeValue; // Update fromDate
  //     secondDate = tempTimeRows[index].time_logs[idx].end_time;
  //   } else if (name === 'end_time') {
  //     if (newDateTimeValue <= tempTimeRows[index].time_logs[idx].start_time) {
  //       makeAlert('End Time should be after Start Time');
  //       return;
  //     }
  //     tempTimeRows[index].time_logs[idx].end_time = newDateTimeValue; // Update toDate
  //     secondDate = tempTimeRows[index].time_logs[idx].start_time;
  //   }
  //   let newTimeDiff = calculateHourDifference(newDateTimeValue, secondDate);
  //   let mainTotalHours =
  //     tempTimeRows[index].total_hours -
  //     tempTimeRows[index].time_logs[idx].total_hours +
  //     newTimeDiff;
  //   tempTimeRows[index].time_logs[idx].billing_hours = newTimeDiff;
  //   tempTimeRows[index].time_logs[idx].total_hours = newTimeDiff;
  //   tempTimeRows[index].total_hours = mainTotalHours;
  //   tempTimeRows[index].billing_hours = mainTotalHours;
  //   setTimesheetRows(tempTimeRows);
  // };

  // handler for remove the timesheet items by index and log

  const handleChangeDate = (e, index, idx) => {
    const { name, value } = e.target; // Get input name (start_time or end_time) and value
    const tempTimeRows = [...timesheetRows]; // Create a copy of timesheet rows

    const newDateTimeValue = moment(value).valueOf(); // Convert input value to timestamp

    // Get the current time log for validation
    const currentLog = tempTimeRows[index].time_logs[idx];
    const isStartDate = name === 'start_time';

    // Validation: Ensure that start_time is before end_time and vice versa
    if (isStartDate && newDateTimeValue >= currentLog.end_time) {
      makeAlert('Start Time should be before End Time');
      return; // Exit without updating if validation fails
    }
    if (!isStartDate && newDateTimeValue <= currentLog.start_time) {
      makeAlert('End Time should be after Start Time');
      return; // Exit without updating if validation fails
    }

    // Update the respective date field
    if (isStartDate) {
      tempTimeRows[index].time_logs[idx].start_time = newDateTimeValue; // Update From Date
    } else {
      tempTimeRows[index].time_logs[idx].end_time = newDateTimeValue; // Update To Date
    }

    // Recalculate the new time difference for the updated log
    const start = tempTimeRows[index].time_logs[idx].start_time;
    const end = tempTimeRows[index].time_logs[idx].end_time;
    const newTimeDiff = calculateHourDifference(start, end);

    // Update the specific log's total and billing hours
    tempTimeRows[index].time_logs[idx].billing_hours = newTimeDiff;
    tempTimeRows[index].time_logs[idx].total_hours = newTimeDiff;

    // Update the parent task's total and billing hours
    tempTimeRows[index].total_hours = tempTimeRows[index].time_logs.reduce(
      (acc, log) => acc + log.total_hours,
      0,
    );
    tempTimeRows[index].billing_hours = tempTimeRows[index].time_logs.reduce(
      (acc, log) => acc + log.billing_hours,
      0,
    );

    // Update the state with the modified rows
    setTimesheetRows(tempTimeRows);
  };

  const handleRemoveRow = (type, index, idx) => {
    const tempRows = [...timesheetRows];
    console.log('remove items', tempRows, index);

    if (type == 'row') {
      tempRows.splice(index, 1);
    }
    if (type == 'log') {
      tempRows[index].total_hours =
        tempRows[index].total_hours - tempRows[index].time_logs[idx].total_hours;
      tempRows[index].billing_hours =
        tempRows[index].billing_hours - tempRows[index].time_logs[idx].billing_hours;
      tempRows[index].time_logs.splice(idx, 1);
      if (tempRows[index].time_logs.length == 0) {
        tempRows.splice(index, 1);
      }
    }
    setTimeSheetData(tempRows);
    setTimesheetRows(tempRows);
  };

  // data - time details
  // tIdx - timelog id
  // index - timesheet index
  const handleEditBillingHours = (data, tIdx, index) => {
    console.log({ billing_hours: data, tIdx });
    const [hours, minutes] = data.split(':').map(Number);
    const totalMilliseconds = hours * 3600000 + minutes * 60000;
    const updatedTimeSheetData = timesheetRows?.map((item, idx) => {
      if (idx === index) {
        let total_hours = 0;
        item.time_logs?.map((log, logIdx) => {
          if (logIdx === tIdx) {
            log.billing_hours = totalMilliseconds;
          }
          total_hours += log.billing_hours;
        });
        item.billing_hours = total_hours;
      }
      return item;
    });
    console.log({ handleEditBillingHours: updatedTimeSheetData });
    setTimesheetRows(updatedTimeSheetData);
  };

  // time -- edited timesheet item billing hours
  // index -- timesheet item index
  const handleEditTotalBillingHours = (time, index) => {
    const [hours, minutes] = time.split(':').map(Number);
    const totalMilliseconds = hours * 3600000 + minutes * 60000;
    const updatedTimeSheetData = timesheetRows?.map((item, idx) => {
      if (idx === index) {
        item.billing_hours = totalMilliseconds; // Update parent billing hours
        const totalTimeLogs = item?.time_logs?.length;
        // Update each time log billing hours
        const splitTimings = totalMilliseconds / totalTimeLogs;
        // Distribute billing hours evenly across all time logs
        const time_logs = item?.time_logs?.map((log) => {
          log.billing_hours = splitTimings;
          return log;
        });
        item.time_logs = time_logs;
      }
      return item;
    });
    setTimesheetRows(updatedTimeSheetData);
  };

  const isEditActive = (isMain, index) => {
    if (isMain) {
      setContextTime({ index: null, isEdit: false });
      setContextParentTime({ index: index, isEdit: true });
    } else {
      setContextTime({ index: index, isEdit: true });
      setContextParentTime({ index: null, isEdit: false });
    }
  };

  // to delete the item
  const handleDelete = () => {
    if (isRemoveModal?.isLog) {
      handleRemoveRow('log', isRemoveModal.index, isRemoveModal.logIndex);
    } else {
      handleRemoveRow('row', isRemoveModal.index);
    }
    setIsRemoveModal({
      isLog: false,
      index: null,
      logIndex: null,
      isRemove: false,
    });
  };

  // to close the modal
  const modalClose = () => {
    setIsRemoveModal({ isLog: false, isRemove: false, index: null });
  };

  const rows = timesheetRows?.map((item, index) => {
    const isExpanded = expandedRows.includes(item?.task_id) || false;
    return (
      <>
        <Tr key={index}>
          <Td>
            <div className='d-flex gap-2'>
              {item?.time_logs?.length > 0 && (
                <div onClick={() => handleToggleRow(item?.task_id)} className='pointer'>
                  {!isExpanded ? (
                    <Play color='#87909E' fill='#87909E' size={10} />
                  ) : (
                    <img src={Images.PlayDown} />
                  )}
                </div>
              )}
              {index + 1}
            </div>
          </Td>
          <Td className='timesheet-td-18'>{item?.task_name}</Td>
          <Td className='timesheet-td-18 '>
            {moment(parseInt(item?.from_date)).format('DD-MM-YYYY')}
          </Td>
          <Td className='timesheet-td-18'>
            {moment(parseInt(item?.to_date)).format('DD-MM-YYYY')}
          </Td>
          <Td className='timesheet-td-18'>
            {convertMillisecondsToHoursAndMinutes(item?.total_hours)}
          </Td>
          <Td className='timesheet-td-18'>
            {contextParentTime?.index === index && contextParentTime?.isEdit ? (
              <section className=' form-group'>
                <div className='d-flex gap-2 align-items-center'>
                  <TimeInputBox
                    billingHours={item?.billing_hours}
                    handleEditBillingHours={(data) => handleEditTotalBillingHours(data, index)}
                    setContextTime={setContextTime}
                  />
                </div>
              </section>
            ) : (
              <div
                className={`task-single-menu-value ${isEditable ? 'underline-hover' : ''} `}
                role={isEditable && 'button'}
                onClick={() => isEditable && isEditActive(true, index)}
              >
                {convertMillisecondsToHoursAndMinutes(item?.billing_hours)}
              </div>
            )}
          </Td>
          {isEditable && roleBasedPermission?.ACTION_TIME_SHEET_DELETE && (
            <Td>
              <div
                className='icon-bg pointer'
                onClick={() =>
                  setIsRemoveModal({ index: index, isRemove: true, isLog: false, logIndex: null })
                }
              >
                <Trash size={15} />
              </div>
            </Td>
          )}
        </Tr>
        {isExpanded &&
          item?.time_logs.map((timelog, idx) => {
            return (
              <Tr key={idx}>
                <Td></Td>
                <Td className='timesheet-td-18'>{timelog?.task_name}</Td>
                <Td
                  ref={inputRefFromDate}
                  className={`timesheet-td-18 ${isEditable ? 'underline' : ''}`}
                  onClick={() => {
                    isEditable && setEditableFromDate(true);
                    setEditableToDate(false);
                    setEditDateModalData(timelog);
                  }}
                >
                  {editableFromDate && editDateModalData.id == timelog.id ? (
                    <input
                      type='datetime-local'
                      name='start_time'
                      value={moment(timelog?.start_time).format('YYYY-MM-DDTHH:mm')}
                      max={moment(timelog?.end_time).format('YYYY-MM-DDTHH:mm')}
                      className='timesheet-dateeditinput'
                      onChange={(e) => handleChangeDate(e, index, idx)}
                    />
                  ) : (
                    <>{moment(timelog?.start_time).format('DD-MMM-YYYY hh:mm a')}</>
                  )}
                </Td>
                <Td
                  className={`timesheet-td-18 ${isEditable ? 'underline' : ''}`}
                  onClick={() => {
                    isEditable && setEditableToDate(true);
                    setEditableFromDate(false);
                    setEditDateModalData(timelog);
                  }}
                  ref={inputRefToDate}
                >
                  {editableToDate && editDateModalData.id == timelog.id ? (
                    <input
                      type='datetime-local'
                      name='end_time'
                      value={moment(timelog?.end_time).format('YYYY-MM-DDTHH:mm')}
                      min={moment(timelog?.start_time).format('YYYY-MM-DDTHH:mm')}
                      className='timesheet-dateeditinput'
                      onChange={(e) => handleChangeDate(e, index, idx)}
                    />
                  ) : (
                    <>{moment(timelog?.end_time).format('DD-MMM-YYYY hh:mm a')}</>
                  )}
                </Td>
                <Td className='timesheet-td-18'>
                  {convertMillisecondsToTimeDetails(timelog?.total_hours)}
                </Td>
                <Td className='timesheet-td-18'>
                  {contextTime?.index === idx && contextTime?.isEdit ? (
                    <section className=' form-group'>
                      <div className='d-flex gap-2 align-items-center'>
                        <TimeInputBox
                          billingHours={timelog?.billing_hours}
                          handleEditBillingHours={(data) =>
                            handleEditBillingHours(data, idx, index)
                          }
                          setContextTime={setContextTime}
                        />
                      </div>
                    </section>
                  ) : (
                    <div
                      className={`task-single-menu-value ${isEditable ? 'underline-hover' : ''} `}
                      role={isEditable && 'button'}
                      onClick={() => isEditable && isEditActive(false, idx)}
                    >
                      {convertMillisecondsToTimeDetails(timelog?.billing_hours)}
                    </div>
                  )}
                </Td>
                {isEditable && roleBasedPermission?.ACTION_TIME_SHEET_DELETE && (
                  <Td>
                    <div
                      className='icon-bg pointer'
                      onClick={() =>
                        setIsRemoveModal({
                          index: index,
                          isRemove: true,
                          isLog: true,
                          logIndex: idx,
                        })
                      }
                    >
                      <Trash size={15} />
                    </div>
                  </Td>
                )}
              </Tr>
            );
          })}
      </>
    );
  });
  return (
    <>
      {rows}
      {isRemoveModal?.isRemove && (
        <Modal.Container>
          <Modal.View className={'status-delete'}>
            <Modal.Head handleClose={() => modalClose()} Heading={'Delete Confirmation'} />
            <Modal.Description>
              <img src={Images.DeleteBin} width={35} height={50} alt='' className='mb-3' />
              <div className='del-description'>{DELETE_NOTE.COMMON}</div>
            </Modal.Description>
            <div className='modal_footer'>
              <Button.Container handleOk={() => modalClose()} className={'status-del-cancel'}>
                <Button.Title title={'Cancel'} />
              </Button.Container>
              <Button.Container handleOk={() => handleDelete()}>
                <Button.Title title={'Delete'} />
              </Button.Container>
            </div>
          </Modal.View>
        </Modal.Container>
      )}
    </>
  );
};

export const TimeInputBox = ({
  billingHours,
  handleEditBillingHours = () => {},
  setContextTime = () => {},
}) => {
  const [timeInput, setTimeInput] = useState(
    `${getHoursAndMinute(billingHours)?.hours}:${getHoursAndMinute(billingHours)?.minutes}`,
  );
  const handleInputChange = (e) => {
    const value = e.target.value;

    // Regex to allow only valid HH:mm format input
    if (/^([01]?[0-9]|2[0-3]):[0-5]?[0-9]?$/.test(value) || value === '') {
      setTimeInput(value);
      handleEditBillingHours(value);
    }
  };

  return (
    <input
      type='text'
      value={timeInput}
      onChange={handleInputChange}
      placeholder='HH:mm'
      style={{
        fontSize: '16px',
        width: '100px',
        textAlign: 'center',
        borderRadius: '4px',
        border: '1px solid #ccc',
      }}
      onKeyDown={(e) => {
        if (e.key === 'enter') setContextTime(false);
      }}
    />
  );
};
