import React from 'react';

function ActionPopup({ actionAlert }) {
  if (!actionAlert.message) {
    return null;
  }

  const { message, isSuccess, showButton, onButtonClick } = actionAlert;

  return (
    <div className='action-pop-up'>
      <PopupContainer
        message={message}
        isSuccess={isSuccess}
        showButton={showButton}
        onClick={onButtonClick}
      />
    </div>
  );
}

const PopupContainer = ({ isSuccess, message, showButton, onClick }) => {
  if (isSuccess) {
    return <SuccessContent message={message} showButton={showButton} onClick={onClick} />;
  }

  return <DeleteContent message={message} showButton={showButton} onClick={onClick} />;
};

const SuccessContent = ({ message, showButton, onClick }) => {
  return (
    <div className='d-flex gap-2'>
      <img src='/icons/alert-success.svg' width={18} height={18} className=' mb-auto' />
      <div className='fz-13px '>
        <div className='fw-500'>{message}</div>
        {showButton && (
          <div
            onClick={onClick}
            role='button'
            className='fz-13px fw-600'
            style={{ marginTop: 7, color: 'var(--accent-color)' }}
          >
            View
          </div>
        )}
      </div>
    </div>
  );
};
const DeleteContent = ({ message, showButton, onClick }) => {
  return (
    <div className='d-flex gap-2'>
      <img src='/icons/alert-error.svg' width={18} height={18} className=' mb-auto' />
      <div className='fz-13px'>
        <div className='fw-500'>{message}</div>
        {showButton && (
          <div
            role='button'
            onClick={onClick}
            className='fz-13px fw-600 btn-wrapper ps-1 w-fit pe-2'
            style={{ marginTop: 7, color: 'var(--accent-color)' }}
          >
            Undo
          </div>
        )}
      </div>
    </div>
  );
};

export default ActionPopup;
