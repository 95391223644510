/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import { Modal } from '../_components';
import EmailTemplateForm from './EmailTemplateForm';

export function Check() {
  const [modal, setModal] = useState(false);
  return (
    <>
      <div onClick={() => setModal(true)}>OPEN</div>
      {modal && (
        <Modal.Container handleClose={() => setModal(false)}>
          <Modal.View className='modal-view-trigger' containerClass='view-end'>
            <EmailTemplateForm />
          </Modal.View>
        </Modal.Container>
      )}
    </>
  );
}
