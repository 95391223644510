import React from 'react';

const IconLabelSet = ({
  icon,
  label,

  fontSize,
  fontWeight,
  className = '',
  textClassName = '',
  iconClassName = 'd-flex align-items-center',
  gap,
  onClick = () => {},
  hideLargeName = true,
}) => {
  return (
    <div className={`${className} pointer`}>
      <div
        onClick={() => {
          onClick();
        }}
        className={`d-flex align-items-center `}
        style={{
          fontSize: fontSize ? fontSize : '12px',
          fontWeight: fontWeight ? fontWeight : 600,
          gap: gap ? gap : 6,
        }}
      >
        {icon && <div className={iconClassName}>{icon}</div>}
        <div className={` no-wrap ${textClassName} ${hideLargeName ? 'breadcrumbs-name' : ''}`}>
          {label}
        </div>
      </div>
    </div>
  );
};

export default IconLabelSet;
