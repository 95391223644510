import { apiConstants } from '../_constants';
import { authHeader, handleResponse } from '../_helpers';
import { jsonToQueryString } from '../_helpers/utilFunctions';

export const templateService = {
  getTemplateList,
  getSingleTemplate,
  addTemplate,
  deactivateTemplate,
  duplicateTemplate,
};

async function getTemplateList(payload) {
  const apiUrl = `${apiConstants.apiUrl}/template_list`;
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(payload),
  };
  return fetch(apiUrl, requestOptions).then(handleResponse);
}

async function getSingleTemplate(data) {
  const params = jsonToQueryString(data);
  const apiUrl = `${apiConstants.apiUrl}/get_template?${params}`;
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };

  return fetch(apiUrl, requestOptions).then(handleResponse);
}

async function addTemplate(payload) {
  const apiUrl = `${apiConstants.apiUrl}/template_add`;
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(payload),
  };

  return fetch(apiUrl, requestOptions).then(handleResponse);
}

async function deactivateTemplate(payload) {
  const apiUrl = `${apiConstants.apiUrl}/template_deactivate`;
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(payload),
  };

  return fetch(apiUrl, requestOptions).then(handleResponse);
}
async function duplicateTemplate(payload) {
  const apiUrl = `${apiConstants.apiUrl}/template_duplicate`;
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(payload),
  };

  return fetch(apiUrl, requestOptions).then(handleResponse);
}
