/* eslint-disable no-unused-vars */
import { Home } from 'lucide-react';
import React, { useContext } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { GlobalContext, MyTeamIcon } from '../Utils';
import { FileTab, FolderTab } from '../Utils/SvgIcons';
import IconLabelSet from '../_components/IconLabelSet';
import NoPermission from '../_components/NoPermission';
import { LIST_PERMISSION_VALUE, RT } from '../_constants';
import FileList from './FileList';
import FileTabs from './FileTabs';
import FolderList from './FolderList';

export default function FileHome() {
  const nav = useNavigate();
  const location = useLocation();
  const { folderId } = useParams();
  const searchParams = new URLSearchParams(location.search);
  const folderNameParam = searchParams.get('folder_name');
  const { roleBasedPermission } = useContext(GlobalContext);
  const isActive = (path) => location.pathname.includes(path);
  // const isActive = (path) => {
  //   if (path === RT.FOLDERLIST) {
  //     return (
  //       location.pathname.includes(RT.FOLDERLIST) || location.pathname.includes(`${RT.FILELIST}/`)
  //     );
  //   }
  //   if (path === RT.FILELIST) {
  //     return location.pathname === `/${RT.FILESYSTEM}/${RT.FILELIST}`;
  //   }
  //   return false;
  // };

  const renderNavigation = () => {
    const pathSegments = location.pathname.split('/').filter((segment) => segment);
    const navigation = [
      <IconLabelSet
        key='home'
        label='Home'
        fontWeight={400}
        icon={<Home size={13} />}
        onClick={() => nav(`/`)}
      />,
    ];

    if (pathSegments.includes(RT.FOLDERLIST) || pathSegments.includes(RT.FILELIST)) {
      if (pathSegments.includes(RT.FILELIST) && !folderId) {
        navigation.push(
          <img
            key='slash2'
            className='slash-img-icon-size ms-1 mt-1'
            src={MyTeamIcon.Slash}
            alt=''
          />,
        );
        navigation.push(
          <IconLabelSet
            key='file'
            label='Files'
            fontWeight={500}
            color={`#2A2E34`}
            icon={<FileTab height={10} width={12} />}
            onClick={() => nav(`/${RT.FILESYSTEM}/${RT.FILELIST}`)}
          />,
        );
      }

      if (pathSegments.includes(RT.FOLDERLIST) || folderId) {
        navigation.push(
          <img
            key='slash2'
            className='slash-img-icon-size ms-1 mt-1'
            src={MyTeamIcon.Slash}
            alt=''
          />,
        );

        navigation.push(
          <IconLabelSet
            key='folder'
            label='Folder'
            fontWeight={folderId ? 400 : 500}
            color={`#2A2E34`}
            icon={<FolderTab height={13} width={15} />}
            onClick={() => nav(`/${RT.FILESYSTEM}/${RT.FOLDERLIST}`)}
          />,
        );
      }

      if (folderId) {
        navigation.push(
          <img
            key='slash3'
            className='slash-img-icon-size ms-1 mt-1 '
            src={MyTeamIcon.Slash}
            alt=''
          />,
        );

        navigation.push(
          <span
            key='id-segment'
            className='fz-14px-rem  text-capitalize '
            style={{ fontWeight: 500 }}
          >
            {folderNameParam}
          </span>,
        );
      }
    }

    return navigation;
  };

  if (roleBasedPermission?.ACTION_FILE_LIST === LIST_PERMISSION_VALUE.NO_ACCESS) {
    return <NoPermission />;
  }
  return (
    <div className='fade-entry'>
      {/* url section  */}

      <div className='url-container '>
        <div className='d-flex align-items-center  breadcrumbs url-navigation'>
          {renderNavigation()}
        </div>
      </div>
      <div>
        <div className='file-table-container'>
          {/* tabs section  */}
          <section>
            <FileTabs />
          </section>
          {/* list section  */}
          <section>
            {isActive(`${RT.FILELIST}`) && <FileList />}
            {isActive(`${RT.FOLDERLIST}`) && <FolderList />}
          </section>
        </div>
      </div>
    </div>
  );
}
