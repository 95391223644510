import { apiConstants } from '../_constants';
import { authHeader, handleResponse } from '../_helpers';

export const calendarEventService = {
  getEventList,
};

async function getEventList(payload) {
  const apiUrl = `${apiConstants.apiUrl}/calendar-event-list`;

  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(payload),
  };

  return fetch(apiUrl, requestOptions).then(handleResponse);
}
