/* eslint-disable no-unused-vars */
import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { MENU, NOTIFICATION_TYPE, RT } from '../_constants';
import { getSettingsByName, getSettingValueByLabel, GlobalContext } from '../Utils';

const NotificationHead = ({ id, counts }) => {
  const { globalSettings } = useContext(GlobalContext);
  const menuSettings =
    globalSettings &&
    globalSettings.length &&
    globalSettings.find((settings) => settings?.name === 'menu-settings');
  const hasExist = (default_name) => {
    return (
      menuSettings?.labels &&
      menuSettings?.labels.some((label) => label.default_name === default_name)
    );
  };

  const GeneralSettings = getSettingsByName(globalSettings, 'general-settings');
  let reminderPermitted = getSettingValueByLabel(GeneralSettings, 'Reminder');

  const navigate = useNavigate();

  const TABS = [
    { name: 'Notification', value: NOTIFICATION_TYPE.TASK, count: counts?.NotificationCount },
    { name: 'Updates', value: NOTIFICATION_TYPE.TASK_CHAT, count: counts?.UpdateCount },
  ];
  if (hasExist(MENU.chat.default_name)) {
    TABS.push(
      { name: 'Chats', value: NOTIFICATION_TYPE.CHAT, count: counts?.chatNotificationCount }, // temporarily commented
    );
  }
  if (reminderPermitted) {
    TABS.push(
      { name: 'Reminder', value: NOTIFICATION_TYPE.REMINDER, count: counts?.reminderUnreadCount }, // temporarily commented
    );
  }

  return (
    <section className='notification_tabs'>
      <div className='notification_line'></div>
      <div className='notification_tab_contain'>
        {TABS.map((tab, index) => {
          return (
            <div
              key={index}
              className={tab?.value == id ? 'notification_tab_active' : 'notification_tab_inactive'}
              onClick={() => {
                navigate(`/${RT.NOTIFICATION}/${tab?.value}`);
              }}
            >
              <div className='fs-13'>{`${tab?.name} `}</div>

              <div
                className={
                  tab?.value == id
                    ? 'notification-count-border-active'
                    : 'notification-count-border-inactive'
                }
              >
                {tab?.count ? tab?.count : 0}
              </div>
            </div>
          );
        })}
      </div>
    </section>
  );
};

export default NotificationHead;
