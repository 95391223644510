import { combineReducers } from 'redux';
import { authentication } from './authentication.reducer';

// import { alert } from './alert.reducer';
import { demo } from './demo.reducer';
import { misc } from './misc.reducer';
import { modal } from './modal.reducer';

let reducers = {
  // alert,
  misc,
  demo,
  modal,
  authentication,
};

const rootReducer = combineReducers(reducers);

export default rootReducer;
