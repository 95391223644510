import React from 'react';
import { InfoIcon } from '../Utils/SvgIcons';

export default function NoPermission({ data = 'You have no permission' }) {
  return (
    <div
      className='d-flex justify-content-center align-items-center h-100 fz-13px gap-1'
      style={{ color: 'var(--second-text-color)' }}
    >
      <InfoIcon /> {data}
    </div>
  );
}
