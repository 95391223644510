/* eslint-disable no-unused-vars */
import { useMutation, useQuery } from '@tanstack/react-query';
import draftToHtml from 'draftjs-to-html';
import moment from 'moment';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { USER_LIST_ACTIONS } from '../_constants';
import { taskService, userService } from '../_services';
import { AddReminderModal, validateReminderDate } from '../Reminders/RemindersAddWithList';
import { reminderState, TASK_TABS, taskAssignUserSelect } from '../Task/taskConstants';
import { GlobalContext } from '../Utils';

export const CalendarReminderAdd = ({
  closeEditing = () => {},
  defaultAssignee = {},
  userId = null,
  data = {},
  eventRefetch = () => {},
}) => {
  const [reminderData, setReminderData] = useState({
    ...reminderState,
    user_id: userId,
    reminder_date: new Date(), // Set to current date initially
  });

  useEffect(() => {
    if (data?.reminder_date) {
      const reminderDate = new Date(data.reminder_date);
      const endDate = new Date(data.endDate);
      const today = moment().startOf('day');

      if (data?.id) {
        setReminderData((prev) => ({
          ...prev,
          reminder_date: reminderDate,
        }));
        return;
      }

      // Check if reminder date is today or a future date
      if (moment(reminderDate).isSameOrAfter(today)) {
        setReminderData((prev) => ({
          ...prev,
          reminder_date: reminderDate,
          endDate,
        }));
      } else {
        setReminderData((prev) => ({
          ...prev,
          reminder_date: today.toDate(), // Set to today if date is in the past
          endDate: today.toDate(),
        }));
      }
    }
  }, [data?.reminder_date]);
  useMemo(() => {
    if (data) {
      setReminderData(data);
    }
  }, [data]);

  const { makeAlert } = useContext(GlobalContext);

  const { data: userData, isLoading: userLoading } = useQuery({
    queryKey: ['user-list'],
    queryFn: async () =>
      await userService.getRoleBasedUserList({
        select: taskAssignUserSelect,
        action: USER_LIST_ACTIONS.ACTION_TASK_ALLOCATION,
      }),
    select: (data) => {
      const userData = data?.data?.rows?.map((item) => {
        return {
          value: item.id,
          label: item.first_name + ' ' + item.last_name,
          id: item.id,
          ...item,
        };
      });
      const updatedDefaultUser = defaultAssignee?.id
        ? {
            value: defaultAssignee.id,
            label: defaultAssignee.first_name + ' ' + defaultAssignee.last_name,
            id: defaultAssignee.id,
            ...defaultAssignee,
          }
        : null;

      // Check if the default assignee already exists in the userData array
      const isDefaultUserPresent = userData.some(
        (user) => parseInt(user.id) === parseInt(defaultAssignee?.id),
      );

      const updatedUserData =
        updatedDefaultUser && !isDefaultUserPresent ? [...userData, updatedDefaultUser] : userData;

      return updatedUserData;
    },
  });
  /**
   * handleChange - for handling the event
   * @param {Object} e  - get e.target name values
   */
  const handleChange = (event) => {
    const { name, value } = event.target;
    setReminderData({ ...reminderData, [name]: value });
  };
  /**
   * handleAddOrUpdateReminder - reminder validation handler
   * @param {*} index
   * @param {*} status
   */
  const handleAddOrUpdateReminder = (index = null, status = '') => {
    const { isValidTime, message } = validateReminderDate(reminderData?.reminder_date); // validate reminder date with current date time
    if (!isValidTime) {
      // check if the time is valid - the time should be 1 minute greater than current
      return;
    }
    const dateMillie = moment(reminderData?.reminder_date).valueOf();
    const reminder = {
      ...reminderData,
      updated_time: moment().valueOf(),
      status: index !== null ? status ?? reminderData[index].status : 1,
      reminder_date: dateMillie,
      ...(index === null && {
        task_id: reminderData?.task_id,
        created_time: moment().valueOf(),
      }),
    };
    if (!reminder.user_id) {
      reminder.user_id = reminderData?.user_id ?? JSON.parse(localStorage.getItem('user'))?.id;
    }
    if (typeof reminder?.note === 'object') {
      reminder.note = draftToHtml(reminder?.note);
    }
    mutateAsync(reminder);
  };

  const { mutateAsync, isPending } = useMutation({
    mutationKey: ['chat-group-add'],
    mutationFn: (payload) => taskService.reminderAdd(payload),
    onSuccess: () => {
      closeEditing();
      eventRefetch();
      setReminderData(reminderState);
    },
    onError: (err) => {
      console.error(err);
      makeAlert(err?.message);
    },
  });

  return (
    <AddReminderModal
      handleChange={handleChange}
      closeEditing={closeEditing}
      handleSubmit={handleAddOrUpdateReminder}
      data={reminderData}
      isSubmitted={isPending}
      isLoading={userLoading}
      userData={userData ?? []}
      from={data?.reminder ? 'list' : TASK_TABS.CALENDAR}
      eventRefetch={eventRefetch}
      defaultAssignee={defaultAssignee}
    />
  );
};
