import { keepPreviousData, useQuery, useQueryClient } from '@tanstack/react-query';
import { Pen, Plus, SquareUserRound, Trash } from 'lucide-react';
import React, { useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Table, Tbody, Td, Th, Thead, Tr } from 'react-super-responsive-table';
import { Images } from '../Utils';
import { CompanyIcon, LoadingIcon } from '../Utils/SvgIcons';
import { Button, PaginationComponent, TableRow } from '../_components';
import { ErrorComponent } from '../_components/ErrorComponent';
import { Modal } from '../_components/Modal';
import { SearchBar } from '../_components/Search';
import ToolTip from '../_components/Tooltip/ToolTip';
import { DELETE_NOTE } from '../_constants/deletenote.constants';
import useDebounce from '../_helpers/useDebounce';
import { departmentService } from '../_services/department.service';
import { DepartmentAdd } from './DepartmentAdd';

// const TABLE_COLUMN = ['Sl.No', 'Department', 'Organization', 'Action'];

const TABLE_HEADINGS = [
  { name: <img src={Images.SerialNo} height={14} width={14} />, minWidth: 50 },

  {
    name: (
      <div className='d-flex align-items-center gap-1'>
        {/* <ProgressIcon /> Progress */}
        <SquareUserRound size={15} stroke='var(--second-text-color)' />
        Department
      </div>
    ),
    minWidth: 50,
  },
  {
    name: (
      <div className='d-flex align-items-center gap-1'>
        {/* <User /> */}
        <CompanyIcon height={16} width={16} color='var(--second-text-color)' /> Organization
      </div>
    ),
    minWidth: 50,
  },

  {},
];

export function DepartmentList() {
  // hooks section
  const queryClient = useQueryClient();

  // reference section
  const reference = useRef(null);
  const tableContainerRef = useRef(null);

  //  useState section
  const [editDepartmentID, setEditDepartmentID] = useState(null);
  const [searchParams, setSearchParams] = useSearchParams();
  const [query, setQuery] = useState({
    select: ['id', 'name', 'company_id'],
    is_department: 1,
    pageVo: {
      pageNo: parseInt(searchParams.get('pageNo')) || 1,
      noOfItems: 10,
    },
    searchKey: '',
  });
  const [addModal, setAddModal] = useState(false);
  const debouncedSearch = useDebounce(query?.searchKey, 500);
  const [deleteModal, setDeleteModal] = useState(false);

  // department list API call section
  const {
    data: departmentData,
    isLoading,
    refetch,
    error,
    isSuccess,
  } = useQuery({
    queryKey: ['departmentList', debouncedSearch, query?.pageVo?.pageNo],
    queryFn: () => departmentService.departmentList(query),
    placeholderData: keepPreviousData,
  });

  const modalClose = () => {
    setDeleteModal(false);
    setEditDepartmentID(null);
  };

  // section trigger when delete option selected
  const handleDeactivate = async (id) => {
    try {
      await departmentService.departmentDeactivate({ id });
      let isNeedToBack = departmentData?.data?.rows.length === 1 && departmentData?.data?.pages > 1;

      if (isNeedToBack) {
        const page = searchParams.get('pageNo');
        searchParams.set('pageNo', page - 1);
        setSearchParams(searchParams);
        setQuery((prev) => ({
          ...prev,
          pageVo: {
            ...prev.pageVo,
            pageNo: prev.pageVo.pageNo - 1,
          },
        }));
      } else {
        refetch();
      }
      await queryClient.invalidateQueries('departmentList');
      modalClose();
    } catch (error) {
      console.error('Error deleting department :', error);
    }
  };

  // get the count of department
  const listItemCount = departmentData?.data?.rows.length;

  // table data definition section
  const tableData = departmentData?.data?.rows?.map((department, index) => {
    const slNo = (departmentData?.data?.page - 1) * departmentData?.data?.limit + index + 1;

    return (
      <tr key={index}>
        <Td>{slNo}</Td>

        <Td>{department?.name}</Td>
        <Td>{department?.Company?.name}</Td>
        {/* <Td style={{ verticalAlign: 'middle', cursor: 'pointer' }}>
          <div
            className='ellipsis_icon'
            ref={reference}
            onClick={(event) => {
              event.stopPropagation();
            }}
          >
            <div onClick={() => setContextMenuIndex(index)}>
              <Ellipsis />
            </div>
            <ContextMenu
              handleClick={(value) => {
                setEditDepartmentID(department?.id);
                handleClickOption(value);
              }}
              isContextVisible={contextMenuIndex === index}
              onRequestClose={() => {
                // setEdiTdepartmentID(null);
                setContextMenuIndex(null);
              }}
            />
          </div>
        </Td> */}
        <Td
          style={{ verticalAlign: 'middle', cursor: 'pointer' }}
          ref={reference}
          onClick={(event) => {
            event.stopPropagation();
          }}
        >
          <div className='d-flex gap-2 justify-content-end icon-div invisible'>
            <ToolTip tooltipText={`Edit Department`} isModern popOver scrollRef={tableContainerRef}>
              <div
                className='icon-bg'
                onClick={() => {
                  setEditDepartmentID(department.id);
                  setAddModal(true);
                }}
              >
                <Pen size={15} />
              </div>
            </ToolTip>
            <ToolTip
              tooltipText={`Delete Department`}
              isModern
              popOver
              scrollRef={tableContainerRef}
            >
              <div
                className='icon-bg'
                onClick={(event) => {
                  event.stopPropagation();
                  setEditDepartmentID(department?.id);
                  setDeleteModal(true);
                }}
              >
                <Trash size={15} />
              </div>
            </ToolTip>
          </div>
        </Td>
      </tr>
    );
  });

  // search box handleChange
  const handleSearch = (event) => {
    searchParams.set('pageNo', 1);
    setSearchParams(searchParams);
    let search = event.target.value;
    setQuery((prevQuery) => ({
      ...prevQuery,
      pageVo: { ...query.pageVo, pageNo: 1 },
      searchKey: search,
    }));
  };

  return (
    <div className='department_list sub_padding'>
      {/* component will active when error occurs   */}
      <ErrorComponent error={error?.message} />

      <section>
        <div className='tab_contain mt-2'>
          <SearchBar handleSearch={handleSearch} searchKey={query.searchKey} />

          <div className='d-flex gap-2'>
            <ToolTip tooltipText={'Add Department'} isModern popOver>
              <div
                className='menu-item-outline'
                onClick={() => {
                  setAddModal(true);
                }}
              >
                <Plus size={16} color='#87909E' />
              </div>
            </ToolTip>
          </div>
        </div>

        <section className='mt-2'>
          <div
            className='table-container no-padding'
            ref={tableContainerRef}
            style={{ maxHeight: '60vh' }}
          >
            <Table className='table table-hover tbl mb-0'>
              <Thead>
                <Tr className='table-head'>
                  {TABLE_HEADINGS.map((heading, index) => {
                    return (
                      <Th
                        key={index}
                        scope='col'
                        style={{ minWidth: heading.minWidth, maxWidth: heading.maxWidth }}
                      >
                        {heading.name}
                      </Th>
                    );
                  })}
                </Tr>
              </Thead>

              <Tbody>
                {isLoading && (
                  <TableRow noOfCols={4}>
                    <LoadingIcon size={60} />
                  </TableRow>
                )}
                {!listItemCount && isSuccess && <TableRow noOfCols={8}>No data found</TableRow>}
                {listItemCount > 0 && isSuccess && tableData}
              </Tbody>
            </Table>
          </div>
        </section>
        {listItemCount > 0 && (
          <section className='mt-2'>
            <PaginationComponent
              pageOnClick={(data) => {
                setQuery((prev) => ({
                  ...prev,
                  pageVo: {
                    ...prev.pageVo,
                    pageNo: data,
                  },
                }));
                searchParams.set('pageNo', data);
                setSearchParams(searchParams);
              }}
              demoData={departmentData?.data}
            />
          </section>
        )}
      </section>

      {/* definition of edit modal section  */}
      {addModal && (
        <Modal.Container
          handleClose={() => {
            setAddModal(false);
            setEditDepartmentID(null);
          }}
        >
          <Modal.View className={'modal_view_label'}>
            <Modal.Head
              Heading={editDepartmentID ? 'Edit Department' : 'Add Department'}
              handleClose={() => {
                setAddModal(false);
                setEditDepartmentID(null);
              }}
            />

            <DepartmentAdd
              id={editDepartmentID}
              handleClose={() => {
                setAddModal(false);
                setEditDepartmentID(null);
                refetch();
              }}
            />
          </Modal.View>
        </Modal.Container>
      )}

      {/* definition of delete modal  section  */}
      {deleteModal && (
        <Modal.Container>
          <Modal.View className={'status-delete'}>
            <Modal.Head handleClose={() => modalClose()} Heading={'Delete Confirmation'} />
            <Modal.Description>
              <img src={Images.DeleteBin} width={35} height={50} alt='' className='mb-3' />
              <div className='del-description'>{DELETE_NOTE.DEPARTMENT}</div>
            </Modal.Description>
            <div className='modal_footer'>
              <Button.Container handleOk={() => modalClose()} className={'status-del-cancel'}>
                <Button.Title title={'Cancel'} />
              </Button.Container>
              <Button.Container handleOk={() => handleDeactivate(editDepartmentID)}>
                <Button.Title title={'Delete'} />
              </Button.Container>
            </div>
          </Modal.View>
        </Modal.Container>
      )}
    </div>
  );
}
