import React from 'react';
import { Cell, Legend, Pie, PieChart, ResponsiveContainer } from 'recharts';
import { floatToStringTime, floatToTime } from '../Utils';

const COLORS = ['#FEDCD3', '#FB9EBB']; // Define colors similar to your screenshot

// Custom Legend to display circles instead of rectangles
const CustomLegend = ({ payload }) => {
  return (
    <div className='graph-info-container'>
      {payload.map((entry, index) => {
        const { hours, minutes } = floatToTime(entry?.payload?.value);
        return (
          <div key={`item-${index}`} className='d-flex gap-3  align-items-center '>
            {/* Circle instead of rectangle */}
            <div className='d-flex align-items-center gap-1'>
              <div
                style={{
                  backgroundColor: entry.color,
                }}
                className='graph-info-label '
              />
              <span>{entry.value} : </span>
            </div>
            <div style={{ fontWeight: 700 }}>{floatToStringTime(hours, minutes)}</div>
          </div>
        );
      })}
    </div>
  );
};

export const PieChartReport = ({ reportData }) => {
  const data = [
    { name: 'Estimated Time', value: reportData?.estimatedHour },
    { name: 'Sum of Efforts', value: reportData?.effort },
  ];

  return (
    <ResponsiveContainer height={400} className={'mx-auto'}>
      <PieChart className='pie-container'>
        <Pie data={data} cx='50%' cy='50%' outerRadius={150} fill='#8884d8' dataKey='value' label>
          {data.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
          ))}
        </Pie>

        <Legend
          content={<CustomLegend />}
          align='right'
          verticalAlign='middle'
          width={'fit-content'}
        />
      </PieChart>
    </ResponsiveContainer>
  );
};
