import { X } from 'lucide-react';
import moment from 'moment';
import React, { useState } from 'react';
import { Button } from '../_components';
import SearchableInfiniteQuerySelect from '../_components/SearchableInfiniteQuerySelect';
import ToolTip from '../_components/Tooltip/ToolTip';
import { CommonDropDownStyle } from '../_constants';
import { taskService } from '../_services';
import { Cancel, Tick } from '../Utils/SvgIcons';
import { calculateHourDifference } from './timesheetMisc';

export const AddTimeLog = ({ handleClose = () => {}, client_id, handleAdd = () => {}, data }) => {
  const [submitted, setSubmitted] = useState(false);
  const [timesheetData, setTimesheetData] = useState({
    task_id: null,
    task_name: '',
    end_time: null,
    start_time: null,
    total_hours: null,
    billing_hours: null,
  });
  const [errorMessage, setErrorMessage] = useState('');

  const handleChange = (e) => {
    const { name, value } = e.target;
    const updatedTimesheetData = { ...timesheetData };
    let timeDiff;

    if (name === 'start_time') {
      const fromDate = moment(value).valueOf();
      updatedTimesheetData.start_time = fromDate;

      if (updatedTimesheetData.end_time && fromDate >= updatedTimesheetData.end_time) {
        setErrorMessage('Start time should be before End time');
        updatedTimesheetData.total_hours = null;
        updatedTimesheetData.billing_hours = null;
      } else {
        setErrorMessage('');
        if (updatedTimesheetData.end_time) {
          timeDiff = calculateHourDifference(fromDate, updatedTimesheetData.end_time);
          updatedTimesheetData.total_hours = timeDiff;
          updatedTimesheetData.billing_hours = timeDiff;
        }
      }
    }

    if (name === 'end_time') {
      const toDate = moment(value).valueOf();
      updatedTimesheetData.end_time = toDate;

      if (updatedTimesheetData.start_time && toDate <= updatedTimesheetData.start_time) {
        setErrorMessage('End time should be after Start time');
        updatedTimesheetData.total_hours = null;
        updatedTimesheetData.billing_hours = null;
      } else {
        setErrorMessage('');
        if (updatedTimesheetData.start_time) {
          timeDiff = calculateHourDifference(updatedTimesheetData.start_time, toDate);
          updatedTimesheetData.total_hours = timeDiff;
          updatedTimesheetData.billing_hours = timeDiff;
        }
      }
    }

    setTimesheetData(updatedTimesheetData);
  };

  const handleChangeTask = (selectedOption) => {
    setTimesheetData((prev) => ({
      ...prev,
      task_id: selectedOption.id,
      task_name: selectedOption.name,
    }));
  };

  const handleSubmit = () => {
    setSubmitted(true);
    if (
      timesheetData.task_id &&
      timesheetData.start_time &&
      timesheetData.end_time &&
      timesheetData.total_hours &&
      timesheetData.billing_hours
    ) {
      handleAdd(timesheetData);
    }
  };

  return (
    <div className='timesheet-addtimelog-container'>
      <div className='timesheet-addtimelog-header'>
        <div className='timesheet-add-headtxt'>Add Time Sheet</div>
        <ToolTip tooltipText={`Close`} isModern>
          <div
            className=''
            onClick={() => {
              handleClose(true);
            }}
          >
            <X size={16} color='#87909E' />
          </div>
        </ToolTip>
      </div>
      <div className='timesheet-formitem mb-3 '>
        <label className='mb-1 fz-13px-rem  label_inputtimesheet-add-label '>Task</label>
        <div className='task-single-menu-value underline-hover'>
          <SearchableInfiniteQuerySelect
            name='task_id'
            getOptionLabel={(option) => option?.name}
            getOptionValue={(option) => option?.id}
            queryFn={(params) =>
              taskService.taskListAdvanced({
                ...params,
                select: ['id', 'name'],
                default_name: 'all',
                ...(client_id ? { client_id: [client_id] } : {}),
                ...(data?.userIds ? { assignee_id: data?.userIds } : {}),
                action: 'timesheet',
              })
            }
            styles={CommonDropDownStyle}
            queryKey={['task-list-time-sheet', client_id]}
            enableCondition={true}
            isCustomFunction
            placeholder={`Choose Task`}
            // styles={customFlagSelectBoxStylesForTimesheet}
            className={
              'timesheet-select timesheet-addinput ' +
              (submitted && !timesheetData.task_id ? 'has-error' : '')
            }
            value={
              timesheetData?.task_id
                ? { name: timesheetData?.task_name, id: timesheetData?.task_id }
                : null
            }
            onChange={handleChangeTask}
          />
        </div>
      </div>
      <label className='mb-1 fz-13px-rem  label_inputtimesheet-add-label '>Start Date</label>
      <div className='timesheet-formitem-row  mb-3  '>
        <input
          type='datetime-local'
          className={
            'input-box timesheet-addinput ' +
            (submitted && !timesheetData.start_time ? 'has-error' : '')
          }
          id='start_time'
          name='start_time'
          value={
            timesheetData.start_time
              ? moment(timesheetData.start_time).format('YYYY-MM-DDTHH:mm')
              : ''
          }
          min={moment(data?.fromDate).format('YYYY-MM-DDTHH:mm')}
          max={
            timesheetData.end_time
              ? moment(timesheetData.end_time).format('YYYY-MM-DDTHH:mm')
              : moment(data?.toDate).format('YYYY-MM-DDTHH:mm')
          }
          placeholder={`Start Time`}
          onChange={(event) => {
            handleChange(event);
          }}
        />
      </div>
      <label className='mb-1 fz-13px-rem  label_inputtimesheet-add-label '>End Date</label>
      <div className='timesheet-formitem-row  mb-3  '>
        <input
          type='datetime-local'
          className={
            'input-box timesheet-addinput ' +
            (submitted && !timesheetData.end_time ? 'has-error' : '')
          }
          id='end_time'
          name='end_time'
          value={
            timesheetData.end_time ? moment(timesheetData.end_time).format('YYYY-MM-DDTHH:mm') : ''
          }
          min={
            timesheetData.start_time
              ? moment(timesheetData.start_time).format('YYYY-MM-DDTHH:mm')
              : moment(data?.fromDate).format('YYYY-MM-DDTHH:mm')
          }
          max={moment(data?.toDate).format('YYYY-MM-DDTHH:mm')}
          placeholder={`End Time `}
          onChange={(event) => {
            handleChange(event);
          }}
        />
      </div>

      {errorMessage && (
        <div
          className='error-message'
          style={{ color: 'red', marginBottom: '10px', fontSize: '12px' }}
        >
          {errorMessage}
        </div>
      )}

      <div className='timesheet-btn-container justify-content-end'>
        <div className='d-flex gap-2'>
          <Button.Container className='cancel-btn' handleOk={handleClose}>
            <Cancel /> <Button.Title title='Close' />
          </Button.Container>
          <Button.Container
            type='submit'
            handleOk={() => {
              handleSubmit();
            }}
          >
            <Tick />
            <Button.Title title={'Add'} />
            <Button.Loading color='#FFF' size={20} />
          </Button.Container>
        </div>
      </div>
    </div>
  );
};
