import { keepPreviousData, useMutation, useQuery } from '@tanstack/react-query';
import draftToHtml from 'draftjs-to-html';
import parse from 'html-react-parser';
import { ArrowLeftRight, Calendar, File, Hourglass, Info } from 'lucide-react';
import moment from 'moment';
import React, { forwardRef, useContext, useEffect, useMemo, useRef, useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css'; // Import the necessary styles
import { Button } from '../_components';
import StackedAvatars from '../_components/Avatar/StackedAvatars';
import IconLabelSet from '../_components/IconLabelSet';
import { Popover } from '../_components/Popover';
import SearchableQuerySelect from '../_components/SearchableQuerySelect';
import { CustomDropdownIndicator } from '../_components/SelectionDropDownIcon';
import ToolTip from '../_components/Tooltip/ToolTip';
import { USER_LIST_ACTIONS } from '../_constants';
import { taskService, userService } from '../_services';
import { labelService } from '../_services/label.service';
import { DropDownStyle } from '../OKR/okrConstants';
import { EditableDescription } from '../Task/TaskComponents';
import {
  CustomVariableInput,
  getFormattedLabel,
  TimeRangeSelector,
} from '../Task/TaskComponents/CustomInputs';
import EditableField from '../Task/TaskComponents/EditableField';
import { labelSelectValues, taskAssignUserSelect } from '../Task/taskConstants';
import { getLabelIsActive, LinkOptions, removeTagsAndGetContent } from '../Task/taskValidation';
import { fullNameUser, GlobalContext } from '../Utils';
import { Label } from '../Utils/SvgIcons';

// type = 1 task,2 normal
const CalendarEventAdd = ({
  data = {},
  closeEditing = () => {},
  handleAdd = () => {},
  isEditable = false,
  //   tableRef,
}) => {
  const isMenuLabelOn = getLabelIsActive();
  const [isSubmitted, setIsSubmitted] = useState(false);

  const [eventData, setEventData] = useState({
    title: '',
    description: '',
    allocated_users: [],
    type: 2,
    label: [],
    reminder_date: new Date(), // Set to current date initially
    start_time: data?.id ? data?.start_time : moment(data?.reminder_date).format('hh:mm a'),
    end_time: data?.id ? data?.end_time : moment(data?.reminder_date).format('hh:mm a'),
    task_id: null,
    ...data,
  });

  const [isNormalEvent, setIsNormalEvent] = useState(false);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [selectedTask, setSelectedTask] = useState(null);
  const [contextTimeItem, setContextTimeItem] = useState(false); //state for add time visibility
  const { makeAlert } = useContext(GlobalContext);

  useEffect(() => {
    if (data?.reminder_date) {
      const reminderDate = new Date(data.reminder_date);
      const today = moment().startOf('day');

      if (data?.id) {
        setEventData((prev) => ({
          ...prev,
          reminder_date: reminderDate,
          type: data?.task_id ? 1 : 2,
        }));
        if (data?.task_id) setSelectedTask({ name: data?.title, id: data?.task_id });
        setIsNormalEvent(data?.task_id ? true : false);
        return;
      }

      // Check if reminder date is today or a future date
      if (moment(reminderDate).isSameOrAfter(today)) {
        setEventData((prev) => ({
          ...prev,
          reminder_date: reminderDate,
        }));
      } else {
        setEventData((prev) => ({
          ...prev,
          reminder_date: today.toDate(), // Set to today if date is in the past
        }));
      }
    }
  }, [data?.reminder_date]);

  useMemo(() => {
    if (data?.allocated_users?.length) {
      setSelectedUsers(data?.allocated_users);
    }
  }, [data?.allocated_users]);
  //   const [contextDateItem, setContextDateItem] = useState(false); //state for add time visibility

  const { data: taskList, isLoading: taskLoading } = useQuery({
    queryKey: ['task-list-for-calender-subtask'],
    queryFn: () => taskService.taskListAdvanced({ select: ['id', 'name'] }),
    select: (response) => {
      const taskData = response?.data?.rows;
      const taskByType = data?.task_id ? { name: data?.title, id: parseInt(data?.task_id) } : {};
      console.log({ taskByType, task_id: data });

      return taskByType ? [...taskData, taskByType] : taskData;
    },
    placeholderData: keepPreviousData,
  });

  const { data: usersForVerification } = useQuery({
    queryKey: ['user-list-verify'],
    queryFn: () =>
      userService.getRoleBasedUserList({
        select: taskAssignUserSelect,
        action: USER_LIST_ACTIONS.ACTION_TASK_ALLOCATION,
      }),
    select: (data) => {
      return data?.data?.rows;
    },
  });
  // to fetch the label list
  const { data: labelList } = useQuery({
    queryKey: ['label'],
    queryFn: () =>
      labelService.labelList({
        select: labelSelectValues,
        pageVo: { noOfItems: 30 },
      }),
    select: (data) => {
      const response = data?.data?.rows?.map((item) => {
        return { value: item.id, label: item.name, ...item };
      });
      return response;
    },
    enabled: isMenuLabelOn,
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    console.log({ handleChange: name, value });
    if (name == 'task_id') {
      setSelectedTask({ name: value.name, value: value.id });
      setEventData((prev) => ({
        ...prev,
        [name]: value.id,
      }));
      return;
    }
    if (isSubmitted) {
      setIsSubmitted(false);
    }

    if (name == 'allocated_users') {
      const updatedUser = [...selectedUsers, ...value];
      setSelectedUsers(updatedUser);

      setEventData((prev) => ({
        ...prev,
        [name]: updatedUser.filter((value) => value.id),
      }));
      return;
    }
    setEventData({
      ...eventData,
      [name]: value,
    });
  };

  const handleRemoveUser = (user) => {
    const removedUser = selectedUsers.filter((item) => parseInt(item.id) !== parseInt(user.id));
    setSelectedUsers(removedUser);
    setEventData({
      ...eventData,
      allocated_users: removedUser.filter((item) => item.id),
    });
  };

  const handleSubmit = async () => {
    // Submit form logic here
    const finalEventData = { ...eventData };
    setIsSubmitted(true);

    // Validation for normal event
    if (!isNormalEvent && eventData.title === '') {
      makeAlert('Please add event title');
      return;
    } else if (isNormalEvent && !eventData.task_id) {
      makeAlert('Please choose task for this event');
      return;
    }
    if (data?.id && !isNormalEvent) {
      // for edit in case switch to normal event remove task_id
      finalEventData.task_id = null;
    }

    if (!finalEventData.start_time) {
      makeAlert('Please choose start time');
      return;
    }

    if (!finalEventData.end_time) {
      makeAlert('Please choose end time');
      return;
    }

    if (!finalEventData.reminder_date) {
      makeAlert('Please choose event date');
      return;
    }

    const reminderDate = moment(finalEventData.reminder_date).startOf('day'); // The event's date

    // Parse and combine start time and end time with the reminder date
    const startTime = moment(finalEventData.start_time, 'hh:mm A');
    const endTime = moment(finalEventData.end_time, 'hh:mm A');

    // Create full datetime objects by combining the reminder date with the times
    const fullStartTime = moment(reminderDate)
      .set({ hour: startTime.get('hour'), minute: startTime.get('minute') })
      .valueOf();

    const fullEndTime = moment(reminderDate)
      .set({ hour: endTime.get('hour'), minute: endTime.get('minute') })
      .valueOf();

    // If the event is today, the start time must be at least 5 minutes greater than the current time
    if (moment(finalEventData.reminder_date).isSame(moment(), 'day')) {
      const fiveMinutesLater = moment(finalEventData.start_time).add(5, 'minutes').valueOf();
      console.log({ reminderDate, fiveMinutesLater, fullStartTime });

      if (fullStartTime <= fiveMinutesLater) {
        makeAlert('Start time must be at least 5 minutes greater than the current time');
        return;
      }
    }

    // Ensure the end time is at least 5 minutes greater than the start time
    if (fullEndTime <= fullStartTime + 5 * 60 * 1000) {
      // 5 minutes in milliseconds
      makeAlert('End time must be at least 5 minutes greater than the start time');
      return;
    }
    console.log({ finalEventData });

    // Prepare the final event data
    finalEventData.start_time = fullStartTime;
    finalEventData.end_time = fullEndTime;
    finalEventData.created_time = moment().valueOf();
    finalEventData.updated_time = moment().valueOf();
    finalEventData.status = 1;
    finalEventData.type = 2;

    // convert note draft object to html string
    if (typeof finalEventData?.note === 'object') {
      finalEventData.note = draftToHtml(finalEventData?.note);
    }
    // Convert reminder_date to milliseconds (Unix timestamp)
    finalEventData.reminder_date = moment(finalEventData.reminder_date).valueOf();

    finalEventData.allocated_users = finalEventData.allocated_users?.length
      ? finalEventData.allocated_users.map((item) => {
          return item.id;
        })
      : [];
    // Submit the final event data
    await mutateAsync(finalEventData);
  };

  const { mutateAsync, isPending } = useMutation({
    mutationKey: ['event-add'],
    mutationFn: (payload) => taskService.reminderAdd(payload),
    onSuccess: () => {
      handleAdd();
      closeEditing();
      setIsSubmitted(false);
    },
    onError: (err) => {
      console.error(err);
      setIsSubmitted(false);
      makeAlert(err?.message);
    },
  });
  //   const { isValidTime, message } = validateReminderDate(eventData.start_time);
  const relatedTableContainerRef = useRef(null);

  // eslint-disable-next-line react/display-name
  const ExampleCustomInput = forwardRef(({ value, onClick, className }) => (
    <IconLabelSet
      label={value ? value : 'Choose Date'}
      gap={8}
      icon={<Calendar width={11} height={15} />}
      className={`${className} d-flex`}
      onClick={onClick}
    />
  ));
  console.log({ isNormalEvent, eventData });

  return (
    <section
      onClick={() => {
        setContextTimeItem(false);
      }}
    >
      <div className='modal_view_content'>
        <div className={`task-add-body`}>
          <section className='w-100'>
            <div
              className='w-100 position-relative'
              style={{ minHeight: 160, width: '30vw', zIndex: 9999 }}
            >
              <div className={`form-group  py-1 d-flex gap-2 mt-1`}>
                <div className='mt-10px  w-80'>
                  {isNormalEvent ? (
                    <SearchableQuerySelect
                      queryKey={'task-list-for-calender-subtask'}
                      queryFn={taskService.taskListAdvanced}
                      getFunctionParams={(searchKey) => ({
                        searchKey,
                        select: ['id', 'name'],
                      })}
                      isCustomFunction
                      initialValue={taskList}
                      isLoading={taskLoading}
                      getOptionLabel={(option) => option?.name}
                      getOptionValue={(option) => option.id}
                      components={{ DropdownIndicator: CustomDropdownIndicator }} // Use the custom dropdown indicator here
                      onChange={(selectedOption) => {
                        handleChange({ target: { name: 'task_id', value: selectedOption } });
                      }}
                      value={selectedTask}
                      styles={DropDownStyle}
                      placeholder='Choose Task'
                      className={`select-box ${isPending && !eventData.task_id && 'field-error'}`}
                      menuPortalTarget={document.getElementById('MODAL')}
                      isDisabled={!isEditable}
                    />
                  ) : (
                    <CustomVariableInput
                      onChange={(event) => {
                        handleChange(event);
                      }}
                      className={`taskname-input  w-100  border-none
                        ${isSubmitted && !eventData?.title && 'error-border-bottom '}`}
                      id='title'
                      name='title'
                      value={eventData?.title}
                      placeholder={'What you have worked on?'}
                      isDisabled={!isEditable}
                    />
                  )}
                </div>
                {isEditable && (
                  <ToolTip tooltipText={isNormalEvent ? 'Add Title' : 'Choose Task'} isModern>
                    <ArrowLeftRight
                      size={16}
                      color='#010108'
                      onClick={() => setIsNormalEvent(!isNormalEvent)}
                      className='cursor-pointer'
                    />
                  </ToolTip>
                )}
                {isSubmitted && ((!isNormalEvent && !eventData?.title) || eventData?.task_id) && (
                  <ToolTip
                    tooltipText={
                      !isNormalEvent && !eventData?.title
                        ? 'Event title is required'
                        : 'Task is required'
                    }
                    isModern
                  >
                    <Info size={16} color='#f24f4f' />
                  </ToolTip>
                )}
              </div>
              <div className={`form-group `} style={{ maxWidth: '38.5vw' }}>
                <div>
                  <EditableDescription
                    key={2}
                    isEditable={isEditable}
                    isUpdate
                    handleChange={(val) => handleChange(val)}
                    data={{ note: data?.note }}
                  >
                    <div className={`task-description-dummy mb-3`}>
                      <div className='d-flex  ptr ms-1 py-1' style={{ flexDirection: 'column' }}>
                        {!removeTagsAndGetContent(draftToHtml(data?.note)) ? (
                          <IconLabelSet
                            textClassName='description'
                            label={'Add Description'}
                            iconClassName='d-flex justify-content-center align-items-center'
                            icon={<File width={14} height={16} />}
                          />
                        ) : (
                          parse(draftToHtml(data?.note), LinkOptions)
                        )}
                      </div>{' '}
                    </div>
                  </EditableDescription>
                </div>
              </div>
              <div className='calendar-event-timeline-container position-relative'>
                <div className={`col-md-2 d-flex gap-2 ellipsis_icon `}>
                  <div className='position-relative'>
                    <div className={`task-icon-bg cursor-pointer calendar-date-picker `}>
                      <Popover.PopoverItem
                        positionLeft={(width) => width - '100%'}
                        positionTop={() => 'calc(100% - 230px)'}
                        scrollRef={relatedTableContainerRef}
                        zIndex={9}
                      >
                        <DatePicker
                          selected={eventData.reminder_date}
                          onChange={(date) => {
                            handleChange({ target: { name: 'reminder_date', value: date } });
                          }}
                          customInput={<ExampleCustomInput />}
                          autoFocus
                          timeFormat='hh:mm a'
                          minDate={new Date()}
                          calendarClassName='ml-2'
                          disabled={!isEditable}
                        />
                      </Popover.PopoverItem>
                    </div>
                  </div>
                </div>
                <div className={`col d-flex gap-2`}>
                  <div className='position-relative'>
                    <div
                      className={`calendar-event-container `}
                      onClick={(e) => {
                        e.stopPropagation();
                        isEditable && setContextTimeItem(true);
                      }}
                      style={{
                        width: contextTimeItem
                          ? 250
                          : eventData.start_time || eventData.end_time
                          ? eventData.start_time && eventData.end_time
                            ? '11vw'
                            : '10.6vw'
                          : 130, // Transition between widths
                        transition: 'width 0.5s ease-out, transform 0.5s ease-out', // Animate width with ease-out
                        transform: contextTimeItem ? 'scale(1)' : 'scale(1)', // Optional transform effect
                      }}
                    >
                      {!contextTimeItem ? (
                        <IconLabelSet
                          label={
                            eventData.start_time || eventData.end_time
                              ? (eventData.start_time
                                  ? moment(eventData.start_time, 'HH:mm').format('hh:mm A')
                                  : ' - - ') +
                                ' - ' +
                                (eventData.end_time
                                  ? moment(eventData.end_time, 'HH:mm').format('hh:mm A')
                                  : ' - - ')
                              : 'Choose Time'
                          }
                          gap={8}
                          icon={<Hourglass width={11} height={15} />}
                          className='ellipsis_icon task-icon-bg cursor-pointer px-2  d-flex '
                        />
                      ) : (
                        <TimeRangeSelector
                          startTime={eventData.start_time}
                          endTime={eventData.end_time}
                          onChange={handleChange}
                          className='ellipsis_icon task-icon-bg cursor-pointer calendar-date-picker calendar-time-picker  px-2'
                        />
                      )}
                    </div>
                  </div>
                </div>{' '}
                <div className='col form-group d-flex gap-2 ellipsis_icon'>
                  <div className='ellipsis_icon' hidden={!isMenuLabelOn}>
                    <EditableField
                      onSave={(val) => {
                        handleChange({
                          target: { value: val?.label, name: 'label' },
                        });
                      }}
                      key={1}
                      type='select'
                      queryFn={labelService.labelList}
                      functionParams={{
                        select: labelSelectValues,
                      }}
                      placeholder='Select labels'
                      list={labelList}
                      data={labelList?.filter((item) => eventData?.label.includes(item.value))}
                      name='label'
                      isMulti
                      isEditable={isEditable}
                      querySelect={(val) => {
                        return val?.data?.rows?.map((item) => {
                          return {
                            ...item,
                            value: item.id,
                            label: item.name,
                            id: item.id,
                          };
                        });
                      }}
                      formattedOptionLabel={(data) => {
                        return getFormattedLabel({
                          value: data?.id,
                          color: data?.label_color,
                          label: data?.name,
                        });
                      }}
                    >
                      <div role='button' className={`task-icon-bg align-items-center  px-2  `}>
                        <IconLabelSet
                          label={
                            eventData?.label?.length > 0
                              ? eventData?.label?.length + ' Label'
                              : 'Labels'
                          }
                          icon={<Label size={13} />}
                        />
                      </div>
                    </EditableField>
                  </div>
                </div>
                {/* <div className='d-flex gap-2 ms-1 position-relative mb-5 justify-content-between'></div> */}
              </div>
              <div
                className={` gap-2 ms-1 position-relative mb-2 justify-content-between pb-2 ${
                  !isNormalEvent ? 'd-flex' : 'd-none'
                }`}
              >
                <div className='my-3' style={{ marginLeft: 3 }}>
                  {/* <Popover.PopoverItem
                    positionLeft={(width) => width - '100%'}
                    positionTop={() => 'calc(100% - 230px)'}
                    scrollRef={relatedTableContainerRef}
                  > */}
                  <StackedAvatars
                    selectProps={{
                      isCustomFunction: true,
                      menuPlacement: 'top',
                      queryKey: 'user-list-verify',
                      queryFn: userService.getRoleBasedUserList,
                      getFunctionParams: (searchKey) => ({
                        searchKey,
                        select: taskAssignUserSelect,
                        action: USER_LIST_ACTIONS.ACTION_TASK_ALLOCATION,
                      }),
                    }}
                    imageList={selectedUsers}
                    getNameByKey={(user) => fullNameUser(user.first_name, user.last_name)}
                    getSourceByKey={(user) => user.image_url}
                    getGenderByKey={(user) => user.gender}
                    size={30}
                    dropdownSize={40}
                    isClearable={(user) => user.id > 0 && isEditable}
                    onAvatarRemove={(user) => {
                      handleRemoveUser(user);
                    }}
                    options={usersForVerification}
                    isAddable={isEditable}
                    justifyPosition=''
                    showInitials={false}
                    getValueByKey={(user) => user.id}
                    onAdd={(selectedUsers) => {
                      handleChange({
                        target: {
                          value: selectedUsers,
                          name: 'allocated_users',
                        },
                      });
                    }}
                  />
                  {/* </Popover.PopoverItem> */}
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
      {isEditable && (
        <div className='mx-4 my-3'>
          <hr className='m-0 my-1' />
        </div>
      )}
      <div className={`modal_footer`}>
        <div className='d-flex gap-2'>
          <Button.Container className='cancel-btn' isDisabled={isPending} handleOk={closeEditing}>
            <Button.Title title='Cancel' />
          </Button.Container>
          {isEditable && (
            <Button.Container
              type='submit'
              handleOk={() => {
                handleSubmit();
              }}
              isDisabled={isPending}
            >
              <Button.Title title={data?.id ? 'Update ' : 'Add '} />
              <Button.Loading color='#FFF' isLoading={isPending} size={20} />
            </Button.Container>
          )}
        </div>
      </div>
    </section>
  );
};

export default CalendarEventAdd;
