/* eslint-disable no-unused-vars */
import { GitMerge } from 'lucide-react';
import React, { useState } from 'react';
import { Images, validateTaskWithTemplateTest } from '../../Utils';
import { Button, Modal } from '../../_components';
import { DELETE_NOTE } from '../../_constants/deletenote.constants';
import { formMode } from '../taskConstants';
import { InlineRelatedTaskAdd } from './InlineRelatedTaskAdd';
import { InlineRelatedTaskItem } from './InlineRelatedTaskItem';

function SingleRelatedTaskPreview({
  index,
  relatedTaskDetails,
  allRelatedTask,
  // onEdit = () => {},
  onDelete = () => {},
  onSubmit,
  userList = [],
  setUserSearch,
  userSearch,
  templateData,
  labelSearch,
  labelList = [],
  setLabelSearch,
  isRelatedTask = false,
  componentMode = formMode.FORM,
  isMenuSubTaskOn = false,
  isMenuLabelOn = false,
  scrollRef = null,
  expandAll = {},
  setExpandAll = () => {},
}) {
  const validation_fields = templateData?.validation_fields;
  const [editTaskIndex, setEditTaskIndex] = useState(null); //used for tracing the currently editing task's index.

  const [deleteModal, setDeleteModal] = useState(false);
  let validationData = [];
  const validationError = validateTaskWithTemplateTest(validation_fields, [relatedTaskDetails]);
  if (!validationError?.isValid) {
    validationData = validationError?.requiredFields;
  }

  if (componentMode == formMode.INLINE || editTaskIndex != null) {
    return (
      <div className='d-flex align-items-center w-100 gap-2cpy-2'>
        {componentMode == formMode.INLINE && <GitMerge size={18} color='#879096' />}
        <InlineRelatedTaskAdd
          editTaskIndex={editTaskIndex}
          key={editTaskIndex}
          setUserSearch={setUserSearch}
          taskData={relatedTaskDetails}
          setTaskData={(value) => {
            onSubmit(value);
          }}
          templateData={templateData}
          onAdd={() => {
            setEditTaskIndex(null);
          }}
          setLabelSearch={setLabelSearch}
          labelSearch={labelSearch}
          userSearch={userSearch}
          userList={userList}
          labelList={labelList}
          isEdit={true}
          onActiveForm={true}
          onCancel={() => {
            setEditTaskIndex(null);
          }}
          isRelatedTask={isRelatedTask}
          componentMode={componentMode}
          isUpdateButtonVisible
        />
      </div>
    );
  }
  return (
    <div className=''>
      <div className='d-flex align-items-center justify-content-between gap-2  py-2 '>
        <InlineRelatedTaskItem
          relatedTaskData={relatedTaskDetails}
          setEditTaskIndex={setEditTaskIndex}
          index={index}
          setDeleteModal={setDeleteModal}
          onSubmitSubtask={(subtasks) => onSubmit({ subtasks: subtasks }, index)}
          allRelatedTask={allRelatedTask}
          validation_fields={validation_fields}
          isMenuSubTaskOn={isMenuSubTaskOn}
          isMenuLabelOn={isMenuLabelOn}
          setLabelSearch={setLabelSearch}
          setUserSearch={setUserSearch}
          labelSearch={labelSearch}
          userSearch={userSearch}
          userList={userList}
          labelList={labelList}
          setTaskData={(value) => {
            onSubmit(value);
          }}
          scrollRef={scrollRef}
          expandAll={expandAll}
          setExpandAll={setExpandAll}
        />
      </div>
      {deleteModal && (
        <Modal.Container>
          <Modal.View className={'status-delete'}>
            <Modal.Head handleClose={() => setDeleteModal(false)} Heading={'Delete Confirmation'} />
            <Modal.Description>
              <img src={Images.DeleteBin} className='my-2' width={35} height={50} alt='' />
              <div className='del-description'>{DELETE_NOTE.RELATED_TASK}</div>
            </Modal.Description>
            <div className='modal_footer'>
              <Button.Container
                handleOk={() => setDeleteModal(false)}
                className={'status-del-cancel'}
              >
                <Button.Title title={'Cancel'} />
              </Button.Container>
              <Button.Container
                handleOk={() => {
                  onDelete();
                  setDeleteModal(false);
                }}
              >
                <Button.Title title={'Delete'} />
                <Button.Loading color='#FFF' />
              </Button.Container>
            </div>
          </Modal.View>
        </Modal.Container>
      )}
    </div>
  );
}

export default SingleRelatedTaskPreview;
