import React, { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import ToolTip from '../../_components/Tooltip/ToolTip';
import { ChatGroupCreateIcon, ChatUnreadIcon } from '../../Utils/SvgIcons';
import ChatCreationPopUp from '../ChatCreationPopUp';

function ChatUpdateHeader({ onGroupCreation }) {
  const [searchParam, setSearchParam] = useSearchParams();
  const isUnread = searchParam.get('unread');
  const [isGroupAddActive, setIsGroupAddActive] = useState(false);
  // const [isUnreadActive, setIsUnreadActive] = useState(false);
  return (
    <div className='d-flex justify-content-between ' style={{ gap: 5 }}>
      <div
        className={`d-flex gap-2 position-relative align-items-center ${
          isGroupAddActive ? 'btn-active-accent' : ''
        }`}
        onClick={() => setIsGroupAddActive(true)}
      >
        <ToolTip tooltipText={'Create Group'} isModern hideTooltip={isGroupAddActive}>
          <div className='add_item_button add-btn-chat d-flex'>
            <ChatGroupCreateIcon color={isGroupAddActive ? 'var(--accent-color)' : '#87909E'} />
          </div>
          {isGroupAddActive && (
            <ChatCreationPopUp
              onGroupCreation={onGroupCreation}
              onClose={(event) => {
                event && event.stopPropagation();
                setIsGroupAddActive(false);
              }}
            />
          )}
        </ToolTip>
      </div>
      <div
        className={`d-flex gap-2 position-relative align-items-center ${
          isUnread ? 'btn-active-accent' : ''
        }`}
        onClick={() => {
          if (!isUnread) {
            searchParam.set('unread', 'true');
          } else {
            searchParam.delete('unread');
          }
          setSearchParam(searchParam);
        }}
      >
        <ToolTip tooltipText='Unread' isModern hideTooltip={isUnread}>
          <div className='add_item_button add-btn-chat d-flex'>
            <ChatUnreadIcon color={isUnread ? 'var(--accent-color)' : '#87909E'} />
          </div>
        </ToolTip>
      </div>
    </div>
  );
}

export default ChatUpdateHeader;
