/* eslint-disable no-unused-vars */
import { ArrowDownToLine, Plus, SendHorizontal, Trash2, X } from 'lucide-react';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import useProgressDownloader from '../Hook/useProgressDownloader';
import { convertBytes, fullNameUser as getFullName } from '../Utils';
import { CircularProgressBar } from '../Utils/SvgIcons';
import CircularAvatar from '../_components/Avatar/CircularAvatar';
import { apiConstants } from '../_constants';
import ChatAttachmentsMenu from './ChatAttachments/ChatAttachmentsMenu';
import { ChatMessageCards } from './ChatAttachments/ChatMessageCards';
import ChatInputBox from './ChatInputBox';

function MediaPreview({
  isInput = false,
  isPrevBeforeSend = false,
  payloads = [],
  files = [],
  isLocal = false,
  createdTime = null,
  currentIndex = 0,
  creatorDetails = null,
  onClose = () => {},
  onFileChange = () => {},
  onSubmit = () => {},
  onInputChange = () => {},
  onDelete = () => {},
}) {
  const [index, setIndex] = useState(currentIndex);
  const [IsAttachmentMenuVisible, setIsAttachmentMenuVisible] = useState(false);
  const [attachmentPosition, setAttachmentPosition] = useState({ x: 0, y: 0 });
  const [isTextAreaFocused, setIsTextAreaFocused] = useState(false);

  useEffect(() => {
    const handleKeyDown = (event) => {
      const textAreaElement = document.getElementById('chat-text-input-ta');
      if (textAreaElement && document.activeElement === textAreaElement) {
        return;
      }
      switch (event.key) {
        case 'ArrowLeft':
          setIndex((prevIndex) => (prevIndex - 1 + files.length) % files.length);
          break;
        case 'ArrowRight':
          setIndex((prevIndex) => (prevIndex + 1) % files.length);
          break;
        default:
          break;
      }
    };

    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [files.length]);

  // Set isUploading false when previewing before sent, since all added file will have isUpload true by default
  const focusedFile = isPrevBeforeSend ? { ...files[index], isUploading: false } : files[index];

  const { downloadFile, abortDownload, isDownloading, progress, downloadedBytes } =
    useProgressDownloader({
      fileName: focusedFile.name,
      sourceUrl: apiConstants.imgUrlS3 + focusedFile.uploaded_path,
    });
  const { value: loadedMB, unit: loadedUnit } = convertBytes(downloadedBytes);

  const handleDelete = (idx) => {
    if (idx >= files.length - 1) {
      setIndex((prev) => prev - 1);
    }

    onDelete(idx, files.length - 1);
  };

  return createPortal(
    <div className='media-preview fade-in'>
      {/* For non-input -> header username, download, forward For both -> close */}
      <div className='d-flex justify-content-between'>
        <div className='d-flex gap-1'>
          {creatorDetails && (
            <>
              <CircularAvatar
                isMale={creatorDetails?.gender === 1}
                size={40}
                source={apiConstants.imgUrlS3 + creatorDetails?.image_url}
              />
              <div>
                <div className='fz-15px fw-500'>
                  {getFullName(creatorDetails.first_name, creatorDetails.last_name)}
                </div>
                {createdTime && (
                  <div className='grey-txt fz-13px'>{moment(createdTime).format('hh:mm a')}</div>
                )}
              </div>
            </>
          )}
        </div>
        {/* CLose button */}
        <div className='d-flex gap-4'>
          {/* Don't need the download button for local files */}
          {!isLocal && (
            <div>
              {isDownloading ? (
                <CircularProgressBar
                  unit={loadedUnit}
                  onClick={() => {
                    abortDownload();
                  }}
                  downloadedMB={loadedMB}
                  size='35'
                  percentage={Math.round(progress)}
                  color='black'
                />
              ) : (
                <div className='btn-wrapper pe-1' onClick={downloadFile}>
                  <ArrowDownToLine />
                </div>
              )}
            </div>
          )}
          <div className='btn-wrapper pe-1' onClick={() => onClose()}>
            <X />
          </div>
        </div>
      </div>

      <div className='main-prev my-4'>
        <ChatMessageCards.Image file={focusedFile} isLocal={isLocal} />
        <ChatMessageCards.DocumentPreview file={focusedFile} isLocal={isLocal} />
        <ChatMessageCards.Video file={focusedFile} isLocal={isLocal} />
        <ChatMessageCards.Audio key={focusedFile.name} file={focusedFile} isLocal={isLocal} />
        {/* <ChatMessageCards.RecordedAudio file={focusedFile} isLocal={isLocal} /> */}
      </div>

      {/* input box for text only */}
      {isInput && (
        <ChatInputBox
          hideAttachments
          hideAudioRec
          hideSend
          initialObject={{ message: payloads[index].message, files: files }}
          onSubmit={onSubmit}
          onInputChange={(e) => onInputChange(e, index)}
        />
      )}

      {/* For both Images  */}
      <div className='d-flex gap-1'>
        <div className={`image-prev-slide overflow-auto`}>
          {files.map((file, idx) => {
            const imageUrl = isLocal
              ? URL.createObjectURL(file.file)
              : apiConstants.imgUrlS3 + file?.uploaded_path;
            const isActive = idx === index;
            const isLastIndex = !isInput && idx === files.length - 1;
            return (
              <div
                key={file.name}
                onClick={() => (isActive && isInput ? handleDelete(idx) : setIndex(idx))}
                className={`${isActive ? 'active' : ''} selected-img ${
                  // TODO: REMOVE THE me-auto on else part after implementing the chat multi-upload
                  isLastIndex ? 'me-auto' : ''
                }`}
              >
                {isInput && (
                  <div className='delete-btn btn-wrapper pe-1'>
                    <Trash2 size={20} color='black' />
                  </div>
                )}

                <ChatMessageCards.FileIcons attachedType={file.attachedType} imgUrl={imageUrl} />
              </div>
            );
          })}
          {/* Temporarily removed, TODO: Remove false condition after multi-upload in chat as an individual messages is done*/}
          {isInput && (
            <div
              className='image-prev-add btn-wrapper pe-1 me-auto'
              onClick={(event) => {
                event.stopPropagation();
                setAttachmentPosition({ x: event.clientX, y: event.clientY });
                setIsAttachmentMenuVisible(true);
              }}
            >
              <Plus size={60} />
            </div>
          )}
          {IsAttachmentMenuVisible && (
            <div
              style={{
                position: 'fixed',
                left: attachmentPosition.x - 130,
                top: attachmentPosition.y,
              }}
            >
              <ChatAttachmentsMenu
                onChange={onFileChange}
                onRequestClose={() => setIsAttachmentMenuVisible(false)}
                multiSelect
              />
            </div>
          )}
        </div>
        {isInput && (
          <button className='send-btn' onClick={onSubmit}>
            <SendHorizontal size={20} color='#FFF' />
          </button>
        )}
      </div>
    </div>,
    document.getElementById('MODAL'),
  );
}

export default MediaPreview;
