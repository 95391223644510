import { keepPreviousData, useQuery, useQueryClient } from '@tanstack/react-query';
import { Home, ListFilter, Pen, Plus, Trash } from 'lucide-react';
import moment from 'moment';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Table, Tbody, Td, Th, Thead, Tr } from 'react-super-responsive-table';
import { TaskListTypeConst } from '../BoardView/BoardView.constant';
import useOutsideClickHandler from '../Hook/useOutsideClickHandler';
import { GlobalContext, Images, MyTeamIcon, fullNameUser } from '../Utils';
import {
  Calendar,
  CompletedDot,
  LoadingIcon,
  ProgressIcon,
  ProjectIcon,
  StatusIcon,
  User,
} from '../Utils/SvgIcons';
import { Button, Modal, PaginationComponent, TableRow } from '../_components';
import CircularAvatar from '../_components/Avatar/CircularAvatar';
import { ErrorComponent } from '../_components/ErrorComponent';
import IconLabelSet from '../_components/IconLabelSet';
import NoPermission from '../_components/NoPermission';
import { SearchBar } from '../_components/Search';
import ToolTip from '../_components/Tooltip/ToolTip';
import { LIST_PERMISSION_VALUE, MENU, RT, apiConstants } from '../_constants';
import { DELETE_NOTE } from '../_constants/deletenote.constants';
import useDebounce from '../_helpers/useDebounce';
import { projectService } from '../_services';
import '../common.css';
import { ProjectFilter } from './ProjectFilter';

export function ProjectList() {
  // hooks Section
  const nav = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const queryClient = useQueryClient();
  const [isFilterVisible, setIsFilterVisible] = useState(false);
  const { makeAlert, getMenuLabelName, roleBasedPermission } = useContext(GlobalContext);

  const LABEL_OF_PROJECT = getMenuLabelName(MENU.project.default_name);

  const PROJECT_TABLE_HEADINGS = [
    { name: <img src={Images.SerialNo} height={14} width={14} />, minWidth: 50 },
    {
      name: (
        <div className='d-flex align-items-center gap-1'>
          <ProjectIcon height={16} width={16} color='#87909E' /> {LABEL_OF_PROJECT}
        </div>
      ),
      minWidth: '10vw',
      maxWidth: '20vw',
    },
    {
      name: (
        <div className='d-flex align-items-center gap-1'>
          <ProgressIcon /> Progress
        </div>
      ),
      minWidth: 50,
    },
    {
      name: (
        <div className='d-flex align-items-center gap-1'>
          <User /> Owner
        </div>
      ),
      minWidth: 50,
    },
    {
      name: (
        <div className='d-flex align-items-center gap-1'>
          <StatusIcon /> Status
        </div>
      ),
      minWidth: 50,
    },
    {
      name: (
        <div className='d-flex align-items-center gap-1'>
          <Calendar height={14} width={14} /> Start Date
        </div>
      ),
      minWidth: 50,
    },
    {
      name: (
        <div className='d-flex align-items-center gap-1'>
          {' '}
          <Calendar height={14} width={14} /> Due Date
        </div>
      ),
      minWidth: 50,
    },
    {
      name: <div></div>,
    },
  ];

  // reference section
  const tableContainerRef = useRef(null);
  const reference = useRef(null);

  // useState section
  const [query, setQuery] = useState({
    select: ['id', 'name', 'start_date', 'end_date', 'status'],
    pageVo: {
      pageNo: parseInt(searchParams.get('pageNo')) || 1,
      noOfItems: 10,
    },
    searchKey: '',
  });
  const project_owner_id = searchParams.get('project_owner');
  const status = searchParams.get('status');
  const start_date = searchParams.get('start_date');
  const end_date = searchParams.get('end_date');
  const [deleteModal, setDeleteModal] = useState(false);
  const [editProjectId, setEditProjectId] = useState(null);

  const debouncedSearch = useDebounce(query?.searchKey, 500);

  // code to redirect page 1 after adding filter
  useEffect(() => {
    if (searchParams.get('pageNo')) {
      const page = parseInt(searchParams.get('pageNo'));
      if (query.pageVo.pageNo !== page) {
        setQuery((prevQuery) => ({
          ...prevQuery,
          pageVo: { ...prevQuery.pageVo, pageNo: page },
        }));
      }
    }
  }, [searchParams, query.pageVo.pageNo]);

  const modalClose = () => {
    setDeleteModal(false);
    setEditProjectId(null);
  };

  // filter popup reference
  const { containerRef: filterRef } = useOutsideClickHandler({
    onClose: () => setIsFilterVisible(false),
  });

  const filterToUrl = {};
  if (project_owner_id) filterToUrl.project_owner_id = project_owner_id;
  if (status) filterToUrl.status = status === 'active' ? 1 : -1;
  if (start_date) filterToUrl.start_date = start_date;
  if (end_date) filterToUrl.end_date = end_date;

  const filterCount = Object.keys(filterToUrl).length;
  const {
    data: projectData,
    isLoading,
    isSuccess,
    error,
    refetch,
  } = useQuery({
    queryKey: ['projectList', query?.pageVo?.pageNo, debouncedSearch, filterToUrl],
    queryFn: () => {
      const queryParams = { ...query, ...filterToUrl };
      if (!status) {
        queryParams.listFor = 'active-inactive';
      }
      return projectService.projectListAdvanced(queryParams);
    },
    enabled: roleBasedPermission?.ACTION_PROJECT_LIST !== LIST_PERMISSION_VALUE.NO_ACCESS,
    placeholderData: keepPreviousData,
  });

  // section trigger when delete option selected

  const handleDeactivate = async (id) => {
    try {
      await projectService.projectDeactivate({ id });
      let isNeedToBack = projectData?.data?.rows.length === 1 && projectData?.data?.pages > 1;

      if (isNeedToBack) {
        const page = searchParams.get('pageNo');
        searchParams.set('pageNo', page - 1);
        setSearchParams(searchParams);
        setQuery((prev) => ({
          ...prev,
          pageVo: {
            ...prev.pageVo,
            pageNo: prev.pageVo.pageNo - 1,
          },
        }));
      } else {
        refetch();
      }
      await queryClient.invalidateQueries('projectList');
      modalClose();

      makeAlert('Deleted Successfully');
    } catch (error) {
      makeAlert(error?.message || 'Failed to delete');

      console.error('Error deleting project :', error);
    }
  };

  // NO ACCESS MANAGE
  if (roleBasedPermission?.ACTION_PROJECT_LIST === LIST_PERMISSION_VALUE.NO_ACCESS) {
    return <NoPermission />;
  }

  // get the count of project
  const listItemCount = projectData?.data?.rows.length;

  const tableData = projectData?.data?.rows?.map((project, index) => {
    const slNo = (projectData?.data?.page - 1) * projectData?.data?.limit + index + 1;
    const ownerName = fullNameUser(
      project?.project_owner_details?.first_name,
      project?.project_owner_details?.last_name,
    );
    const isMale = project?.project_owner_details?.gender === 1;
    const isNeutral = project?.project_owner_details?.gender === 3;
    const url = project?.project_owner_details?.compressed_image_url
      ? project?.project_owner_details?.compressed_image_url
      : project?.project_owner_details?.image_url;
    const finalUrl = url ? apiConstants.imgUrlS3 + url : null;
    let childProgress = parseFloat(project?.completion_percentage);
    // Ensure childProgress is a number before formatting
    if (typeof childProgress === 'number' && childProgress > 0) {
      // Check if the childProgress is a whole number
      if (childProgress % 1 === 0) {
        childProgress = childProgress.toFixed(0); // No decimal points
      } else {
        childProgress = childProgress.toFixed(1); // One decimal point
      }
    }

    const getColorBasedOnPercent = () => {
      if (childProgress < 30) {
        return { full: 'rgba(234, 77, 77, 1)', light: 'rgba(234, 77, 77, 0.2)' };
      } else if (childProgress < 70) {
        return { full: 'rgba(237, 166, 0, 1)', light: 'rgba(237, 166, 0, 0.2)' };
      } else {
        return { full: 'rgba(28, 169, 119, 1)', light: 'rgba(28, 169, 119, 0.2)' };
      }
    };

    const progressColors = getColorBasedOnPercent();

    return (
      <Tr
        key={project?.id}
        onClick={() => {
          nav(
            `/${RT.PROJECT}/${project?.id}/${RT.PROJECT_TASK_LIST}?status=${
              project.navigation_id ?? 'in-progress'
            }&&list_type=${TaskListTypeConst.Project}&&pageNo=${searchParams.get('pageNo') ?? 1}`,
          );
        }}
      >
        <td style={{ fontWeight: 500, paddingLeft: 24 }}>{slNo}</td>
        <td className='cursor-pointer table-responsive-td' style={{ fontWeight: 500 }}>
          {project?.name}
        </td>
        <Td>
          <div className='d-flex align-items-center gap-2'>
            <div className='progress-bar-parent' style={{ backgroundColor: progressColors.light }}>
              <div
                className='progress-bar-child'
                style={{ width: childProgress + '%', backgroundColor: progressColors.full }}
              />
            </div>
            {childProgress + '%'}
          </div>
        </Td>
        <Td>
          <div className='d-flex align-items-center'>
            <CircularAvatar
              size={25}
              className='fz-none zoom-in employee-img-with-low-border'
              source={finalUrl}
              isMale={isMale}
              isNeutral={isNeutral}
            />
            <span style={{ fontWeight: 500 }}>{ownerName}</span>
          </div>
        </Td>
        <Td>
          {project?.status === 1 ? (
            <div className='d-flex align-items-center' style={{ gap: 10 }}>
              <CompletedDot />{' '}
              <span className='completed-text' style={{ fontWeight: 500 }}>
                Active
              </span>
            </div>
          ) : (
            <div className='d-flex align-items-center' style={{ gap: 10 }}>
              <CompletedDot color='#87909E' />{' '}
              <span className='completed-text' style={{ fontWeight: 500 }}>
                In-active
              </span>
            </div>
          )}
        </Td>

        <Td>
          <span>
            {project?.start_date
              ? moment(parseInt(project?.start_date)).format('MMM DD, YYYY')
              : '--'}
          </span>
        </Td>
        <Td>
          <span>
            {project?.end_date ? moment(parseInt(project?.end_date)).format('MMM DD, YYYY') : '--'}
          </span>
        </Td>

        <Td
          style={{ verticalAlign: 'middle', cursor: 'pointer' }}
          ref={reference}
          onClick={(event) => {
            event.stopPropagation();
          }}
        >
          {(roleBasedPermission?.ACTION_PROJECT_DELETE ||
            roleBasedPermission?.ACTION_PROJECT_EDIT) && (
            <div className='d-flex gap-2 justify-content-end icon-div invisible'>
              {roleBasedPermission?.ACTION_PROJECT_EDIT && (
                <ToolTip
                  tooltipText={`Edit ${LABEL_OF_PROJECT}`}
                  isModern
                  popOver
                  scrollRef={tableContainerRef}
                >
                  <div
                    className='icon-bg'
                    onClick={(event) => {
                      event.stopPropagation();
                      setEditProjectId(project?.id);
                      nav(
                        `/${RT.PROJECT_ADD}/${project?.id}?pageNo=${
                          searchParams.get('pageNo') ?? 1
                        }`,
                      );
                    }}
                  >
                    <Pen size={15} />
                  </div>
                </ToolTip>
              )}
              {roleBasedPermission?.ACTION_PROJECT_DELETE && (
                <ToolTip
                  tooltipText={`Delete ${LABEL_OF_PROJECT}`}
                  isModern
                  popOver
                  scrollRef={tableContainerRef}
                >
                  <div
                    className='icon-bg'
                    onClick={(event) => {
                      event.stopPropagation();
                      setEditProjectId(project?.id);
                      setDeleteModal(true);
                    }}
                  >
                    <Trash size={15} />
                  </div>
                </ToolTip>
              )}
            </div>
          )}
        </Td>
      </Tr>
    );
  });

  return (
    <div className='fade-entry'>
      <div className=' url-container '>
        <div className='d-flex align-items-center  breadcrumbs url-navigation'>
          <IconLabelSet
            label={'Home'}
            fontWeight={400}
            icon={<Home size={13} />}
            onClick={() => nav(`/`)}
          />
          <img className='slash-img-icon-size ms-1 mt-1' src={MyTeamIcon.Slash} alt='' />
          <IconLabelSet
            label={LABEL_OF_PROJECT}
            fontWeight={500}
            color={`#2A2E34`}
            icon={<ProjectIcon height={12} width={12} color='#2A2E34' />}
            onClick={() => nav(`/${RT.PROJECT_LIST}`)}
          />
        </div>
      </div>
      {/* component will active when error occurs   */}
      <div>
        <ErrorComponent error={error?.message} />
        <div className='project-table-container'>
          {/* header input section */}
          <section>
            <div className='tab_contain'>
              <SearchBar
                handleSearch={(event) => {
                  searchParams.set('pageNo', 1);
                  setSearchParams(searchParams);
                  setQuery({
                    ...query,
                    searchKey: event.target.value,
                    pageVo: { ...query.pageVo, pageNo: 1 },
                  });
                }}
                searchKey={query.searchKey}
              />
              <div className='d-flex gap-1 align-items-center justify-content-end'>
                {roleBasedPermission?.ACTION_PROJECT_ADD && (
                  <ToolTip tooltipText={`Add ${LABEL_OF_PROJECT}`} isModern>
                    <div className='menu-item-outline' onClick={() => nav(`/${RT.PROJECT_ADD}/0`)}>
                      <Plus size={16} color='#87909E' />
                    </div>
                  </ToolTip>
                )}

                {/* <ToolTip tooltipText={'Sort'} isModern hideTooltip={isSortVisible}>
              <div
                className='menu-item-outline'
                ref={reference}
                style={{ background: isSortVisible || Boolean(sortCount) ? '#e9f2ff' : '' }}
                onClick={() => {
                  setIsFilterVisible(false);
                  setIsSortVisible(true);
                }}
              >
                <ArrowUpDown
                  size={16}
                  color={isSortVisible || Boolean(sortCount) ? '#3454d1' : '#87909E'}
                />

                {isSortVisible && (
                  <div>
                    <TaskListSort
                      searchParams={searchParams}
                      setSearchParams={setSearchParams}
                      handleClose={(event) => {
                        event.stopPropagation();
                        setIsSortVisible(false);
                      }}
                      setQuery={setQuery}
                    />
                  </div>
                )}
              </div>
            </ToolTip> */}
                <ToolTip tooltipText={'Filter'} isModern hideTooltip={isFilterVisible}>
                  <div
                    className='menu-item-outline'
                    style={{
                      background: isFilterVisible || Boolean(filterCount) ? '#e9f2ff' : '',
                    }}
                    ref={filterRef}
                    onClick={() => {
                      // setIsSortVisible(false);
                      setIsFilterVisible(true);
                    }}
                  >
                    <ListFilter
                      size={16}
                      color={isFilterVisible || Boolean(filterCount) ? '#3454d1' : '#87909E'}
                    />
                    {isFilterVisible && (
                      <ProjectFilter
                        setSearchParams={setSearchParams}
                        handleClose={(event) => {
                          event.stopPropagation();
                          setIsFilterVisible(false);
                        }}
                        searchParams={searchParams}
                      />
                    )}

                    {Boolean(filterCount) && <div className='filter-count'>{filterCount}</div>}
                  </div>
                </ToolTip>
              </div>
            </div>
          </section>

          <section className='mt-2'>
            <div
              className='project-table table-container no-padding'
              ref={tableContainerRef}
              style={{ maxHeight: '65vh' }}
            >
              <Table className='table table-hover tbl mb-0'>
                <Thead>
                  <Tr className='table-head'>
                    {PROJECT_TABLE_HEADINGS.map((heading, index) => {
                      return (
                        <Th
                          key={index}
                          scope='col'
                          style={{ minWidth: heading.minWidth, maxWidth: heading.maxWidth }}
                        >
                          {heading.name}
                        </Th>
                      );
                    })}
                  </Tr>
                </Thead>
                <Tbody style={{ verticalAlign: 'middle' }}>
                  {isLoading && (
                    <TableRow noOfCols={8}>
                      <LoadingIcon size={60} />
                    </TableRow>
                  )}
                  {!listItemCount && isSuccess && <TableRow noOfCols={8}>No data found</TableRow>}
                  {listItemCount > 0 && isSuccess && tableData}
                </Tbody>
              </Table>
            </div>
          </section>
          {listItemCount > 0 && (
            <section className='mt-2'>
              <PaginationComponent
                pageOnClick={(data) => {
                  setQuery((prev) => ({
                    ...prev,
                    pageVo: {
                      ...prev.pageVo,
                      pageNo: data,
                    },
                  }));
                  searchParams.set('pageNo', data);
                  setSearchParams(searchParams);
                }}
                demoData={projectData?.data}
              />
            </section>
          )}
        </div>

        {deleteModal && (
          <Modal.Container>
            <Modal.View className={'status-delete'}>
              <Modal.Head handleClose={() => modalClose()} Heading={'Delete Confirmation'} />
              <Modal.Description>
                <img src={Images.DeleteBin} width={35} height={50} alt='' className='mb-3' />
                <div className='del-description'>{DELETE_NOTE.PROJECT}</div>
              </Modal.Description>
              <div className='modal_footer'>
                <Button.Container handleOk={() => modalClose()} className={'status-del-cancel'}>
                  <Button.Title title={'Cancel'} />
                </Button.Container>
                <Button.Container handleOk={() => handleDeactivate(editProjectId)}>
                  <Button.Title title={'Delete'} />
                </Button.Container>
              </div>
            </Modal.View>
          </Modal.Container>
        )}
      </div>
    </div>
  );
}
