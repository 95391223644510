/* eslint-disable no-unused-vars */
import { Bell } from 'lucide-react';
import React from 'react';
import { CustomEmptyContainer } from '../Task/TaskComponents/CustomInputs';
import { NOTIFICATION_EMPTY_TEXTS } from './notificationConstant';
import { NotificationItem } from './NotificationItem';

const TaskNotification = ({ data, setIsNotificationVisible = () => {} }) => {
  // const user = useWhoAmI();
  const pathName = window.location.pathname;

  return (
    <div>
      {data?.length ? (
        data?.map((item, index) => {
          return (
            <>
              <NotificationItem
                setIsNotificationVisible={setIsNotificationVisible}
                data={item}
                // whoAmI={user}
                pathName={pathName}
              />
            </>
          );
        })
      ) : (
        <CustomEmptyContainer
          Icon={() => {
            return (
              <div className='notification-icon-empty-container'>
                <Bell size={19} color='#87909E' />
              </div>
            );
          }}
          text={NOTIFICATION_EMPTY_TEXTS.NOTIFICATION_UNREAD}
          disableAdd
        />
      )}
    </div>
  );
};

export default TaskNotification;
