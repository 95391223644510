import React, { useState } from 'react';
import { DateRange } from 'react-date-range';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import IconLabelSet from '../_components/IconLabelSet';
import useOutsideClickHandler from '../Hook/useOutsideClickHandler';
import './Report.css';

const ReportDatePicker = ({ onDateChange, initialStartDate, initialEndDate }) => {
  const [dateVisible, setDateVisible] = useState(false);
  const [state, setState] = useState([
    {
      startDate: initialStartDate,
      endDate: initialEndDate,
      key: 'selection',
    },
  ]);

  const { containerRef: dateRef } = useOutsideClickHandler({
    onClose: () => {
      onDateChange(state[0]);
      setDateVisible(false);
    },
    dependencies: [state[0].startDate, state[0].endDate],
  });

  const handleSelect = (item) => {
    const startDate = item.selection.startDate;
    const endDate = item.selection.endDate;
    setState([{ startDate: startDate, endDate: endDate, key: 'selection' }]);
  };

  // const formatDate = (timestamp) => {
  //   const date = new Date(timestamp);
  //   return date.toLocaleDateString('en-GB', {
  //     day: 'numeric',
  //     month: 'long',
  //     year: 'numeric',
  //   });
  // };
  const formatDate = (timestamp) => {
    const date = new Date(timestamp);
    return date.toLocaleDateString('en-GB', {
      day: 'numeric',
      month: 'short',
    });
  };

  return (
    <div style={{ position: 'relative' }}>
      {/* <div
        ref={dateRef}
        style={{
          background: '#F2F2F2',
          height: 30,
          width: 'fit-content',
          border: 'none',
          padding: '2px 5px',
          fontSize: '13px',
          borderRadius: '5px',
          cursor: 'pointer',
          color: 'gray',
          display: 'flex',
          alignItems: 'center',
        }}
        onClick={() => {
          setDateVisible(!dateVisible);
        }}
      >
        <span style={{ color: 'gray' }}>Date:</span>{' '}
        {state ? `${formatDate(state[0].startDate)} - ${formatDate(state[0].endDate)}` : ''}{' '}
        <ChevronDown size={16} />
      </div> */}

      <div
        className='time-history-date'
        onClick={() => {
          setDateVisible(!dateVisible);
        }}
        ref={dateRef}
      >
        <IconLabelSet
          className='d-flex'
          label={`${formatDate(state[0].startDate)} - ${formatDate(state[0].endDate)}`}
          gap={8}
          color={'#2a2e34'}
          fontWeight={'500'}
          // icon={<Calendar size={13} />}
        />
      </div>
      {dateVisible && (
        <div
          ref={dateRef}
          style={{
            position: 'absolute',
            zIndex: 1000,
            background: 'var(--bg-primary)',
            boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
            borderRadius: '8px',
            marginTop: 7,
          }}
        >
          <DateRange
            editableDateInputs={true}
            onChange={handleSelect}
            moveRangeOnFirstSelection={false}
            ranges={state.map((range) => ({
              ...range,
              startDate: range.startDate,
              endDate: range.endDate,
            }))}
          />
        </div>
      )}
    </div>
  );
};

export default ReportDatePicker;
