import React, { useEffect } from 'react';
import useOutsideClickHandler from '../Hook/useOutsideClickHandler';
import '../main.css';
export const SelectableContextMenu = ({
  isContextVisible,
  onRequestClose = () => {},
  isClosable = true,
  children,
  className = 'selectable-context-menu',
}) => {
  // const contextRef = useRef(null);

  const { containerRef: contextRef } = useOutsideClickHandler({ onClose: () => onRequestClose() });

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!contextRef.current) {
        return;
      }
      const parentElement = contextRef.current.parentNode;
      if (parentElement && !parentElement.contains(event.target)) {
        isClosable && onRequestClose();
      }
    };
    document.addEventListener('click', handleClickOutside, false);
    return () => {
      document.removeEventListener('click', handleClickOutside, false);
    };
  }, []);

  if (!isContextVisible) {
    return null;
  }
  return (
    <div ref={contextRef} className={className}>
      {children}
    </div>
  );
};
