import { authHeader, authHeaderMulti, handleResponse } from '../_helpers';

import { apiConstants } from '../_constants';

export const miscService = {
  fileUpload,
  createSignedUploadUrl,
  b64toBlob,
  formatDate,
  formatTime,
  getParameterByName,
  getMetaDataFromUrl,
  initFunction,
  hexToRgbAndReduceIntensity,
  globalSearchResultList,
  getWidget,
  addWidget,
  getAllWidgetPresets,
};

function createSignedUploadUrl(data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(data),
  };
  return fetch(apiConstants.apiUrl + '/get_signed_upload_url', requestOptions).then(handleResponse);
}

function b64toBlob(b64Data, contentType, sliceSize) {
  contentType = contentType || '';
  sliceSize = sliceSize || 512;

  let byteCharacters = atob(b64Data);
  let byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    let slice = byteCharacters.slice(offset, offset + sliceSize);

    let byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    let byteArray = new Uint8Array(byteNumbers);

    byteArrays.push(byteArray);
  }

  let blob = new Blob(byteArrays, { type: contentType });
  return blob;
}

function fileUpload(file, name) {
  let data = new FormData();
  data.append('file', file, name);
  data.append('name', 'filename');

  const requestOptions = {
    method: 'POST',
    body: data,
    headers: authHeaderMulti(),
  };
  return fetch(apiConstants.apiUrl + '/uploadfileblob', requestOptions).then(handleResponse);
}

function formatDate(date) {
  let d = new Date(date),
    month = '' + (d.getMonth() + 1),
    day = '' + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = '0' + month;
  if (day.length < 2) day = '0' + day;

  return [day, month, year].join('/');
}

function formatTime(date) {
  let d = new Date(date),
    hours = d.getHours(),
    minutes = '' + d.getMinutes(),
    a = ' AM';

  if (hours > 12) {
    hours = '' + hours - 12;
    a = ' PM';
  } else if (hours === 0) {
    hours = '12';
  } else {
    hours = '' + hours;
  }

  if (minutes.length < 2) minutes = '0' + minutes;

  return hours + ':' + minutes + a;
}

function getParameterByName(name, url) {
  if (!url) url = window.location.href;
  name = name.replace(/[[\]]/g, '\\$&');
  var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
    results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return '';
  return decodeURIComponent(results[2].replace(/\+/g, ' '));
}

async function getMetaDataFromUrl(url) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify({ url }),
  };
  const response = await fetch(`${apiConstants.apiUrl}/metadata`, requestOptions);
  return handleResponse(response);
}

async function initFunction(data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify({ init: true, updateEnabledValues: data }),
  };
  return fetch(apiConstants.apiUrl + '/initialize_data', requestOptions).then(handleResponse);
}

function hexToRgbAndReduceIntensity(hex, intensityPercent) {
  hex = hex || '87909E';
  // Remove the '#' if present
  hex = hex.replace('#', '');

  // Convert hex to RGB
  let r = parseInt(hex.substring(0, 2), 16);
  let g = parseInt(hex.substring(2, 4), 16);
  let b = parseInt(hex.substring(4, 6), 16);

  // Return the new RGB values as an array

  return `rgba(${r}, ${g}, ${b} , ${(1 * intensityPercent) / 100})`;
}

function getWidget(label) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };
  return fetch(apiConstants.apiUrl + `/get-personalization?label=${label}`, requestOptions).then(
    handleResponse,
  );
}

function addWidget(widgetData) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(widgetData),
  };
  return fetch(apiConstants.apiUrl + `/add-personalization`, requestOptions).then(handleResponse);
}

function getAllWidgetPresets(data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(data),
  };
  return fetch(apiConstants.apiUrl + `/get-widget-presets`, requestOptions).then(handleResponse);
}

async function globalSearchResultList(data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(data),
  };
  return fetch(apiConstants.apiUrl + '/get_global_search_result', requestOptions).then(
    handleResponse,
  );
}
