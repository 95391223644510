import draftToHtml from 'draftjs-to-html';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import ReactSelect from 'react-select';
import { CustomVariableInput } from '../Task/TaskComponents/CustomInputs';
import ErrorBlock from '../Template/SubComponents/ErrorBlock';
import { MilestoneState, convertHtmlToRawContent } from '../Utils';
import { Cancel, Tick } from '../Utils/SvgIcons';
import { Button } from '../_components';
import { CommonDropDownStyle } from '../_constants';
import { EDITOR_TOOLBAR_OPTIONS } from '../_constants/template.constants';
import { milestoneService } from '../_services/milestone.service';
import { durationOptions } from './milestone.constants';
import './milestone.css';

//-- Milestone Add --//
const MilestoneAdd = ({ id, type = '', project_id, handleClose = () => {} }) => {
  const [milestone, setMilestone] = useState(MilestoneState);
  const [isLoading, setIsLoading] = useState(false);
  const [submitted, setSubmitted] = useState(false);

  const [query, setQuery] = useState({
    select: ['id', 'name', 'duration', 'start_date', 'end_date', 'goal', ''],
  });

  const today = moment().format('YYYY-MM-DD');

  useEffect(() => {
    if (id && id != '0') {
      query.id = id;
      getMilestone(query);
    }
  }, [id]);

  const getMilestone = async (queryTemp) => {
    let queryNew = queryTemp ? queryTemp : query;
    setQuery(queryNew);
    setIsLoading(true);
    try {
      const response = await milestoneService.milestoneGet(queryNew);
      if (response?.data) {
        setMilestone({
          ...response?.data,
          goal: convertHtmlToRawContent(response.data.goal),
        });
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleChange = (event) => {
    const { name, value, type } = event.target;
    if (name == 'duration' && value != 0) {
      setMilestone({
        ...milestone,
        [name]: value,
        end_date: moment(parseInt(milestone?.start_date))
          .add(parseInt(value) * 7, 'days')
          .valueOf(),
      });
      return;
    }
    if (type === 'date') {
      if (name == 'start_date' && milestone.duration != 0) {
        setMilestone({
          ...milestone,
          [name]: moment(value).valueOf(),
          end_date: moment(value)
            .add(parseInt(milestone.duration) * 7, 'days')
            .valueOf(),
        });
        return;
      }
      setMilestone({
        ...milestone,
        [name]: moment(value).valueOf(),
      });
      return;
    }
    setMilestone({
      ...milestone,
      [name]: value,
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setSubmitted(true);
    setIsLoading(true);
    if (
      milestone?.name?.toString().trim() === '' ||
      milestone?.duration === '' ||
      milestone?.start_date === '' ||
      milestone?.end_date === ''
    ) {
      setIsLoading(false);
      return null;
    }

    const payload = { ...milestone, goal: draftToHtml(milestone.goal), project_id: project_id };
    try {
      await milestoneService.milestoneAdd(payload).then(() => {
        setSubmitted(false);
      });
      handleClose(event);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
      setSubmitted(false);
    }
  };

  return (
    <div className=' d-flex flex-column '>
      <div className='modal_header '>
        <h6 className='menu-item-head'>{id > 0 ? `Edit  ${type} ` : `Create  ${type} `}</h6>
        {!id && (
          <div
            className='menu-item-clear'
            style={{ fontSize: 12, cursor: 'pointer' }}
            onClick={() => {
              setMilestone(MilestoneState);
              setSubmitted(false);
            }}
          >
            Clear
          </div>
        )}
      </div>
      <form action='' className='modal_view_content gap-2' onSubmit={handleSubmit}>
        <div className='row '>
          <div className='mt-10px '>
            <CustomVariableInput
              name='name'
              value={milestone?.name}
              placeholder={`Enter ${type} name`}
              onChange={(event) => handleChange(event)}
              className={`form-control w-100  border-none
               `}
            />
            <ErrorBlock
              errorMsg={` ${type === 'sprint' ? 'Sprint' : 'Milestone'} name required`}
              hasError={submitted && milestone?.name.toString().trim() === ''}
            />
          </div>
        </div>

        {/* Duration selection  */}
        <div className='col-10 '>
          <div className='fz-13px-rem mb-1 milestone-txt '>
            Time Interval <span className='field-required'> *</span>{' '}
          </div>
          <ReactSelect
            name='duration'
            // menuIsOpen={true}
            // menuPortalTarget={document.getElementById('MODAL')}
            classNames={{ menuPortal: () => 'fz-13px-rem duration-list' }}
            styles={CommonDropDownStyle}
            getOptionLabel={(option) => option?.label}
            getOptionValue={(option) => option?.value}
            options={durationOptions}
            onChange={(selectedOption) => {
              handleChange({
                target: {
                  name: 'duration',
                  value: selectedOption.value,
                },
              });
            }}
            value={durationOptions?.find(
              (option) => option.value === parseInt(milestone?.duration),
            )}
          />

          {submitted && !milestone?.duration && isNaN(milestone?.duration) && (
            <div className='help-block'>Duration selection is required</div>
          )}
        </div>

        {/* end of duration  */}

        {/* date Select */}
        <div className='d-flex col-md-10 gap-1 mb-0'>
          <div className='w-50'>
            <label className='fz-13px-rem mb-1 milestone-txt'>
              Start-Date <span className='field-required'> *</span>
            </label>
            <input
              type='date'
              className='input-box'
              name='start_date'
              min={!id && today}
              onChange={handleChange}
              value={moment(parseInt(milestone?.start_date)).format('YYYY-MM-DD')}
            />
            {submitted && !milestone?.start_date && (
              <div className='help-block'> Start Date is required</div>
            )}
          </div>
          <div className='w-50'>
            <label className=' fz-13px-rem mb-1 milestone-txt'>
              End-Date <span className='field-required'> *</span>
            </label>
            <input
              type='date'
              className='input-box'
              disabled={milestone.duration != 0}
              // min={!id && today}
              min={moment(parseInt(milestone?.start_date)).format('YYYY-MM-DD')}
              name='end_date'
              placeholder={today}
              value={moment(parseInt(milestone?.end_date)).format('YYYY-MM-DD')}
              onChange={handleChange}
            />
            {submitted && !milestone?.end_date && (
              <div className='help-block'>End Date is required</div>
            )}
          </div>
        </div>

        <div>
          {/* date end */}
          <div className=' mb-2'>
            <div>
              <label className=' fz-13px-rem milestone-txt mb-1'>Goal</label>
              {(!id || milestone.id) && (
                <Editor
                  // key={milestone?.goal}
                  toolbar={EDITOR_TOOLBAR_OPTIONS}
                  stripPastedStyles={true}
                  onChange={(contentState) =>
                    handleChange({
                      target: {
                        name: 'goal',
                        value: contentState,
                      },
                    })
                  }
                  defaultContentState={milestone?.goal}
                  wrapperClassName='milestone-wrapper-style wrapper-width'
                  editorClassName='milestone-editor-style'
                  toolbarClassName='editor-toolbar'
                  placeholder='Type Your Goal'
                />
              )}
            </div>
          </div>
        </div>
      </form>

      <div className='modal_footer'>
        {/* <div className=' d-flex justify-content-end gap-2 '> */}
        {/* {!id && (
                <Button.Container
                  className={'cancel-btn'}
                  type='button'
                  handleOk={handleCancel} 
                >
                  <Button.Title title={'Cancel'} />
                </Button.Container>
              )} */}
        <Button.Container className={'cancel-btn'} type='button' handleOk={handleClose}>
          <Cancel />
          <Button.Title title={'Cancel'} />
        </Button.Container>
        <Button.Container type='submit' isDisabled={isLoading} handleOk={handleSubmit}>
          <Tick /> <Button.Title title={'Save'} />
          <Button.Loading isLoading={isLoading} />
        </Button.Container>
      </div>
      {/* </div> */}
    </div>
  );
};

export default MilestoneAdd;
