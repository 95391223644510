/* eslint-disable no-unused-vars */
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { Plus, Zap } from 'lucide-react';
import React, { useContext, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Button, Modal } from '../_components';
import { workflowService } from '../_services';
import { GlobalContext } from '../Utils';
import { Cancel, Tick } from '../Utils/SvgIcons';
import { TRIGGERS } from './workflow.constants';
import './workflow.css';

export function TriggerCreationNode({ data }) {
  const { makeAlert } = useContext(GlobalContext);

  const [scrollFlag, setScrollFlag] = useState({ isTopReached: true, isBottomReached: false });

  const queryClient = useQueryClient();
  const [triggerModal, setTriggerModal] = useState(false);
  const [selectedValue, setSelectedValue] = useState({});

  const { wf_id } = useParams();

  const addWorkflow = async (data) => {
    console.log('data', data);
    if (!data.id) {
      makeAlert('Please select trigger', 'err');
      return;
    }
    delete data.conditions;
    let wfData = {
      id: wf_id,
      trigger_type: data.id,
      createTriggerNode: true,
      data: data,
    };
    await workflowService.addWorkflow(wfData);
    queryClient.refetchQueries('workflow-steps-list');
    setTriggerModal(false);
    setSelectedValue({});
  };

  // --react query fot mutation function
  const { mutate: handleMutate, isPending } = useMutation({
    mutationFn: addWorkflow,
    onSuccess: () => {
      //   queryClient.invalidateQueries(['workflow-list']);
    },
  });

  // console.log('SELECTED value', selectedValue);

  return (
    <>
      <div className='trg-container'>
        {/* flash icon */}
        <div className='trg-flash-icon-container'>
          <div className='trg-flash-icon'>
            <Zap size={18} color='black' fill='black' />
          </div>
        </div>
        {/* flash icon */}
        {/*  */}
        {/* Main question text */}
        <div className='trg-main-text'>How do you want to trigger?</div>
        {/* Button below */}
        <div className='trg-button-container'>
          <div onClick={() => setTriggerModal(true)} className='trg-trigger-button'>
            <Plus size={13} />
            <div className='trg-trigger-button-text'>Select Trigger</div>
          </div>
        </div>
      </div>

      {triggerModal && (
        <Modal.Container handleClose={() => setTriggerModal(false)}>
          <Modal.View className='modal-view-trigger' containerClass='view-end'>
            <div className='trigger-modal-container'>
              {/* Header */}
              <div className='trigger-modal-header'>
                <h2 className='trigger-modal-title'>Set Trigger</h2>
                <p className='trigger-modal-subtitle'>Pick a trigger to start workflow</p>
              </div>

              {/* Scrollable Body */}
              <TriggerModalBody selectedValue={selectedValue} setSelectedValue={setSelectedValue} />

              {/* Sticky Footer */}

              <div
                style={{
                  boxShadow: scrollFlag.isBottomReached
                    ? ''
                    : '0 -6px 5px -5px var(--shadow, #091e4229)',
                }}
                className='client-add-button-container modal_footer'
              >
                <Button.Container
                  className={'outline-cancel-button'}
                  type='reset'
                  handleOk={() => {
                    setTriggerModal(false);
                  }}
                >
                  <Cancel />
                  <Button.Title title={'Cancel'} />
                </Button.Container>

                <Button.Container
                  handleOk={(e) => handleMutate(selectedValue)}
                  type='submit'
                  className={'blue-btn'}
                  isDisabled={isPending}
                >
                  <Tick />
                  <Button.Title title={'Save'} />
                  <Button.Loading isLoading={isPending} />
                </Button.Container>
              </div>
            </div>
          </Modal.View>
        </Modal.Container>
      )}
    </>
  );
}

const TriggerModalBody = ({ selectedValue, setSelectedValue }) => {
  // State to track which radio is selected. e.g., "task_status" for "Task status is"

  const handleChange = (item) => {
    setSelectedValue((prev) => ({ ...prev, ...item }));
  };

  const taskTriggers = TRIGGERS.filter((trigger) => trigger.type === 'task');
  const projectTriggers = TRIGGERS.filter((trigger) => trigger.type === 'project');

  return (
    <div className='trigger-modal-body'>
      <div style={{ fontSize: 14, fontWeight: '500', marginBottom: 5 }}>Task based triggers</div>
      {taskTriggers.map((item) => (
        <div
          className={`trigger-item ${selectedValue.id === item.id ? 'selected' : ''}`}
          key={item.id}
        >
          <label className='trigger-label'>
            <input
              type='radio'
              name='triggerOption'
              value={item.id}
              checked={selectedValue?.id === item.id}
              onChange={() => handleChange(item)}
              className='trigger-input'
            />
            {item.label}
          </label>
        </div>
      ))}
      <div style={{ fontSize: 14, fontWeight: '500', marginBottom: 5 }}>Project based triggers</div>
      {projectTriggers.map((item) => (
        <div
          className={`trigger-item ${selectedValue.id === item.id ? 'selected' : ''}`}
          key={item.id}
        >
          <label className='trigger-label'>
            <input
              type='radio'
              name='triggerOption'
              value={item.id}
              checked={selectedValue?.id === item.id}
              onChange={() => handleChange(item)}
              className='trigger-input'
            />
            {item.label}
          </label>
        </div>
      ))}
    </div>
  );
};
