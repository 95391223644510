export const FELIDS = {
  STATUS_GROUP: 'STATUS_GROUP',
  PREVIOUS_STATUS: 'PREVIOUS_STATUS',
  NEW_STATUS: 'NEW_STATUS',
};

export const INPUT_TYPE = {
  TEXT: 'TEXT',
  NUMBER: 'NUMBER',
  D_DROPDOWN: 'D_DROPDOWN', // Dynamic dropdown
  S_DROPDOWN: 'S_DROPDOWN', // Static dropdown
  DATE: 'DATE',
};

export const WF_TYPE = {
  ACTION: 'ACTION',
  CONDITION: 'CONDITION',
  DELAY: 'DELAY',
};
export const ACTION_TYPE = {
  MAIL: 'MAIL',
  SMS: 'SMS',
  WHATS_APP_SMS: 'whatsapp-sms',
};

// used in the email template form
export const mailTemplateOptions = [
  { label: 'Email template', value: ACTION_TYPE.MAIL },
  { label: 'SMS template', value: ACTION_TYPE.SMS },
  { label: `WhatsApp template`, value: ACTION_TYPE.WHATS_APP_SMS },
];

export const wfTypeOptions = [
  { label: 'Condition', value: WF_TYPE.CONDITION },
  { label: 'Action', value: WF_TYPE.ACTION },
  { label: 'Delay', value: WF_TYPE.DELAY },
];

export const actionOptions = [
  { value: ACTION_TYPE.MAIL, label: 'Send email' },
  { value: ACTION_TYPE.SMS, label: 'Send SMS' },
  { value: ACTION_TYPE.WHATS_APP_SMS, label: 'Send WhatsApp message' },
];

export const TRIGGER_TYPE = {
  // TASK TRIGGERS

  TASK_CREATION: 'TASK_CREATION',
  TASK_UPDATE: 'TASK_UPDATE',
  TASK_STATUS_CHANGE: 'TASK_STATUS_CHANGE',

  // PROJECT TRIGGERS

  PROJECT_CREATION: 'PROJECT_CREATION',
  PROJECT_UPDATE: 'PROJECT_UPDATE',
  //  PROJECT TRIGGERS
};

export const TASK_FIELDS = {
  CLIENT_ID: 'client_id',
  MILESTONE_ID: 'milestone_id',
  PROJECT_ID: 'project_id',
  PRIORITY: 'priority',
  ASSIGNEE_ID: 'assignee_id',
  STATUS_GROUP_ID: 'status_group_id',
  STATUS_ID: 'status_id',
  SAMPLE: 'sample_id',
  LABEL: 'label',
};

export const TASK_CONDITIONS = {
  // [TASK_FIELDS.SAMPLE]: {
  //   label: 'Sample based',
  //   type: 'number',
  //   supportOperators: ['='],
  //   inputType: INPUT_TYPE.S_DROPDOWN,
  //   dropDownValue: [
  //     { value: 1, label: 'Milestone 1' },
  //     { value: 2, label: 'Milestone 2' },
  //     { value: 3, label: 'Milestone 3' },
  //   ],
  // },
  // [TASK_FIELDS.CLIENT_ID]: {
  //   label: 'Client based',
  //   type: 'number',
  //   supportOperators: ['='],
  //   inputType: INPUT_TYPE.D_DROPDOWN,
  // },
  [TASK_FIELDS.PROJECT_ID]: {
    label: 'Project ',
    type: 'number',
    supportOperators: ['='],
    inputType: INPUT_TYPE.D_DROPDOWN,
    tableType: 'TASK',
  },
  // [TASK_FIELDS.MILESTONE_ID]: {
  //   label: 'Milestone based',
  //   type: 'number',
  //   supportOperators: ['='],
  //   inputType: INPUT_TYPE.D_DROPDOWN,
  // },
  [TASK_FIELDS.PRIORITY]: {
    label: 'Priority ',
    type: 'number',
    supportOperators: ['='],
    inputType: INPUT_TYPE.D_DROPDOWN,
    tableType: 'TASK',
  },
  [TASK_FIELDS.LABEL]: {
    label: 'Labels ',
    type: 'number',
    supportOperators: ['='],
    inputType: INPUT_TYPE.D_DROPDOWN,
    tableType: 'TASK',
  },
  [TASK_FIELDS.ASSIGNEE_ID]: {
    label: 'Owner ',
    type: 'number',
    supportOperators: ['='],
    inputType: INPUT_TYPE.D_DROPDOWN,
    tableType: 'TASK',
  },
};

export const EXTRA_TASK_CONDITIONS = {
  [TASK_FIELDS.STATUS_GROUP_ID]: {
    label: 'Status Group ',
    type: 'number',
    supportOperators: ['='],
    inputType: INPUT_TYPE.D_DROPDOWN,
    tableType: 'TASK',
  },
  [TASK_FIELDS.STATUS_ID]: {
    label: 'Status ',
    type: 'number',
    supportOperators: ['='],
    inputType: INPUT_TYPE.D_DROPDOWN,
    tableType: 'TASK',
  },
};

export const TRIGGERS = [
  {
    id: TRIGGER_TYPE.TASK_CREATION,
    name: TRIGGER_TYPE.TASK_CREATION,
    label: 'Task Created',
    type: 'task',
    conditions: [
      // { [TASK_FIELDS.MILESTONE_ID]: EXTRA_TASK_CONDITIONS[TASK_FIELDS.MILESTONE_ID] },
      // { [TASK_FIELDS.CLIENT_ID]: EXTRA_TASK_CONDITIONS[TASK_FIELDS.CLIENT_ID] },
      ...Object.entries(TASK_CONDITIONS).map(([key, value]) => ({ [key]: value })),
    ],
  },
  {
    id: TRIGGER_TYPE.TASK_UPDATE,
    name: TRIGGER_TYPE.TASK_UPDATE,
    label: 'Task Updated',
    type: 'task',
    conditions: [
      // { [TASK_FIELDS.STATUS_GROUP_ID]: EXTRA_TASK_CONDITIONS[TASK_FIELDS.STATUS_GROUP_ID] },
      { [TASK_FIELDS.STATUS_ID]: EXTRA_TASK_CONDITIONS[TASK_FIELDS.STATUS_ID] },
      ...Object.entries(TASK_CONDITIONS).map(([key, value]) => ({ [key]: value })),
    ],
  },
  {
    id: TRIGGER_TYPE.TASK_STATUS_CHANGE,
    name: TRIGGER_TYPE.TASK_STATUS_CHANGE,
    label: 'Task Status Changed',
    type: 'task',
    conditions: [
      { [TASK_FIELDS.STATUS_GROUP_ID]: EXTRA_TASK_CONDITIONS[TASK_FIELDS.STATUS_GROUP_ID] },
      { [TASK_FIELDS.STATUS_ID]: EXTRA_TASK_CONDITIONS[TASK_FIELDS.STATUS_ID] },
    ],
  },
];

// Styles for the dropdown
export const customStyles = {
  control: (provided) => ({
    ...provided,
    // backgroundColor: 'lightblue', // Background color
    borderColor: '#d2d2d2', // Border color
    height: '35px', // Consistent control height
    minHeight: '30px', // Prevent shrinkage
    boxShadow: 'none', // Remove extra shadows
    display: 'flex',
    alignItems: 'center', // Align content vertically
    fontSize: '18px', // Adjust font size
  }),
  placeholder: (provided) => ({
    ...provided,
    fontSize: '14px', // Adjust placeholder font size
    color: '#6c757d', // Placeholder text color
    margin: '0px', // Remove unnecessary margins
    display: 'flex',
    alignItems: 'center', // Vertical alignment
  }),
  valueContainer: (provided) => ({
    ...provided,
    height: '100%', // Match the control height
    padding: '0 8px', // Proper padding for text alignment
    display: 'flex',
    alignItems: 'center', // Vertical alignment
  }),
  input: (provided) => ({
    ...provided,
    margin: '0px', // Remove unnecessary margins
    fontSize: '14px', // Adjust font size
  }),
  singleValue: (provided) => ({
    ...provided,
    margin: '0px', // Remove unnecessary margins
    fontSize: '14px', // Adjust font size
    display: 'flex',
    alignItems: 'center', // Vertical alignment
  }),
  indicatorSeparator: () => ({
    display: 'none', // Remove the separator
  }),
  indicatorsContainer: (provided) => ({
    ...provided,
    height: '100%', // Match the control height
    alignItems: 'center', // Center align the dropdown indicator
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    padding: '4px', // Adjust spacing
  }),
  menu: (provided) => ({
    ...provided,
    fontSize: '14px', // Dropdown options font size
    zIndex: 10, // Ensure it appears above other elements
  }),
  option: (provided, state) => ({
    ...provided,
    fontSize: '14px', // Font size for each option
    backgroundColor: state.isFocused ? '#e2e2e2' : 'white', // Highlight focused option
    color: 'black', // Text color
    padding: '5px 5px', // Option padding for better spacing
    display: 'flex',
    alignItems: 'center', // Vertical alignment for options
  }),
};
