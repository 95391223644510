import { Plus } from 'lucide-react';
import React, { useContext, useState } from 'react';
import { Popover } from '../_components/Popover';
import useOutsideClickHandler from '../Hook/useOutsideClickHandler';
import { FileSystemImage, GlobalContext } from '../Utils';
import { FolderAdd } from './FolderAdd';

export default function EmptyFolder() {
  const [isFolderAdd, setIsFolderAdd] = useState(false);
  // filter popup reference
  const { containerRef: folderAddRef } = useOutsideClickHandler({
    onClose: () => setIsFolderAdd(false),
  });

  const { roleBasedPermission } = useContext(GlobalContext);

  return (
    <div className='empty-outer'>
      <div className='icon-outer-file'>
        <img src={FileSystemImage.EmptyFolder} alt='no' height={60} width={60} />
      </div>
      <span className='empty-head'>No folder found</span>
      <span className='empty-text'> There is no folder in your directory</span>
      {roleBasedPermission?.ACTION_FILE_ADD && (
        <div
          className='mt-3 empty-button '
          ref={folderAddRef}
          onClick={() => {
            setIsFolderAdd(true);
          }}
        >
          <Plus size={15} />
          {'Add'}

          {isFolderAdd && (
            <Popover.PopoverItem
              onClose={() => setIsFolderAdd(false)}
              positionLeft={(width) => -330 + width}
              positionTop={(height, width, childHeight) => {
                console.log(height, width);

                return -childHeight - height - 5;
              }}
              scrollRef={folderAddRef}
            >
              <div ref={folderAddRef}>
                <FolderAdd
                  shareWithListPlacement='top'
                  handleClose={(event) => {
                    event.stopPropagation();
                    setIsFolderAdd(false);
                  }}
                />
              </div>
            </Popover.PopoverItem>
          )}
        </div>
      )}
    </div>
  );
}
