import React from 'react';
import { useSearchParams } from 'react-router-dom';
import { Images } from '../Utils';
import { InvitationSignUpForm } from './InvitationSignUpForm';
import { InvitationWelcome } from './InvitationWelcome';
import './invite.css';
import { InviteLoginPage } from './InviteLoginPage';

export const InvitationAcceptPage = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const currentPage = searchParams.get('view');

  return (
    <div>
      <div className='company_icon_contain'>
        <img src={Images.CompanyLogo} />
      </div>
      {currentPage === 'home' && (
        <InvitationWelcome setSearchParams={setSearchParams} searchParams={searchParams} />
      )}
      {currentPage === 'signIn' && (
        <InviteLoginPage setSearchParams={setSearchParams} searchParams={searchParams} />
      )}
      {currentPage === 'signUp' && (
        <InvitationSignUpForm setSearchParams={setSearchParams} searchParams={searchParams} />
      )}
    </div>
  );
};
