/* eslint-disable no-unused-vars */
import { ArrowLeft } from 'lucide-react';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import IconLabelSet from '../../_components/IconLabelSet';
import { RT } from '../../_constants';
import { authService } from '../../_services';

const NewPassword = ({ email, setEmail }) => {
  let navigate = useNavigate();
  const [passwordData, setPasswordData] = useState({
    password: '',
    confirm_password: '',
  });
  const [error, setError] = useState();
  const [submitted, setSubmitted] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setPasswordData({
      ...passwordData,
      [name]: value,
    });
  };

  const changePassword = async (e) => {
    e.preventDefault();
    setSubmitted(true);

    setTimeout(() => {
      setError('');
    }, 3000);
    if (
      passwordData.password.toString().trim() == '' ||
      passwordData.confirm_password.toString().trim() == ''
    ) {
      return;
    }
    if (
      passwordData.password.toString().trim() !== passwordData.confirm_password.toString().trim()
    ) {
      setError('Confirm Passwords does not match');
      return;
    }

    const payload = {
      password: passwordData.password,
      email_id: email.email,
    };
    try {
      const response = await authService.newPassword(payload);
      if (response.error_code == 0) {
        navigate(`/${RT.LOGIN}`);
      }
    } catch (error) {
      if (error.message) {
        setError(error.message);
      }
    } finally {
      setSubmitted(false);
    }
  };
  return (
    <div>
      <div className='login_header_contain'>
        {' '}
        <div className='login_company_icon_contain'>
          <img src='/logo.svg' />
        </div>
      </div>
      <div className='login_body_container'>
        <div className='row d-flex' style={{ gap: '100px' }}>
          <div className='col-md-5'>
            <div className='mt-5'>
              <div>
                <div className='login-welcome-txt'>Set New Password</div>
                <div className='forgot_second_txt'>
                  Your new password data must be different from to previously used passwords{' '}
                </div>
              </div>

              <form name='form' onSubmit={changePassword}>
                <div className='card_Container mt-3'>
                  <div className={'form-group'}>
                    <label className='mt-2 login_label mb-1' htmlFor='username'>
                      New Password
                    </label>
                    <input
                      type='password'
                      className={`form-control inputbox
                          ${
                            submitted && passwordData?.password.toString().trim() == ''
                              ? 'has-error'
                              : ''
                          }
                        `}
                      name='password'
                      onChange={handleChange}
                    />
                  </div>
                  <div className={'form-group'}>
                    <label className='mt-2 login_label mb-1' htmlFor='username'>
                      Confirm Password{' '}
                    </label>
                    <input
                      type='password'
                      className={`form-control inputbox
                        ${
                          submitted && passwordData?.confirm_password.toString().trim() == ''
                            ? 'has-error'
                            : ''
                        }
                      `}
                      name='confirm_password'
                      onChange={handleChange}
                    />
                  </div>
                  <div className='form-group   mt-3'>
                    <button className='btn login_Button width-100'> Reset Password </button>
                  </div>
                  <div className='mt-2'>
                    <div className='d-flex align-items-center justify-content-center'>
                      <IconLabelSet
                        fontSize={13}
                        fontWeight={500}
                        className='cursor-pointer'
                        color={'#1E3DB9'}
                        icon={<ArrowLeft size={14} />}
                        label={'Return to login'}
                        onClick={() => navigate(`/${RT.LOGIN}`)}
                      />
                    </div>
                  </div>
                  <div className='d-flex justify-content-center error-txt'>{error}</div>{' '}
                </div>
              </form>
            </div>
          </div>
          <div className='col-md-5'>
            <div className='login_img_container'>
              <img src='/icons/forgotnewpassword.png' style={{ height: '450px' }} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewPassword;
