import React from 'react';

export default function AccessDenied() {
  return (
    <div style={{ marginTop: 50, textAlign: 'center' }}>
      <h1>Access Denied</h1>
      <p>You are not authorized to access this page</p>
    </div>
  );
}
