import { ChevronLeft } from 'lucide-react';
import React from 'react';
import { useSearchParams } from 'react-router-dom';
import ChatUserRow from '../ChatUserRow';

function ChatUpdateList({
  updates,
  isActive,
  onUpdateClick,
  showCategoryHeader = true,
  onChatHomeClick,
}) {
  const [searchParams] = useSearchParams();
  const isUnread = Boolean(searchParams.get('unread'));

  if (updates?.length <= 0) {
    if (isUnread) {
      return (
        <div className='fz-13px update-padding grey-text text-center mt-100-center'>
          No {isUnread ? 'unread' : ''} updates
        </div>
      );
    }
    return null;
  }

  return (
    <>
      {showCategoryHeader && (
        <div className='update-padding pb-2 sticky-head d-flex'>
          {searchParams.get('update_id') ? (
            <div
              className='chat_msg fz-13px pointer '
              style={{ color: '#656F7D' }}
              onClick={onChatHomeClick}
            >
              <ChevronLeft size={13} /> Back
            </div>
          ) : (
            <div className='chat_msg fz-13px' style={{ color: '#656F7D' }}>
              Messages
            </div>
          )}
        </div>
      )}
      {updates?.map((update) => {
        return (
          <ChatUserRow
            update={update}
            key={update.id}
            active={() => isActive(update)}
            onClick={(update) => {
              onUpdateClick(update);
            }}
          />
        );
      })}
    </>
  );
}

export default ChatUpdateList;
