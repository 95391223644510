/* eslint-disable no-unused-vars */
import { getApp, getApps, initializeApp } from 'firebase/app';
import { getMessaging, isSupported, onMessage } from 'firebase/messaging';
import { useEffect, useState } from 'react';

// Firebase configuration
export const firebaseConfig = {
  apiKey: 'AIzaSyBTP7RZmOyebPjSgaKGW5xv5FBAV8oYN_8',
  authDomain: 'hidesk-44f2e.firebaseapp.com',
  projectId: 'hidesk-44f2e',
  storageBucket: 'hidesk-44f2e.appspot.com',
  messagingSenderId: '137210691743',
  appId: '1:137210691743:web:2171d85a1fa17224d622aa',
};

function useMessagingListeners() {
  const [notificationData, setNotificationData] = useState(null);
  const [newNotification, setNewNotification] = useState(false);

  useEffect(() => {
    let unsubscribeOnMessage = null;
    let backgroundMessageHandler = null;

    async function setupMessaging() {
      function triggerNewNotification() {
        setNewNotification(true);
        setTimeout(() => {
          setNewNotification(false);
        }, 2000); // Reset newNotification flag after 2 seconds
      }

      const messagingSupported = await isSupported();
      if (!messagingSupported) {
        console.error('This browser does not support Firebase Messaging');
        return;
      }

      // Initialize Firebase app if not already initialized
      const app = !getApps().length ? initializeApp(firebaseConfig) : getApp();

      const messaging = getMessaging(app);

      // Foreground message listener
      unsubscribeOnMessage = onMessage(messaging, (payload) => {
        console.log('Received foreground message:', payload);
        setNotificationData(payload.data);
        triggerNewNotification();
      });

      // Background message listener
      backgroundMessageHandler = (event) => {
        if (event.data && event.data.type === 'PUSH_NOTIFICATION') {
          console.log('Received background message:', event.data);
          setNotificationData(event.data?.payload?.data);
          triggerNewNotification();
        }
      };

      if (navigator.serviceWorker) {
        navigator.serviceWorker.addEventListener('message', backgroundMessageHandler);
      }

      // // Background message listener
      // backgroundMessageHandler = (event) => {
      //   console.log('Received background message:', event.data);
      //   if (event.data && event.data.type === 'PUSH_NOTIFICATION') {
      //     // setNotificationData(event.data.payload);
      //     // triggerNewNotification();
      //   }
      // };
      // navigator.serviceWorker.addEventListener('message', backgroundMessageHandler);

      // Initialize messaging
      // const messaging = getMessaging(app);

      // Foreground message listener
      // unsubscribeOnMessage = onMessage(messaging, (payload) => {
      //   console.log('Received foreground message:', payload);
      //   setNotificationData(payload);
      //   triggerNewNotification();
      // });

      // Background message listener
      // navigator.serviceWorker.ready.then((registration) => {
      //   if (registration.active) {
      //     backgroundMessageHandler = (event) => {
      //       console.log('Received NUNU background message:', event.data);
      //       // if (event.data && event.data.type === 'BACKGROUND_NOTIFICATION') {
      //       //   setNotificationData(event.data);
      //       //   triggerNewNotification();
      //       // }
      //     };
      //     navigator.serviceWorker.addEventListener('message', backgroundMessageHandler);
      //   }
      // });
    }

    setupMessaging();

    // Cleanup function
    return () => {
      if (unsubscribeOnMessage) {
        unsubscribeOnMessage();
      }
      if (backgroundMessageHandler) {
        navigator.serviceWorker.ready.then(() => {
          navigator.serviceWorker.removeEventListener('message', backgroundMessageHandler);
        });
      }
    };
  }, []);

  return { notificationData, newNotification };
}

export default useMessagingListeners;
