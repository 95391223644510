/* eslint-disable no-unused-vars */
import moment from 'moment';
import React from 'react';
import { UpdateItem } from '../Dashboard/UpdateItem';
import { fullNameUser } from '../Utils';
import NoPermission from '../_components/NoPermission';
import { apiConstants, USER_PLACEHOLDER } from '../_constants';

export function UpdatesListItem({ updatesList, queryPayload }) {
  // TODO:- change dummy image
  const pathName = window.location.pathname;

  const updateListUI = updatesList?.rows?.length ? (
    updatesList?.rows?.map((item, index) => {
      const currentIndex = (updatesList?.page - 1) * updatesList?.limit + index || 0;
      let taskName = item?.Task?.name;
      let updatesCount = item.update_count;
      let taskOwner = item?.Task?.User;
      let taskOwnerName = fullNameUser(taskOwner?.first_name, taskOwner?.last_name);
      let taskOwnerProfile = taskOwner?.image_url
        ? apiConstants.imgUrlS3 + taskOwner?.image_url
        : USER_PLACEHOLDER.MALE;

      let newLastMessage = item?.last_message;

      let lastMessageData = item.message[item.message.length - 1];
      let lastMessage = lastMessageData?.message;

      let ltMsgCreatorDetails = lastMessageData?.creator_details;
      let ltMsgCreatorName = fullNameUser(
        ltMsgCreatorDetails?.first_name,
        ltMsgCreatorDetails?.last_name,
      );

      let ltMsgCreatorProfile = item?.User?.image_url
        ? apiConstants.imgUrlS3 + item?.User?.image_url
        : USER_PLACEHOLDER.MALE;

      const formattedDate = moment(parseInt(item?.last_message_time)).format(
        'MMM D, YYYY [at] h:mm A',
      );

      let taskId = item?.task_id;

      return (
        <>
          <UpdateItem
            index={currentIndex}
            queryPayload={queryPayload}
            formattedDate={formattedDate}
            taskOwnerProfile={taskOwnerProfile}
            taskName={taskName}
            ltMsgCreatorProfile={ltMsgCreatorProfile}
            ltMsgCreatorName={ltMsgCreatorName}
            lastMessage={newLastMessage}
            updatesCount={updatesCount}
            taskId={taskId}
            pathName={pathName}
          />
        </>
      );
    })
  ) : (
    <div>
      {/* <p className='text-center' style={{ fontSize: 13 }}>
        No updates available
      </p> */}
      <NoPermission data='No updates available' />
    </div>
  );

  return <div>{updateListUI}</div>;
}
