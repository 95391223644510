import React, { useEffect, useState } from 'react';

export const Pagination = (props) => {
  const [paginationItems, setPaginationItems] = useState([]);
  const pageOnClick = (data) => {
    if (props.pageOnClick) {
      props.pageOnClick(data);
    }
  };

  const setListAndPagination = (demoData) => {
    if (!demoData) return;
    // if (!demoData.pages) {
    //   demoData.pages = 10;
    // }
    // if (!demoData.page && demoData.pageNo) {
    //   demoData.page = demoData.pageNo;
    // }

    let paginationItems = [];
    if (demoData && demoData.pages > 1) {
      let start = 1;
      let end = demoData.pages;
      let currentPageNo = demoData.page;
      let totalPages = demoData.pages;
      if (totalPages > 5) {
        if (currentPageNo + 4 > totalPages) {
          start = totalPages - 4;
          end = totalPages;
        } else {
          if (currentPageNo == end) {
            start = currentPageNo;
            end = parseInt(currentPageNo) + 4;
          } else if (currentPageNo == 1) {
            start = currentPageNo;
            end = start + 4;
          } else if (currentPageNo == start) {
            end = currentPageNo;
            start = currentPageNo - 4;
            if (currentPageNo - 4 <= 0) {
              start = 1;
              end = 5;
            }
          } else if (currentPageNo < start) {
            end = start;
            start = end - 4;
          } else if (currentPageNo > start) {
            if (currentPageNo - 2 <= 0) {
              start = 1;
              end = 5;
            } else {
              start = currentPageNo - 2;
              end = currentPageNo + 2;
            }
          } else if (!end && !start) {
            start = 1;
            end = 5;
          }
        }
      }
      paginationItems.push(
        <li
          key='prev'
          className='page-item '
          onClick={() => {
            if (currentPageNo > 1) {
              pageOnClick(currentPageNo - 1);
            }
          }}
        >
          {' '}
          <a className='page-link rmv'> Prev </a>{' '}
        </li>,
      );
      for (let i = start; i <= end; i = i + 1) {
        paginationItems.push(
          <li
            onClick={() => pageOnClick(i)}
            className={'page-item ' + (currentPageNo === i ? 'active ' : 'noactive')}
            key={i}
          >
            <a className='page-link rmv'>{i}</a>
          </li>,
        );
      }
      paginationItems.push(
        <li
          key='next'
          className='page-item '
          onClick={() => {
            if (totalPages > currentPageNo) {
              pageOnClick(currentPageNo + 1);
            }
          }}
        >
          {' '}
          <a className='page-link rmv'> Next </a>{' '}
        </li>,
      );
    }

    setPaginationItems(paginationItems);
  };

  useEffect(() => {
    setListAndPagination(props.demoData);
  }, [props.demoData]);

  return <ul className='pagination'> {paginationItems}</ul>;
};
