/* eslint-disable no-unused-vars */
import { useQuery } from '@tanstack/react-query';
import { AlarmCheck, PlusCircle } from 'lucide-react';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import { useNavigate } from 'react-router-dom';
import GlobalSearchContainer from '../GlobalSearch/GlobalSearchContainer';
import { useUser } from '../Hook/useUser';
import { useWhoAmI } from '../Hook/useWhoAmI';
import NotificationModal from '../Notification/NotificationModal';
import LogOutModal from '../Profile/LogOutModal';
import { TaskAdd } from '../Task';
import '../Task/task.css';
import {
  getSettingsByName,
  getSettingValue,
  getSettingValueByLabel,
  GlobalContext,
  Images,
} from '../Utils';
import { FileIcon, LoadingIcon, ProjectIcon } from '../Utils/SvgIcons';
import { Modal } from '../_components';
import { apiConstants, MENU, RT } from '../_constants';
import useDebounce from '../_helpers/useDebounce';
import { organizationService } from '../_services/organization.service';
import CircularAvatar from './Avatar/CircularAvatar';
import { DraggableFloatingIcon } from './Draggable/DraggableWrapper';
import { TaskTimer } from './Draggable/TaskTimer';
import { NoteContainer } from './NoteContainer';
import NotificationBell from './NotificationBell';
// In your main component or header where you're using Firebase messaging
// Update this path based on your project structure

export function Header() {
  const navigate = useNavigate();

  // const userValidate = useUserValidate();
  const { activeTask, roleBasedPermission, getMenuLabelName } = useContext(GlobalContext);
  const LABEL_OF_PROJECT = getMenuLabelName(MENU.project.default_name);

  //-- hooks

  const { data: newUser, profileLoading } = useWhoAmI();
  const user = useUser();
  const dropdownMenuRef = useRef();

  const { globalSettings, theme, toggleTheme } = useContext(GlobalContext);

  const menuSettings = getSettingsByName(globalSettings, 'menu-settings');
  const GeneralSettings = getSettingsByName(globalSettings, 'general-settings');

  const Slogan =
    GeneralSettings?.labels?.find((settings) => settings?.label === 'Slogan').value || '';

  const draggable_task =
    GeneralSettings?.labels?.find((settings) => settings?.default_name === 'draggable_task')
      ?.value || false;

  let aiChat = getSettingValue(GeneralSettings, 'ai_chat');
  // let notePermitted = getSettingValue(GeneralSettings, 'note');
  let searchPermitted = getSettingValue(GeneralSettings, 'global_search');
  let reminderPermitted = getSettingValueByLabel(GeneralSettings, 'Reminder');

  const hasExist = (default_name) => {
    return menuSettings?.labels?.some((label) => label.default_name === default_name);
  };

  const isMale = newUser?.gender === 1;
  const isNeutral = newUser?.gender === 3;
  const url = newUser?.compressed_image_url ? newUser?.compressed_image_url : newUser?.image_url;
  const finalUrl = url ? apiConstants.imgUrlS3 + url : null;
  const is_admin =
    globalSettings &&
    globalSettings.length &&
    globalSettings.find((settings) => settings?.name === 'is_admin');

  //-- state
  const [isModal, setIsModal] = useState({
    taskModal: false,
  });
  const [isOpen, setIsOpen] = useState(false);
  const [organizationSearch, setOrganizationSearch] = useState(''); // state for  organization list organizationSearch
  const debounceOrganization = useDebounce(organizationSearch, 500);
  const [isNotificationVisible, setIsNotificationVisible] = useState(false);
  const [isLogOutModalVisible, setIsLogOutModalVisible] = useState(false);
  const toggleDropdownMenu = () => {
    if (dropdownMenuRef.current.classList.contains('show')) {
      dropdownMenuRef.current.classList.remove('show');
    } else {
      dropdownMenuRef.current.classList.add('show');
    }
  };
  // const handleClickOutside = (event) => {
  //   if (dropdownMenuRef.current && !dropdownMenuRef.current.contains(event.target)) {
  //     dropdownMenuRef.current.classList.remove('show');
  //   }
  // };

  const handleClickOutside = (event) => {
    if (
      dropdownMenuRef.current &&
      !dropdownMenuRef.current.contains(event.target) &&
      !event.target.closest('.clickable')
    ) {
      dropdownMenuRef.current.classList.remove('show');
    }
  };

  //--effects
  useEffect(() => {
    // Add the event listener
    document.addEventListener('click', handleClickOutside, true);
    // Clean up the event listener when the component unmounts
    return () => {
      document.removeEventListener('click', handleClickOutside, false);
    };
  }, []);

  // organization list
  const { data: orgData } = useQuery({
    queryKey: ['COMPANY-SWITCH-LIST', debounceOrganization],
    queryFn: () =>
      organizationService.organizationList({
        searchKey: organizationSearch,
        select: ['id', 'name', 'logo'],
      }),
    enabled: user?.id ? true : false,
  });

  //-- handleCompanyChange
  const handleCompanyChange = async (selectedOption) => {
    if (selectedOption.id === user?.company_id) {
      return;
    }

    const userDetails = await organizationService.makeDefaultOrganization({
      company_id: selectedOption?.id,
      company_name: selectedOption?.name,
    });

    // setIsOpen(false);
    // console.log('pavaaaaa', userDetails?.data?.user, userDetails?.data?.is_profile_completed);

    if (userDetails?.data?.is_profile_completed) {
      navigate('/');
    } else {
      navigate(`/${RT.INVITE_ACCEPT}?view=signUp`);
    }
    window.location.reload();
  };

  const orgList = orgData?.data?.rows
    ? orgData.data.rows.map((org) => ({ image_url: org.logo, name: org?.name, id: org?.id }))
    : [];

  const modalContent = document.getElementById('MODAL');

  const DraggableView = createPortal(
    <div>
      <DraggableFloatingIcon>
        <TaskTimer />
      </DraggableFloatingIcon>
    </div>,
    modalContent,
  );

  const GetListElements = () => {
    if (!roleBasedPermission?.ACTION_TASK_ADD && !roleBasedPermission?.ACTION_PROJECT_ADD) {
      return <></>;
    }
    return (
      <div className='position-relative'>
        <div
          role='button'
          className='pointer d-flex clickable  header-menu-icon'
          onClick={() => toggleDropdownMenu()}
        >
          <PlusCircle size={19} />
        </div>
        <ul
          className='dropdown-menu drop_down py-2'
          ref={dropdownMenuRef}
          aria-labelledby='dropdownMenuButton1'
        >
          {roleBasedPermission?.ACTION_TASK_ADD && (
            <li className='fz-13px-rem cursor-pointer global-add-list-item  '>
              <a
                className=' px-3 gap-1  py-2 global-add-list-text user-select-none'
                onClick={() => {
                  dropdownMenuRef.current.classList.remove('show');

                  navigate(`${RT.TASK_LIST}?status=todo&list_type=team-task`, {
                    state: {
                      taskModal: true,
                    },
                    replace: true,
                  });
                }}
              >
                <FileIcon color='var(--icon-light)' size={16} />
                Task
              </a>
            </li>
          )}
          {roleBasedPermission?.ACTION_PROJECT_ADD && hasExist(MENU.project.default_name) && (
            <li className='fz-13px-rem cursor-pointer global-add-list-item '>
              <a
                className=' px-3 gap-1  py-2 global-add-list-text user-select-none'
                onClick={() => {
                  dropdownMenuRef.current.classList.remove('show');
                  navigate(`/${RT.PROJECT_ADD}/0`);
                }}
              >
                <ProjectIcon color='var(--icon-light)' height={16} width={16} />
                {LABEL_OF_PROJECT}
              </a>
            </li>
          )}
        </ul>
      </div>
    );
  };

  return (
    <div className='header_contain '>
      <div className='slogan-header'>
        <div className='fz-13px fw-500 grey-text text-nowrap header_slogan'>{Slogan}</div>
      </div>

      {/* <button onClick={toggleTheme} className='btn'>
        Switch to {theme === 'light' ? 'Dark' : 'Light'} Mode
      </button> */}

      <div className='header_sub_contain'>
        {activeTask && draggable_task && DraggableView}

        {searchPermitted && <GlobalSearchContainer />}
        {aiChat && (
          <div className='cursor-pointer btn' onClick={() => navigate('/ai')}>
            <img loading='lazy' src={Images.ai_chat} height={16} width={16} />
            AI
            <span className='ai-header-text'> help</span>
          </div>
        )}

        {/* {is_admin && is_admin?.value && ( */}
        {/* <div className='header-menu-icon' onClick={() => setIsOpen(true)}>
          <StackedAvatars
            justifyPosition='justify-content-left'
            getSourceByKey={(user) => user?.logo}
            getNameByKey={(user) => user?.name}
            imageList={[
              {
                name: newUser?.companies?.find((item) => item.id == user?.company_id)?.name,
                logo: newUser?.companies?.find((item) => item.id == user?.company_id)?.logo || null,
              },
            ]}
            showInitials={true}
            hideTooltip={isOpen}
            size={23}
          />

          {isOpen && (
            <Popover.PopoverItem positionLeft={(width) => -width} onClose={() => setIsOpen(false)}>
              <div>
                <CustomSelectModal
                  listData={orgList}
                  menuCssPosition={'static'}
                  setSearch={(value) => setOrganizationSearch(value)}
                  selectedValueVisibility={false} // show the selected value
                  search={organizationSearch}
                  handleChange={(value) => {
                    setOrganizationSearch('');
                    handleCompanyChange(value);
                  }}
                  left={16}
                  formatOptionLabel={(data, metaData) => (
                    <UserDropDown
                      size={19}
                      gap={14}
                      data={data}
                      metaData={metaData}
                      showInitials={true}
                      getNameByKey={() => data?.name}
                    />
                  )}
                />
              </div>
            </Popover.PopoverItem>
          )}
        </div> */}
        {/* )} */}

        <GetListElements />
        {/* <div className='d-flex clickable pointer'>
          <Search size={18} />
        </div> */}
        {/* {hasExist(MENU.settings.default_name) && ( */}
        {/* {(roleBasedPermission?.ACTION_ORG_MANAGE || roleBasedPermission?.ACTION_USER_MANAGE) && (
          <div
            className='d-flex pointer clickable header-menu-icon'
            role='button'
            onClick={() => navigate(`${RT.SETTINGS}/${RT.GENERAL}`)}
          >
            <Settings size={19} />
          </div>
        )} */}
        {/* )} */}
        <NotificationBell
          isNotificationVisible={isNotificationVisible}
          setIsNotificationVisible={setIsNotificationVisible}
        >
          <NotificationModal setIsNotificationVisible={setIsNotificationVisible} />
        </NotificationBell>

        <NoteContainer />

        {reminderPermitted && (
          <div
            className='d-flex pointer clickable  header-menu-icon'
            role='button'
            onClick={() => navigate(`${RT.REMINDER_LIST}`)}
          >
            <AlarmCheck size={19} />
          </div>
        )}
        {/* <div>
          <img className='tab-img' src={Images.Dashboard} />
        </div>
        <div
        //  onClick={() => logout()}
        >
          <img className='tab-img' src={Images.Dashboard} />
        </div> */}
        {/* <div className='name_abb'>CK</div> */}
        <div
          className='pointer'
          style={{ position: 'relative' }}
          onClick={() => setIsLogOutModalVisible((prev) => !prev)}
        >
          {profileLoading ? (
            <LoadingIcon size={40} />
          ) : (
            <CircularAvatar
              size={29}
              className='fz-none zoom-in employee-img-with-low-border'
              source={finalUrl}
              isMale={isMale}
              isNeutral={isNeutral}
            />
          )}
          {isLogOutModalVisible && <LogOutModal onClose={() => setIsLogOutModalVisible(false)} />}
        </div>
      </div>
      {isModal.taskModal && (
        <Modal.Container>
          <Modal.Head containerClass={'task-add-head'}></Modal.Head>
          <Modal.View className={'task-add-container'}>
            <TaskAdd
              id={0}
              handleClose={() => setIsModal({ taskModal: false })}
              selectDefaultTemplateIfPresent
              defaultTemplateId={1}
            />
          </Modal.View>
        </Modal.Container>
      )}
    </div>
  );
}
