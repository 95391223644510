import { useQuery } from '@tanstack/react-query';
import draftToHtml from 'draftjs-to-html';
import { X } from 'lucide-react';
import React, { useContext, useEffect, useState } from 'react';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { useNavigate } from 'react-router-dom';
import { Button } from '../../_components';
import { ErrorComponent } from '../../_components/ErrorComponent';
import { MENU, RT, USER_LIST_ACTIONS } from '../../_constants';
import { TEMPLATE_ERROR_MSGS } from '../../_constants/template.constants';
import useDebounce from '../../_helpers/useDebounce';
import { taskService, userService } from '../../_services';
import { labelService } from '../../_services/label.service';
import { TaskListTypeConst } from '../../BoardView/BoardView.constant';
import { useUser } from '../../Hook/useUser';
import { TaskAddFormSection } from '../../Task/TaskComponents';
import TaskAddRelatedTaskList from '../../Task/TaskComponents/TaskAddRelatedTaskList';
import {
  labelSelectValues,
  taskAssignUserSelect,
  taskErrorKeyValues,
  taskValidationConstant,
} from '../../Task/taskConstants';
import { InlineRelatedTaskAdd } from '../../Task/TaskTableContent/InlineRelatedTaskAdd';
import SingleRelatedTaskPreview from '../../Task/TaskTableContent/SingleRelatedTaskPreview';
import {
  getHours,
  getLabelIsActive,
  getSubTaskIsActive,
  validateHours,
} from '../../Task/taskValidation';
import {
  convertHtmlToRawContent,
  floatToDayHour,
  GlobalContext,
  taskState,
  validateTaskWithTemplateTest,
} from '../../Utils';
import { Cancel, Tick } from '../../Utils/SvgIcons';

export function DuplicateTaskAdd({
  // eslint-disable-next-line no-unused-vars
  id = null, // for getting task details and related tasks
  okr_Parent_id,
  handleClose = () => {},
  // defaultTemplateName = 'Default Template', //For identifying the default added template. This name is reserved for default template.
  // assignTo = null,
  milestoneID = null,
  showHeaderAndFooter = false, // to show header and footer
  defaultAssignee = null,
  afterTaskAddFn,
  // clientDetails = {},
}) {
  const [taskData, setTaskData] = useState(taskState);
  const [needVerifyUser, setNeedVerifyUser] = useState(false); // for toggle action to display verified user's
  const [relatedTaskData, setRelatedTaskData] = useState(taskState);
  const [editTaskIndex, setEditTaskIndex] = useState(null); //used for tracing the currently editing task's index.
  const [isSubmitted, setIsSubmitted] = useState(false);
  const { makeAlert } = useContext(GlobalContext);
  const navigate = useNavigate();
  const currentUser = useUser().id;
  const [validationData, setValidationData] = useState([]); //state for add tags visibility
  const [labelSearch, setLabelSearch] = useState('');
  const [userSearch, setUserSearch] = useState(''); // state for  user list search
  const debouncedLabel = useDebounce(labelSearch, 500);
  const debouncedUser = useDebounce(userSearch, 500);
  // to fetch the label list
  // eslint-disable-next-line no-unused-vars
  const { data: taskDuplicate, error: taskDetailsFetchError } = useQuery({
    queryKey: ['taskData', id],
    queryFn: () => taskService.getTaskDuplication({ id: id }),
    select: (data) => {
      const taskDuplicateData = data.data?.[0];
      return taskDuplicateData;
    },
  });

  useEffect(() => {
    if (taskDuplicate?.parent_task) {
      setTaskData({
        ...taskData,
        ...taskDuplicate?.parent_task?.[0],
        note: convertHtmlToRawContent(taskDuplicate?.parent_task?.[0]?.note),
        milestone_id: milestoneID ?? null,
        verifying_users: taskDuplicate?.parent_task?.[0].verify_users_details,
        relatedTasks: taskDuplicate?.related_task
          ? taskDuplicate?.related_task?.map((tsk) => {
              const customStartTime = floatToDayHour(tsk.custom_start_time);
              return {
                ...tsk,
                assigned_user: tsk?.assignee_details?.[0],
                label: tsk?.label,
                label_details: tsk?.label_details,
                labelDetails: tsk?.label_details,
                custom_start_days: customStartTime.days,
                custom_start_hours: customStartTime.hours,
                note: convertHtmlToRawContent(tsk.note),
                assignee_id: tsk?.assignee_details?.[0]?.id,
                project_id: taskDuplicate?.parent_task?.[0]?.project_id,
                milestone_id: milestoneID ?? null,
                subtasks: tsk?.subtasks ?? [],
              };
            })
          : [],
        validation_fields: taskDuplicate?.validation_fields,
        is_verify_user_changeable: taskDuplicate?.is_verify_user_changeable,
        expected_hours: Math.floor(taskDuplicate?.parent_task?.[0]?.expected_hours),
        expected_minutes: Math.round((taskDuplicate?.parent_task?.[0]?.expected_hours % 1) * 60),
      });
    }
  }, [taskDuplicate?.parent_task]);

  const { globalSettings } = useContext(GlobalContext);

  const menuSettings =
    globalSettings &&
    globalSettings.length &&
    globalSettings.find((settings) => settings?.name === 'menu-settings');

  const hasExist = (default_name) => {
    return (
      menuSettings?.labels &&
      menuSettings?.labels.some((label) => label.default_name === default_name)
    );
  };

  // for get settings label value and subtask value - get values by context API
  const isMenuLabelOn = getLabelIsActive();

  const isMenuSubTaskOn = getSubTaskIsActive();

  // to fetch the label list
  const { data: labelData, error: labelFetchError } = useQuery({
    queryKey: ['label', debouncedLabel],
    queryFn: () =>
      labelService.labelList({
        searchKey: debouncedLabel,
        select: labelSelectValues,

        pageVo: { noOfItems: 30 },
      }),
    select: (data) => {
      const response = data?.data?.rows?.map((item) => {
        return { value: item.id, label: item.name, ...item };
      });
      return response;
    },
    enabled: isMenuLabelOn,
  });

  // getUsers--
  const { data: userData, error: assignUserFetchError } = useQuery({
    queryKey: [
      'user-list',
      taskDuplicate?.parent_task?.[0]?.project_id,
      taskData?.template_id,
      debouncedUser,
    ],
    queryFn: () =>
      userService.getRoleBasedUserList({
        searchKey: debouncedUser,
        select: taskAssignUserSelect,
        action: USER_LIST_ACTIONS.ACTION_TASK_ALLOCATION,
        ...(taskDuplicate?.parent_task?.[0]?.project_id
          ? { project_id: taskDuplicate?.parent_task?.[0]?.project_id }
          : {}),
        ...(taskData?.template_id ? { template_id: taskData?.template_id } : {}),
      }),
    select: (data) => {
      const userData = data?.data?.rows?.map((item) => {
        return {
          value: item.id,
          label: item.first_name + ' ' + item.last_name,
          id: item.id,
          ...item,
        };
      });
      const updatedDefaultUser =
        defaultAssignee && !userSearch
          ? {
              value: defaultAssignee.id,
              label: defaultAssignee.first_name + ' ' + defaultAssignee.last_name,
              id: defaultAssignee.id,
              ...defaultAssignee,
            }
          : null;
      // Check if the default assignee already exists in the userData array
      const isDefaultUserPresent = userData.some((user) => user.id == defaultAssignee?.id);

      const updatedUserData =
        updatedDefaultUser && !isDefaultUserPresent ? [...userData, updatedDefaultUser] : userData;
      return updatedUserData;
    },
    enabled: taskData?.template_id ? true : false,
  });

  // getUsers-- verify
  const { data: verifyUserData, error: verifyUserFetchError } = useQuery({
    queryKey: ['user-list-verify'],
    queryFn: () =>
      userService.getRoleBasedUserList({
        select: taskAssignUserSelect,
        action: USER_LIST_ACTIONS.ACTION_TASK_VERIFY_USER_LIST,
      }),
    select: (data) => {
      const userData = data?.data?.rows?.map((item) => {
        return {
          value: item.id,
          label: item.first_name + ' ' + item.last_name,
          id: item.id,
          ...item,
        };
      });
      const updatedVerifiedUser =
        defaultAssignee && !userSearch
          ? {
              value: defaultAssignee.id,
              label: defaultAssignee.first_name + ' ' + defaultAssignee.last_name,
              id: defaultAssignee.id,
              ...defaultAssignee,
            }
          : null;
      // Check if the default assignee already exists in the userData array
      const isDefaultUserPresent = userData.some((user) => user.id == defaultAssignee?.id);

      const updatedVerifiedUserData =
        updatedVerifiedUser && !isDefaultUserPresent
          ? [...userData, updatedVerifiedUser]
          : userData;
      return updatedVerifiedUserData;
    },
  });

  // handles the related task details
  const handleRelatedTaskAdd = (setIsAddRowVisible, setIsAddRowActive) => {
    //Means edit
    if (editTaskIndex != null && editTaskIndex <= taskData.relatedTasks.length) {
      const updatedRelatedTask = taskData?.relatedTasks?.map((item, index) => {
        if (index === editTaskIndex) {
          return { ...relatedTaskData };
        }
        return { ...item };
      });
      setTaskData((prev) => ({
        ...prev,
        relatedTasks: updatedRelatedTask,
      }));
      setEditTaskIndex(null);
      return;
    }
    // Else add
    setTaskData((prev) => ({
      ...prev,
      relatedTasks: [...prev.relatedTasks, relatedTaskData],
    }));
    setRelatedTaskData(taskState);
    setIsAddRowVisible(false);
    setIsAddRowActive(false);
  };
  // clear error and submission
  const errorTimeout = () => {
    setTimeout(() => {
      setIsSubmitted(false);
      setValidationData([]); // error fields clearing
    }, 3000);
  };

  // Client list
  // const { data: clientList } = useQuery({
  //   queryKey: ['clientListForTask'],
  //   queryFn: () => clientService.clientList({ select: ['id', 'name'] }),
  //   select: (data) => {
  //     return data?.data?.rows;
  //   },
  //   enabled:
  //     hasExist(MENU.contact.default_name) && taskData?.validation_fields != VALIDATION_VALUES.HIDE,
  // });

  // submission having validation and add task  /  error display if any
  const handleSubmit = () => {
    const taskDataWithProject = taskDuplicate?.parent_task?.[0]?.project_id
      ? { ...taskData, project_id: taskDuplicate?.parent_task?.[0]?.project_id }
      : { ...taskData };
    const finalTaskData = milestoneID
      ? { ...taskDataWithProject, milestone_id: milestoneID }
      : { ...taskDataWithProject, milestone_id: null };
    if (taskData?.expected_hours || taskData?.expected_minutes) {
      finalTaskData.expected_hours = getHours(taskData); // get hours by hours and minutes
    }

    if (
      needVerifyUser &&
      (!finalTaskData?.verifying_users || finalTaskData.verifying_users.length === 0)
    ) {
      makeAlert('Please select verifying users');
      errorTimeout();
      return;
    }

    if (finalTaskData?.verifying_users?.length > 0) {
      finalTaskData.verifying_users = finalTaskData.verifying_users.map((user) => user.id);
    }

    if (typeof taskData?.note === 'object') {
      finalTaskData.note = draftToHtml(taskData?.note);
    }

    console.log({ templateValidationFields: taskData?.template_validation_fields });

    const validationError = validateTaskWithTemplateTest(taskData?.template_validation_fields, [
      finalTaskData,
    ]); // checking which fields are required for task and shows error - template
    if (!validationError?.isValid) {
      setValidationData(validationError?.requiredFields);
      if (validationError?.requiredFields?.includes('verifying_users')) {
        makeAlert(taskValidationConstant.VERIFY_USERS_REQUIRED);
      }
      errorTimeout();
      return;
    }
    if (okr_Parent_id) {
      finalTaskData.okr_parent_id = okr_Parent_id;
    }

    const isValidHour = validateHours(
      taskData?.task_start_date,
      taskData?.task_end_date,
      finalTaskData.expected_hours,
    ); // to validate the hours according to the start - end dates

    if (isValidHour) {
      let time = parseFloat(finalTaskData.expected_hours);
      finalTaskData.expected_hours = time;
    } else {
      makeAlert(taskValidationConstant.INVALID_EXPECTED_TIME);
      errorTimeout();
      return;
    }
    for (let relatedTask of finalTaskData.relatedTasks) {
      // relatedTask.verifying_users = finalTaskData?.verifying_users;
      relatedTask.verifying_users = finalTaskData?.verifying_users?.map((item) => item?.id);

      if (typeof relatedTask?.note === 'object') {
        const note = draftToHtml(relatedTask?.note);
        relatedTask.note = note;
      }
      relatedTask.expected_hours = getHours(relatedTask); // get hours by hours and minutes
      const validationErrorRelatedTask = validateTaskWithTemplateTest(
        taskData?.template_validation_fields,
        [relatedTask],
      );
      if (!validationErrorRelatedTask?.isValid) {
        let errorFields = validationErrorRelatedTask?.requiredFields?.map((item) => {
          return taskErrorKeyValues[item];
        });
        makeAlert(`${errorFields} required in related task  "${relatedTask?.name}"`); // make alert for which fields are required for individual related task
        errorTimeout();
        return;
      }
      relatedTask.task_start_date = taskData.task_start_date;
      relatedTask.task_end_date = taskData.task_end_date; // review needed
      if (typeof relatedTask.assigned_user === 'object')
        relatedTask.assignee_id = relatedTask.assigned_user.id;
      if (typeof relatedTask.note === 'object') relatedTask.note = draftToHtml(relatedTask.note);
    }
    delete finalTaskData.expected_minutes;
    setIsSubmitted(true);
    try {
      taskService.taskAdd(finalTaskData).then(
        (res) => {
          if (res?.statusCode == 200) {
            setIsSubmitted(false);
            setTaskData({ ...taskState, template_id: taskData.template_id });
            handleClose();
            if (afterTaskAddFn) {
              afterTaskAddFn();
            } else {
              const taskForLoggedUser =
                parseInt(finalTaskData?.assignee_id) === parseInt(currentUser);
              const isMyTaskPresent = hasExist(MENU?.myResponsibility?.default_name);
              console.log({ responseForDuplicate: res });

              if (
                !okr_Parent_id &&
                taskDuplicate?.parent_task?.[0]?.project_id &&
                !taskDuplicate?.parent_task?.[0]?.template_id
              )
                if (milestoneID) {
                  navigate(
                    `/${RT.PROJECT}/${taskDuplicate?.parent_task?.[0]?.project_id}/${RT.PROJECT_TASK_LIST}?status=todo&pageNo=1&milestone_id=${milestoneID}&list_type=${TaskListTypeConst.Project}
              `,
                  );
                } else {
                  navigate(
                    `/${RT.PROJECT}/${taskDuplicate?.parent_task?.[0]?.project_id}/${RT.PROJECT_TASK_LIST}?status=todo&pageNo=1&list_type=${TaskListTypeConst.Project}`,
                  );
                }
              if (
                !okr_Parent_id &&
                !taskDuplicate?.parent_task?.[0]?.project_id &&
                taskDuplicate?.parent_task?.[0]?.template_id
              )
                navigate(
                  `/${RT.TEMPLATE}/${taskDuplicate?.parent_task?.[0]?.template_id}?status=${res?.data?.status_group_item_id}&pageNo=1&list_type=${TaskListTypeConst.Template}`,
                );
              if (
                !okr_Parent_id &&
                !taskDuplicate?.parent_task?.[0]?.projectID &&
                !taskDuplicate?.parent_task?.[0]?.template_id &&
                taskDuplicate?.parent_task?.[0]?.clientDetails?.id
              )
                navigate(
                  `/${RT.CLIENT}/${taskDuplicate?.parent_task?.[0]?.clientDetails?.id}/${RT.CLIENT_TASK_LIST}?status=todo&list_type=${TaskListTypeConst.Template}`,
                );
              else
                navigate(
                  `/${RT.TASK_LIST}?status=todo&pageNo=1&list_type=${
                    taskForLoggedUser && isMyTaskPresent ? 'my-task' : 'team-task'
                  }`,
                );
            }
          }
        },
        (err) => {
          makeAlert(err?.message);
        },
      );
    } catch (e) {
      makeAlert(e?.message ?? 'Server is busy');
      console.log(e);
      setIsSubmitted(false);
    } finally {
      errorTimeout();
    }
  };
  /***
   * For deleting the related task
   * @param index - The index of the task to be deleted
   */
  const handleRelatedTaskDelete = (index) => {
    // Get the task to be deleted
    const taskToBeDeleted = taskData.relatedTasks[index];
    // If task depends on other task, user can't delete it.
    const isTaskDependsOn = taskData.relatedTasks.some(
      (task) => task.preceding_task_id === taskToBeDeleted.id,
    );
    if (isTaskDependsOn) {
      makeAlert(TEMPLATE_ERROR_MSGS.TASK_DEPENDS_ON_OTHER_TASK);
      return;
    }

    const relatedTask = taskData.relatedTasks.splice(index, 1);

    console.log('handleRelatedTaskDelete ---- ', index, relatedTask);

    // Otherwise delete the task
    setTaskData((prev) => ({
      ...prev,
      relatedTasks: prev.relatedTask.filter((_, idx) => idx !== index),
    }));
  };

  // to update related task data in the list
  const updateRelatedTaskFromSingleView = (updatedValues, idx) => {
    setTaskData((prev) => {
      return {
        ...prev,
        relatedTasks: prev.relatedTasks.map((item, index) => {
          if (index === idx) {
            if (typeof updatedValues === 'function') {
              return updatedValues(item);
            }
            return { ...item, ...updatedValues };
          }
          return item;
        }),
      };
    });
  };

  // onCancel add task
  const onCancel = () => {
    setRelatedTaskData(taskState);
    setEditTaskIndex(null);
  };
  //on edit related task data ---
  const onEditRelatedTask = (index) => {
    setEditTaskIndex(index);
    setRelatedTaskData(taskData?.relatedTasks[index]);
  };

  //on toggle inactive press remove the selected verify users
  const handleVerifyUserToggle = (toggleValue) => {
    setNeedVerifyUser(toggleValue);
    // if (!toggleValue) {
    //   setTaskData({ ...taskData, verifying_users: [] });
    // }
  };
  return (
    <section>
      <ErrorComponent
        error={
          taskDetailsFetchError?.message ||
          verifyUserFetchError?.message ||
          assignUserFetchError?.message ||
          labelFetchError?.message
        }
      />
      <div
        className={`d-flex align-items-center px-4 justify-content-between pt-2 ${
          showHeaderAndFooter ? 'd-none' : ''
        }`}
      >
        <div className='d-flex align-items-center gap-3'>
          <span className='font-16'>Duplicate Task</span>
          <div className=''>{/* to show the template name */}</div>
        </div>
        <X onClick={handleClose} className='ptr' color='#87909E' />
      </div>
      <div>
        <div className={`modal_view_content `}>
          <TaskAddFormSection
            key={'task-description'}
            templateData={taskData}
            taskData={taskData}
            setTaskData={setTaskData}
            userList={userData}
            verifyUserList={verifyUserData}
            labelList={labelData}
            isSubmitted={isSubmitted}
            validationData={validationData}
            setNeedVerifyUser={handleVerifyUserToggle}
            needVerifyUser={needVerifyUser}
            isMenuLabelOn={isMenuLabelOn}
            isMenuSubTaskOn={isMenuSubTaskOn}
            // hasExist={hasExist}
            // clientList={clientList}
            // isProjectExistClient={clientDetails?.id ? true : false}
          />
          {taskData?.relatedTasks?.length > 0 && (
            <div className={`flex-1 template-container  mx-2`}>
              <TaskAddRelatedTaskList>
                <div>
                  <div className={`sub-title mb-1`}>Related Tasks</div>
                  {taskData?.relatedTasks?.map((relatedTask, idx) => {
                    return (
                      <SingleRelatedTaskPreview
                        allRelatedTask={taskData?.relatedTasks}
                        templateData={taskData}
                        key={idx}
                        index={idx}
                        setUserSearch={setUserSearch}
                        relatedTaskDetails={relatedTask}
                        onEdit={() => {
                          onEditRelatedTask(idx);
                        }}
                        onDelete={() => {
                          handleRelatedTaskDelete(idx);
                        }}
                        userList={userData}
                        onSubmit={(updatedValues) =>
                          updateRelatedTaskFromSingleView(updatedValues, idx)
                        }
                        setLabelSearch={setLabelSearch}
                        labelList={labelData}
                        onActiveForm={true}
                        onCancel={onCancel}
                        isRelatedTask={true}
                        isMenuLabelOn={isMenuLabelOn}
                        isMenuSubTaskOn={isMenuSubTaskOn}
                      />
                    );
                  })}
                  <div className='d-flex align-items-center w-100 gap-2 mb-4'>
                    <InlineRelatedTaskAdd
                      key={relatedTaskData?.id}
                      taskData={relatedTaskData}
                      setTaskData={setRelatedTaskData}
                      templateData={taskData}
                      onAdd={(setIsAddRowVisible, setIsAddRowActive) =>
                        handleRelatedTaskAdd(setIsAddRowVisible, setIsAddRowActive)
                      }
                      setLabelSearch={setLabelSearch}
                      userList={userData}
                      labelList={labelData}
                      onActiveForm={editTaskIndex !== null}
                      onCancel={onCancel}
                      isRelatedTask={true}
                      projectID={taskDuplicate?.parent_task?.[0]?.project_id}
                      isEdit={editTaskIndex !== null}
                      editExisting={editTaskIndex !== null}
                      setSearch={setUserSearch}
                      setEditTaskIndex={setEditTaskIndex}
                      setUserSearch={setUserSearch}
                      labelSearch={labelSearch}
                      userSearch={userSearch}
                      isUpdateButtonVisible
                      isMenuLabelOn={isMenuLabelOn}
                      isMenuSubTaskOn={isMenuSubTaskOn}
                    />
                  </div>
                </div>
              </TaskAddRelatedTaskList>
            </div>
          )}
        </div>
        <div className={`modal_footer ${showHeaderAndFooter && 'd-none'}`}>
          <div className='d-flex gap-2'>
            <Button.Container
              className='cancel-btn'
              isDisabled={isSubmitted}
              handleOk={handleClose}
            >
              <Cancel size={16} color='#87909E' />
              <Button.Title title='Cancel' />
            </Button.Container>
            <Button.Container type='submit' handleOk={handleSubmit} isDisabled={isSubmitted}>
              <Tick size={16} color='#FFF' />
              <Button.Title />
              <Button.Loading color='#FFF' isLoading={isSubmitted} size={20} />
            </Button.Container>
          </div>
        </div>{' '}
      </div>
    </section>
  );
}
