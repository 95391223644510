/* eslint-disable no-unused-vars */
import React from 'react';
import { Images } from '../Utils/Images';
import { LoadingIcon } from '../Utils/SvgIcons';

const CustomButton = ({ children, isDisabled, className, handleOk, id, ...rest }) => {
  const custom_class = className || 'default-btn';

  return (
    <button id={id} onClick={handleOk} disabled={isDisabled} className={custom_class} {...rest}>
      {children}
    </button>
  );
};

const ButtonTitle = ({ title, className }) => {
  const content = title || 'Create';
  const class_name = className || 'button_txt';
  // btn_txt

  return <div className={class_name}>{content}</div>;
};

const ButtonImage = ({ src, className }) => {
  const img_src = src || Images.Add;
  const class_name = className || 'btn_img';
  return <img className={class_name} src={img_src} />;
};

const ButtonLoading = ({
  isLoading = false,
  color = 'var(--bg-primary)',
  size = 25,
  className = '',
}) => {
  return <>{isLoading ? <LoadingIcon className={className} color={color} size={size} /> : null}</>;
};

export const Button = {
  Container: CustomButton,
  Title: ButtonTitle,
  Image: ButtonImage,
  Loading: ButtonLoading,
};
