import { useMutation } from '@tanstack/react-query';
import axios from 'axios';
import { useState } from 'react';
import { miscService } from '../_services';
import { getFileType, sanitizeFileName } from '../Utils';
/***
 * Pass progressId, presignedUploadUrl and file object as payload of mutate function.
 * Use progressMap to get the upload status(Will indicate from 0 to 100 in numbers) - Will be in the structure {passed_id:10}
 * For uploading file directly use createSignedUrlAndUploadFile(always wrap in try catch),
 * If only want to use the upload part and signed url are managed outside, then use mutation for that.
 */
export const useFileUploadMutation = ({ onSuccess = () => {} } = { onSuccess: () => {} }) => {
  const [progressMap, setProgressMap] = useState({});

  const mutation = useMutation({
    mutationFn: (args) =>
      axios.put(args.presignedUploadUrl, args.file, {
        headers: { 'Content-Type': 'multipart/form-data' },
        onUploadProgress: (ev) =>
          setProgressMap((prev) => ({
            ...prev,
            [args?.progressId ?? 0]: Math.round((ev.loaded * 100) / ev.total),
          })),
      }),
    onSuccess: (data, variable) => {
      // Remove the key from the progress
      const currentProgressId = variable?.progressId ?? 0;
      const { [currentProgressId]: temp, ...rest } = progressMap;
      setProgressMap(rest);
      onSuccess(data, variable, temp);
    },
  });
  /**
   * For creating signed url and upload file. Check progressMap[0] to get the progress, if haven't passed the progressId, which is the second param
   * @param {File} file - The file to be uploaded
   * @param {any} [progressId=0] - The id to be used to save the progress status, by default it is zero.
   * The progress status will be in progressMap[0] by default
   * @returns {Promise<string>} The name of the file
   * @throws error
   * @async
   *  */
  const createSignedUrlAndUploadFile = async (file, progressId = 0) => {
    const fileObj = {
      name: file.name.replace(/[^\w.-]|[\s&]/g, ''),
      sanitizeName: sanitizeFileName(file.name),
      fileType: getFileType(file.name.split('.').pop()),
      size: file.size,
      extension: file.name.split('.').pop(),
      file,
    };
    try {
      let signedData = await miscService.createSignedUploadUrl({
        type: fileObj.fileType, //--video,image,audio
        ext: fileObj.extension, //--jpg or mp4
        name: fileObj.sanitizeName,
      });

      if (signedData?.data?.signedUrl) {
        let signedUrl = signedData.data.signedUrl;
        let fileName = signedData.data.filename;

        await mutation.mutateAsync({
          presignedUploadUrl: signedUrl,
          file: fileObj.file,
          progressId: progressId,
        });
        return fileName;
      }
    } catch (err) {
      console.log(err);
      throw err;
    }
  };

  return { ...mutation, progressMap, createSignedUrlAndUploadFile };
};
