import { useMutation } from '@tanstack/react-query';
import { Buffer } from 'buffer';
import { Plus } from 'lucide-react';
import moment from 'moment';
import React, { useContext, useRef, useState } from 'react';
import { Cropper } from 'react-cropper';
import { useNavigate } from 'react-router-dom';
import ReactSelect from 'react-select';
import { Button } from '../_components';
import CircularAvatar from '../_components/Avatar/CircularAvatar';
import { CustomDropdownIndicator } from '../_components/SelectionDropDownIcon';
import { apiConstants } from '../_constants';
import { miscService, userService } from '../_services';
import { useUser } from '../Hook/useUser';
import {
  DropDownStyle,
  MY_TEAM_ERROR_MSG,
  MY_TEAM_REQUIRED_FIELD_MSG,
} from '../Myteam/MyTeamConstant';
import ErrorBlock from '../Template/SubComponents/ErrorBlock';
import {
  compressImageOnly,
  getFileType,
  GlobalContext,
  phoneNumberValidation,
  sanitizeFileName,
} from '../Utils';
import { LoadingIcon } from '../Utils/SvgIcons';

export const InvitationSignUpForm = ({ searchParams }) => {
  let { globalSettingsRefetch, currentTaskRefetch } = useContext(GlobalContext);
  // eslint-disable-next-line no-unused-vars
  const [employee, setEmployee] = useState({});
  const [compressed, setCompressed] = useState(null);
  const [image, setImage] = useState();

  const loggerUser = useUser();
  // eslint-disable-next-line no-unused-vars
  const [submitted, setSubmitted] = useState(false);
  const [isPictureLoading, setIsPictureLoading] = useState(false);
  const [isPreviewOpen, setIsPreviewOpen] = useState(false);
  const [file, setFile] = useState();

  const navigate = useNavigate();
  const { makeAlert } = useContext(GlobalContext);
  const imageRef = useRef(null);

  const encodedData = Array.from(searchParams.entries()).find(([key]) => key !== 'view')?.[0];

  let decodedData = {};
  if (encodedData) {
    decodedData = JSON.parse(decodeURIComponent(encodedData));
  }

  let today = moment().format('YYYY-MM-DD');

  const genderList = [
    { label: 'Male', value: 1 },
    { label: 'Female', value: 2 },
    { label: 'Others', value: 3 },
  ];

  const handleChange = (event) => {
    const { name, value } = event.target;

    setEmployee((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleUserUpdate = async (data) => {
    try {
      let response = await userService.updateUserProfile({
        ...data,
        company_id: decodedData.company_id ?? loggerUser?.company_id,
        email_id: decodedData.email_id ?? loggerUser.email_id,
      });
      return response;
    } catch (err) {
      console.error(err, 'Error during invite user request'); // Log the error for debugging
      throw err; // Rethrow the error to ensure React Query's onError is triggered
    }
  };

  const {
    mutate: handleMutate,
    // isPending,
    // isError,
    // error,
    // isPaused, //-- true when network offline mode
  } = useMutation({
    mutationFn: handleUserUpdate,
    mutationKey: ['user-details-update'],
    onSuccess: (data) => {
      console.log(data, 'responsess');
      makeAlert('User Details added.');
      globalSettingsRefetch();
      currentTaskRefetch();
      // getPermissions();
      navigate('/');
    },
    onError: (err) => {
      if (err) {
        makeAlert(err?.message || 'Registration failed');
      }
    },
  });

  const handleSubmit = async (event) => {
    event.preventDefault();
    setSubmitted(true);

    const isPhoneValid = employee?.phone_no ? phoneNumberValidation(employee?.phone_no) : true;
    if (!isPhoneValid) {
      makeAlert(MY_TEAM_ERROR_MSG.PHONE_NUMBER);
      return;
    }
    if (
      // !employee?.email_id?.toString()?.trim() ||
      // !employee?.password?.toString()?.trim() ||
      // !employee?.username?.toString()?.trim() ||
      !employee?.first_name?.toString()?.trim() ||
      !employee?.last_name?.toString()?.trim() ||
      !employee?.gender
    ) {
      return null;
    }
    const dateOfBirth = new Date(employee?.dob).getTime();

    let payload = {
      ...employee,
      dob: dateOfBirth,
      first_name: employee.first_name.toString().trim(),
      last_name: employee.last_name.toString().trim(),
      // username: rest.username.toString().trim(),
      // password: password.toString().trim(),
    };

    handleMutate(payload);
  };

  //function to change url to file
  function dataUrlToFile(dataUrl, filename) {
    const arr = dataUrl.split(',');
    if (arr.length < 2) {
      return undefined;
    }
    const mimeArr = arr[0].match(/:(.*?);/);
    if (!mimeArr || mimeArr.length < 2) {
      return undefined;
    }
    const mime = mimeArr[1];
    const buff = Buffer.from(arr[1], 'base64');
    return new File([buff], filename, { type: mime });
  }
  const handleImageUpload = async () => {
    setIsPictureLoading(true);
    const data = dataUrlToFile(image?.imageUrl, image?.imageFile?.name);
    const { compressedData, uploadedData } = await onFileSelect(data); // handles the cropped image
    const img = uploadedData.pop();

    setEmployee((prev) => ({
      ...prev,
      image_url: img,
      compressed_image_url: compressedData?.fileNameCompressed ?? null,
    }));
    setCompressed(compressedData?.compressedImageBlobUrl);
    setIsPictureLoading(false);
    setIsPreviewOpen(false);
  };
  const onFileSelect = async (fileData) => {
    let cloneSelectedFiles = [];
    cloneSelectedFiles.push({
      name: fileData.name.replace(/[^\w.-]|[\s&]/g, ''),
      sanitizeName: sanitizeFileName(fileData.name),
      fileType: getFileType(fileData.name.split('.').pop()),
      size: fileData.size,
      extension: fileData.name.split('.').pop(),
      file: fileData,
    });
    const response = await handleFileUpload(cloneSelectedFiles);
    return response;
  };
  const handleFileUpload = async (data) => {
    if (!data.length) {
      return;
    }
    const uploadedData = [];
    const compressedData = { compressedImageBlobUrl: '', fileNameCompressed: '' };
    for (let fileObj of data) {
      try {
        if (fileObj.fileType === 'image') {
          let { compressedFile, compressedImageBlobUrl } = await compressImageOnly(fileObj.file);

          let signedDataCompressed = await miscService.createSignedUploadUrl({
            type: fileObj.fileType, //--video,image,audio
            ext: fileObj.extension, //--jpg or mp4
            name: `${fileObj.sanitizeName}_compressed`,
          });

          if (signedDataCompressed?.data?.signedUrl) {
            let signedUrlCompressed = signedDataCompressed.data.signedUrl;
            let fileNameCompressed = signedDataCompressed.data.filename;

            // Upload the compressed file
            await fetch(signedUrlCompressed, {
              method: 'PUT',
              headers: { 'Content-Type': 'multipart/form-data' },
              body: compressedFile,
            }).then((response) => response.text());

            compressedData.fileNameCompressed = fileNameCompressed;
            compressedData.compressedImageBlobUrl = compressedImageBlobUrl;

            // compressedBlobData.push(compressedImageBlobUrl);
          }
        }

        let signedData = await miscService.createSignedUploadUrl({
          type: fileObj.fileType, //--video,image,audio
          ext: fileObj.extension, //--jpg or mp4
          name: fileObj.sanitizeName,
        });
        if (signedData?.data?.signedUrl) {
          let signedUrl = signedData.data.signedUrl;
          let fileName = signedData.data.filename;
          await fetch(signedUrl, {
            method: 'PUT',
            headers: { 'Content-Type': 'multipart/form-data' },
            body: fileObj.file,
          }).then((response) => response.text());
          uploadedData.push(fileName);
        }
      } catch (error) {
        console.log({ error });
      }
    }
    return { uploadedData, compressedData };
  };

  ///function to change image
  const changePicture = (event) => {
    const { files } = event.target;
    setFile(URL.createObjectURL(event?.target?.files[0]));
    setImage((prev) => ({
      ...prev,
      imageFile: files[0],
    }));
    setIsPreviewOpen(true);
  };

  //use to crop image choose
  const onCrop = () => {
    const imageElement = imageRef?.current;
    const cropper = imageElement?.cropper;
    setImage((prev) => ({
      ...prev,
      imageUrl: cropper.getCroppedCanvas().toDataURL(),
    }));
  };

  let imageUrl = compressed
    ? compressed
    : employee?.compressed_image_url
    ? apiConstants.imgUrlS3 + employee.compressed_image_url
    : employee?.image_url
    ? apiConstants.imgUrlS3 + employee?.image_url
    : '';

  return (
    <div className='invite-accept-body'>
      <div className='invite-sign-up-page'>
        <form action='' className='d-flex flex-column gap-2 ' onSubmit={handleSubmit}>
          <div className='invite-greeting'>Add user</div>
          <div>
            <div className='position-relative cursor-pointer' style={{ width: 'fit-content' }}>
              <label
                className='cursor-pointer'
                htmlFor='image_upload'
                onClick={() => {
                  setIsPictureLoading(true);
                  // Set a timeout to hide the loading icon after the file dialog likely opens
                  setTimeout(() => {
                    setIsPictureLoading(false); // Hide loading icon after a brief delay
                  }, 1500); // Adjust this delay as needed
                }}
              >
                <CircularAvatar isImgStacked size={75} source={imageUrl} htmlFor='image_upload' />
                <div className='add-plus-btn-bg'>
                  <Plus color='var(--bg-primary)' size={17} />
                </div>
                <div style={{ position: 'absolute', top: 25, left: 25 }}>
                  {isPictureLoading && <LoadingIcon size={25} />}
                </div>
              </label>
              <input
                type='file'
                name='image_url'
                id='image_upload'
                hidden
                onChange={changePicture}
                accept='image/*'
              />
            </div>
            <div className='row mb-2'>
              <div className='col-6 padding-right-5'>
                <label className='form-input-label'>
                  First Name<span className='field-required'> *</span>
                </label>
                <input
                  type='text'
                  className='input-box'
                  name='first_name'
                  value={employee?.first_name}
                  placeholder='Employee name'
                  onChange={(event) => {
                    handleChange(event);
                  }}
                />
                <ErrorBlock
                  errorMsg={MY_TEAM_REQUIRED_FIELD_MSG.FIRST_NAME}
                  hasError={submitted && !employee?.first_name?.toString()?.trim()}
                />
              </div>
              <div className='col-6 padding-left-5'>
                <label className='form-input-label'>
                  Last Name<span className='field-required'> *</span>
                </label>
                <input
                  type='text'
                  className='input-box'
                  name='last_name'
                  value={employee?.last_name}
                  placeholder='Last name '
                  onChange={(event) => {
                    handleChange(event);
                  }}
                />

                <ErrorBlock
                  errorMsg={MY_TEAM_REQUIRED_FIELD_MSG.LAST_NAME}
                  hasError={submitted && !employee?.last_name?.toString()?.trim()}
                />
              </div>
            </div>
            <div className='row mb-2'>
              <div className='col'>
                <div className='form-group'>
                  <label htmlFor='address' className='form-input-label'>
                    Address
                  </label>
                  <textarea
                    rows={3}
                    className='form-control box-focus fz-13px'
                    id='address'
                    name='address'
                    value={employee?.address}
                    placeholder='Enter address '
                    onChange={(event) => {
                      handleChange(event);
                    }}
                  />
                </div>
              </div>
            </div>
            <div className='row mb-2'>
              <div className='col padding-right-5'>
                <div className='row'>
                  <div className='col-6 padding-right-5'>
                    <label className='form-input-label'>
                      Gender<span className='field-required'> *</span>
                    </label>
                    <ReactSelect
                      styles={DropDownStyle}
                      components={{ DropdownIndicator: CustomDropdownIndicator }}
                      options={genderList}
                      getOptionLabel={(option) => {
                        return option?.label;
                      }}
                      getOptionValue={(option) => {
                        return option?.value;
                      }}
                      onChange={(selectedOption) => {
                        handleChange({
                          target: {
                            name: 'gender',
                            value: selectedOption.value,
                          },
                        });
                      }}
                      value={
                        genderList.find((option) => option?.value === employee?.gender) || null
                      }
                    />
                    <ErrorBlock
                      errorMsg={MY_TEAM_REQUIRED_FIELD_MSG.GENDER}
                      hasError={submitted && !employee?.gender}
                    />
                  </div>

                  <div className='col-6 padding-left-5'>
                    <label className='form-input-label dob-text'>Date of Birth</label>
                    <input
                      type='date'
                      className='input-box'
                      max={today}
                      name='dob'
                      min={0}
                      style={{ padding: '4px ' }}
                      value={employee?.dob}
                      onChange={(event) => {
                        handleChange(event);
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className=' col padding-left-5'>
                <label className='form-input-label'>Phone Number</label>
                <input
                  type='text'
                  className='input-box'
                  name='phone_no'
                  value={employee?.phone_no}
                  placeholder='Enter phone number '
                  onChange={(event) => {
                    handleChange(event);
                  }}
                />
              </div>
            </div>
            {/* <div className='row mb-2'>
              <div>
                <label className='form-input-label'>Email</label>
                <input
                  type='email'
                  className='input-box'
                  name='email_id'
                  value={employee?.email_id}
                  placeholder='Enter email address '
                  onChange={(event) => {
                    handleChange(event);
                  }}
                />
                <ErrorBlock
                  errorMsg={'Email ID required'}
                  hasError={submitted && !employee?.email_id?.toString()?.trim()}
                />
              </div>

              <div className='col padding-left-5'>
                <label className='form-input-label'>
                  Username <span className='field-required'> *</span>
                </label>
                <input
                  type='text'
                  className='input-box'
                  name='username'
                  value={employee?.username}
                  placeholder='Username'
                  onChange={(event) => {
                    handleChange(event);
                  }}
                />
                <ErrorBlock
                  errorMsg={MY_TEAM_REQUIRED_FIELD_MSG.USERNAME}
                  hasError={submitted && !employee?.username?.toString()?.trim()}
                />
              </div>
            </div> */}
            {/* <div className='row mb-2 '>
              <div className='col-6  padding-right-5'>
                <label htmlFor='password' className='form-input-label'>
                  Password <span className='field-required'> *</span>
                </label>
                <div className='password-block'>
                  <div style={{ position: 'relative' }}>
                    <input
                      type={isPasswordVisible ? 'text' : 'password'}
                      className='input-box'
                      autoComplete='off'
                      id='password'
                      name='password'
                      placeholder='Password'
                      value={employee?.password}
                      onChange={(event) => {
                        handleChange(event);
                      }}
                    />
                    <a
                      className='password-icon'
                      onClick={() => setIsPasswordVisible((prev) => !prev)}
                    >
                      {isPasswordVisible ? (
                        <Eye width={18} height={18} />
                      ) : (
                        <EyeOff width={18} height={18} />
                      )}
                    </a>
                  </div>
                  <ErrorBlock
                    errorMsg={MY_TEAM_REQUIRED_FIELD_MSG.PASSWORD}
                    hasError={submitted && !employee?.password?.toString()?.trim()}
                  />
                </div>
              </div>
            </div> */}
          </div>
          <div className='invite-border-bottom mt-2' />

          <div className='client-add-button-container '>
            {/* <Button.Container
              className={'outline-cancel-button'}
              type='reset'
              handleOk={() => {
                searchParams.set('view', 'signIn');
                setSearchParams(searchParams);
              }}
            >
              <Button.Title title={'Cancel'} />
            </Button.Container> */}
            <Button.Container
              type='submit'
              className={'blue-btn'}
              // handleOk={() => {
              //   searchParams.delete('home');
              //   searchParams.delete('signIn');
              //   searchParams.set('signUp', true);
              //   setSearchParams(searchParams);
              // }}
            >
              <Button.Title title={'Continue'} />
              <Button.Loading />
            </Button.Container>
          </div>
          {isPreviewOpen && (
            <div className='image-preview-container'>
              <div className='image-preview-card'>
                <div className='image-preview'>
                  <Cropper
                    crop={onCrop}
                    src={file}
                    initialAspectRatio={1}
                    aspectRatio={1}
                    ref={imageRef}
                    style={{ maxHeight: '50vh' }}
                  />
                </div>
                <div className='image-preview-button-div'>
                  <a className='btn image-preview-button' onClick={handleImageUpload}>
                    Save {isPictureLoading && <LoadingIcon size={30} />}
                  </a>
                  <a onClick={() => setIsPreviewOpen(false)} className='btn image-preview-button'>
                    Cancel
                  </a>
                </div>
              </div>
            </div>
          )}
        </form>
      </div>
    </div>
  );
};
