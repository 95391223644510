/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import './ChatInterface.css';

import { useInfiniteQuery, useMutation } from '@tanstack/react-query';
import { aiService } from '../_services/ai.service';
import useObserver from '../Hook/useObserver';

const ChatInterface = () => {
  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState('');

  const getData = async (question) => {
    let response = await aiService.generateResponse({ message: question });
    return response.data;
  };

  const {
    data: aiChatData,
    fetchNextPage,
    hasNextPage,
    refetch,
    isLoading,
    isFetchingNextPage,
  } = useInfiniteQuery({
    queryKey: ['chat_list'],
    queryFn: ({ pageParam = 1 }) =>
      aiService.aiChatList({
        pageVo: {
          noOfItems: 10,
          pageNo: pageParam,
        },
      }),
    getNextPageParam: (lastPage) => {
      return parseInt(lastPage.data.page) < parseInt(lastPage.data.pages)
        ? parseInt(lastPage.data.page) + 1
        : undefined;
    },
  });

  const { observerRef } = useObserver({
    onIntersection: () => {
      if (hasNextPage && !isFetchingNextPage) {
        fetchNextPage();
      }
    },
    dependency: [hasNextPage],
  });

  // --react query for mutation function
  const {
    mutateAsync: handleMutate,
    isPending,
    isError,
    error,
    isPaused, //-- true when network offline mode
  } = useMutation({
    mutationFn: getData,
    mutationKey: ['response'],
    onSuccess: (data) => {
      // Take the first element of the items array
      const firstItem = items[0];

      // Combine firstItem with the response data
      const updatedFirstItem = { ...firstItem, response: data };

      // Set the updated first item at the beginning of the items array
      setItems((prevItems) => [updatedFirstItem, ...prevItems.slice(1)]); // Maintain other items except the first

      // Debugging log for successful response
    },
  });

  const handleSend = () => {
    if (input.trim()) {
      // Check if there are any items to modify
      if (items.length > 0) {
        const lastItem = items[items.length - 1];

        // Modify the last item and push it to the state
        const updatedLastItem = { ...lastItem, response: null, message: input };

        // Prepend the updated item to the beginning of the items array
        setItems((prevItems) => [updatedLastItem, ...prevItems]); // Add updated item to the start
      }

      // Call the mutation to handle AI response (assuming this is async)
      handleMutate(input);

      // Clear the input field
      setInput('');
    }
  };

  const [items, setItems] = useState([]);

  React.useEffect(() => {
    if (aiChatData?.pages) {
      const allChatItems = aiChatData.pages.flatMap((page) => page.data.rows);
      setItems(allChatItems); // Save the computed items in the state
    }
  }, [aiChatData]);

  // const allItems = React.useMemo(() => {
  //   if (!aiChatData?.pages) return [];
  //   return aiChatData.pages.flatMap((page) => page.data.rows);
  // }, [aiChatData]);

  return (
    <div className='chat-container'>
      <div className='chat-box'>
        {isPending && <div className='message-bubble bot-message'>Generating...</div>}
        {items.map((message, index) => (
          <>
            {console.log('HERE-->22', message?.response?.aiResponse)}
            {!message?.response?.is_list_based && <div>{message?.response?.aiResponse}</div>}
            <div style={{ background: 'red' }}>{message?.message}</div>
          </>
        ))}
        <div ref={observerRef} style={{ width: '100%', height: 130, background: 'red' }}>
          LOADING
        </div>
      </div>
      <div className='input-area'>
        <input
          type='text'
          className='message-input'
          value={input}
          onChange={(e) => setInput(e.target.value)}
          placeholder='Enter your message...'
        />
        <button className='send-button' onClick={handleSend}>
          Send
        </button>
      </div>
    </div>
  );
};

export default ChatInterface;
