import { z } from 'zod';
import { typeConstants } from '../_constants';
import {
  manualProgressType,
  objectiveType,
  okrValidationConstant,
  progressType,
} from './okrConstants';

export const okrObjectiveSchema = z
  .object({
    // Assignee id should be greater than 0
    assignee_id: z
      .number({
        required_error: okrValidationConstant.ASSIGNEE_ID_REQUIRED,
        invalid_type_error: okrValidationConstant.INVALID_ASSIGNEE_ID,
        coerce: true,
      })
      .gt(0, { message: okrValidationConstant.INVALID_ASSIGNEE_ID }),
    // Name should be required with more than 1 character
    name: z
      .string({
        required_error: okrValidationConstant.NAME_REQUIRED,
        invalid_type_error: okrValidationConstant.INVALID_NAME,
      })
      .min(1, { message: okrValidationConstant.INVALID_NAME }),
    note: z
      .string({
        invalid_type_error: okrValidationConstant.INVALID_DESCRIPTION,
      })
      .optional(),
    interval: z.number({
      required_error: okrValidationConstant.INTERVAL_REQUIRED,
      invalid_type_error: okrValidationConstant.INVALID_INTERVAL,
      coerce: true,
    }),
    // Expected hours,  start date & task end date - Number greater than 0(dates are in milliseconds)
    task_start_date: z
      .number({
        required_error: okrValidationConstant.START_DATE_REQUIRED,
        invalid_type_error: okrValidationConstant.INVALID_START_DATE,
        coerce: true,
      })
      .gt(0),
    task_end_date: z
      .number({
        required_error: okrValidationConstant.END_DATE_REQUIRED,
        coerce: true,
      })
      .gt(0),
    visibility_type: z.number({
      required_error: okrValidationConstant.VISIBILITY_REQUIRED,
      invalid_type_error: okrValidationConstant.INVALID_VISIBILITY,
      coerce: true,
    }),
    objective_type: z.number({
      required_error: okrValidationConstant.TYPE_REQUIRED,
      invalid_type_error: okrValidationConstant.TYPE_REQUIRED,
      coerce: true,
    }),

    visibility_team: z.array(z.any()).optional(),

    okr_progress_type: z.number({
      required_error: okrValidationConstant.MEASUREMENT_REQUIRED,
      invalid_type_error: okrValidationConstant.INVALID_MEASUREMENT,
      coerce: true,
    }),

    manual_progress_type: z.number({
      required_error: okrValidationConstant.RESULT_TYPE,
      invalid_type_error: okrValidationConstant.INVALID_RESULT_TYPE,
      coerce: true,
    }),
    progress_measure_start: z
      .number({
        required_error: okrValidationConstant.RESULT_CURRENT,
        invalid_type_error: okrValidationConstant.INVALID_RESULT_CURRENT,
        coerce: true,
      })
      .optional(),
    progress_measure_end: z
      .number({
        required_error: okrValidationConstant.RESULT_TARGET,
        invalid_type_error: okrValidationConstant.INVALID_RESULT_TARGET,
        coerce: true,
      })
      .optional(),
    id: z.string().optional(), // Assuming 'id' is a string and optional

    manual_current_progress: z
      .number({
        invalid_type_error: okrValidationConstant.INVALID_CURRENT_PROGRESS,
        coerce: true,
      })
      .optional(),
  })

  // Validate that task_end_date is greater than task_start_date
  .refine((data) => data.task_end_date >= data.task_start_date, {
    message: okrValidationConstant.END_DATE_MUST_BE_GREATER_THAN_START_DATE,
    path: ['task_end_date'],
  })
  .refine(
    (data) => {
      return (
        data.visibility_type !== 1 || (data.visibility_team && data.visibility_team.length > 0)
      );
    },
    { message: okrValidationConstant.VISIBILITY_TEAM_REQUIRED, path: ['visibility_team'] },
  )
  // validate if team based okr then should select team
  .refine(
    (data) => {
      // Check if objective_type is 'team' and visibility_team is selected
      if (parseInt(data.objective_type) === objectiveType.team) {
        return data.visibility_team && data.visibility_team.length > 0;
      }
      // No need to check if objective_type is not 'team'
      return true;
    },
    { message: okrValidationConstant.VISIBILITY_TEAM_REQUIRED, path: ['visibility_team'] },
  )
  // Validate that progress end is greater than start
  .refine(
    (data) => {
      return (
        parseInt(data.progress_measure_end) >= parseInt(data.progress_measure_start) ||
        parseInt(data?.okr_progress_type) !== progressType.manually
      );
    },
    {
      message: okrValidationConstant.PROGRESS_START_END_MISS_MATCH,
      path: ['okr_progress_type'],
    },
  )
  .refine(
    (data) => {
      return (
        data?.manual_progress_type !== manualProgressType.binary ||
        (data.progress_measure_end !== null && !isNaN(data.progress_measure_end))
      );
    },
    { message: okrValidationConstant.RESULT_BINARY },
  )
  .refine(
    (data) => {
      return (
        !data.id ||
        parseInt(data?.okr_progress_type) !== progressType.manually ||
        (data.progress_measure_end >= data.manual_current_progress &&
          data.progress_measure_start <= data.manual_current_progress)
      );
    },
    { message: okrValidationConstant.INCORRECT_PROGRESS, path: ['manual_current_progress'] },
  );

export const okrChildSchema = z
  .object({
    // Assignee id should be greater than 0
    assignee_id: z
      .number({
        required_error: okrValidationConstant.ASSIGNEE_ID_REQUIRED,
        invalid_type_error: okrValidationConstant.INVALID_ASSIGNEE_ID,
        coerce: true,
      })
      .gt(0, { message: okrValidationConstant.INVALID_ASSIGNEE_ID }),
    // Name should be required with more than 1 character
    name: z
      .string({
        required_error: okrValidationConstant.NAME_REQUIRED,
        invalid_type_error: okrValidationConstant.INVALID_NAME,
      })
      .min(1, { message: okrValidationConstant.INVALID_NAME }),
    note: z
      .string({
        invalid_type_error: okrValidationConstant.INVALID_DESCRIPTION,
      })
      .optional(),
    interval: z.number({
      required_error: okrValidationConstant.INTERVAL_REQUIRED,
      invalid_type_error: okrValidationConstant.INVALID_INTERVAL,
      coerce: true,
    }),
    // Expected hours,  start date & task end date - Number greater than 0(dates are in milliseconds)
    task_start_date: z
      .number({
        required_error: okrValidationConstant.START_DATE_REQUIRED,
        invalid_type_error: okrValidationConstant.INVALID_START_DATE,
        coerce: true,
      })
      .gt(0),
    task_end_date: z
      .number({
        required_error: okrValidationConstant.END_DATE_REQUIRED,
        coerce: true,
      })
      .gt(0),
    visibility_team: z.array(z.any()).optional(),

    okr_progress_type: z.number({
      required_error: okrValidationConstant.MEASUREMENT_REQUIRED,
      invalid_type_error: okrValidationConstant.INVALID_MEASUREMENT,
      coerce: true,
    }),
    manual_progress_type: z.number({
      required_error: okrValidationConstant.RESULT_TYPE,
      invalid_type_error: okrValidationConstant.INVALID_RESULT_TYPE,
      coerce: true,
    }),
    progress_measure_start: z
      .number({
        required_error: okrValidationConstant.RESULT_CURRENT,
        invalid_type_error: okrValidationConstant.INVALID_RESULT_CURRENT,
        coerce: true,
      })
      .optional(),
    progress_measure_end: z
      .number({
        required_error: okrValidationConstant.RESULT_TARGET,
        invalid_type_error: okrValidationConstant.INVALID_RESULT_TARGET,
        coerce: true,
      })
      .optional(),
    id: z.string().optional(), // Assuming 'id' is a string and optional
    objective_type: z.number({
      required_error: okrValidationConstant.TYPE_REQUIRED,
      invalid_type_error: okrValidationConstant.TYPE_REQUIRED,
      coerce: true,
    }),

    manual_current_progress: z
      .number({
        invalid_type_error: okrValidationConstant.INVALID_CURRENT_PROGRESS,
        coerce: true,
      })
      .optional(),
    type: z.number({
      invalid_type_error: okrValidationConstant.TYPE_REQUIRED,
      coerce: true,
    }),
  })
  .refine(
    (data) => {
      return (
        data?.objective_type !== 0 ||
        data.visibility_team ||
        parseInt(data?.type) === typeConstants.KEY_RESULT
      );
    },
    { message: okrValidationConstant.VISIBILITY_TEAM_REQUIRED },
  )
  // validate if team based okr then should select team
  .refine(
    (data) => {
      // Check if objective_type is 'team' and visibility_team is selected
      if (parseInt(data.objective_type) === objectiveType.team) {
        return data.visibility_team && data.visibility_team.length > 0;
      }
      // No need to check if objective_type is not 'team'
      return true;
    },
    { message: okrValidationConstant.VISIBILITY_TEAM_REQUIRED, path: ['visibility_team'] },
  )
  // Validate that progress end is greater than start
  .refine(
    (data) => {
      console.log('aswinnnn');

      return (
        parseInt(data.progress_measure_end) >= parseInt(data.progress_measure_start) ||
        parseInt(data?.okr_progress_type) !== progressType.manually
      );
    },
    {
      message: okrValidationConstant.PROGRESS_START_END_MISS_MATCH,
      path: ['okr_progress_type'],
    },
  )
  // Validate that task_end_date is greater than task_start_date
  .refine((data) => data.task_end_date >= data.task_start_date, {
    message: okrValidationConstant.END_DATE_MUST_BE_GREATER_THAN_START_DATE,
    path: ['task_end_date'],
  })

  .refine(
    (data) => {
      console.log('aswinnnn');

      return (
        data?.manual_progress_type !== manualProgressType.binary ||
        (data.progress_measure_end !== null && !isNaN(data.progress_measure_end))
      );
    },
    { message: okrValidationConstant.RESULT_BINARY },
  )
  .refine(
    (data) => {
      console.log('aswinnnn');

      return (
        !data.id ||
        parseInt(data?.okr_progress_type) !== progressType.manually ||
        // parseInt(data?.manual_progress_type) !== manualProgressType.binary ||
        (data.progress_measure_end >= data.manual_current_progress &&
          data.progress_measure_start <= data.manual_current_progress)
      );
    },
    { message: okrValidationConstant.INCORRECT_PROGRESS, path: ['manual_current_progress'] },
  );
