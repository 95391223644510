/* eslint-disable no-unused-vars */
import {
  Calendar,
  CircleUserRound,
  Clock,
  Hash,
  ListTodo,
  Shapes,
  SquareCheck,
} from 'lucide-react';
import React, { useState } from 'react';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import ReactSelect from 'react-select';
import { Heading } from '../Misc';
import DatePicker from '../_components/DatePicker';
// import DatePicker from './DatePicker';

export const TaskReport = () => {
  const [selectedDateRange, setSelectedDateRange] = useState({
    startDate: new Date(),
    endDate: null,
  });

  const handleDateChange = (range) => {
    setSelectedDateRange(range);
  };

  const initialStartDate = new Date();
  initialStartDate.setHours(0, 0, 0, 0);
  const initialEndDate = new Date();
  initialEndDate.setHours(23, 59, 59, 999);

  return (
    <div className='p-3'>
      <Heading title={'Task Report'} />
      <div className='mt-4 d-flex gap-2'>
        {/* <div className={'search_contain ' + (searchKey ? 'active' : '')} style={{ flex: 1 }}>
          <Search size={20} color='black' />
          <input type='text' className='bodr_none' />
        </div> */}

        {/* task selection  */}
        <div style={{ width: 'fit-content' }}>
          <ReactSelect
            styles={{
              control: (provided, state) => ({
                ...provided,
                background: '#eeeeee',
                minHeight: '10px',
                border: 'none',
                fontSize: '13px',
                boxShadow: state.isFocused ? 'none' : provided.boxShadow,
              }),
              menu: (provided) => ({
                ...provided,
                minWidth: '250px',
                overflowWrap: 'break-word',
                wordBreak: 'break-word',
                whiteSpace: 'normal',
                overflow: 'auto',
              }),
              indicatorSeparator: (provided) => ({
                ...provided,
                display: 'none',
              }),
            }}
            placeholder='Task'
            getOptionLabel={(option) => `${option.first_name} ${option.last_name}`}
            getOptionValue={(option) => option.id}
          />
        </div>
        {/* end of task selection  */}
        {/* project selection  */}
        <div style={{ width: 'fit-content' }}>
          <ReactSelect
            styles={{
              control: (provided, state) => ({
                ...provided,
                background: '#eeeeee',
                minHeight: '10px',
                border: 'none',
                fontSize: '13px',
                boxShadow: state.isFocused ? 'none' : provided.boxShadow,
              }),
              menu: (provided) => ({
                ...provided,
                minWidth: '250px',
                overflowWrap: 'break-word',
                wordBreak: 'break-word',
                whiteSpace: 'normal',
                overflow: 'auto',
              }),
              indicatorSeparator: (provided) => ({
                ...provided,
                display: 'none',
              }),
            }}
            placeholder='Project'
            getOptionLabel={(option) => `${option.first_name} ${option.last_name}`}
            getOptionValue={(option) => option.id}
          />
        </div>
        {/* end of project selection  */}
        <div style={{ width: 'fit-content' }}>
          <ReactSelect
            styles={{
              control: (provided, state) => ({
                ...provided,
                background: '#eeeeee',
                minHeight: '10px',
                border: 'none',
                fontSize: '13px',
                boxShadow: state.isFocused ? 'none' : provided.boxShadow,
              }),
              menu: (provided) => ({
                ...provided,
                minWidth: '250px',
                overflowWrap: 'break-word',
                wordBreak: 'break-word',
                whiteSpace: 'normal',
                overflow: 'auto',
              }),
              indicatorSeparator: (provided) => ({
                ...provided,
                display: 'none',
              }),
            }}
            placeholder='Member'
            getOptionLabel={(option) => `${option.first_name} ${option.last_name}`}
            getOptionValue={(option) => option.id}
          />
        </div>

        <DatePicker
          onDateChange={handleDateChange}
          initialEndDate={initialEndDate}
          initialStartDate={initialStartDate}
        />
      </div>
      <section className='mt-2'>
        <div className='table_outer'>
          <table className='table table-hover tbl mb-0'>
            <thead>
              <tr>
                <th style={{ width: 'fit-content' }}>
                  <Hash size={20} />
                  Sl.No
                </th>
                <th>
                  <Calendar size={20} />
                  Date
                </th>
                <th>
                  <SquareCheck size={20} />
                  Action
                </th>
                <th>
                  <CircleUserRound size={20} />
                  Member
                </th>
                <th>
                  <Clock size={20} />
                  Time
                </th>
                <th>
                  <ListTodo size={20} />
                  Tasks
                </th>
                <th>
                  <Shapes size={20} />
                  Difference
                </th>
              </tr>
            </thead>
            <tbody style={{ verticalAlign: 'middle' }}>{/* {tableData} */}</tbody>
          </table>
        </div>
      </section>
    </div>
  );
};
